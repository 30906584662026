import {
  FETCH_EXPENSES_PROJECT,
  FETCH_EXPENSES_PROJECT_SUCCESS,
  FETCH_EXPENSES_PROJECT_FAILED,
  FETCH_FILTER_YEAR_EXPENSE_PRJ,
  FETCH_FILTER_YEAR_EXPENSE_PRJ_SUCCESS,
  FETCH_FILTER_YEAR_EXPENSE_PRJ_FAILED,
  FETCH_EXPENSE_CATEGORY,
  FETCH_EXPENSE_CATEGORY_SUCCESS,
  FETCH_EXPENSE_CATEGORY_FAILED,
  FETCH_CREATE_EXPENSE_PRJ,
  FETCH_CREATE_EXPENSE_PRJ_SUCCESS,
  FETCH_CREATE_EXPENSE_PRJ_FAILED,
  FILTER_PROJECT_EXPENSES_REQUEST,
  FILTER_PROJECT_EXPENSES_SUCCESS,
  FILTER_PROJECT_EXPENSES_FAILED,
  FETCH_CUSTOMER,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAILED,
  FETCH_PAYMENT_MODE,
  FETCH_PAYMENT_MODE_SUCCESS,
  FETCH_PAYMENT_MODE_FAILED,
  FETCH_TAX,
  FETCH_TAX_SUCCESS,
  FETCH_TAX_FAILED,
} from "./types";

//fetch data expenses
export const fetchExpensesProject = (data) => {
  return {
    type: FETCH_EXPENSES_PROJECT,
    payload: data,
  };
};

export const fetchExpensesProjectSuccess = (data) => {
  return {
    type: FETCH_EXPENSES_PROJECT_SUCCESS,
    payload: data,
  };
};

export const fetchExpensesProjectFailed = () => {
  return {
    type: FETCH_EXPENSES_PROJECT_FAILED,
  };
};

//filter year

export const fetchFilterYearExpensePrj = (data) => {
  return {
    type: FETCH_FILTER_YEAR_EXPENSE_PRJ,
    payload: data,
  };
};

export const fetchFilterYearExpensePrjSuccess = (data) => {
  return {
    type: FETCH_FILTER_YEAR_EXPENSE_PRJ_SUCCESS,
    payload: data,
  };
};

export const fetchFilterYearExpensePrjFailed = () => {
  return {
    type: FETCH_FILTER_YEAR_EXPENSE_PRJ_FAILED,
  };
};

// fetch expenses category
export const fetchExpenseCategory = (data) => {
  return {
    type: FETCH_EXPENSE_CATEGORY,
    payload: data,
  };
};

export const fetchExpenseCategorySuccess = (data) => {
  return {
    type: FETCH_EXPENSE_CATEGORY_SUCCESS,
    payload: data,
  };
};

export const fetchExpenseCategoryFailed = () => {
  return {
    type: FETCH_EXPENSE_CATEGORY_FAILED,
  };
};

//create expense
export const fetchCreateExpensePrj = (data) => {
  return {
    type: FETCH_CREATE_EXPENSE_PRJ,
    payload: data,
  };
};

export const fetchCreateExpensePrjSuccess = (data) => {
  return {
    type: FETCH_CREATE_EXPENSE_PRJ_SUCCESS,
    payload: data,
  };
};

export const fetchCreateExpensePrjFailed = () => {
  return {
    type: FETCH_CREATE_EXPENSE_PRJ_FAILED,
  };
};

//Filter project expenses
export const filterProjectExpensesRequest = (projectId, data) => {
  return {
    type: FILTER_PROJECT_EXPENSES_REQUEST,
    data,
    projectId,
  };
};

export const filterProjectExpensesSuccess = (data) => {
  return {
    type: FILTER_PROJECT_EXPENSES_SUCCESS,
    payload: data,
  };
};

export const filterProjectExpensesFailed = () => {
  return {
    type: FILTER_PROJECT_EXPENSES_FAILED,
  };
};

// fetch customer
export const fetchCustomer = (data) => {
  return {
    type: FETCH_CUSTOMER,
    payload: data,
  };
};

export const fetchCustomerSuccess = (data) => {
  return {
    type: FETCH_CUSTOMER_SUCCESS,
    payload: data,
  };
};

export const fetchCustomerFailed = () => {
  return {
    type: FETCH_CUSTOMER_FAILED,
  };
};

//fetch payment mode
export const fetchPaymentMode = (data) => {
  return {
    type: FETCH_PAYMENT_MODE,
    payload: data,
  };
};

export const fetchPaymentModeSuccess = (data) => {
  return {
    type: FETCH_PAYMENT_MODE_SUCCESS,
    payload: data,
  };
};

export const fetchPaymentModeFailed = () => {
  return {
    type: FETCH_PAYMENT_MODE_FAILED,
  };
};

//fetch tax
export const fetchTax = (data) => {
  return {
    type: FETCH_TAX,
    payload: data,
  };
};

export const fetchTaxSuccess = (data) => {
  return {
    type: FETCH_TAX_SUCCESS,
    payload: data,
  };
};

export const fetchTaxFailed = () => {
  return {
    type: FETCH_TAX_FAILED,
  };
};
