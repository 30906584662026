import ActionTypes from "./types";

//Project Activity
export const getProjectActivityRequest = (projectId) => {
    
    return {
        type: ActionTypes.GET_PROJECT_ACTIVITY_REQUEST,
        payload: projectId,
    };
};

export const getProjectActivitySuccess = (data) => {
    return {
        type: ActionTypes.GET_PROJECT_ACTIVITY_SUCCESS,
        payload: data,
    };
};

export const getProjectActivityFailed = () => {
    return {
        type: ActionTypes.GET_PROJECT_ACTIVITY_FAILED,
    };
};

export const editProjectActivityRequest = (projectId, data) => {
    return {
        type: ActionTypes.EDIT_PROJECT_ACTIVITY_REQUEST,
        data,
        projectId,
    };
};

export const editProjectActivitySuccess = (data) => {
    return {
        type: ActionTypes.EDIT_PROJECT_ACTIVITY_SUCCESS,
        payload: data,
    };
};

export const editProjectActivityFailed = () => {
    return {
        type: ActionTypes.EDIT_PROJECT_ACTIVITY_FAILED,
    };
};