import {
  FETCH_INVOICES_PROJECT,
  FETCH_INVOICES_PROJECT_SUCCESS,
  FETCH_INVOICES_PROJECT_FAILED,
  FETCH_STAFF_INVOICE_PRJ,
  FETCH_STAFF_INVOICE_PRJ_SUCCESS,
  FETCH_STAFF_INVOICE_PRJ_FAILED,
  FILTER_INVOICE_PRJ,
  FILTER_INVOICE_PRJ_SUCCESS,
  FILTER_INVOICE_PRJ_FAILED,
  FETCH_FILTER_YEAR_INVOICE_PRJ,
  FETCH_FILTER_YEAR_INVOICE_PRJ_SUCCESS,
  FETCH_FILTER_YEAR_INVOICE_PRJ_FAILED,
  FETCH_TOTAL_INVOICE_PRJ,
  FETCH_TOTAL_INVOICE_PRJ_SUCCESS,
  FETCH_TOTAL_INVOICE_PRJ_FAILED,
  FETCH_CURRENCIES,
  FETCH_CURRENCIES_SUCCESS,
  FETCH_CURRENCIES_FAILED,
} from "./types";

const INIT_STATE = {
  isLoading: false,
  dataInvoice: {},
  filterYearInvoicePrj: {},
  totalInvoicePrj: {},
  listUserInvoicePrj: [],
  listCurrencies: [],
};

const InvoiceProjectReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_INVOICES_PROJECT:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_INVOICES_PROJECT_SUCCESS:
      return {
        ...state,
        dataInvoice: action.payload,
        isLoading: false,
      };
    case FETCH_INVOICES_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case FETCH_FILTER_YEAR_INVOICE_PRJ:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_FILTER_YEAR_INVOICE_PRJ_SUCCESS:
      return {
        ...state,
        filterYearInvoicePrj: action.payload,
        isLoading: false,
      };

    case FETCH_FILTER_YEAR_INVOICE_PRJ_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_TOTAL_INVOICE_PRJ:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_TOTAL_INVOICE_PRJ_SUCCESS:
      return {
        ...state,
        totalInvoicePrj: action.payload,
        isLoading: false,
      };

    case FETCH_TOTAL_INVOICE_PRJ_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_STAFF_INVOICE_PRJ:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_STAFF_INVOICE_PRJ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listUserInvoicePrj: action.payload,
      };

    case FETCH_STAFF_INVOICE_PRJ_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case FILTER_INVOICE_PRJ:
      return {
        ...state,
        isLoading: true,
      };
    case FILTER_INVOICE_PRJ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dataInvoice: action.payload,
      };

    case FILTER_INVOICE_PRJ_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_CURRENCIES:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_CURRENCIES_SUCCESS:
      return {
        ...state,
        listCurrencies: action.payload,
        isLoading: false,
      };

    case FETCH_CURRENCIES_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return { ...state };
  }
};

export default InvoiceProjectReducer;
