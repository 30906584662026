export const GET_LIST_CUSTOMER_GROUP_REQUEST = 'GET_LIST_CUSTOMER_GROUP_REQUEST';
export const GET_LIST_CUSTOMER_GROUP_FAILED = 'GET_LIST_CUSTOMER_GROUP_FAILED';
export const GET_LIST_CUSTOMER_GROUP_SUCCESS = 'GET_LIST_CUSTOMER_GROUP_SUCCESS';

export const CREATE_CUSTOMER_GROUP_REQUEST = 'CREATE_CUSTOMER_GROUP_REQUEST';
export const CREATE_CUSTOMER_GROUP_FAILED = 'CREATE_CUSTOMER_GROUP_FAILED';
export const CREATE_CUSTOMER_GROUP_SUCCESS = 'CREATE_CUSTOMER_GROUP_SUCCESS';

export const EDIT_CUSTOMER_GROUP_REQUEST = 'EDIT_CUSTOMER_GROUP_REQUEST';
export const EDIT_CUSTOMER_GROUP_FAILED = 'EDIT_CUSTOMER_GROUP_FAILED';
export const EDIT_CUSTOMER_GROUP_SUCCESS = 'EDIT_CUSTOMER_GROUP_SUCCESS';

export const DELETE_CUSTOMER_GROUP_REQUEST = 'DELETE_CUSTOMER_GROUP_REQUEST';
export const DELETE_CUSTOMER_GROUP_FAILED = 'DELETE_CUSTOMER_GROUP_FAILED';
export const DELETE_CUSTOMER_GROUP_SUCCESS = 'DELETE_CUSTOMER_GROUP_SUCCESS';