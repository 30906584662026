import {
    GET_LIST_CUSTOMER_GROUP_REQUEST,
    GET_LIST_CUSTOMER_GROUP_FAILED,
    GET_LIST_CUSTOMER_GROUP_SUCCESS,

    CREATE_CUSTOMER_GROUP_REQUEST,
    CREATE_CUSTOMER_GROUP_FAILED,
    CREATE_CUSTOMER_GROUP_SUCCESS,

    EDIT_CUSTOMER_GROUP_REQUEST,
    EDIT_CUSTOMER_GROUP_FAILED,
    EDIT_CUSTOMER_GROUP_SUCCESS,

    DELETE_CUSTOMER_GROUP_REQUEST,
    DELETE_CUSTOMER_GROUP_FAILED,
    DELETE_CUSTOMER_GROUP_SUCCESS
} from './types';

export const getListCustomerGroup = (search, params) => {
    return {
        type: GET_LIST_CUSTOMER_GROUP_REQUEST,
        search, params
    }
};

export const getListCustomerGroupSuccess = (data) => {
    return {
        type: GET_LIST_CUSTOMER_GROUP_SUCCESS,
        payload: data
    }
};

export const getListCustomerGroupFailed = () => {
    return {
        type: GET_LIST_CUSTOMER_GROUP_FAILED
    }
};

export const createCustomerGroup = (data) => {
    return {
        type: CREATE_CUSTOMER_GROUP_REQUEST,
        payload: data,
    }
};

export const createCustomerGroupSuccess = (data) => {
    return {
        type: CREATE_CUSTOMER_GROUP_SUCCESS,
        payload: data
    }
};

export const createCustomerGroupFailed = () => {
    return {
        type: CREATE_CUSTOMER_GROUP_FAILED
    }
};

export const editCustomerGroup = (data) => {
    return {
        type: EDIT_CUSTOMER_GROUP_REQUEST,
        payload: data,
    }
};

export const editCustomerGroupSuccess = (data) => {
    return {
        type: EDIT_CUSTOMER_GROUP_SUCCESS,
        payload: data
    }
};

export const editCustomerGroupFailed = () => {
    return {
        type: EDIT_CUSTOMER_GROUP_FAILED
    }
};

export const deleteCustomerGroup = (data) => {
    return {
        type: DELETE_CUSTOMER_GROUP_REQUEST,
        payload: data,
    }
};

export const deleteCustomerGroupSuccess = (data) => {
    return {
        type: DELETE_CUSTOMER_GROUP_SUCCESS,
        payload: data
    }
};

export const deleteCustomerGroupFailed = () => {
    return {
        type: DELETE_CUSTOMER_GROUP_FAILED
    }
};

