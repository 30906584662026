export default {
    lead: {
        index: {
            buttonAddLead: "New lead",
            importLead: "Import lead",
            switchToKanban: "SWITCH TO KANBAN",
            chartTooltip:"Leads Summary",
            titleDialogCreateLead: 'Create new lead',
            removeLead: "Remove lead",
            confirmRemoveLead: "Are you sure remove",
            cancelRemoveLead: "Cancel",
            sureRemoveLead: "Yes",
            titleFilter: "Filter by",
            keyWordPlaceholder: "Search",
            assignedPlaceholder: "Assigned",
            statusPlaceholder: "Status",
            sourcePlaceholder: "Source",
            view: "View",
            edit: "Edit",
            delete: "Delete"
        },
        common: {
            id: "Id",
            to:"To",
            lead: "Lead",
            rate: "Rate",
            tax: "Tax",
            amount: "Amount",
            noTax: "No tax",
            subTotal: "Sub total",
            discount: "Discount",
            adjustment: "Adjustment",
            qty:"Qty",
            hours: "Hours",
            allowComment: "Allow comment",
            currency: "Currency",
            discountType: "Discount type",
            related: "Related",
            asigned: "Asigned",
            name: "Name",
            company: "Company",
            email: "Email",
            numberPhone: "Phone",
            tag: "Tags",
            createBy: " Create by",
            status: "Status",
            source: "Source",
            createAt: "Create at",
            address: "Address",
            position: "Position",
            city: "City",
            state: "State",
            website: "Website",
            country:"Country",
            zip: "Zip",
            defaultLanguage: "Default language",
            description: "Description",
            public: "Public",
            lastContact:"Last Contact",
            activityLog: "Activity log",
            yes: "Yes",
            no: "No",
            proposal: "Proposal",
            subject: "Subject",
            total: "Total",
            date: "Date",
            acceptDate: "Accept date",
            priorities:"Priority 1",
            customerPersonal: "Customer Personality",
            dateContact: 'Date Contacted',
            createLeadSuccess: 'Create new lead success',
            deleteLeadSuccess: 'Delete lead success',
            updateLeadSuccess: 'Update lead success',
    

        },
        profile: {
            createNewLead: "Create new lead",
            isPublic: "Public",
            contactToDay: "Contact Today",
            buttonClose: "Close",
            buttonSave: "Save",
            view:{
                buttonEdit: "Edit",
                viewMore: "more",
                disconnected: "Set is disconnected",
                spam: "Set is spam",
                delete: "Delete",
                switchToCustomer: "Switch to customer",
                convertToCustomer: "Convert to customer",
                print: "Print",
                titleInforLead: "Lead information",
                titleGeneral: "General information",
                titleCustomField:"Custom Fields",
                tooltipSwitch: "Email already in customer data"
            }
        },
        menuTabs:{
            profile: "Profile",
            proposal: "Proposals",
            task: "Task",
            file: "Attachment",
            remind: "Reminder",
            note: "Note",
            activityLog: "Activity log"
        },
        proposal: {
            title: "Create new proposal",
            customer: "Customer",
            noDiscount: 'No Discount',
            beforeTax: 'Before Tax',
            afterTax: 'After Tax',
            draft: 'Draft',
            send: 'Send',
            open: 'Open',
            rivised: 'Revised',
            declined: 'Declined',
            accept: 'Accept'

        },
        remind : {
            setLeadReminder : 'Set Lead Reminder',
            editLeadReminder : 'Edit Lead Reminder',
            description: 'Description',
            date: 'Date',
            remind: 'Remind',
            isNotify: 'Is notified ?',
            remindTooltip: 'This option allows you to never forget anything about your customers.',
            dateNotifi: 'Date to be notified',
            setReminder: 'Set reminder to',
            sendEmail: 'Send also an email for this reminder'
        },
        note : {
            touch: 'I got in touch with this lead',
            notContact: 'I have not contacted this lead',
            dateContact: 'Date Contacted',
            addnote: 'Add Note'
        },
        convertCustomer: {
            firstname: 'First Name',
            lastname: 'Last Name',
            position: 'Position',
            email: 'Email',
            company: 'Company',
            phone: 'Phone',
            website: 'Website',
            address: 'Address',
            city: 'City',
            state: 'State',
            country: 'Country',
            zipcode: 'Zip Code',
            copyCustomField: 'Copy custom fields to customer profile',
            customerPersonality: 'Customer Personality',
            customerFieldMerge: 'Customer fields merging',
            customField: 'Merge as custom field',
            customFieldTitle: 'If any of the following custom fields do not exists for customer will be auto created with the same name otherwise only the value will be copied from the lead profile.',
            contactField: 'Merge as contact field',
            dataField: 'Merge with database field',
            doNotMerge: 'Do not merge',
            password: 'Password',
            sendPassword: 'Send SET password email',
            notSendPassword: 'Do not send welcome email',
            transferLead: 'Transfer lead notes to customer profile',
            backToLead: 'Back to lead',
            save: 'Save'
        }
    }

}