import apiBase from "../../../../common/baseAPI";

const EXPENSES_PROJECT_URL = "expenses/project";
const FILTER_YEAR_EXPENSE_PRJ_URL = "expenses/project/year";
const EXPENSES_CATEGORY_URL = "expenses-category";
const CUSTOMER_URL = "customer";
const PAYMENT_MODE_URL = "paymentModes";
const TAX_URL = "tax";
const CREATE_EXPENSE_URL = "expenses";
const FILTER_PROJECT_EXPENSE_URL = "expenses/filter/project";

const getExpensesProject = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${EXPENSES_PROJECT_URL}/${data.id}?search=${data.search}&&limit=${data.limit}&&page=${data.page}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const filterYearExpensePrj = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${FILTER_YEAR_EXPENSE_PRJ_URL}/${data.id}?year=[${data.year}]`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const getExpenseCategory = () => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${EXPENSES_CATEGORY_URL}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const getCustomer = () => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${CUSTOMER_URL}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const getPaymentMode = () => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${PAYMENT_MODE_URL}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const getTax = () => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${TAX_URL}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

//filter project expenses
const filterProjectExpenses = (action) => {
  const {
    billable,
    year,
    month,
    category,
    invoice,
    notInvoice,
    recurring,
    limit,
    page,
  } = action.data;
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${FILTER_PROJECT_EXPENSE_URL}/${
          action.projectId
        }?limit=${limit}&&page=${page}&&billable=[${billable || ""}]&&year=[${
          year || ""
        }]&&month=[${month || ""}]&&category=[${category || ""}]&&invoice=${
          invoice || ""
        }&&notInvoice=${notInvoice || ""}&&recurring=${recurring || ""}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const createExpensesProject = (data) => {
  const newFormData = new FormData();
  for (var key in data) {
    newFormData.append(key, data[key]);
  }
  const header = { "Content-Type": "multipart/form-data" };
  return new Promise((resolve, reject) => {
    return apiBase
      .post(`${CREATE_EXPENSE_URL}`, newFormData, header)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export {
  getExpensesProject,
  filterYearExpensePrj,
  getExpenseCategory,
  getPaymentMode,
  getCustomer,
  getTax,
  filterProjectExpenses,
  createExpensesProject,
};
