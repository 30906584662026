import ActionTypes from "./type";

const INIT_STATE = {
  listStatementCustomer: {},
  isLoading: false,
};

const StatementCustomerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_STATEMENT_CUSTOMER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_STATEMENT_CUSTOMER_SUCCESS:
      return {
        ...state,
        listStatementCustomer: action.data,
        isLoading: false,
      };
    case ActionTypes.FETCH_STATEMENT_CUSTOMER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return { ...state };
  }
};

export default StatementCustomerReducer;
