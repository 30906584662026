import apiBase from "./../../../../../common/baseAPI";

const URL = 'spamfilter';

const getListSupportSpamFilter = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const getSpamFilterTypeofSender = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${URL}?search=&&limit=15&&page=1&&typeTicket=sender`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getSpamFilterTypeofSubject = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${URL}?search=&&limit=15&&page=1&&typeTicket=subject`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getSpamFilterTypeofPhrase = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${URL}?search=&&limit=15&&page=1&&typeTicket=phrase`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createSupportSpamFilter = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(URL, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const editSupportSpamFilter = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${URL}/${data.id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteSupportSpamFilter = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${URL}/${data}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const fetchSupportSpamFilter = (action) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${URL}?search=${action.search}&&limit=${action.params.limit}&&page=${action.params.page}&&typeTicket=${action.params.type}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export {
    getListSupportSpamFilter,
    createSupportSpamFilter,
    editSupportSpamFilter,
    deleteSupportSpamFilter,
    fetchSupportSpamFilter,
    getSpamFilterTypeofSender,
    getSpamFilterTypeofSubject,
    getSpamFilterTypeofPhrase

}