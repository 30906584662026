import {
    FETCH_LIST_CONTRACT,
    FETCH_LIST_CONTRACT_SUCCESS,
    FETCH_LIST_CONTRACT_FAILED,
    FETCH_CONTRACT,
    FETCH_CONTRACT_SUCCESS,
    FETCH_CONTRACT_FAILED,
    DELETE_CONTRACT,
    DELETE_CONTRACT_SUCCESS,
    DELETE_CONTRACT_FAILED,
    CREATE_CONTRACT,
    CREATE_CONTRACT_SUCCESS,
    CREATE_CONTRACT_FAILED,
    FETCH_LIST_CUSTOMER_CONTRACT,
    FETCH_LIST_CUSTOMER_CONTRACT_SUCCESS,
    FETCH_LIST_CUSTOMER_CONTRACT_FAILED,
    SET_EDIT_CONTRACT,
    UPDATE_CONTRACT,
    UPDATE_CONTRACT_SUCCESS,
    UPDATE_CONTRACT_FAILED,
    FILTER_CONTRACT,
    FILTER_CONTRACT_SUCCESS,
    FILTER_CONTRACT_FAILED,
    FETCH_SUMMARY_CONTRACT,
    FETCH_SUMMARY_CONTRACT_SUCCESS,
    FETCH_SUMMARY_CONTRACT_FAILED,
    FETCH_LIST_ATTACHMENT_CONTRACT,
    FETCH_LIST_ATTACHMENT_CONTRACT_SUCCESS,
    FETCH_LIST_ATTACHMENT_CONTRACT_FAILED,
    CREATE_ATTACHMENT_CONTRACT,
    CREATE_ATTACHMENT_CONTRACT_SUCCESS,
    CREATE_ATTACHMENT_CONTRACT_FAILED,
    DELETE_ATTACHMENT_CONTRACT,
    DELETE_ATTACHMENT_CONTRACT_SUCCESS,
    DELETE_ATTACHMENT_CONTRACT_FAILED,
    FETCH_NOTE_CONTRACT,
    FETCH_NOTE_CONTRACT_SUCCESS,
    FETCH_NOTE_CONTRACT_FAILED,
    CREATE_NOTE_CONTRACT,
    CREATE_NOTE_CONTRACT_SUCCESS,
    CREATE_NOTE_CONTRACT_FAILED,
    DELETE_NOTE_CONTRACT,
    DELETE_NOTE_CONTRACT_SUCCESS,
    DELETE_NOTE_CONTRACT_FAILED,
    UPDATE_NOTE_CONTRACT,
    UPDATE_NOTE_CONTRACT_SUCCESS,
    UPDATE_NOTE_CONTRACT_FAILED,
    FETCH_LIST_COMMENT_CONTRACT,
    FETCH_LIST_COMMENT_CONTRACT_SUCCESS,
    FETCH_LIST_COMMENT_CONTRACT_FAILED,
    CREATE_COMMENT_CONTRACT,
    CREATE_COMMENT_CONTRACT_SUCCESS,
    CREATE_COMMENT_CONTRACT_FAILED,
    DELETE_COMMENT_CONTRACT,
    DELETE_COMMENT_CONTRACT_SUCCESS,
    DELETE_COMMENT_CONTRACT_FAILED,
    UPDATE_COMMENT_CONTRACT,
    UPDATE_COMMENT_CONTRACT_SUCCESS,
    UPDATE_COMMENT_CONTRACT_FAILED,
    FETCH_LIST_RENEWAL_CONTRACT,
    FETCH_LIST_RENEWAL_CONTRACT_SUCCESS,
    FETCH_LIST_RENEWAL_CONTRACT_FAILED,
    CREATE_RENEWAL_CONTRACT,
    CREATE_RENEWAL_CONTRACT_SUCCESS,
    CREATE_RENEWAL_CONTRACT_FAILED,
    DELETE_RENEWAL_CONTRACT,
    DELETE_RENEWAL_CONTRACT_SUCCESS,
    DELETE_RENEWAL_CONTRACT_FAILED,
    FETCH_LIST_TASK_CONTRACT,
    FETCH_LIST_TASK_CONTRACT_SUCCESS,
    FETCH_LIST_TASK_CONTRACT_FAILED,
    CREATE_TASK_CONTRACT,
    CREATE_TASK_CONTRACT_SUCCESS,
    CREATE_TASK_CONTRACT_FAILED,
    DELETE_TASK_CONTRACT,
    DELETE_TASK_CONTRACT_SUCCESS,
    DELETE_TASK_CONTRACT_FAILED,
    UPDATE_TASK_CONTRACT,
    UPDATE_TASK_CONTRACT_SUCCESS,
    UPDATE_TASK_CONTRACT_FAILED,
    DATA_COMMENT,
    DATA_NOTES,
    FETCH_CONTRACT_TYPE,
    FETCH_CONTRACT_TYPE_SUCCESS,
    FETCH_CONTRACT_TYPE_FAILED,
    CREATE_CONTRACT_TYPE,
    CREATE_CONTRACT_TYPE_SUCCESS,
    CREATE_CONTRACT_TYPE_FAILED,
    FETCH_CUSTOM_FIELD,
    FETCH_CUSTOM_FIELD_SUCCESS,
    FETCH_CUSTOM_FIELD_FAILED,
    FETCH_CONTRACT_BY_TYPE,
    FETCH_CONTRACT_BY_TYPE_SUCCESS,
    FETCH_CONTRACT_BY_TYPE_FAILED,
    FETCH_CONTRACT_VALUE_BY_TYPE,
    FETCH_CONTRACT_VALUE_BY_TYPE_SUCCESS,
    FETCH_CONTRACT_VALUE_BY_TYPE_FAILED,
    FETCH_YEAR,
    FETCH_YEAR_SUCCESS,
    FETCH_YEAR_FAILED,
    ADD_NEW_CONTRACT_PENDING,
    ADD_NEW_CONTRACT_SUCCESS,
    ADD_NEW_CONTRACT_FAILED,
} from "./types";

export const fetchListContract = (search, params) => {
    return {
        type: FETCH_LIST_CONTRACT,
        search,
        params,
    };
};
export const fetchListContractSuccess = (data) => {
    return {
        type: FETCH_LIST_CONTRACT_SUCCESS,
        payload: data,
    };
};
export const fetchListContractFailed = () => {
    return {
        type: FETCH_LIST_CONTRACT_FAILED,
    };
};

export const fetchContract = (id) => {
    return {
        type: FETCH_CONTRACT,
        payload: id,
    };
};
export const fetchContractSuccess = (data) => {
    return {
        type: FETCH_CONTRACT_SUCCESS,
        payload: data,
    };
};
export const fetchContractFailed = () => {
    return {
        type: FETCH_CONTRACT_FAILED,
    };
};

export const createContract = (data, history) => {
    return {
        type: CREATE_CONTRACT,
        payload: data,
        history,
    };
};
export const createContractSuccess = (data) => {
    return {
        type: CREATE_CONTRACT_SUCCESS,
        payload: data,
    };
};
export const createContractFailed = () => {
    return {
        type: CREATE_CONTRACT_FAILED,
    };
};

export const deleteContract = (data, history) => {
    return {
        type: DELETE_CONTRACT,
        payload: data,
        history,
    };
};
export const deleteContractSuccess = (data) => {
    return {
        type: DELETE_CONTRACT_SUCCESS,
        payload: data,
    };
};
export const deleteContractFailed = () => {
    return {
        type: DELETE_CONTRACT_FAILED,
    };
};

export const fetchListCustomerContract = () => {
    return {
        type: FETCH_LIST_CUSTOMER_CONTRACT,
    };
};
export const fetchListCustomerContractSuccess = (data) => {
    return {
        type: FETCH_LIST_CUSTOMER_CONTRACT_SUCCESS,
        payload: data,
    };
};
export const fetchListCustomerContractFailed = () => {
    return {
        type: FETCH_LIST_CUSTOMER_CONTRACT_FAILED,
    };
};
export const setEditContract = (data) => {
    return {
        type: SET_EDIT_CONTRACT,
        payload: data,
    };
};
export const updateContract = (data) => {
    return {
        type: UPDATE_CONTRACT,
        payload: data,
    };
};
export const updateContractSuccess = (data) => {
    return {
        type: UPDATE_CONTRACT_SUCCESS,
        payload: data,
    };
};
export const updateContractFailed = () => {
    return {
        type: UPDATE_CONTRACT_FAILED,
    };
};

export const filterContract = (data) => {
    return {
        type: FILTER_CONTRACT,
        payload: data,
    };
};
export const filterContractSuccess = (data) => {
    return {
        type: FILTER_CONTRACT_SUCCESS,
        payload: data,
    };
};
export const filterContractFailed = () => {
    return {
        type: FILTER_CONTRACT_FAILED,
    };
};

export const fetchSummaryContract = () => {
    return {
        type: FETCH_SUMMARY_CONTRACT,
    };
};
export const fetchSummaryContractSuccess = (data) => {
    return {
        type: FETCH_SUMMARY_CONTRACT_SUCCESS,
        payload: data,
    };
};
export const fetchSummaryContractFailed = () => {
    return {
        type: FETCH_SUMMARY_CONTRACT_FAILED,
    };
};
export const fetchListAttachmentContract = (id) => {
    return {
        type: FETCH_LIST_ATTACHMENT_CONTRACT,
        payload: id,
    };
};

export const fetchListAttachmentContractSuccess = (data) => {
    return {
        type: FETCH_LIST_ATTACHMENT_CONTRACT_SUCCESS,
        payload: data,
    };
};

export const fetchListAttachmentContractFailed = () => {
    return {
        type: FETCH_LIST_ATTACHMENT_CONTRACT_FAILED,
    };
};

export const createAttachmentContract = (id, data) => {
    return {
        type: CREATE_ATTACHMENT_CONTRACT,
        payload: data,
        id,
    };
};

export const createAttachmentContractSuccess = () => {
    return {
        type: CREATE_ATTACHMENT_CONTRACT_SUCCESS,
    };
};

export const createAttachmentContractFailed = () => {
    return {
        type: CREATE_ATTACHMENT_CONTRACT_FAILED,
    };
};

export const deleteAttachmentContract = (id) => {
    return {
        type: DELETE_ATTACHMENT_CONTRACT,
        payload: id,
    };
};

export const deleteAttachmentContractSuccess = () => {
    return {
        type: DELETE_ATTACHMENT_CONTRACT_SUCCESS,
    };
};

export const deleteAttachmentContractFailed = () => {
    return {
        type: DELETE_ATTACHMENT_CONTRACT_FAILED,
    };
};

export const fetchNoteContract = (id) => {
    return {
        type: FETCH_NOTE_CONTRACT,
        payload: id,
    };
};

export const fetchNoteContractSuccess = (data) => {
    return {
        type: FETCH_NOTE_CONTRACT_SUCCESS,
        payload: data,
    };
};

export const fetchNoteContractFailed = () => {
    return {
        type: FETCH_NOTE_CONTRACT_FAILED,
    };
};

export const createNoteContract = (id, data) => {
    return {
        type: CREATE_NOTE_CONTRACT,
        payload: data,
        id,
    };
};

export const createNoteContractSuccess = () => {
    return {
        type: CREATE_NOTE_CONTRACT_SUCCESS,
    };
};

export const createNoteContractFailed = () => {
    return {
        type: CREATE_NOTE_CONTRACT_FAILED,
    };
};

export const deleteNoteContract = (id) => {
    return {
        type: DELETE_NOTE_CONTRACT,
        payload: id,
    };
};

export const deleteNoteContractSuccess = () => {
    return {
        type: DELETE_NOTE_CONTRACT_SUCCESS,
    };
};

export const deleteNoteContractFailed = () => {
    return {
        type: DELETE_NOTE_CONTRACT_FAILED,
    };
};

export const updateNoteContract = (id, data) => {
    return {
        type: UPDATE_NOTE_CONTRACT,
        payload: data,
        id,
    };
};

export const updateNoteContractSuccess = () => {
    return {
        type: UPDATE_NOTE_CONTRACT_SUCCESS,
    };
};

export const updateNoteContractFailed = () => {
    return {
        type: UPDATE_NOTE_CONTRACT_FAILED,
    };
};

export const fetchListCommentContract = (id) => {
    return {
        type: FETCH_LIST_COMMENT_CONTRACT,
        payload: id,
    };
};

export const fetchListCommentContractSuccess = (data) => {
    return {
        type: FETCH_LIST_COMMENT_CONTRACT_SUCCESS,
        payload: data,
    };
};

export const fetchListCommentContractFailed = () => {
    return {
        type: FETCH_LIST_COMMENT_CONTRACT_FAILED,
    };
};

export const createCommentContract = (id, data) => {
    return {
        type: CREATE_COMMENT_CONTRACT,
        payload: data,
        id,
    };
};

export const createCommentContractSuccess = () => {
    return {
        type: CREATE_COMMENT_CONTRACT_SUCCESS,
    };
};

export const createCommentContractFailed = () => {
    return {
        type: CREATE_COMMENT_CONTRACT_FAILED,
    };
};

export const deleteCommentContract = (id) => {
    return {
        type: DELETE_COMMENT_CONTRACT,
        payload: id,
    };
};

export const deleteCommentContractSuccess = () => {
    return {
        type: DELETE_COMMENT_CONTRACT_SUCCESS,
    };
};

export const deleteCommentContractFailed = () => {
    return {
        type: DELETE_COMMENT_CONTRACT_FAILED,
    };
};

export const updateCommentContract = (id, data) => {
    return {
        type: UPDATE_COMMENT_CONTRACT,
        payload: data,
        id,
    };
};

export const updateCommentContractSuccess = () => {
    return {
        type: UPDATE_COMMENT_CONTRACT_SUCCESS,
    };
};

export const updateCommentContractFailed = () => {
    return {
        type: UPDATE_COMMENT_CONTRACT_FAILED,
    };
};
// renewl Actions

export const fetchListRenewalContract = (id) => {
    return {
        type: FETCH_LIST_RENEWAL_CONTRACT,
        payload: id,
    };
};

export const fetchListRenewalContractSuccess = (data) => {
    return {
        type: FETCH_LIST_RENEWAL_CONTRACT_SUCCESS,
        payload: data,
    };
};

export const fetchListRenewalContractFailed = () => {
    return {
        type: FETCH_LIST_RENEWAL_CONTRACT_FAILED,
    };
};

export const createRenewalContract = (id, data) => {
    return {
        type: CREATE_RENEWAL_CONTRACT,
        payload: data,
        id,
    };
};

export const createRenewalContractSuccess = () => {
    return {
        type: CREATE_RENEWAL_CONTRACT_SUCCESS,
    };
};

export const createRenewalContractFailed = () => {
    return {
        type: CREATE_RENEWAL_CONTRACT_FAILED,
    };
};

export const deleteRenewalContract = (id) => {
    return {
        type: DELETE_RENEWAL_CONTRACT,
        payload: id,
    };
};

export const deleteRenewalContractSuccess = () => {
    return {
        type: DELETE_RENEWAL_CONTRACT_SUCCESS,
    };
};

export const deleteRenewalContractFailed = () => {
    return {
        type: DELETE_RENEWAL_CONTRACT_FAILED,
    };
};
// ACTIONS MODULE TASKS

export const fetchListTaskContract = (id) => {
    return {
        type: FETCH_LIST_TASK_CONTRACT,
        payload: id,
    };
};

export const fetchListTaskContractSuccess = (data) => {
    return {
        type: FETCH_LIST_TASK_CONTRACT_SUCCESS,
        payload: data,
    };
};

export const fetchListTaskContractFailed = () => {
    return {
        type: FETCH_LIST_TASK_CONTRACT_FAILED,
    };
};

export const createTaskContract = (id, data) => {
    return {
        type: CREATE_TASK_CONTRACT,
        payload: data,
        id,
    };
};

export const createTaskContractSuccess = () => {
    return {
        type: CREATE_TASK_CONTRACT_SUCCESS,
    };
};

export const createTaskContractFailed = () => {
    return {
        type: CREATE_TASK_CONTRACT_FAILED,
    };
};

export const deleteTaskContract = (id) => {
    return {
        type: DELETE_TASK_CONTRACT,
        payload: id,
    };
};

export const deleteTaskContractSuccess = () => {
    return {
        type: DELETE_TASK_CONTRACT_SUCCESS,
    };
};

export const deleteTaskContractFailed = () => {
    return {
        type: DELETE_TASK_CONTRACT_FAILED,
    };
};

export const updateTaskContract = (id, data) => {
    return {
        type: UPDATE_TASK_CONTRACT,
        payload: data,
        id,
    };
};

export const updateTaskContractSuccess = () => {
    return {
        type: UPDATE_TASK_CONTRACT_SUCCESS,
    };
};

export const updateTaskContractFailed = () => {
    return {
        type: UPDATE_TASK_CONTRACT_FAILED,
    };
};

export const setDataComment = (data) => {
    return {
        type: DATA_COMMENT,
        payload: data,
    };
};

export const setDataNotes = (data) => {
    return {
        type: DATA_NOTES,
        payload: data,
    };
};

export const fetchContractType = () => {
    return {
        type: FETCH_CONTRACT_TYPE,
    };
};

export const fetchContractTypeSuccess = (data) => {
    return {
        type: FETCH_CONTRACT_TYPE_SUCCESS,
        payload: data,
    };
};

export const fetchContractTypeFailed = () => {
    return {
        type: FETCH_CONTRACT_TYPE_FAILED,
    };
};

export const createContractType = (data) => {
    return {
        type: CREATE_CONTRACT_TYPE,
        payload: data,
    };
};

export const createContractTypeSuccess = () => {
    return {
        type: CREATE_CONTRACT_TYPE_SUCCESS,
    };
};

export const createContractTypeFailed = () => {
    return {
        type: CREATE_CONTRACT_TYPE_FAILED,
    };
};

export const fetchCustomField = () => {
    return {
        type: FETCH_CUSTOM_FIELD,
    };
};

export const fetchCustomFieldSuccess = (data) => {
    return {
        type: FETCH_CUSTOM_FIELD_SUCCESS,
        payload: data,
    };
};

export const fetchCustomFieldFailed = () => {
    return {
        type: FETCH_CUSTOM_FIELD_FAILED,
    };
};

export const fetchContractByType = () => {
    return {
        type: FETCH_CONTRACT_BY_TYPE,
    };
};

export const fetchContractByTypeSuccess = (data) => {
    return {
        type: FETCH_CONTRACT_BY_TYPE_SUCCESS,
        payload: data,
    };
};

export const fetchContractByTypeFailed = () => {
    return {
        type: FETCH_CONTRACT_BY_TYPE_FAILED,
    };
};

export const fetchContractValueByType = () => {
    return {
        type: FETCH_CONTRACT_VALUE_BY_TYPE,
    };
};

export const fetchContractValueByTypeSuccess = (data) => {
    return {
        type: FETCH_CONTRACT_VALUE_BY_TYPE_SUCCESS,
        payload: data,
    };
};

export const fetchContractValueByTypeFailed = () => {
    return {
        type: FETCH_CONTRACT_VALUE_BY_TYPE_FAILED,
    };
};

export const fetchYear = () => {
    return {
        type: FETCH_YEAR,
    };
};

export const fetchYearSuccess = (data) => {
    return {
        type: FETCH_YEAR_SUCCESS,
        payload: data,
    };
};

export const fetchYearFailed = () => {
    return {
        type: FETCH_YEAR_FAILED,
    };
};
export const addNewContractPending = (params) => {
    return {
        type: ADD_NEW_CONTRACT_PENDING,
        payload: { params: params },
    };
};

export const addNewContractSuccess = () => {
    return {
        type: ADD_NEW_CONTRACT_SUCCESS,
    };
};

export const addNewContractFailed = () => {
    return {
        type: ADD_NEW_CONTRACT_FAILED,
    };
};
