export default {
    task: {
        attachFiles: "Attach Files",
        summary: "Task summary",
        export: "EXPORT",
        bulk: "BULK ACTION",
        name: "Name",
        status: "Status",
        startDate: "Start Date",
        dueDate: "Due Date",
        assignedTo: "Assigned To",
        tags: "Tags",
        priority: "Priority",
        delete: "Delete",
        recurringTask: "Recurring Task",
        markAs: "Mark as",
        notStarted: "Not Started",
        inProgress: "In Progress",
        testing: "Testing",
        awaitingFeedback: "Awaiting Feedback",
        complete: "Complete",
        allStatus: "All Status",
        low: "Low",
        medium: "Medium",
        high: "High",
        urgent: "Urgent",
        edit: "Edit",
        public: "Public",
        billable: "Billable",
        subject: "Subject",
        hourlyRate: "Hourly Rate",
        repeatEvery: "Repeat Every",
        totalCycles: "Total Cycles",
        infinity: "Infinity",
        relatedTo: "Related To",
        contract: "Contract",
        taskDescription: "Task Description",
        description: "Description",
        checklistItem: "Checklist Item",
        checklistItemNone: "Checklist items not found for this task",
        comment: "Comment",
        addComment: "Add Comment",
        taskInfo: "Task Info",
        createdAt: "Created At",
        billableAmount: "Billable Amount",
        userLoggedTime: "Your logged time",
        totalLoggedTime: "Total logged time",
        reminder: "Reminder",
        createReminder: "Create Reminder",
        reminderNone: "No reminders for this task",
        assignee: "Assignees",
        assignTaskTo: "Assign task to",
        follower: "Followers",
        addFollower: "Add Followers",
        followersNone: "No followers for this task",
        uploadFile: "Drop files here to upload",
        member: "Member",
        timeStart: "Start Time",
        timeEnd: "End Time",
        timeSpent: "Time Spent",
        timerNone: "No started timers found",
        note: "Note",
        confirm: "CONFIRM",
        close: "CLOSE",
        save: "SAVE",
        deleteTitle: "Delete Task",
        deleteConfirm: "Are you sure you want to delete",
        cancel: "CANCEL",
        noRepeat: "Do not repeat",
        week: "Week",
        day: "Day",
        month: "Month",
        year: "Year",
        twoWeeks: "2 Weeks",
        oneMonth: "1 Month",
        twoMonths: "2 Months",
        threeMonths: "3 Months",
        sixMonths: "6 Months",
        oneYear: "1 Year",
        custom: "Custom",
        haveRelate: "Related",
        noSelect: "Do not select",
        project: "Project",
        invoice: "Invoice",
        customer: "Customer",
        estimate: "Estimate",
        ticket: "Ticket",
        expense: "Expense",
        lead: "Lead",
        proposal: "Proposal",
        createTaskTitle: "Add new task",
        taskOverviewTitle: "Tasks Overview",
        allMonth: "All Month",
        jan: "January",
        feb: "February",
        march: "March",
        april: "April",
        may: "May",
        june: "June",
        july: "July",
        aug: "August",
        sept: "September",
        oct: "October",
        nov: "November",
        dec: "December",
        backToTask: "BACK TO TASKS LIST",
        totalAttachment: "Total attachments added",
        totalComments: "Total comments",
        finishOn: "Finished on time?",
        markAsComplete: "Mark As Complete",
        markAsInProgress: "Mark As In Progress",
        statistical: "Statistical",
        timeChart: "Timesheets",
        reload: "Reload",
        action: "Action",
        dateNotified: "Date to be notified",
        setReminder: "Set reminder to",
        sendMailToReminder: "Send also an email for this reminder",
        filter: "Filter",
        all: "All",
        inDayTask: "Today's Tasks",
        overTerm: "Due Date Passed",
        upcomingTask: "Upcoming Tasks",
        myTask: "Tasks assigned to me",
        myFollowingTask: "Tasks assigned to me",
        notAssigned: "Not Assigned",
        recurring: "Recurring",
        billed: "Billed",
        notBilled: "Not Billed",
        byAssignedMember: "By Assigned Member",
        copy: "Copy",
        todayTask: "Today's Tasks",
        validation: {
            requirement: "This field is required.",
        },
        newTask: "New Task",
        assignedMember: "By Assigned Member",
        userTask: "Tasks assigned to me",
        followingTask: "Tasks i'm following",
        files: "Files",
        // table: {
        //   name: 'Name',
        //   status: 'Status',
        //   startDate: 'Start Date',
        //   dueDate: 'Due Date',
        //   assignedTo: 'Assigned To',
        //   tags: 'Tags',
        //   priority: 'Priority',
        // },
        // name: {
        //   edit: 'Edit',
        //   delete: 'Delete',
        //   recurring: 'Recurring Task',
        // },
        // status: {
        //   markAs: 'Mark as',
        //   notStarted: 'Not Started',
        //   inProgress: 'In Progress',
        //   testing: 'Testing',
        //   awaitingFeedback: 'Awaiting Feedback',
        //   complete: 'Complete',
        //   all: 'All Status',
        // },
        // priority: {
        //   low: 'Low',
        //   medium: 'Medium',
        //   high: 'High',
        //   urgent: 'Urgent',
        // },
        // edit: {
        //   label: 'Edit task',
        //   public: 'Public',
        //   billable: 'Billable',
        //   subject: 'Subject',
        //   hourlyRate: 'Hourly Rate',
        //   startDate: 'Start Date',
        //   dueDate: 'Due Date',
        //   priority: 'Priority',
        //   repeatEvery: 'Repeat Every',
        //   totalCycles: 'Total Cycles',
        //   infinity: 'Infinity',
        //   relatedTo: 'Related To',
        //   contract: 'Contract',
        //   tags: 'Tags',
        //   taskDescription: 'Task Description',
        //   description: 'Description',
        //   checklistItem: 'Checklist Item',
        //   checklistItemNone: 'Checklist items not found for this task',
        //   comment: 'Comment',
        //   addComment: 'Add Comment',
        //   taskInfo: 'Task Info',
        //   createdAt: 'Created At',
        //   status: 'Status',
        //   billableAmount: 'Billable Amount',
        //   userLoggedTime: 'Your logged time',
        //   totalLoggedTime: 'Total logged time',
        //   reminder: 'Reminder',
        //   createReminder: 'Create Reminder',
        //   reminderNone: 'No reminders for this task',
        //   assignee: 'Assignees',
        //   assignTask: 'Assign task to',
        //   followers: 'Followers',
        //   addFollowers: 'Add Followers',
        //   followersNone: 'No followers for this task',
        //   upload: 'Drop files here to upload',
        //   member: 'Member',
        //   timeStart: 'Start Time',
        //   timeEnd: 'End Time',
        //   timeSpent: 'Time Spent',
        //   timeChartNull: 'No started timers found',
        //   note: 'Note',
        //   confirm: 'CONFIRM',
        //   close: 'CLOSE',
        //   save: 'SAVE',
        // },
        // delete: {
        //   title: 'Delete Task',
        //   confirm: 'Are you sure you want to delete',
        //   cancel: 'CANCEL',
        //   yes: 'YES',
        // },
        // taskRepeat: {
        //   nothing: 'Do not repeat',
        //   week: 'Week',
        //   day: 'Day',
        //   month: 'Month',
        //   year: 'Year',
        //   twoWeeks: '2 Weeks',
        //   oneMonth: '1 Month',
        //   twoMonths: '2 Months',
        //   threeMonths: '3 Months',
        //   sixMonths: '6 Months',
        //   oneYear: '1 Year',
        //   custom: 'Custom',
        // },
        // related: {
        //   title: 'Related',
        //   nothing: 'No request',
        //   project: 'Project',
        //   invoice: 'Invoice',
        //   customer: 'Customer',
        //   estimate: 'Estimate',
        //   contract: 'Contract',
        //   ticket: 'Ticket',
        //   expense: 'Expense',
        //   lead: 'Lead',
        //   proposal: 'Proposal',
        // },
        // addNew: {
        //   title: 'Add new task'
        // },
        // taskOverview: {
        //   title: 'Tasks Overview',
        //   back: 'BACK TO TASKS LIST',
        //   allStaff: 'All Staff Members',
        //   month: {
        //     allMonth: 'All Month',
        //     jan: 'January',
        //     feb: 'February',
        //     march: 'March',
        //     april: 'April',
        //     may: 'May',
        //     june: 'June',
        //     july: 'July',
        //     aug: 'August',
        //     sept: 'September',
        //     oct: 'October',
        //     nov: 'November',
        //     dec: 'December',
        //   },
        //   table: {
        //     name: 'Name',
        //     startDate: 'Start Date',
        //     dueDate: 'Due Date',
        //     status: 'Status',
        //     totalAttachment: 'Total attachments added',
        //     totalComments: 'Total comments',
        //     checklistItems: 'Checklist Items',
        //     totalLoggedTime: 'Total Logged Time',
        //     finishOn: 'Finished on time?',
        //     assignedTo: 'Assigned to',
        //   }
        // },
        // filter: {
        //   title: 'Filter',
        //   all: 'All',
        //   todayTask: "Today's Tasks",
        //   dueDate: 'Due Date Passed',
        //   upcomingTask: 'Upcoming Tasks',
        //   userTask: 'Tasks assigned to me',
        //   followingTask: "Tasks i'm following",
        //   notAssigned: 'Not Assigned',
        //   recurring: 'Recurring',
        //   billed: 'Billed',
        //   notBilled: 'Not Billed',
        //   assignedMember: 'By Assigned Member',
        // },
        // tooltip: {
        //   markAsComplete: 'Mark As Complete',
        //   markAsInProgress: 'Mark As In Progress',
        //   statistical: 'Statistical',
        //   timeChart: 'Timesheets',
        //   edit: 'Edit',
        //   reload: 'Reload',
        //   delete: 'Delete',
        // },
        // action: {
        //   title: 'Actions',
        //   edit: 'Edit',
        //   copy: 'Copy',
        //   delete: 'Delete',
        // },
        // reminder: {
        //   dateNotified: 'Date to be notified',
        //   setReminder: 'Set reminder to',
        //   description: 'Description',
        //   sendMail: 'Send also an email for this reminder',
        //   createReminder: 'CREATE REMINDER',
        // },
        // validation: {
        //   requirement: 'This value is required.',

        // }
    },
};
