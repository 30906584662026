import ActionTypes from './types';

const INIT_STATE = {
    listPredefinedReplies: [],
    goBackHome: false,
    isLoading: false,
    itemEdit: [],
    deleteReply: false,
};

const AdminSupportPredefinedRepliesReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_LIST_PREDEFINED_REPLIES_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case ActionTypes.GET_LIST_PREDEFINED_REPLIES_SUCCESS:
            return {
                ...state,
                listPredefinedReplies: action.payload,
                isLoading: false,
                goBackHome: false,
            };

        case ActionTypes.GET_LIST_PREDEFINED_REPLIES_FAILED:
            return {
                ...state,
                isLoading: false,
            };


        case ActionTypes.GET_PREDEFINED_REPLIES_EDIT_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case ActionTypes.GET_PREDEFINED_REPLIES_EDIT_SUCCESS:
            return {
                ...state,
                itemEdit: action.payload,
                isLoading: false,
                goBackHome: false,
            };

        case ActionTypes.GET_PREDEFINED_REPLIES_EDIT_FAILED:
            return {
                ...state,
                isLoading: false,
                goBackHome: false,
            };

        case ActionTypes.CREATE_PREDEFINED_REPLIES_REQUEST:
            return {
                ...state,
                isLoading: true
            }

        case ActionTypes.CREATE_PREDEFINED_REPLIES_SUCCESS:
            state.listPredefinedReplies.data.unshift(action.payload)
            return {
                ...state,
                goBackHome: true,
                isLoading: false,
                listPredefinedReplies: state.listPredefinedReplies
            };

        case ActionTypes.CREATE_PREDEFINED_REPLIES_FAILED:
            return {
                ...state,
                isLoading: false
            };

        case ActionTypes.EDIT_PREDEFINED_REPLIES_REQUEST:
            return {
                ...state,
                isLoading: true
            }

        case ActionTypes.EDIT_PREDEFINED_REPLIES_SUCCESS:
            return {
                ...state,
                goBackHome: true,
                isLoading: false,
                itemEdit: [],
            }

        case ActionTypes.EDIT_PREDEFINED_REPLIES_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.DELETE_PREDEFINED_REPLIES_REQUEST:
            return {
                ...state,
                isLoading: true
            }

        case ActionTypes.DELETE_PREDEFINED_REPLIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                deleteReply: true,
            };

        case ActionTypes.DELETE_PREDEFINED_REPLIES_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.FETCH_PREDEFINED_REPLIES_REQUEST:
            return {
                ...state,
                isLoading: true,
                itemEdit: [],
            }
        case ActionTypes.FETCH_PREDEFINED_REPLIES_SUCCESS:
            return {
                ...state,
                listPredefinedReplies: action.payload,
                isLoading: false,
                goBackHome: false,
                deleteReply: false
            };

        case ActionTypes.FETCH_PREDEFINED_REPLIES_FAILED:
            return {
                ...state,
                isLoading: false,
                goBackHome: false,
                deleteReply: false
            };

        default: return { ...state };
    }
}

export default AdminSupportPredefinedRepliesReducer;