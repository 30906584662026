import apiBase from "../../../../common/baseAPI";

const INVOICES_PROJECT_URL = "invoice/project";
const FETCH_STAFF_INVOICE_PRJ_URL = "staff/by/invoice";
const FILTER_INVOICE_PRJ = "invoice/filter/project";
const FILTER_YEAR_INVOICE_PRJ_URL = "invoice/project/year";
const TOTAL_INVOICE_PRJ_URL = "invoice/project/total";
const CURRENCIES_URL = "currencies";

const getInvoicesProject = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${INVOICES_PROJECT_URL}/${data.id}?search=${data.search}&&limit=${data.limit}&&page=${data.page}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const filterYearInvoicePrj = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${FILTER_YEAR_INVOICE_PRJ_URL}/${data.id}?currency=${data.currency}&&year=[${data.year}]`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const getTotalInvoicePrj = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${TOTAL_INVOICE_PRJ_URL}/${data}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const fetchStaffInvoiceProject = () => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${FETCH_STAFF_INVOICE_PRJ_URL}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const filterInvoicePrj = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${FILTER_INVOICE_PRJ}/${data.id}?limit=15&&page=1&&notSent=${data.notSent}&&record=${data.record}&&status=[${data.status}]&&recurring=${data.recurring}&&year=[${data.year}]&&sale=[${data.sale}]`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const getCurrencies = () => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${CURRENCIES_URL}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export {
  getInvoicesProject,
  filterYearInvoicePrj,
  getTotalInvoicePrj,
  fetchStaffInvoiceProject,
  filterInvoicePrj,
  getCurrencies,
};
