import {
    GET_LIST_CUSTOMER_GROUP_REQUEST,
    GET_LIST_CUSTOMER_GROUP_SUCCESS,
    GET_LIST_CUSTOMER_GROUP_FAILED,
    
    CREATE_CUSTOMER_GROUP_REQUEST,
    CREATE_CUSTOMER_GROUP_SUCCESS,
    CREATE_CUSTOMER_GROUP_FAILED,
    
    EDIT_CUSTOMER_GROUP_REQUEST,
    EDIT_CUSTOMER_GROUP_SUCCESS,
    EDIT_CUSTOMER_GROUP_FAILED,
    
    DELETE_CUSTOMER_GROUP_REQUEST,
    DELETE_CUSTOMER_GROUP_SUCCESS,
    DELETE_CUSTOMER_GROUP_FAILED
} from './types';

const INIT_STATE = {
    listCustomerGroup: [],
    isLoading: false,
    closeDialog: false,
    deleteGroup: false,
};

const AdminCustomerGroupReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_CUSTOMER_GROUP_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case GET_LIST_CUSTOMER_GROUP_SUCCESS:
            return {
                ...state,
                listCustomerGroup: action.payload,
                deleteGroup: false,
                isLoading: false,
            };

        case GET_LIST_CUSTOMER_GROUP_FAILED:
            return {
                ...state,
                isLoading: false,
            };
    
        case CREATE_CUSTOMER_GROUP_REQUEST:
            return {
                ...state,
                isLoading: true,
                closeDialog: false
            }

        case CREATE_CUSTOMER_GROUP_SUCCESS:
            state.listCustomerGroup.data.unshift(action.payload)
            return {
                ...state,
                isLoading: false,
                listCustomerGroup: state.listCustomerGroup,
                closeDialog: true
            };

        case CREATE_CUSTOMER_GROUP_FAILED:
            return {
                ...state,
                isLoading: false,
                closeDialog: true
            };

        case EDIT_CUSTOMER_GROUP_REQUEST:
            return {
                ...state,
                isLoading: true,
                closeDialog: false
            }

        case EDIT_CUSTOMER_GROUP_SUCCESS:
            let itemIndex = state.listCustomerGroup.data.findIndex(item => item.id === action.payload.id)
            if (itemIndex !== -1) {
                state.listCustomerGroup.data.splice(itemIndex, 1, action.payload)
            }
            return {
                ...state,
                listCustomerGroup: state.listCustomerGroup,
                isLoading: false,
                closeDialog: true
            }

        case EDIT_CUSTOMER_GROUP_FAILED:
            return {
                ...state,
                isLoading: false,
                closeDialog: true
            };

        case DELETE_CUSTOMER_GROUP_REQUEST:
            return {
                ...state,
                isLoading: true,
                closeDialog: false,
            };

        case DELETE_CUSTOMER_GROUP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                closeDialog: true,
                deleteGroup: true,
            };

        case DELETE_CUSTOMER_GROUP_FAILED:
            return {
                ...state,
                isLoading: false,
                closeDialog: true
            };
            
        default: return { ...state };
    }
}

export default AdminCustomerGroupReducer;