import React from "react";
import { PrivateRoute } from "./PrivateRoute";
import i18n from "../i18n.js";
import { project, task } from "../constants/Route.js";

const TaskOverview = React.lazy(() => import("../pages/Task/TaskOverview.js"));


export const tasksRoute = [
    {
        path: "/task/overview",
        name: `task overview`,
        component: TaskOverview,
        route: PrivateRoute,
        roles: ["Admin", "TaskOverview"],
        title: "TaskOverview",
        exact: true,
    },
]