import {
    ADD_CONTRACT_TYPE_FAILED,
    ADD_CONTRACT_TYPE_PENDING,
    ADD_CONTRACT_TYPE_SUCCESS,
    DELETE_CONTRACT_TYPE_FAILED,
    DELETE_CONTRACT_TYPE_PENDING,
    DELETE_CONTRACT_TYPE_SUCCESS,
    GET_CONTRACT_TYPE_FAILED,
    GET_CONTRACT_TYPE_PENDING,
    GET_CONTRACT_TYPE_SUCCESS,
    PUT_CONTRACT_TYPE_FAILED,
    PUT_CONTRACT_TYPE_PENDING,
    PUT_CONTRACT_TYPE_SUCCESS,
} from "./type";

export const getContractTypePending = (params) => {
    return {
        type: GET_CONTRACT_TYPE_PENDING,
        payload: { params: params },
    };
};

export const getContractTypeSuccess = (data) => {
    return {
        type: GET_CONTRACT_TYPE_SUCCESS,
        payload: { data: data },
    };
};

export const getContractTypeFailed = () => {
    return {
        type: GET_CONTRACT_TYPE_FAILED,
    };
};
export const addContractTypePending = (body, params) => {
    return {
        type: ADD_CONTRACT_TYPE_PENDING,
        payload: { body: body, params: params },
    };
};

export const addContractTypeSuccess = () => {
    return {
        type: ADD_CONTRACT_TYPE_SUCCESS,
        // payload: { data: data },
    };
};

export const addContractTypeFailed = () => {
    return {
        type: ADD_CONTRACT_TYPE_FAILED,
    };
};
export const putContractTypePending = (body, id, params) => {
    return {
        type: PUT_CONTRACT_TYPE_PENDING,
        payload: { body: body, id: id, params: params },
    };
};

export const putContractTypeSuccess = () => {
    return {
        type: PUT_CONTRACT_TYPE_SUCCESS,
        // payload: { data: data },
    };
};

export const putContractTypeFailed = () => {
    return {
        type: PUT_CONTRACT_TYPE_FAILED,
    };
};
export const deleteContractTypePending = (id, params) => {
    return {
        type: DELETE_CONTRACT_TYPE_PENDING,
        payload: { id: id, params: params },
    };
};

export const deleteContractTypeSuccess = () => {
    return {
        type: DELETE_CONTRACT_TYPE_SUCCESS,
        // payload: { data: data },
    };
};

export const deleteContractTypeFailed = () => {
    return {
        type: DELETE_CONTRACT_TYPE_FAILED,
    };
};
