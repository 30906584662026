import apiBase from "../../../../common/baseAPI";

const DISCUSSION_URL = "project/discussions";

export const getDiscussionProject = (data) => {
  let { id, search, page, limit } = data;
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${DISCUSSION_URL}/${id}?limit=${limit}&&page=${page}&&search=${search}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const createDiscussionProject = (data, id) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .post(`${DISCUSSION_URL}/${id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteDiscussionProject = (id) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .delete(`${DISCUSSION_URL}/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const updateDiscussionProject = (params) => {
  let { data, id } = params;
  return new Promise((resolve, reject) => {
    return apiBase
      .put(`${DISCUSSION_URL}/${id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
