import _ from "lodash";
import apiBase from "../../../../common/baseAPI";

const ITEM_URL = "item";
const TAX_URL = "tax";
const ITEM_GROUP = "item-group";
const getListItemsAPI = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(ITEM_URL, { params: data })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListTax = (data) => {
    let newUrl = TAX_URL;
    return new Promise((resolve, reject) => {
        return apiBase
            .get(newUrl)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const getListItemGroups = (data) => {
    let newUrl = ITEM_GROUP;
    return new Promise((resolve, reject) => {
        return apiBase
            .get(newUrl)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const addNewItem = (data) => {
    // data: object
    //     {
    //         "description": "Cho thuê nhân sự triển khai",
    //          "long_description": "Cho thuê nhân sự triển khai",
    //          "rate": "100.00",
    //          "tax": 1, // Lấy phương thức get: api/tax
    //          "tax2": 1, // Lấy phương thức get: api/tax
    //          "unit": "VNĐ",
    //          "group_id": 3 // Lấy phương thức get: api/item-group
    // }
    let newUrl = ITEM_URL;
    const newData = _.pickBy(data, _.identity);
    return new Promise((resolve, reject) => {
        return apiBase
            .post(newUrl, newData)
            .then((res) => resolve(res))
            .catch((err) => err);
    });
};
export { getListItemsAPI, getListTax, getListItemGroups, addNewItem };
