import {
  FETCH_ESTIMATES_PROJECT,
  FETCH_ESTIMATES_PROJECT_SUCCESS,
  FETCH_ESTIMATES_PROJECT_FAILED,
  FETCH_FILTER_YEAR_ESTIMATE_PRJ,
  FETCH_FILTER_YEAR_ESTIMATE_PRJ_SUCCESS,
  FETCH_FILTER_YEAR_ESTIMATE_PRJ_FAILED,
  FETCH_TOTAL_ESTIMATE_PRJ,
  FETCH_TOTAL_ESTIMATE_PRJ_SUCCESS,
  FETCH_TOTAL_ESTIMATE_PRJ_FAILED,
  FILTER_ESTIMATE,
  FILTER_ESTIMATE_SUCCESS,
  FILTER_ESTIMATE_FAILED,
} from "./types";

const INIT_STATE = {
  isLoading: false,
  dataEstimate: {},
  filterYearEstimatePrj: {},
  totalEstimatePrj: {},
};

const EstimateProjectReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ESTIMATES_PROJECT:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_ESTIMATES_PROJECT_SUCCESS:
      return {
        ...state,
        dataEstimate: action.payload,
        isLoading: false,
      };
    case FETCH_ESTIMATES_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_FILTER_YEAR_ESTIMATE_PRJ:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_FILTER_YEAR_ESTIMATE_PRJ_SUCCESS:
      return {
        ...state,
        filterYearEstimatePrj: action.payload,
        isLoading: false,
      };

    case FETCH_FILTER_YEAR_ESTIMATE_PRJ_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_TOTAL_ESTIMATE_PRJ:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_TOTAL_ESTIMATE_PRJ_SUCCESS:
      return {
        ...state,
        totalEstimatePrj: action.payload,
        isLoading: false,
      };

    case FETCH_TOTAL_ESTIMATE_PRJ_FAILED:
      return {
        ...state,
        isLoading: false,
      };
      case FILTER_ESTIMATE:
      return {
        ...state,
        isLoading: true,
      };
    case FILTER_ESTIMATE_SUCCESS:
      return {
        ...state,
        dataEstimate: action.payload,
        isLoading: false,
      };

    case FILTER_ESTIMATE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return { ...state };
  }
};

export default EstimateProjectReducer;
