import React from "react";
import { PrivateRoute } from "./PrivateRoute";
import i18n from "../i18n.js";
import { store } from "../constants/Route.js";

// handle auth and authorization
const NewContracts = React.lazy(() => import("../pages/Contract/ContractsCustomer/NewContracts.js"));
const EditContracts = React.lazy(() => import("../pages/Contract/EditContract"));

const NewInvoice = React.lazy(() => import("../pages/Sales/Invoice/NewInvoice"));
const EditInvoice = React.lazy(() => import("../pages/Sales/Invoice/EditInvoice"));
const ViewInvoice = React.lazy(() => import("../pages/Sales/Invoice/View"));
const Payments = React.lazy(() => import("../pages/Sales/Payments/index.js"));
const InvoiceItems = React.lazy(() => import("../pages/Sales/Items/index.js"));
const Invoice = React.lazy(() => import("../pages/Sales/Invoice/index.js"));
const Store = React.lazy(() => import("../pages/Store/index.js"));
// const Support = React.lazy(() => import("../pages/Support/index.js"));
const Expense = React.lazy(() => import("../pages/Expense/index.js"));
const Contract = React.lazy(() => import("../pages/Contract/index.js"));


const CreditNotes = React.lazy(() => import("../pages/CreditNote/index.js"));


export const salesRoute = [
    {
        name: `${i18n.t("route.sales")}`,
        route: PrivateRoute,
        roles: ["Admin"],
        title: `${i18n.t("route.sales")}`,
        childs: [
            {
                path: "/contract",
                name: `${i18n.t("route.contract")}`,
                component: Contract,
                route: PrivateRoute,
                roles: ["Admin"],
                title: "Contract",
                exact: true,
            },
            {
                path: "/invoices",
                name: `${i18n.t("route.invoices")}`,
                component: Invoice,
                route: PrivateRoute,
                roles: ["Admin"],
                title: `${i18n.t("route.invoices")}`,
                exact: true,
            },
            {
                path: "/payments",
                name: `${i18n.t("route.payments")}`,
                component: Payments,
                route: PrivateRoute,
                roles: ["Admin"],
                title: `${i18n.t("route.payments")}`,
                exact: true,
            },
            {
                path: "/expense",
                name: `${i18n.t("route.expense")}`,
                component: Expense,
                route: PrivateRoute,
                roles: ["Admin"],
                exact: true,
                title: "Expense",
            },
            
            {
                path: "/credit_notes",
                name: `${i18n.t("route.creditNotes")}`,
                component: CreditNotes,
                route: PrivateRoute,
                roles: ["Admin"],
                title: `${i18n.t("route.creditNotes")}`,
                exact: true,
            },
        ],
    },
    {
        name: `${i18n.t("route.store")}`,
        route: PrivateRoute,
        roles: ["Admin"],
        title: `${i18n.t("route.store")}`,
        childs: [
            {
                path: store.index,
                name: `${i18n.t("route.store")}`,
                component: Store,
                route: PrivateRoute,
                roles: ["Admin"],
                title: `${i18n.t("route.store")}`,
                exact: true
            },
            {
                path: "/items",
                name: `${i18n.t("route.items")}`,
                component: InvoiceItems,
                route: PrivateRoute,
                roles: ["Admin"],
                title: `${i18n.t("route.items")}`,
                exact: true,
            },
        ]        
    },
    {
        path: "/customer/credit_notes/:id",
        name: `Credit_Notes`,
        component: CreditNotes,
        route: PrivateRoute,
        roles: ["Admin", "credit_notes"],
        title: "Credit_Notes",
        exact: true,
    },
    {
        path: "/contract/newContract",
        name: `New Contract`,
        component: NewContracts,
        route: PrivateRoute,
        roles: ["Admin", "Staff"],
        title: `New Contract`,
        exact: true,
    },
    {
        path: "/contract/edit/:id",
        name: `Edit Contract`,
        component: EditContracts,
        route: PrivateRoute,
        roles: ["Admin", "Staff"],
        title: `Edit Contract`,
        exact: true,
    },
    {
        path: "/invoices/list_invoices",
        name: "invoices_list_invoices",
        component: Invoice,
        route: PrivateRoute,
        roles: ["Admin", "view"],
        title: `${i18n.t("route.invoices")}`,
        exact: true,
    },
    {
        path: "/invoice/new",
        name: "invoice_new_sales",
        component: NewInvoice,
        route: PrivateRoute,
        roles: ["Admin", "invoice"],
        title: "New Invoice",
        exact: true,
    },
    {
        path: "/customer/invoice/new/:id",
        name: `Customer Invoice New`,
        component: NewInvoice,
        route: PrivateRoute,
        roles: ["Admin"],
        title: "Customer Invoices",
        exact: true,
    },
    {
        path: "/invoice/edit/:id",
        name: "invoice_edit_sales",
        component: EditInvoice,
        route: PrivateRoute,
        roles: ["Admin", "invoice"],
        title: "Edit Invoice",
        exact: true,
    },
    {
        path: "/invoice/view",
        name: `New Invoice`,
        component: ViewInvoice,
        route: PrivateRoute,
        roles: ["Admin", "invoice"],
        title: "New Invoice",
        exact: true,
    },
    
]