// import _ from 'lodash';
import apiBase from "../common/baseAPI";
// import { ParseSimpleEndpoint } from '../helpers/ParseEndpoint';

const STAFF_URL = "staff";
const ITEM_URL = "item";
const ITEM_GROUP_URL = "item-group";
const TAX_URL = "taxes";

const LEAD_SOURCE = "lead-source";
const COUNTRY_URL = "country";
const TAG_URL = "tag";
const CURRENCY_URL = "currencies";
const STATUS_URL = "lead-status";
const CONTACT_URL = "contact";
export const getListCreatedBy = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(STAFF_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const getListStatus = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(STATUS_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
export const getListContact = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(CONTACT_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const getListSource = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(LEAD_SOURCE)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const getListTags = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${TAG_URL}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const getListCountries = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${COUNTRY_URL}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const getListCurrency = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(CURRENCY_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const getListStaff = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(STAFF_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const getListItem = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(ITEM_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const getListTax = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(TAX_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const getListItemGroup = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(ITEM_GROUP_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const addItemTable = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(ITEM_URL, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
