import {
    GET_LIST_CREDIT_NOTE_FAILED,
    GET_LIST_CREDIT_NOTE_PENDING,
    GET_LIST_CREDIT_NOTE_SUCCESS,
} from "./type";

const INIT_STATE = {
    listCreditNotes: {},
};
const CreditNotesReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_CREDIT_NOTE_PENDING:
            return state;
        case GET_LIST_CREDIT_NOTE_SUCCESS:
            return { ...state, listCreditNotes: action.payload.data };
        case GET_LIST_CREDIT_NOTE_FAILED:
            return state;

        default:
            return state;
    }
};
export default CreditNotesReducer;
