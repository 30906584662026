// @flow
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
	FETCH_LIST_LEAD_SOURCE,
	FETCH_CREATE_LEAD_SOURCE,
	FETCH_EDIT_LEAD_SOURCE,
	FETCH_DELETE_LEAD_SOURCE,
	FETCH_LIST_LEAD_STATUS,
	FETCH_CREATE_LEAD_STATUS,
	FETCH_EDIT_LEAD_STATUS,
	FETCH_DELETE_LEAD_STATUS,
	FETCH_LEAD_EMAIL_INTEGRATION,
	FETCH_CREATE_LEAD_EMAIL_INTEGRATION,
	FETCH_EDIT_LEAD_EMAIL_INTEGRATION,
	FETCH_DELETE_LEAD_EMAIL_INTEGRATION,
	FETCH_LIST_RESPONSIBLE,
	FETCH_LIST_WEB_LEAD,
	FETCH_WEB_LEAD_BY_ID,
	FETCH_CREATE_WEB_LEAD,
	FETCH_EDIT_WEB_LEAD,
	FETCH_DELETE_WEB_LEAD,
	CREATE_ADMIN_LEADS_SPAM_FILTER_REQUEST,
	EDIT_ADMIN_LEADS_SPAM_FILTER_REQUEST,
	DELETE_ADMIN_LEADS_SPAM_FILTER_REQUEST,
	FETCH_ADMIN_LEADS_SPAM_FILTER_REQUEST,
	GET_ALL_ADMIN_LEADS_SPAM_FILTER_REQUEST,
	GET_COUNT_LEADS_SOURCE_REQUEST,
	GET_COUNT_LEADS_STATUS_REQUEST
} from './types';
import {
	fetchListLeadSourceFailed,
	fetchListLeadSourceSuccess,
	fetchCreateLeadSourceFailed,
	fetchCreateLeadSourceSuccess,
	fetchEditLeadSourceFailed,
	fetchEditLeadSourceSuccess,
	fetchDeleteLeadSourceFailed,
	fetchDeleteLeadSourceSuccess,
	fetchListLeadStatusFailed,
	fetchListLeadStatusSuccess,
	fetchCreateLeadStatusFailed,
	fetchCreateLeadStatusSuccess,
	fetchEditLeadStatusFailed,
	fetchEditLeadStatusSuccess,
	fetchDeleteLeadStatusFailed,
	fetchDeleteLeadStatusSuccess,
	fetchLeadEmailIntegrationFailed,
	fetchLeadEmailIntegrationSuccess,
	fetchCreateLeadEmailIntegrationFailed,
	fetchCreateLeadEmailIntegrationSuccess,
	fetchEditLeadEmailIntegrationFailed,
	fetchEditLeadEmailIntegrationSuccess,
	fetchDeleteLeadEmailIntegrationFailed,
	fetchDeleteLeadEmailIntegrationSuccess,
	fetchListResponsibleFailed,
	fetchListResponsibleSuccess,
	fetchListWebLeadFailed,
	fetchListWebLeadSuccess,
	fetchWebLeadByIdFailed,
	fetchWebLeadByIdSuccess,
	fetchCreateWebLeadFailed,
	fetchCreateWebLeadSuccess,
	fetchEditWebLeadFailed,
	fetchEditWebLeadSuccess,
	fetchDeleteWebLeadFailed,
	fetchDeleteWebLeadSuccess,
	createAdminLeadsSpamFilterSuccess,
	createAdminLeadsSpamFilterFailed,
	editAdminLeadsSpamFilterSuccess,
	editAdminLeadsSpamFilterFailed,
	deleteAdminLeadsSpamFilterSuccess,
	deleteAdminLeadsSpamFilterFailed,
	fetchAdminLeadsSpamFilterSuccess,
	fetchAdminLeadsSpamFilterFailed,
	getAllAdminLeadsSpamFilterSuccess,
	getAllAdminLeadsSpamFilterFailed,
	getCountLeadsSourceFailed,
	getCountLeadsSourceSuccess,
	getCountLeadsStatusFailed,
	getCountLeadsStatusSuccess,
} from './actions';
import { enqueueSnackbar } from '../../../../redux/CommonReducer';
import {
	getListLeadSource,
	createLeadSource,
	editLeadSource,
	deleteLeadSource,
	getListLeadStatus,
	createLeadStatus,
	editLeadStatus,
	deleteLeadStatus,
	getLeadEmailIntegration,
	createLeadEmailIntegration,
	editLeadEmailIntegration,
	deleteLeadEmailIntegration,
	getListResponsible,
	getListWebLead,
	getWebLeadById,
	createWebLead,
	editWebLead,
	deleteWebLead,
	getAdminLeadsSpamFilterTypeofSender,
	getAdminLeadsSpamFilterTypeofSubject,
	getAdminLeadsSpamFilterTypeofPhrase,
	createAdminLeadsSpamFilter,
	editAdminLeadsSpamFilter,
	deleteAdminLeadsSpamFilter,
	fetchAdminLeadsSpamFilter,
	getCountLeadsSource,
	getCountLeadsStatus
} from './api';
import { responeCode, typeMessage } from '../../../../constants/Const';
import { throwErrorMessage, parseDataNotifications } from '../../../../helpers/HandleError';

function* fetchListLeadSourceSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(getListLeadSource, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchListLeadSourceSuccess(response.data.result));
		}
		else {
			yield put(fetchListLeadSourceFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchListLeadSourceFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* getCountLeadsSourceSaga() {
	try {
		const response = yield call(getCountLeadsSource);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(getCountLeadsSourceSuccess(response.data.result));
		}
		else {
			yield put(getCountLeadsSourceFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(getCountLeadsSourceFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* fetchCreateLeadSourceSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(createLeadSource, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchCreateLeadSourceSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(fetchCreateLeadSourceFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchCreateLeadSourceFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* fetchEditLeadSourceSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(editLeadSource, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchEditLeadSourceSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(fetchEditLeadSourceFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchEditLeadSourceFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* fetchDeleteLeadSourceSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(deleteLeadSource, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchDeleteLeadSourceSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(fetchDeleteLeadSourceFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchDeleteLeadSourceFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* fetchListLeadStatusSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(getListLeadStatus, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchListLeadStatusSuccess(response.data.result));
		}
		else {
			yield put(fetchListLeadStatusFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchListLeadStatusFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* getCountLeadsStatusSaga() {
	try {
		const response = yield call(getCountLeadsStatus);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(getCountLeadsStatusSuccess(response.data.result));
		}
		else {
			yield put(getCountLeadsStatusFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(getCountLeadsStatusFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* fetchCreateLeadStatusSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(createLeadStatus, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchCreateLeadStatusSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(fetchCreateLeadStatusFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchCreateLeadStatusFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* fetchEditLeadStatusSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(editLeadStatus, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchEditLeadStatusSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(fetchEditLeadStatusFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchEditLeadStatusFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* fetchDeleteLeadStatusSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(deleteLeadStatus, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchDeleteLeadStatusSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(fetchDeleteLeadStatusFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchDeleteLeadStatusFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* fetchLeadEmailIntegrationSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(getLeadEmailIntegration, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchLeadEmailIntegrationSuccess(response.data.result));
		}
		else {
			yield put(fetchLeadEmailIntegrationFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchLeadEmailIntegrationFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* fetchCreateLeadEmailIntegrationSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(createLeadEmailIntegration, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchCreateLeadEmailIntegrationSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(fetchCreateLeadEmailIntegrationFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchCreateLeadEmailIntegrationFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* fetchEditLeadEmailIntegrationSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(editLeadEmailIntegration, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchEditLeadEmailIntegrationSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(fetchEditLeadEmailIntegrationFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchEditLeadEmailIntegrationFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* fetchDeleteLeadEmailIntegrationSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(deleteLeadEmailIntegration, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchDeleteLeadEmailIntegrationSuccess(response.data.result));
		}
		else {
			yield put(fetchDeleteLeadEmailIntegrationFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchDeleteLeadEmailIntegrationFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* fetchListResponsibleSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(getListResponsible, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchListResponsibleSuccess(response.data.result));
		}
		else {
			yield put(fetchListResponsibleFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchListResponsibleFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* fetchListWebLeadSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(getListWebLead, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchListWebLeadSuccess(response.data.result));
		}
		else {
			yield put(fetchListWebLeadFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchListWebLeadFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* fetchWebLeadByIdSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(getWebLeadById, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchWebLeadByIdSuccess(response.data.result));
		}
		else {
			yield put(fetchWebLeadByIdFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchWebLeadByIdFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* fetchCreateWebLeadSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(createWebLead, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchCreateWebLeadSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(fetchCreateWebLeadFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchCreateWebLeadFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* fetchEditWebLeadSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(editWebLead, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchEditWebLeadSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(fetchEditWebLeadFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchEditWebLeadFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* fetchDeleteWebLeadSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(deleteWebLead, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchDeleteWebLeadSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(fetchDeleteWebLeadFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchDeleteWebLeadFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

//SpamFilter

function* getAllAdminLeadsSpamFilterSaga() {
	try {
		const [blockedSender, blockedSubject, blockedPhrases] = yield all([
			call(getAdminLeadsSpamFilterTypeofSender),
			call(getAdminLeadsSpamFilterTypeofSubject),
			call(getAdminLeadsSpamFilterTypeofPhrase)
		])
		if (blockedSender && blockedSubject && blockedPhrases) {
			if (!blockedSender.data || blockedSender.data.error_code !== responeCode.success || !blockedPhrases.data.result
				|| !blockedSubject.data || blockedSender.data.error_code !== responeCode.success || !blockedSubject.data.result
				|| !blockedPhrases.data || blockedPhrases.data.error_code !== responeCode.success || !blockedPhrases.data.result) {
				yield put(getAllAdminLeadsSpamFilterFailed());
				if (!(blockedSender.data && blockedSender.data.error_code === responeCode.success)) {
					yield put(enqueueSnackbar(parseDataNotifications(blockedSender.data.error_mess, typeMessage.error)));
				}
				if (!(blockedSubject.data && blockedSubject.data.error_code === responeCode.success)) {
					yield put(enqueueSnackbar(parseDataNotifications(blockedSubject.data.error_mess, typeMessage.error)));
				}
				if (!(blockedPhrases.data && blockedPhrases.data.error_code === responeCode.success)) {
					yield put(enqueueSnackbar(parseDataNotifications(blockedPhrases.data.error_mess, typeMessage.error)));
				}
				return;
			} else {
				let listAll = [blockedSender.data.result, blockedSubject.data.result, blockedPhrases.data.result]
				yield put(getAllAdminLeadsSpamFilterSuccess(listAll));
			}
		} else {
			yield put(getAllAdminLeadsSpamFilterFailed());
		}
	} catch (error) {
		yield put(getAllAdminLeadsSpamFilterFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
}


function* createAdminLeadsSpamFilterSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(createAdminLeadsSpamFilter, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(createAdminLeadsSpamFilterSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(createAdminLeadsSpamFilterFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(createAdminLeadsSpamFilterFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* editAdminLeadsSpamFilterSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(editAdminLeadsSpamFilter, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(editAdminLeadsSpamFilterSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));

		}
		else {
			yield put(editAdminLeadsSpamFilterFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(editAdminLeadsSpamFilterFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* deleteAdminLeadsSpamFilterSaga(data) {
	const { payload } = data;

	try {
		const response = yield call(deleteAdminLeadsSpamFilter, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(deleteAdminLeadsSpamFilterSuccess(payload));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(deleteAdminLeadsSpamFilterFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(deleteAdminLeadsSpamFilterFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};
function* fetchAdminLeadsSpamFilterSaga(action) {
	try {
		const response = yield call(fetchAdminLeadsSpamFilter, action);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchAdminLeadsSpamFilterSuccess(response.data.result));
		}
		else {
			yield put(fetchAdminLeadsSpamFilterFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchAdminLeadsSpamFilterFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

export function* adminLeadsSaga() {
	yield takeEvery(FETCH_LIST_LEAD_SOURCE, fetchListLeadSourceSaga);
	yield takeEvery(FETCH_CREATE_LEAD_SOURCE, fetchCreateLeadSourceSaga);
	yield takeEvery(FETCH_EDIT_LEAD_SOURCE, fetchEditLeadSourceSaga);
	yield takeEvery(FETCH_DELETE_LEAD_SOURCE, fetchDeleteLeadSourceSaga);

	yield takeEvery(FETCH_LIST_LEAD_STATUS, fetchListLeadStatusSaga);
	yield takeEvery(FETCH_CREATE_LEAD_STATUS, fetchCreateLeadStatusSaga);
	yield takeEvery(FETCH_EDIT_LEAD_STATUS, fetchEditLeadStatusSaga);
	yield takeEvery(FETCH_DELETE_LEAD_STATUS, fetchDeleteLeadStatusSaga);

	yield takeEvery(FETCH_LEAD_EMAIL_INTEGRATION, fetchLeadEmailIntegrationSaga);
	yield takeEvery(FETCH_CREATE_LEAD_EMAIL_INTEGRATION, fetchCreateLeadEmailIntegrationSaga);
	yield takeEvery(FETCH_EDIT_LEAD_EMAIL_INTEGRATION, fetchEditLeadEmailIntegrationSaga);
	yield takeEvery(FETCH_DELETE_LEAD_EMAIL_INTEGRATION, fetchDeleteLeadEmailIntegrationSaga);

	yield takeEvery(FETCH_LIST_RESPONSIBLE, fetchListResponsibleSaga);

	yield takeEvery(FETCH_LIST_WEB_LEAD, fetchListWebLeadSaga);
	yield takeEvery(FETCH_WEB_LEAD_BY_ID, fetchWebLeadByIdSaga);
	yield takeEvery(FETCH_CREATE_WEB_LEAD, fetchCreateWebLeadSaga);
	yield takeEvery(FETCH_EDIT_WEB_LEAD, fetchEditWebLeadSaga);
	yield takeEvery(FETCH_DELETE_WEB_LEAD, fetchDeleteWebLeadSaga);

	yield takeEvery(GET_ALL_ADMIN_LEADS_SPAM_FILTER_REQUEST, getAllAdminLeadsSpamFilterSaga)
	yield takeEvery(CREATE_ADMIN_LEADS_SPAM_FILTER_REQUEST, createAdminLeadsSpamFilterSaga);
	yield takeEvery(EDIT_ADMIN_LEADS_SPAM_FILTER_REQUEST, editAdminLeadsSpamFilterSaga);
	yield takeEvery(DELETE_ADMIN_LEADS_SPAM_FILTER_REQUEST, deleteAdminLeadsSpamFilterSaga);
	yield takeEvery(FETCH_ADMIN_LEADS_SPAM_FILTER_REQUEST, fetchAdminLeadsSpamFilterSaga);
	yield takeEvery(GET_COUNT_LEADS_SOURCE_REQUEST, getCountLeadsSourceSaga)
	yield takeEvery(GET_COUNT_LEADS_STATUS_REQUEST, getCountLeadsStatusSaga)
}

export default adminLeadsSaga;