const ActionTypes = {
  FETCH_REMINDER_CUSTOMER_REQUEST: "FETCH_REMINDER_CUSTOMER_REQUEST",
  FETCH_REMINDER_CUSTOMER_FAILED: "FETCH_REMINDER_CUSTOMER_FAILED",
  FETCH_REMINDER_CUSTOMER_SUCCESS: "FETCH_REMINDER_CUSTOMER_SUCCESS",

  ADD_REMINDER_CUSTOMER_REQUEST: "ADD_REMINDER_CUSTOMER_REQUEST",
  ADD_REMINDER_CUSTOMER_FAILED: "ADD_REMINDER_CUSTOMER_FAILED",
  ADD_REMINDER_CUSTOMER_SUCCESS: "ADD_REMINDER_CUSTOMER_SUCCESS",

  DELETE_REMINDER_CUSTOMER_REQUEST: "DELETE_REMINDER_CUSTOMER_REQUEST",
  DELETE_REMINDER_CUSTOMER_FAILED: "DELETE_REMINDER_CUSTOMER_FAILED",
  DELETE_REMINDER_CUSTOMER_SUCCESS: "DELETE_REMINDER_CUSTOMER_SUCCESS",
};

export default ActionTypes;
