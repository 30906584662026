//fetch expenses
export const FETCH_EXPENSES_PROJECT = "FETCH_EXPENSES_PROJECT";
export const FETCH_EXPENSES_PROJECT_SUCCESS = "FETCH_EXPENSES_PROJECT_SUCCESS";
export const FETCH_EXPENSES_PROJECT_FAILED = "FETCH_EXPENSES_PROJECT_FAILED";

// filter year with expense

export const FETCH_FILTER_YEAR_EXPENSE_PRJ = "FETCH_FILTER_YEAR_EXPENSE_PRJ";
export const FETCH_FILTER_YEAR_EXPENSE_PRJ_SUCCESS =
  "FETCH_FILTER_YEAR_EXPENSE_PRJ_SUCCESS";
export const FETCH_FILTER_YEAR_EXPENSE_PRJ_FAILED =
  "FETCH_FILTER_YEAR_EXPENSE_PRJ_FAILED";

//filter expenses trong project sale

export const FILTER_PROJECT_EXPENSES_REQUEST =
  "FILTER_PROJECT_EXPENSES_REQUEST";
export const FILTER_PROJECT_EXPENSES_SUCCESS =
  "FILTER_PROJECT_EXPENSES_SUCCESS";
export const FILTER_PROJECT_EXPENSES_FAILED = "FILTER_PROJECT_EXPENSES_FAILED";

// create expense

export const FETCH_CREATE_EXPENSE_PRJ = "FETCH_CREATE_EXPENSE_PRJ";
export const FETCH_CREATE_EXPENSE_PRJ_SUCCESS =
  "FETCH_CREATE_EXPENSE_PRJ_SUCCESS";
export const FETCH_CREATE_EXPENSE_PRJ_FAILED =
  "FETCH_CREATE_EXPENSE_PRJ_FAILED";

// get tax

export const FETCH_TAX = "FETCH_TAX";
export const FETCH_TAX_SUCCESS = "FETCH_TAX_SUCCESS";
export const FETCH_TAX_FAILED = "FETCH_TAX_FAILED";

// get expense category

export const FETCH_EXPENSE_CATEGORY = "FETCH_EXPENSE_CATEGORY";
export const FETCH_EXPENSE_CATEGORY_SUCCESS = "FETCH_EXPENSE_CATEGORY_SUCCESS";
export const FETCH_EXPENSE_CATEGORY_FAILED = "FETCH_EXPENSE_CATEGORY_FAILED";

// get customer

export const FETCH_CUSTOMER = "FETCH_CUSTOMER";
export const FETCH_CUSTOMER_SUCCESS = "FETCH_CUSTOMER_SUCCESS";
export const FETCH_CUSTOMER_FAILED = "FETCH_CUSTOMER_FAILED";

// get payment mode

export const FETCH_PAYMENT_MODE = "FETCH_PAYMENT_MODE";
export const FETCH_PAYMENT_MODE_SUCCESS = "FETCH_PAYMENT_MODE_SUCCESS";
export const FETCH_PAYMENT_MODE_FAILED = "FETCH_PAYMENT_MODE_FAILED";
