import {
  FETCH_DISCUSSION,
  FETCH_DISCUSSION_SUCCESS,
  FETCH_DISCUSSION_FAILED,
  CREATE_DISCUSSION,
  CREATE_DISCUSSION_FAILED,
  CREATE_DISCUSSION_SUCCESS,
  DELETE_DISCUSSION,
  DELETE_DISCUSSION_FAILED,
  DELETE_DISCUSSION_SUCCESS,
  UPDATE_DISCUSSION,
  UPDATE_DISCUSSION_FAILED,
  UPDATE_DISCUSSION_SUCCESS,
  SET_EDIT_DISCUSSION,
} from "./types";

// FETCH DISCUSSION
export const fetchDiscussion = (data) => {
  return {
    type: FETCH_DISCUSSION,
    payload: data,
  };
};

export const fetchDiscussionSuccess = (data) => {
  return {
    type: FETCH_DISCUSSION_SUCCESS,
    payload: data,
  };
};

export const fetchDiscussionFailed = () => {
  return {
    type: FETCH_DISCUSSION_FAILED,
  };
};

export const createDiscussion = (data, id) => {
  return {
    type: CREATE_DISCUSSION,
    payload: data,
    id: id,
  };
};

export const createDiscussionSuccess = (data) => {
  return {
    type: CREATE_DISCUSSION_SUCCESS,
    payload: data
  };
};

export const createDiscussionFailed = () => {
  return {
    type: CREATE_DISCUSSION_FAILED,
  };
};

export const deleteDiscussion = (data) => {
  return {
    type: DELETE_DISCUSSION,
    payload: data,
  };
};

export const deleteDiscussionSuccess = (data) => {
  return {
    type: DELETE_DISCUSSION_SUCCESS,
    payload: data,
  };
};

export const deleteDiscussionFailed = () => {
  return {
    type: DELETE_DISCUSSION_FAILED,
  };
};

export const updateDiscussion = (data, id) => {
  return {
    type: UPDATE_DISCUSSION,
    payload: { data, id },
  };
};

export const updateDiscussionSuccess = (data) => {
  return {
    type: UPDATE_DISCUSSION_SUCCESS,
    payload: data,
  };
};

export const updateDiscussionFailed = (data) => {
  return {
    type: UPDATE_DISCUSSION_FAILED,
    payload: data,
  };
};

export const setEditDiscussion = (data) => {
  return {
    type: SET_EDIT_DISCUSSION,
    payload: data,
  };
};
