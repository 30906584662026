const ActionTypes = {
  FETCH_INVOICE_CUSTOMER_REQUEST: "FETCH_INVOICE_CUSTOMER_REQUEST",
  FETCH_INVOICE_CUSTOMER_FAILED: "FETCH_INVOICE_CUSTOMER_FAILED",
  FETCH_INVOICE_CUSTOMER_SUCCESS: "FETCH_INVOICE_CUSTOMER_SUCCESS",

  FILTER_YEAR_INVOICE_CUSTOMER_REQUEST: "FILTER_YEAR_INVOICE_CUSTOMER_REQUEST",
  FILTER_YEAR_INVOICE_CUSTOMER_FAILED: "FILTER_YEAR_INVOICE_CUSTOMER_FAILED",
  FILTER_YEAR_INVOICE_CUSTOMER_SUCCESS: "FILTER_YEAR_INVOICE_CUSTOMER_SUCCESS",
};

export default ActionTypes;
