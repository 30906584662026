import ActionTypes from "./type";

const INIT_STATE = {
  isLoading: false,
  listProposal: [],
};

const ProposalsCustomerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_PROPOSAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_PROPOSAL_SUCCESS:
      return {
        ...state,
        listProposal: action.data,
        isLoading: false,
      };
    case ActionTypes.FETCH_PROPOSAL_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return { ...state };
  }
};

export default ProposalsCustomerReducer;
