const ActionTypes = {
    GET_LIST_SUPPORT_SPAM_FILTER_REQUEST: 'GET_LIST_SUPPORT_SPAM_FILTER_REQUEST',
    GET_LIST_SUPPORT_SPAM_FILTER_FAILED: 'GET_LIST_SUPPORT_SPAM_FILTER_FAILED',
    GET_LIST_SUPPORT_SPAM_FILTER_SUCCESS: 'GET_LIST_SUPPORT_SPAM_FILTER_SUCCESS',
    GET_ALL_SUPPORT_SPAM_FILTER_REQUEST: 'GET_ALL_SUPPORT_SPAM_FILTER_REQUEST',
    GET_ALL_SUPPORT_SPAM_FILTER_FAILED: 'GET_ALL_SUPPORT_SPAM_FILTER_FAILED',
    GET_ALL_SUPPORT_SPAM_FILTER_SUCCESS: 'GET_ALL_SUPPORT_SPAM_FILTER_SUCCESS',

    CREATE_SUPPORT_SPAM_FILTER_REQUEST: 'CREATE_SUPPORT_SPAM_FILTER_REQUEST',
    CREATE_SUPPORT_SPAM_FILTER_FAILED: 'CREATE_SUPPORT_SPAM_FILTER_FAILED',
    CREATE_SUPPORT_SPAM_FILTER_SUCCESS: 'CREATE_SUPPORT_SPAM_FILTER_SUCCESS',

    EDIT_SUPPORT_SPAM_FILTER_REQUEST: 'EDIT_SUPPORT_SPAM_FILTER_REQUEST',
    EDIT_SUPPORT_SPAM_FILTER_FAILED: 'EDIT_SUPPORT_SPAM_FILTER_FAILED',
    EDIT_SUPPORT_SPAM_FILTER_SUCCESS: 'EDIT_SUPPORT_SPAM_FILTER_SUCCESS',

    DELETE_SUPPORT_SPAM_FILTER_REQUEST: 'DELETE_SUPPORT_SPAM_FILTER_REQUEST',
    DELETE_SUPPORT_SPAM_FILTER_FAILED: 'DELETE_SUPPORT_SPAM_FILTER_FAILED',
    DELETE_SUPPORT_SPAM_FILTER_SUCCESS: 'DELETE_SUPPORT_SPAM_FILTER_SUCCESS',

    FETCH_SUPPORT_SPAM_FILTER_REQUEST: 'FETCH_SUPPORT_SPAM_FILTER_REQUEST',
    FETCH_SUPPORT_SPAM_FILTER_FAILED: 'FETCH_SUPPORT_SPAM_FILTER_FAILED',
    FETCH_SUPPORT_SPAM_FILTER_SUCCESS: 'FETCH_SUPPORT_SPAM_FILTER_SUCCESS',
    CHANGE_STATUS_DIALOG: 'CHANGE_STATUS_DIALOG'
};
export default ActionTypes;
