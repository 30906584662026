import apiBase from "./../../../common/baseAPI";

const PATH_URL = "staff";
const PATH_STAFF_NOTE = "note/staff";
const PATH_STAFF_TIMESHEET = "task-timer/staff";
const PATH_STAFF_SET_STATUS = "staff/active";

// const PATH_ADD_STAFF_NOTE = ''
const getListStaff = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(PATH_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createStaff = (data) => {
    let newFormData = new FormData();
    // var postData = JSON.stringify(data.department);
    // newFormData.append("department", postData);
    // for (var i = 0; i < data.department.length; i++) {
    //     newFormData.append("department[]", data.department[i]);
    // }
    const departmentLength = data.department.length;
    if (departmentLength) {
        for (var i = 0; i < departmentLength; i++) {
            newFormData.append(
                `department[${i}][id]`,
                data.department[i] || ""
            );
        }
    }
    newFormData.append("profile_image", data.profile_image || "");
    newFormData.append("first_name", data.first_name);
    newFormData.append("last_name", data.last_name);
    newFormData.append(
        "two_factor_auth_enabled",
        data.two_factor_auth_enabled || ""
    );
    newFormData.append("email", data.email || "");
    newFormData.append("facebook", data.facebook || "");
    newFormData.append("linkedin", data.linkedin || "");
    newFormData.append("phonenumber", data.phonenumber || "");
    newFormData.append("skype", data.skype || "");
    newFormData.append("password", data.password || "");
    newFormData.append("admin", data.admin || "");
    newFormData.append("default_language", data.default_language || "");
    newFormData.append("direction", data.direction || "");
    newFormData.append("hourly_rate", data.hourly_rate || "");
    newFormData.append("email_signature", data.email_signature || "");
    // newFormData.append("department", data.department);

    // const header = { "Content-Type": "multipart/form-data" };
    const header = { "Content-Type": "multipart/form-data" };

    return new Promise((resolve, reject) => {
        return apiBase
            .post(PATH_URL, newFormData, header)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const editStaff = (data) => {
    const newFormData = new FormData();

    newFormData.append("profile_image", data.profile_image);
    newFormData.append("first_name", data.first_name);
    newFormData.append("last_name", data.last_name);
    newFormData.append("two_factor_auth_enabled", data.two_factor_auth_enabled);
    newFormData.append("email", data.email);
    newFormData.append("facebook", data.facebook);
    newFormData.append("linkedin", data.linkedin);
    newFormData.append("phonenumber", data.phonenumber);
    newFormData.append("skype", data.skype);
    newFormData.append("password", data.password);
    newFormData.append("admin", data.admin);
    newFormData.append("default_language", data.default_language);
    newFormData.append("direction", data.direction);
    newFormData.append("hourly_rate", data.hourly_rate);
    newFormData.append("email_signature", data.email_signature);
    newFormData.append("department[]", data.department);
    const header = { "Content-Type": "multipart/form-data" };
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${PATH_URL}/${data.staff_id}`, newFormData, header)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteStaff = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${PATH_URL}/${data}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const filterStaff = (action) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(
                `${PATH_URL}?search=${action.search}&&limit=${action.params.limit}&&page=${action.params.page}`
            )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getDepartments = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get("department")
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getStaffEdit = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${PATH_URL}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const getStaffNote = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${PATH_STAFF_NOTE}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const addStaffNote = (id, params) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${PATH_STAFF_NOTE}/${id}`, params) //description: {description:"abc"}
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const getStaffTimesheet = (id, params) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${PATH_STAFF_TIMESHEET}/${id}`, params)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const setStaffStatus = (id, params) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${PATH_STAFF_SET_STATUS}/${id}`, params)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
export {
    getListStaff,
    createStaff,
    editStaff,
    deleteStaff,
    filterStaff,
    getDepartments,
    getStaffEdit,
    getStaffNote,
    addStaffNote,
    getStaffTimesheet,
    setStaffStatus,
};
