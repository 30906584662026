// @flow
import { Cookies } from "react-cookie";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
    LOGIN_USER,
    LOGOUT_USER,
    REGISTER_USER,
    FORGET_PASSWORD,
} from "../../constants/actionTypes";

import {
    loginUserSuccess,
    loginUserFailed,
    registerUserSuccess,
    registerUserFailed,
    forgetPasswordSuccess,
    forgetPasswordFailed,
} from "./actions";
import apiBase from "../../common/baseAPI";
const LOGIN_URL = "login";
/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
// const fetchJSON = (url, options = {}) => {
//     return fetch(url, options)
//         .then((response) => {
//             if (!response.status === 200) {
//                 throw response.json();
//             }
//             return response.json();
//         })
//         .then((json) => {
//             return json;
//         })
//         .catch((error) => {
//             throw error;
//         });
// };
const fetchJSON = (payload) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${LOGIN_URL}`, payload)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
/**
 * Sets the session
 * @param {*} user
 */
const setSession = (user) => {
    let cookies = new Cookies();
    if (user) cookies.set("user", JSON.stringify(user), { path: "/" });
    else cookies.remove("user");
};
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload }) {
    const { username, password } = payload;
    // const options = {
    //     body: JSON.stringify({ username, password }),
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    // };
    const params = {
        email: payload.username,
        password: payload.password,
    };
    try {
        // const response = yield call(fetchJSON, "/users/authenticate", options);
        const response = yield call(fetchJSON, params);
        console.log(response);
        // {
        //     "id": 1,
        //     "username": "test",
        //     "firstName": "Test",
        //     "lastName": "User",
        //     "role": "Admin",
        //     "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJDb2RlcnRoZW1lIiwiaWF0IjoxNTU1NjgyNTc1LCJleHAiOjE1ODcyMTg1NzUsImF1ZCI6ImNvZGVydGhlbWVzLmNvbSIsInN1YiI6InRlc3QiLCJmaXJzdG5hbWUiOiJIeXBlciIsImxhc3RuYW1lIjoiVGVzdCIsIkVtYWlsIjoidGVzdEBoeXBlci5jb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4ifQ.8qHJDbs5nw4FBTr3F8Xc1NJYOMSJmGnRma7pji0YwB4"
        // }
        // if (response.data && response.data.error_code === 1) {
        if (response.data && response.data.errors === 1) {
            yield put(loginUserFailed(response.data.error_mess));
        } else {
            const objectUser = {
                id: response.data.result.user.id,
                username: response.data.result.user.email,
                firstName: response.data.result.user.name,
                // lastName: response.data.lastName,
                // role: response.data.roles[0].name,
                "role": "Admin",
                token: response.data.result.access_token,
            };
            setSession(objectUser);
            console.log(objectUser);
            yield put(loginUserSuccess(objectUser));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = "Internal Server Error";
                break;
            case 401:
                message = "Invalid credentials";
                break;
            default:
                message = error;
        }
        yield put(loginUserFailed(message));
        setSession(null);
    }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
    try {
        setSession(null);
        yield call(() => {
            history.push("/login");
        });
    } catch (error) {}
}

/**
 * Register the user
 */
function* register({ payload: { fullname, email, password } }) {
    const options = {
        body: JSON.stringify({ fullname, email, password }),
        method: "POST",
        headers: { "Content-Type": "application/json" },
    };

    try {
        const response = yield call(fetchJSON, "/users/register", options);
        yield put(registerUserSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = "Internal Server Error";
                break;
            case 401:
                message = "Invalid credentials";
                break;
            default:
                message = error;
        }
        yield put(registerUserFailed(message));
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { username } }) {
    const options = {
        body: JSON.stringify({ username }),
        method: "POST",
        headers: { "Content-Type": "application/json" },
    };

    try {
        const response = yield call(
            fetchJSON,
            "/users/password-reset",
            options
        );
        yield put(forgetPasswordSuccess(response.message));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = "Internal Server Error";
                break;
            case 401:
                message = "Invalid credentials";
                break;
            default:
                message = error;
        }
        yield put(forgetPasswordFailed(message));
    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgetPassword),
    ]);
}

export default authSaga;
