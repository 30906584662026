import ActionTypes from "./type";

export const fetchReminderCustomerRequest = (id, value, params) => {
  return {
    type: ActionTypes.FETCH_REMINDER_CUSTOMER_REQUEST,
    id,
    value,
    params,
  };
};

export const fetchReminderCustomerSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_REMINDER_CUSTOMER_SUCCESS,
    data,
  };
};

export const fetchReminderCustomerFailed = () => {
  return {
    type: ActionTypes.FETCH_REMINDER_CUSTOMER_FAILED,
  };
};

export const addReminderCustomerRequest = (id, data) => {
  return {
    type: ActionTypes.ADD_REMINDER_CUSTOMER_REQUEST,
    id,
    data,
  };
};

export const addReminderCustomerSuccess = (data) => {
  return {
    type: ActionTypes.ADD_REMINDER_CUSTOMER_SUCCESS,
    data,
  };
};

export const addReminderCustomerFailed = () => {
  return {
    type: ActionTypes.ADD_REMINDER_CUSTOMER_FAILED,
  };
};

export const deleteReminderCustomerRequest = (id) => {
  return {
    type: ActionTypes.DELETE_REMINDER_CUSTOMER_REQUEST,
    id,
  };
};

export const deleteReminderCustomerSuccess = () => {
  return {
    type: ActionTypes.DELETE_REMINDER_CUSTOMER_SUCCESS,
  };
};

export const deleteReminderCustomerFailed = () => {
  return {
    type: ActionTypes.DELETE_REMINDER_CUSTOMER_FAILED,
  };
};
