import {
    FETCH_LIST_EXPENSES,
    FETCH_LIST_EXPENSES_SUCCESS,
    FETCH_LIST_EXPENSES_FAILED,
    GET_EXPENSE_ITEM,
    SET_ISSUCCESS,
    FETCH_EXPENSE_BY_ID,
    FETCH_EXPENSE_BY_ID_SUCCESS,
    FETCH_EXPENSE_BY_ID_FAILED,
    FETCH_DELETE_EXPENSE,
    FETCH_DELETE_EXPENSE_SUCCESS,
    FETCH_DELETE_EXPENSE_FAILED,
    FETCH_PAYMENT_MODE,
    FETCH_PAYMENT_MODE_SUCCESS,
    FETCH_PAYMENT_MODE_FAILED,
    FETCH_LIST_CUSTOMER,
    FETCH_LIST_CUSTOMER_SUCCESS,
    FETCH_LIST_CUSTOMER_FAILED,
    FETCH_LIST_EXPENSES_CATEGORY,
    FETCH_LIST_EXPENSES_CATEGORY_SUCCESS,
    FETCH_LIST_EXPENSES_CATEGORY_FAILED,
    FETCH_CREATE_EXPENSES,
    FETCH_CREATE_EXPENSES_SUCCESS,
    FETCH_CREATE_EXPENSES_FAILED,
    FETCH_EDIT_EXPENSES,
    FETCH_EDIT_EXPENSES_SUCCESS,
    FETCH_EDIT_EXPENSES_FAILED,
    FETCH_LIST_PROJECT,
    FETCH_LIST_PROJECT_SUCCESS,
    FETCH_LIST_PROJECT_FAILED,
    FETCH_LIST_SUMMARY,
    FETCH_LIST_SUMMARY_SUCCESS,
    FETCH_LIST_SUMMARY_FAILED,
} from "./types";

export const fetchListExpenses = (data) => {
    return {
        type: FETCH_LIST_EXPENSES,
        payload: data,
    };
};

export const fetchListExpensesSuccess = (data) => {
    return {
        type: FETCH_LIST_EXPENSES_SUCCESS,
        payload: data,
    };
};

export const fetchListExpensesFailed = () => {
    return {
        type: FETCH_LIST_EXPENSES_FAILED,
    };
};

export const getExpense = (item) => {
    return {
        type: GET_EXPENSE_ITEM,
        payload: item,
    };
};

export const setIsSuccess = (item) => {
    return {
        type: SET_ISSUCCESS,
        payload: item,
    };
};

export const fetchExpenseById = (data) => {
    return {
        type: FETCH_EXPENSE_BY_ID,
        payload: data,
    };
};

export const fetchExpenseByIdSuccess = (data) => {
    return {
        type: FETCH_EXPENSE_BY_ID_SUCCESS,
        payload: data,
    };
};

export const fetchExpenseByIdFailed = () => {
    return {
        type: FETCH_EXPENSE_BY_ID_FAILED,
    };
};

export const fetchDeleteExpense = (data) => {
    return {
        type: FETCH_DELETE_EXPENSE,
        payload: data,
    };
};

export const fetchDeleteExpenseSuccess = (data) => {
    return {
        type: FETCH_DELETE_EXPENSE_SUCCESS,
        payload: data,
    };
};

export const fetchDeleteExpenseFailed = () => {
    return {
        type: FETCH_DELETE_EXPENSE_FAILED,
    };
};

export const fetchPaymentMode = (data) => {
    return {
        type: FETCH_PAYMENT_MODE,
        payload: data,
    };
};

export const fetchPaymentModeSuccess = (data) => {
    return {
        type: FETCH_PAYMENT_MODE_SUCCESS,
        payload: data,
    };
};

export const fetchPaymentModeFailed = () => {
    return {
        type: FETCH_PAYMENT_MODE_FAILED,
    };
};

export const fetchListCustomer = (data) => {
    return {
        type: FETCH_LIST_CUSTOMER,
        payload: data,
    };
};

export const fetchListCustomerSuccess = (data) => {
    return {
        type: FETCH_LIST_CUSTOMER_SUCCESS,
        payload: data,
    };
};

export const fetchListCustomerFailed = () => {
    return {
        type: FETCH_LIST_CUSTOMER_FAILED,
    };
};

export const fetchListExpensesCategory = (data) => {
    return {
        type: FETCH_LIST_EXPENSES_CATEGORY,
        payload: data,
    };
};

export const fetchListExpensesCategorySuccess = (data) => {
    return {
        type: FETCH_LIST_EXPENSES_CATEGORY_SUCCESS,
        payload: data,
    };
};

export const fetchListExpensesCategoryFailed = () => {
    return {
        type: FETCH_LIST_EXPENSES_CATEGORY_FAILED,
    };
};

export const fetchCreateExpenses = (data) => {
    return {
        type: FETCH_CREATE_EXPENSES,
        payload: data,
    };
};

export const fetchCreateExpensesSuccess = (data) => {
    return {
        type: FETCH_CREATE_EXPENSES_SUCCESS,
        payload: data,
    };
};

export const fetchCreateExpensesFailed = () => {
    return {
        type: FETCH_CREATE_EXPENSES_FAILED,
    };
};

export const fetchEditExpenses = (id, data) => {
    return {
        type: FETCH_EDIT_EXPENSES,
        id: id,
        data: data,
    };
};

export const fetchEditExpensesSuccess = (data) => {
    return {
        type: FETCH_EDIT_EXPENSES_SUCCESS,
        payload: data,
    };
};

export const fetchEditExpensesFailed = () => {
    return {
        type: FETCH_EDIT_EXPENSES_FAILED,
    };
};

export const fetchListProject = (data) => {
    return {
        type: FETCH_LIST_PROJECT,
        payload: data,
    };
};

export const fetchListProjectSuccess = (data) => {
    return {
        type: FETCH_LIST_PROJECT_SUCCESS,
        payload: data,
    };
};

export const fetchListProjectFailed = () => {
    return {
        type: FETCH_LIST_PROJECT_FAILED,
    };
};

export const fetchListSummary = (data) => {
    return {
        type: FETCH_LIST_SUMMARY,
        payload: data,
    };
};

export const fetchListSummarySuccess = (data) => {
    return {
        type: FETCH_LIST_SUMMARY_SUCCESS,
        payload: data,
    };
};

export const fetchListSummaryFailed = () => {
    return {
        type: FETCH_LIST_SUMMARY_FAILED,
    };
};
