// import { startcase ActionTypes.} from "lodash";
import ActionTypes from "./types";
import _ from "lodash";

const INIT_STATE = {
  isLoading: false,
  listActivity: [],
};

const ProjectActivityReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_PROJECT_ACTIVITY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_PROJECT_ACTIVITY_SUCCESS:
      return {
        ...state,
        listActivity: action.payload,
        isLoading: false,
      };

    case ActionTypes.GET_PROJECT_ACTIVITY_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.EDIT_PROJECT_ACTIVITY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.EDIT_PROJECT_ACTIVITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.EDIT_PROJECT_ACTIVITY_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return { ...state };
  }
};

export default ProjectActivityReducer;
