const ActionTypes = {
  FETCH_LIST_PROJECTS: "FETCH_LIST_PROJECTS",
  FETCH_LIST_PROJECTS_FAILED: "FETCH_LIST_PROJECTS_FAILED",
  FETCH_LIST_PROJECTS_SUCCESS: "FETCH_LIST_PROJECTS_SUCCESS",

  GET_PROJECT_ID: "GET_PROJECT_ID",

  FETCH_PROJECT_BY_ID: "FETCH_PROJECT_BY_ID",
  FETCH_PROJECT_BY_ID_FAILED: "FETCH_PROJECT_BY_ID_FAILED",
  FETCH_PROJECT_BY_ID_SUCCESS: "FETCH_PROJECT_BY_ID_SUCCESS",

  FETCH_TIMESHEET_PROJECT: "FETCH_TIMESHEET_PROJECT",
  FETCH_TIMESHEET_PROJECT_SUCCESS: "FETCH_TIMESHEET_PROJECT_SUCCESS",
  FETCH_TIMESHEET_PROJECT_FAILED: "FETCH_TIMESHEET_PROJECT_FAILED",

  FETCH_DELETE_TIMESHEET_PROJECT: "FETCH_DELETE_TIMESHEET_PROJECT",
  FETCH_DELETE_TIMESHEET_PROJECT_SUCCESS:
    "FETCH_DELETE_TIMESHEET_PROJECT_SUCCESS",
  FETCH_DELETE_TIMESHEET_PROJECT_FAILED:
    "FETCH_DELETE_TIMESHEET_PROJECT_FAILED",

  FETCH_TASK_BY_PROJECT_TIMESHEET: "FETCH_TASK_BY_PROJECT_TIMESHEET",
  FETCH_TASK_BY_PROJECT_TIMESHEET_SUCCESS:
    "FETCH_TASK_BY_PROJECT_TIMESHEET_SUCCESS",
  FETCH_TASK_BY_PROJECT_TIMESHEET_FAILED:
    "FETCH_TASK_BY_PROJECT_TIMESHEET_FAILED",

  FETCH_STAFF_BY_TASK_TIMESHEET: "FETCH_STAFF_BY_TASK_TIMESHEET",
  FETCH_STAFF_BY_TASK_TIMESHEET_SUCCESS:
    "FETCH_STAFF_BY_TASK_TIMESHEET_SUCCESS",
  FETCH_STAFF_BY_TASK_TIMESHEET_FAILED: "FETCH_STAFF_BY_TASK_TIMESHEET_FAILED",

  FETCH_CREATE_TIMESHEET_PROJECT: "FETCH_CREATE_TIMESHEET_PROJECT",
  FETCH_CREATE_TIMESHEET_PROJECT_SUCCESS:
    "FETCH_CREATE_TIMESHEET_PROJECT_SUCCESS",
  FETCH_CREATE_TIMESHEET_PROJECT_FAILED:
    "FETCH_CREATE_TIMESHEET_PROJECT_FAILED",

  FETCH_EDIT_TIMESHEET_PROJECT: "FETCH_EDIT_TIMESHEET_PROJECT",
  FETCH_EDIT_TIMESHEET_PROJECT_SUCCESS: "FETCH_EDIT_TIMESHEET_PROJECT_SUCCESS",
  FETCH_EDIT_TIMESHEET_PROJECT_FAILED: "FETCH_EDIT_TIMESHEET_PROJECT_FAILED",

  FETCH_FILTER_BY_MEMBER_TIMESHEET_PROJECT:
    "FETCH_FILTER_BY_MEMBER_TIMESHEET_PROJECT",
  FETCH_FILTER_BY_MEMBER_TIMESHEET_PROJECT_SUCCESS:
    "FETCH_FILTER_BY_MEMBER_TIMESHEET_PROJECT_SUCCESS",
  FETCH_FILTER_BY_MEMBER_TIMESHEET_PROJECT_FAILED:
    "FETCH_FILTER_BY_MEMBER_TIMESHEET_PROJECT_FAILED",

  // FILTER DISCUSSION
  FILTER_DISCUSSION: "FILTER_DISCUSSION",
  FILTER_DISCUSSION_SUCCESS: "FILTER_DISCUSSION_SUCCESS",
  FILTER_DISCUSSION_FAILED: "FILTER_DISCUSSION_FAILED",

  //fetch subscription
  FETCH_SUBSCRIPTIONS_PROJECT: "FETCH_SUBSCRIPTIONS_PROJECT",
  FETCH_SUBSCRIPTIONS_PROJECT_SUCCESS: "FETCH_SUBSCRIPTIONS_PROJECT_SUCCESS",
  FETCH_SUBSCRIPTIONS_PROJECT_FAILED: "FETCH_SUBSCRIPTIONS_PROJECT_FAILED",

  //fetch tickets
  FETCH_TICKETS_PROJECT: "FETCH_TICKETS_PROJECT",
  FETCH_TICKETS_PROJECT_SUCCESS: "FETCH_TICKETS_PROJECT_SUCCESS",
  FETCH_TICKETS_PROJECT_FAILED: "FETCH_TICKETS_PROJECT_FAILED",

  //fetch filter by status

  FETCH_FILTER_BY_STATUS_TICKET_PROJECT:
    "FETCH_FILTER_BY_STATUS_TICKET_PROJECT",
  FETCH_FILTER_BY_STATUS_TICKET_PROJECT_SUCCESS:
    "FETCH_FILTER_BY_STATUS_TICKET_PROJECT_SUCCESS",
  FETCH_FILTER_BY_STATUS_TICKET_PROJECT_FAILED:
    "FETCH_FILTER_BY_STATUS_TICKET_PROJECT_FAILED",

  //Milestone

  GET_PROJECT_MILESTONE_REQUEST: "GET_PROJECT_MILESTONE_REQUEST",
  GET_PROJECT_MILESTONE_SUCCESS: "GET_PROJECT_MILESTONE_SUCCESS",
  GET_PROJECT_MILESTONE_FAILED: "GET_PROJECT_MILESTONE_FAILED",

  CREATE_PROJECT_MILESTONE_REQUEST: "CREATE_PROJECT_MILESTONE_REQUEST",
  CREATE_PROJECT_MILESTONE_SUCCESS: "CREATE_PROJECT_MILESTONE_SUCCESS",
  CREATE_PROJECT_MILESTONE_FAILED: "CREATE_PROJECT_MILESTONE_FAILED",
  EDIT_PROJECT_MILESTONE_REQUEST: "EDIT_PROJECT_MILESTONE_REQUEST",
  EDIT_PROJECT_MILESTONE_SUCCESS: "EDIT_PROJECT_MILESTONE_SUCCESS",
  EDIT_PROJECT_MILESTONE_FAILED: "EDIT_PROJECT_MILESTONE_FAILED",
  DELETE_PROJECT_MILESTONE_REQUEST: "DELETE_PROJECT_MILESTONE_REQUEST",
  DELETE_PROJECT_MILESTONE_SUCCESS: "DELETE_PROJECT_MILESTONE_SUCCESS",
  DELETE_PROJECT_MILESTONE_FAILED: "DELETE_PROJECT_MILESTONE_FAILED",
  FETCH_PROJECT_MILESTONE_REQUEST: "FETCH_PROJECT_MILESTONE_REQUEST",
  FETCH_PROJECT_MILESTONE_SUCCESS: "FETCH_PROJECT_MILESTONE_SUCCESS",
  FETCH_PROJECT_MILESTONE_FAILED: "FETCH_PROJECT_MILESTONE_FAILED",
  CHANGE_STATUS_SUCCESS_MILESTONE: "CHANGE_STATUS_SUCCESS_MILESTONE",

  //get api tag
  GET_PROJECT_TAG_REQUEST: "GET_PROJECT_TAG_REQUEST",
  GET_PROJECT_TAG_SUCCESS: "GET_PROJECT_TAG_SUCCESS",
  GET_PROJECT_TAG_FAILED: "GET_PROJECT_TAG_FAILED",

  //get open task trong màn project-view- overview
  GET_PROJECT_OVERVIEW_TASK_REQUEST: "GET_PROJECT_OVERVIEW_TASK_REQUEST",
  GET_PROJECT_OVERVIEW_TASK_SUCCESS: "GET_PROJECT_OVERVIEW_TASK_SUCCESS",
  GET_PROJECT_OVERVIEW_TASK_FAILED: "GET_PROJECT_OVERVIEW_TASK_FAILED",

  //get count Dayleft trong màn project-view- overview
  GET_PROJECT_OVERVIEW_COUNT_REQUEST: "GET_PROJECT_OVERVIEW_COUNT_REQUEST",
  GET_PROJECT_OVERVIEW_COUNT_SUCCESS: "GET_PROJECT_OVERVIEW_COUNT_SUCCESS",
  GET_PROJECT_OVERVIEW_COUNT_FAILED: "GET_PROJECT_OVERVIEW_COUNT_FAILED",

  //get summary project
  GET_PROJECT_SUMMARY_REQUEST: "GET_PROJECT_SUMMARY_REQUEST",
  GET_PROJECT_SUMMARY_SUCCESS: "GET_PROJECT_SUMMARY_SUCCESS",
  GET_PROJECT_SUMMARY_FAILED: "GET_PROJECT_SUMMARY_FAILED",

  CREATE_PROJECT_REQUEST: "CREATE_PROJECT_REQUEST",
  CREATE_PROJECT_SUCCESS: "CREATE_PROJECT_SUCCESS",
  CREATE_PROJECT_FAILED: "CREATE_PROJECT_FAILED",
  EDIT_PROJECT_REQUEST: "EDIT_PROJECT_REQUEST",
  EDIT_PROJECT_SUCCESS: "EDIT_PROJECT_SUCCESS",
  EDIT_PROJECT_FAILED: "EDIT_PROJECT_FAILED",
  DELETE_PROJECT_REQUEST: "DELETE_PROJECT_REQUEST",
  DELETE_PROJECT_SUCCESS: "DELETE_PROJECT_SUCCESS",
  DELETE_PROJECT_FAILED: "DELETE_PROJECT_FAILED",
  SEARCH_PROJECT_REQUEST: "SEARCH_PROJECT_REQUEST",
  SEARCH_PROJECT_SUCCESS: "SEARCH_PROJECT_SUCCESS",
  SEARCH_PROJECT_FAILED: "SEARCH_PROJECT_FAILED",
  COPY_PROJECT_FAILED: "SEARCH_PROJECT_FAILED",
  COPY_PROJECT_REQUEST: "SEARCH_PROJECT_REQUEST",
  COPY_PROJECT_SUCCESS: "SEARCH_PROJECT_SUCCESS",
  //get staff
  GET_ALL_NEW_PROJECT_REQUEST: "GET_ALL_NEW_PROJECT_REQUEST",
  GET_ALL_NEW_PROJECT_SUCCESS: "GET_ALL_NEW_PROJECT_SUCCESS",
  GET_ALL_NEW_PROJECT_FAILED: "GET_ALL_NEW_PROJECT_FAILED",

  //project FILE
  CREATE_PROJECT_FILES_REQUEST: "CREATE_PROJECT_FILES_REQUEST",
  CREATE_PROJECT_FILES_SUCCESS: "CREATE_PROJECT_FILES_SUCCESS",
  CREATE_PROJECT_FILES_FAILED: "CREATE_PROJECT_FILES_FAILED",
  CHANGE_FILES_VISIBLE_REQUEST: "CHANGE_FILES_VISIBLE_REQUEST",
  CHANGE_FILES_VISIBLE_SUCCESS: "CHANGE_FILES_VISIBLE_SUCCESS",
  CHANGE_FILES_VISIBLE_FAILED: "CHANGE_FILES_VISIBLE_FAILED",
  DELETE_PROJECT_FILES_REQUEST: "DELETE_PROJECT_FILES_REQUEST",
  DELETE_PROJECT_FILES_SUCCESS: "DELETE_PROJECT_FILES_SUCCESS",
  DELETE_PROJECT_FILES_FAILED: "DELETE_PROJECT_FILES_FAILED",
  SEARCH_PROJECT_FILES_REQUEST: "SEARCH_PROJECT_FILES_REQUEST",
  SEARCH_PROJECT_FILES_SUCCESS: "SEARCH_PROJECT_FILES_SUCCESS",
  SEARCH_PROJECT_FILES_FAILED: "SEARCH_PROJECT_FILES_FAILED",
  GET_PROJECT_FILES_REQUEST: "GET_PROJECT_FILES_REQUEST",
  GET_PROJECT_FILES_SUCCESS: "GET_PROJECT_FILES_SUCCESS",
  GET_PROJECT_FILES_FAILED: "GET_PROJECT_FILES_FAILED",
  //get all man view
  GET_ALL_PROJECT_VIEW_REQUEST: "GET_ALL_PROJECT_VIEW_REQUEST",
  GET_ALL_PROJECT_VIEW_SUCCESS: "GET_ALL_PROJECT_VIEW_SUCCESS",
  GET_ALL_PROJECT_VIEW_FAILED: "GET_ALL_PROJECT_VIEW_FAILED",

  //get all project view newticket
  GET_ALL_PROJECT_VIEW_NEW_TICKET_REQUEST:
    "GET_ALL_PROJECT_VIEW_NEW_TICKET_REQUEST",
  GET_ALL_PROJECT_VIEW_NEW_TICKET_SUCCESS:
    "GET_ALL_PROJECT_VIEW_NEW_TICKET_SUCCESS",
  GET_ALL_PROJECT_VIEW_NEW_TICKET_FAILED:
    "GET_ALL_PROJECT_VIEW_NEW_TICKET_FAILED",

  //create new ticket,delete Ticket,ticket summary
  CREATE_PROJECT_NEW_TICKET_REQUEST: "CREATE_PROJECT_NEW_TICKET_REQUEST",
  CREATE_PROJECT_NEW_TICKET_SUCCESS: "CREATE_PROJECT_NEW_TICKET_SUCCESS",
  CREATE_PROJECT_NEW_TICKET_FAILED: "CREATE_PROJECT_NEW_TICKET_FAILED",
  DELETE_PROJECT_TICKET_REQUEST: "DELETE_PROJECT_TICKET_REQUEST",
  DELETE_PROJECT_TICKET_SUCCESS: "DELETE_PROJECT_TICKET_SUCCESS",
  DELETE_PROJECT_TICKET_FAILED: "DELETE_PROJECT_TICKET_FAILED",
  GET_PROJECT_TICKET_SUMMARY_REQUEST: "GET_PROJECT_TICKET_SUMMARY_REQUEST",
  GET_PROJECT_TICKET_SUMMARY_SUCCESS: "GET_PROJECT_TICKET_SUMMARY_SUCCESS",
  GET_PROJECT_TICKET_SUMMARY_FAILED: "GET_PROJECT_TICKET_SUMMARY_FAILED",

  // add member project overview
  ADD_MEMBER_PROJECT_PENDING: "ADD_MEMBER_PENDING",
  ADD_MEMBER_PROJECT_SUCCESS: "ADD_MEMBER_SUCCESS",
  ADD_MEMBER_PROJECT_FAILURE: "ADD_MEMBER_FAILURE",
  // delete member project overview
  DELETE_MEMBER_PROJECT_PENDING: "DELETE_MEMBER_PENDING",
  DELETE_MEMBER_PROJECT_SUCCESS: "DELETE_MEMBER_SUCCESS",
  DELETE_MEMBER_PROJECT_FAILURE: "DELETE_MEMBER_FAILURE",
  //staffProject
  FETCH_STAFFS_OF_PROJECT_PENDING: "FETCH_STAFFS_OF_PROJECT_PENDING",
  FETCH_STAFFS_OF_PROJECT_SUCCESS: "FETCH_STAFFS_OF_PROJECT_SUCCESS",
  FETCH_STAFFS_OF_PROJECT_FAILURE: "FETCH_STAFFS_OF_PROJECT_FAILURE",
  //download file
  DOWNLOAD_FILE_OF_PROJECT_PENDING: "DOWNLOAD_FILE_OF_PROJECT_PENDING",
  DOWNLOAD_FILE_OF_PROJECT_SUCCESS: "DOWNLOAD_FILE_OF_PROJECT_SUCCESS",
  DOWNLOAD_FILE_OF_PROJECT_FAILURE: "DOWNLOAD_FILE_OF_PROJECT_FAILURE",
  CREATE_PROJECT_CUSTOMER_REQUEST: "CREATE_PROJECT_CUSTOMER_REQUEST",
  CREATE_PROJECT_CUSTOMER_SUCCESS: "CREATE_PROJECT_CUSTOMER_SUCCESS",
  CREATE_PROJECT_CUSTOMER_FAILED: "CREATE_PROJECT_CUSTOMER_FAILED",

  //chart
  GET_CHART_LOG_TIME_PROJECT: "GET_CHART_LOG_TIME_PROJECT",
  GET_CHART_LOG_TIME_PROJECT_SUCCESS: "GET_CHART_LOG_TIME_PROJECT_SUCCESS",
  GET_CHART_LOG_TIME_PROJECT_FAILED: "GET_CHART_LOG_TIME_PROJECT_FAILED",
};
export default ActionTypes;
