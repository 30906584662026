// @flow
import { call, put, takeEvery } from 'redux-saga/effects';
import {
    GET_LIST_CUSTOMER_GROUP_REQUEST,
    CREATE_CUSTOMER_GROUP_REQUEST,
    EDIT_CUSTOMER_GROUP_REQUEST,
    DELETE_CUSTOMER_GROUP_REQUEST
} from './types';
import {
    getListCustomerGroupFailed,
    getListCustomerGroupSuccess,
    createCustomerGroupFailed,
    createCustomerGroupSuccess,
    editCustomerGroupFailed,
    editCustomerGroupSuccess,
    deleteCustomerGroupFailed,
    deleteCustomerGroupSuccess
} from './actions';
import { enqueueSnackbar } from '../../../../redux/CommonReducer';
import {
    getListCustomerGroup,
    createCustomerGroup,
    editCustomerGroup,
    deleteCustomerGroup
} from './api';
import { responeCode, typeMessage } from '../../../../constants/Const';
import { throwErrorMessage, parseDataNotifications } from '../../../../helpers/HandleError';

function* getListCustomerGroupSaga(action) {
    try {
        const response = yield call(getListCustomerGroup, action);
        if (response && response.data && response.data.error_code === responeCode.success && response.data.result) {
            yield put(getListCustomerGroupSuccess(response.data.result));
        }
        else {
            yield put(getListCustomerGroupFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(getListCustomerGroupFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

function* createCustomerGroupSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(createCustomerGroup, payload);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(createCustomerGroupSuccess(response.data.result));
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
        }
        else {
            yield put(createCustomerGroupFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(createCustomerGroupFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

function* editCustomerGroupSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(editCustomerGroup, payload);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(editCustomerGroupSuccess(response.data.result));
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));

        }
        else {
            yield put(editCustomerGroupFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(editCustomerGroupFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

function* deleteCustomerGroupSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(deleteCustomerGroup, payload);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(deleteCustomerGroupSuccess(payload));
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
        }
        else {
            yield put(deleteCustomerGroupFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(deleteCustomerGroupFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

export function* adminCustomerGroupSaga() {
    yield takeEvery(GET_LIST_CUSTOMER_GROUP_REQUEST, getListCustomerGroupSaga);
    yield takeEvery(CREATE_CUSTOMER_GROUP_REQUEST, createCustomerGroupSaga);
    yield takeEvery(EDIT_CUSTOMER_GROUP_REQUEST, editCustomerGroupSaga);
    yield takeEvery(DELETE_CUSTOMER_GROUP_REQUEST, deleteCustomerGroupSaga);
}

export default adminCustomerGroupSaga;