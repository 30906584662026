export default {
    contract: {
        summary: {
            active: "Hoạt động",
            expried: "Đã hết hạn",
            aboutExpried: "Sắp hết hạn",
            recentlyAdded: "Vừa được thêm",
            trash: "Thùng rác"
        },
        index: {
            add: "New project",
            summary: 'Summary',
            confirmDelete: "Are you want to delete?",
            title: "",
            keywordFind: "Search",
            assigned: "Assigned",
            delete: "Delete",
            cancel: "Cancel",
            status: "Status",
            source: "Source",
            view: "View",
            edit: "Edit",
            yes: "Yes",
            no: "No"
        },
        common: {
            id: "Id",
            name: "Name",
        },
        contract: 'Hợp đồng',
        attachments: 'Tệp tin đính kèm',
        comments: 'Bình luận',
        renewContractTitle: 'Lịch sử làm mới hợp đồng',
        task: 'Phân công',
        notes: 'Ghi chú',
        tooltipEmail: 'Gửi Email',
        tooltipToggle: 'Bật chế độ full màn hình',
        tasks: {
            priority : {
                low: 'Thấp',
                medium: 'Trung bình',
                high: 'Cao',
                urgent: 'Khẩn cấp',
            },
            repeat : {
                week: '1 tuần',
                twoWeek: '2 tuần',
                _1Month: '1 tháng',
                _2Month: '2 tháng',
                _3Month: '3 tháng',
                _6Month: '6 tháng',
                _1Year: '1 năm',
                custom: 'Tùy chỉnh'
            },
            relate: {
                project: 'Dự án',
                invoice: 'Hóa đơn',
                customer: 'Khách hàng',
                estimates: 'Báo giá',
                contract: 'Hợp đồng',
                ticket: 'Yêu cầu',
                expense: 'Chi phí',
                lead: 'Khách tiềm năng',
                proposal: 'Đề xuất kế hoạch'
            } 
        },
        trashContract: 'Xóa tất cả hợp đồng trong thùng rác',
        all: 'Tất cả',
        expried: 'Hết hạn',
        dateendContract: 'Hợp đồng không có ngày kết thúc',
        viewTrash: 'Xem thùng rác',
        newContract: 'Hợp đồng mới',
        month: 'Tháng',
        normal: 'Bình thường',
        test: 'Kiểm tra',
        contractSummary: 'Tổng quan hợp đồng',
        active: 'Kích hoạt',
        expired: 'Đã hoạt động',
        aboutToExpired: 'Sắp hết hạn',
        recentlyAdded:'Vừa được thêm',
        trash:'Thùng rác',
        contractsByType:'Hợp đồng theo loại',
        contractsValueByType: 'Giá trị Hợp đồng theo loại',
        export: 'Xuất ra',
        reload: 'reload',
        searching: 'Tìm kiếm',
        subject: 'Chủ đề',
        customer: 'Khách hàng',
        contractType: 'Loại hợp đồng',
        contractValue:'Giá trị hợp đồng',
        startDate:'Ngày bắt đầu',
        endDate: 'Ngày kết thúc',
        signature: 'Chữ kí',
        view: 'Xem',
        edit: 'Chỉnh sửa',
        subjectContract: 'Xem hợp đồng',
        dropFile: 'Thả file vào đây để upload',
        addComment: 'Thêm bình luận',
        viewPdf: 'Xem file PDF',
        viewPdfNewTab: 'Xem file PDF trong Tab mới',
        download: 'Tải xuống',
        print: 'In',
        sendTo: 'Gửi tới',
        cc: 'CC',
        attachPdf: 'Đính kèm file PDF',
        previewEmail: 'Xem trước mẫu Email',
        more: 'Thêm',
        viewContract: 'Xem hợp đồng',
        markAsSign: 'Đánh dấu là đã ký',
        copy: 'Sao chép',
        delete: 'Xóa bỏ',
        mergeField: 'Những trường đại diện sẵn có',
        noTrackEmail: 'Không có email được theo dõi nào được gửi',
        renewContract : {
            title: 'Làm mới hợp đồng',
            alert: 'Không tìm thấy bản làm mới của hợp đồng này',
            startDate: 'Ngày bắt đầu',
            endDate: 'Ngày kết thúc',
            contractValue: 'Gía trị hợp đồng',

        },
        contractByType: 'Hợp đồng theo loại',
        contractValueByType: 'Giá trị hợp đồng theo loại(VND)',
        january: 'Tháng 1',
        february: 'Tháng 2',	
        march: 'Tháng 3',	
        april: 'Tháng 4',	
        may: 'Tháng 5',	
        june: 'Tháng 6',	
        july: 'Tháng 7',	
        august: 'Tháng 8',	
        september: 'Tháng 9',
        october: 'Tháng 10',	
        november: 'Tháng 11',	
        december: 'Tháng 12',
    }
}