// FETCH_DISCUSSION
export const FETCH_DISCUSSION = "FETCH_DISCUSSION";
export const FETCH_DISCUSSION_SUCCESS = "FETCH_DISCUSSION_SUCCESS";
export const FETCH_DISCUSSION_FAILED = "FETCH_DISCUSSION_FAILED";

// CREATE_DISCUSSION
export const CREATE_DISCUSSION = "CREATE_DISCUSSION";
export const CREATE_DISCUSSION_SUCCESS = "CREATE_DISCUSSION_SUCCESS";
export const CREATE_DISCUSSION_FAILED = "CREATE_DISCUSSION_FAILED";

// DELETE_DISCUSSION
export const DELETE_DISCUSSION = "DELETE_DISCUSSION";
export const DELETE_DISCUSSION_SUCCESS = "DELETE_DISCUSSION_SUCCESS";
export const DELETE_DISCUSSION_FAILED = "DELETE_DISCUSSION_FAILED";

// EDIT DISCUSSION
export const UPDATE_DISCUSSION = "UPDATE_DISCUSSION";
export const UPDATE_DISCUSSION_SUCCESS = "UPDATE_DISCUSSION_SUCCESS";
export const UPDATE_DISCUSSION_FAILED = "UPDATE_DISCUSSION_FAILED";

export const SET_EDIT_DISCUSSION = "SET_EDIT_DISCUSSION";
