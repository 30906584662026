// import { startcase ActionTypes.} from "lodash";
import ActionTypes from "./types";
import _ from "lodash";

const INIT_STATE = {
  isLoading: false,
  isEdit: false,
  isDelete: false,
  isCreate: false,
  listNotes: {},
};

const ProjectNotesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_PROJECT_NOTES_REQUEST:
      return {
        ...state,
        isLoading: true,
        isCreate: false,
        isDelete: false,
        isEdit: false,
      };

    case ActionTypes.GET_PROJECT_NOTES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listNotes: action.payload,
      };

    case ActionTypes.GET_PROJECT_NOTES_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.EDIT_PROJECT_NOTES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.EDIT_PROJECT_NOTES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEdit: true,
      };

    case ActionTypes.EDIT_PROJECT_NOTES_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.DELETE_PROJECT_NOTES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.DELETE_PROJECT_NOTES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDelete: true,
      };

    case ActionTypes.DELETE_PROJECT_NOTES_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.CREATE_PROJECT_NOTES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.CREATE_PROJECT_NOTES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreate: true,
      };

    case ActionTypes.CREATE_PROJECT_NOTES_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return { ...state };
  }
};

export default ProjectNotesReducer;
