// @flow
import { call, put, takeEvery } from 'redux-saga/effects';
import ActionTypes from './types';
import {
	createFinanceTaxRateFailed,
	createFinanceTaxRateSuccess,
	editFinanceTaxRateFailed,
	editFinanceTaxRateSuccess,
	deleteFinanceTaxRateFailed,
	deleteFinanceTaxRateSuccess,
	fetchFinanceTaxRateFailed,
	fetchFinanceTaxRateSuccess,
	//currency
	createFinanceCurrencyFailed,
	createFinanceCurrencySuccess,
	editFinanceCurrencyFailed,
	editFinanceCurrencySuccess,
	deleteFinanceCurrencyFailed,
	deleteFinanceCurrencySuccess,
	fetchFinanceCurrencyFailed,
	fetchFinanceCurrencySuccess,
	//paymentMode
	createFinancePaymentModeFailed,
	createFinancePaymentModeSuccess,
	editFinancePaymentModeFailed,
	editFinancePaymentModeSuccess,
	deleteFinancePaymentModeFailed,
	deleteFinancePaymentModeSuccess,
	fetchFinancePaymentModeFailed,
	fetchFinancePaymentModeSuccess,
	changeActiveStatusPaymentModeFailed,
	changeActiveStatusPaymentModeSuccess,
	//expenses
	createFinanceExpensesFailed,
	createFinanceExpensesSuccess,
	editFinanceExpensesFailed,
	editFinanceExpensesSuccess,
	deleteFinanceExpensesFailed,
	deleteFinanceExpensesSuccess,
	fetchFinanceExpensesFailed,
	fetchFinanceExpensesSuccess,

} from './actions';
import { enqueueSnackbar } from '../../../../redux/CommonReducer';
import {
	createFinanceTaxRate,
	editFinanceTaxRate,
	deleteFinanceTaxRate,
	fetchFinanceTaxRate,
	//Finance-currency
	createFinanceCurrency,
	editFinanceCurrency,
	deleteFinanceCurrency,
	fetchFinanceCurrency,
	//Finance-paymentMode
	createFinancePaymentMode,
	editFinancePaymentMode,
	deleteFinancePaymentMode,
	fetchFinancePaymentMode,
	changeActiveStatusPaymentMode,
	//Finance-expenses
	createFinanceExpenses,
	editFinanceExpenses,
	deleteFinanceExpenses,
	fetchFinanceExpenses,
} from './api';
import { responeCode, typeMessage } from '../../../../constants/Const';
import { throwErrorMessage, parseDataNotifications } from '../../../../helpers/HandleError';

function* fetchFinanceTaxRateSaga(action) {
	try {
		const response = yield call(fetchFinanceTaxRate, action.payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchFinanceTaxRateSuccess(response.data.result));
		}
		else {
			yield put(fetchFinanceTaxRateFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchFinanceTaxRateFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};



function* createFinanceTaxRateSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(createFinanceTaxRate, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(createFinanceTaxRateSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(createFinanceTaxRateFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(createFinanceTaxRateFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* editFinanceTaxRateSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(editFinanceTaxRate, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(editFinanceTaxRateSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(editFinanceTaxRateFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(editFinanceTaxRateFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* deleteFinanceTaxRateSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(deleteFinanceTaxRate, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(deleteFinanceTaxRateSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(deleteFinanceTaxRateFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(deleteFinanceTaxRateFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

//Finance-curency
function* fetchFinanceCurrencySaga(action) {
	try {
		const response = yield call(fetchFinanceCurrency, action.payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchFinanceCurrencySuccess(response.data.result));
		}
		else {
			yield put(fetchFinanceCurrencyFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchFinanceCurrencyFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};



function* createFinanceCurrencySaga(data) {
	const { payload } = data;
	try {
		const response = yield call(createFinanceCurrency, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(createFinanceCurrencySuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(createFinanceCurrencyFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(createFinanceCurrencyFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* editFinanceCurrencySaga(data) {
	const { payload } = data;
	try {
		const response = yield call(editFinanceCurrency, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(editFinanceCurrencySuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(editFinanceCurrencyFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(editFinanceCurrencyFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* deleteFinanceCurrencySaga(data) {
	const { payload } = data;
	try {
		const response = yield call(deleteFinanceCurrency, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(deleteFinanceCurrencySuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(deleteFinanceCurrencyFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(deleteFinanceCurrencyFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};


//Finance-PaymentMode
function* fetchFinancePaymentModeSaga(action) {
	try {
		const response = yield call(fetchFinancePaymentMode, action.payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchFinancePaymentModeSuccess(response.data.result));
		}
		else {
			yield put(fetchFinancePaymentModeFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchFinancePaymentModeFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};



function* createFinancePaymentModeSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(createFinancePaymentMode, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(createFinancePaymentModeSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(createFinancePaymentModeFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(createFinancePaymentModeFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* editFinancePaymentModeSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(editFinancePaymentMode, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(editFinancePaymentModeSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(editFinancePaymentModeFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(editFinancePaymentModeFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* deleteFinancePaymentModeSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(deleteFinancePaymentMode, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(deleteFinancePaymentModeSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(deleteFinancePaymentModeFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(deleteFinancePaymentModeFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* changeActiveStatusPaymentModeSaga(action) {
	try {
		const response = yield call(changeActiveStatusPaymentMode, action);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(changeActiveStatusPaymentModeSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(changeActiveStatusPaymentModeFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(changeActiveStatusPaymentModeFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

//Finance-expenses category
function* fetchFinanceExpensesSaga(action) {
	try {
		const response = yield call(fetchFinanceExpenses, action.payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(fetchFinanceExpensesSuccess(response.data.result));
		}
		else {
			yield put(fetchFinanceExpensesFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(fetchFinanceExpensesFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};



function* createFinanceExpensesSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(createFinanceExpenses, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(createFinanceExpensesSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(createFinanceExpensesFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(createFinanceExpensesFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* editFinanceExpensesSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(editFinanceExpenses, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(editFinanceExpensesSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(editFinanceExpensesFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(editFinanceExpensesFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};

function* deleteFinanceExpensesSaga(data) {
	const { payload } = data;
	try {
		const response = yield call(deleteFinanceExpenses, payload);
		if (response && response.data && response.data.error_code === responeCode.success) {
			yield put(deleteFinanceExpensesSuccess(response.data.result));
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
		}
		else {
			yield put(deleteFinanceExpensesFailed());
			yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
		}
	} catch (error) {
		yield put(deleteFinanceExpensesFailed());
		yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
	}
};


export function* adminFinanceSaga() {
	yield takeEvery(ActionTypes.FETCH_FINANCE_TAX_RATE_REQUEST, fetchFinanceTaxRateSaga);
	yield takeEvery(ActionTypes.CREATE_FINANCE_TAX_RATE_REQUEST, createFinanceTaxRateSaga);
	yield takeEvery(ActionTypes.EDIT_FINANCE_TAX_RATE_REQUEST, editFinanceTaxRateSaga);
	yield takeEvery(ActionTypes.DELETE_FINANCE_TAX_RATE_REQUEST, deleteFinanceTaxRateSaga);
	//Finance-currency
	yield takeEvery(ActionTypes.FETCH_FINANCE_CURRENCY_REQUEST, fetchFinanceCurrencySaga);
	yield takeEvery(ActionTypes.CREATE_FINANCE_CURRENCY_REQUEST, createFinanceCurrencySaga);
	yield takeEvery(ActionTypes.EDIT_FINANCE_CURRENCY_REQUEST, editFinanceCurrencySaga);
	yield takeEvery(ActionTypes.DELETE_FINANCE_CURRENCY_REQUEST, deleteFinanceCurrencySaga);
	//Finance-PaymentMode
	yield takeEvery(ActionTypes.FETCH_FINANCE_PAYMENT_MODE_REQUEST, fetchFinancePaymentModeSaga);
	yield takeEvery(ActionTypes.CREATE_FINANCE_PAYMENT_MODE_REQUEST, createFinancePaymentModeSaga);
	yield takeEvery(ActionTypes.EDIT_FINANCE_PAYMENT_MODE_REQUEST, editFinancePaymentModeSaga);
	yield takeEvery(ActionTypes.DELETE_FINANCE_PAYMENT_MODE_REQUEST, deleteFinancePaymentModeSaga);
	yield takeEvery(ActionTypes.CHANGE_ACTIVE_STATUS_PAYMENT_MODE_REQUEST, changeActiveStatusPaymentModeSaga);
	//Finance-Expenses Category
	yield takeEvery(ActionTypes.FETCH_FINANCE_EXPENSES_REQUEST, fetchFinanceExpensesSaga);
	yield takeEvery(ActionTypes.CREATE_FINANCE_EXPENSES_REQUEST, createFinanceExpensesSaga);
	yield takeEvery(ActionTypes.EDIT_FINANCE_EXPENSES_REQUEST, editFinanceExpensesSaga);
	yield takeEvery(ActionTypes.DELETE_FINANCE_EXPENSES_REQUEST, deleteFinanceExpensesSaga);
}

export default adminFinanceSaga;