export default {
  support: {
    newTicket: "New ticket",
    weeklyStats: "Weekly Stats",
    filterBy: "Filter by",
    edit: "Edit",
    btnFilter: {
      all: "All",
      assigned: "Tickets assigned to me",
      open: "Open",
      inProgress: "In Progress",
      answered: "Answered",
      onHold: "On Hold",
      close: "Close",
    },
    weeklyStatistics: "Weekly Ticket Openings Statistics",
    dialogBulkActions: {
      bulkActions: "Bulk Actions",
      massDelete: "Mass Delete",
      changeStatus: "Change Status",
      department: "Department",
      ticketPriority: "Ticket Priority",
      tags: "Tags",
      service: "Service",
      close: "Close",
      confirm: "Confirm",
    },
    ticketWithoutContact: "Ticket without contact",
    ticketToContact: "Ticket to contact",
    tableSupport: {
      subject: "Subject",
      tags: "Tags",
      department: "Department",
      service: "Service",
      contact: "Contact",
      status: "Status",
      priority: "Priority",
      lastReply: "Last Reply",
      created: "Created",
    },
    note: "Note",
    addNote: "Add Note",
    tabReminders: {
      setTicketReminders: "Set Ticket Reminder",
      iconQuestion: "This option allows you to never forget anything about your customers.",
      dateNotified: "Date to be notified",
      setReminder: "Set reminder to",
      description: "Description",
      checkboxSend: "Send also an email for this reminder",
      save: "Save",
      date: "Date",
      remind: "Remind",
      isNotified: "Is notified?"
    },
    tabTasks: {
      newTask: "New task",
      filterAll: "All",
      notStarted: "Not Started",
      inProgress: "InProgress",
      dueDatePassed: "Due Date Passed",
      name: "Name",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned to",
      tags: "Tags",
      priority: "Priority",
    },
    tabOtherTickets: {
      subject: "Subject",
      tags: "Tags",
      department: "Department",
      service: "Service",
      contact: "Contact",
      status: "Status",
      priority: "Priority",
      lastReply: "Last Reply",
      created: "Created"
    }
  },
}