import {
    FETCH_ESTIMATES_PROJECT,
    FETCH_ESTIMATES_PROJECT_SUCCESS,
    FETCH_ESTIMATES_PROJECT_FAILED,
    FETCH_FILTER_YEAR_ESTIMATE_PRJ,
    FETCH_FILTER_YEAR_ESTIMATE_PRJ_SUCCESS,
    FETCH_FILTER_YEAR_ESTIMATE_PRJ_FAILED,
    FETCH_TOTAL_ESTIMATE_PRJ,
    FETCH_TOTAL_ESTIMATE_PRJ_SUCCESS,
    FETCH_TOTAL_ESTIMATE_PRJ_FAILED,
    FILTER_ESTIMATE,
    FILTER_ESTIMATE_SUCCESS,
    FILTER_ESTIMATE_FAILED,
} from './types';

//fetch estimate project
export const fetchEstimatesProject = (data) => {
    return {
        type: FETCH_ESTIMATES_PROJECT,
        payload: data,
    };
};

export const fetchEstimatesProjectSuccess = (data) => {
    return {
        type: FETCH_ESTIMATES_PROJECT_SUCCESS,
        payload: data,
    };
};

export const fetchEstimatesProjectFailed = () => {
    return {
        type: FETCH_ESTIMATES_PROJECT_FAILED,
    };
};

//filter year
export const fetchFilterYearEstimatePrj = (data) => {
    return {
        type: FETCH_FILTER_YEAR_ESTIMATE_PRJ,
        payload: data,
    };
};

export const fetchFilterYearEstimatePrjSuccess = (data) => {
    return {
        type: FETCH_FILTER_YEAR_ESTIMATE_PRJ_SUCCESS,
        payload: data,
    };
};

export const fetchFilterYearEstimatePrjFailed = () => {
    return {
        type: FETCH_FILTER_YEAR_ESTIMATE_PRJ_FAILED,
    };
};

//fetch total estimate
export const fetchTotalEstimatePrj = (data) => {
    return {
        type: FETCH_TOTAL_ESTIMATE_PRJ,
        payload: data,
    };
};

export const fetchTotalEstimatePrjSuccess = (data) => {
    return {
        type: FETCH_TOTAL_ESTIMATE_PRJ_SUCCESS,
        payload: data,
    };
};

export const fetchTotalEstimatePrjFailed = () => {
    return {
        type: FETCH_TOTAL_ESTIMATE_PRJ_FAILED,
    };
};

//fetch total estimate
export const fetchFilterEstimate = (data) => {
    return {
        type: FILTER_ESTIMATE,
        payload: data,
    };
};

export const fetchFilterEstimateSuccess = (data) => {
    return {
        type: FILTER_ESTIMATE_SUCCESS,
        payload: data,
    };
};

export const fetchFilterEstimateFailed = () => {
    return {
        type: FILTER_ESTIMATE_FAILED,
    };
};