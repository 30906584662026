import ActionTypes from './types';

export const getListSupportServiceRequest = () => {
    return {
        type: ActionTypes.GET_LIST_SUPPORT_SERVICE_REQUEST
    }
};

export const getListSupportServiceSuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_SUPPORT_SERVICE_SUCCESS,
        payload: data
    }
};

export const getListSupportServiceFailed = () => {
    return {
        type: ActionTypes.GET_LIST_SUPPORT_SERVICE_FAILED,
    }
};

export const createSupportServiceRequest = (data) => {
    return {
        type: ActionTypes.CREATE_SUPPORT_SERVICE_REQUEST,
        payload: data,
    }
};

export const createSupportServiceSuccess = (data) => {
    return {
        type: ActionTypes.CREATE_SUPPORT_SERVICE_SUCCESS,
        payload: data
    }
};

export const createSupportServiceFailed = () => {
    return {
        type: ActionTypes.CREATE_SUPPORT_SERVICE_FAILED
    }
};

export const editSupportServiceRequest = (data) => {
    return {
        type: ActionTypes.EDIT_SUPPORT_SERVICE_REQUEST,
        payload: data,
    }
};

export const editSupportServiceSuccess = (data) => {
    return {
        type: ActionTypes.EDIT_SUPPORT_SERVICE_SUCCESS,
        payload: data
    }
};

export const editSupportServiceFailed = () => {
    return {
        type: ActionTypes.EDIT_SUPPORT_SERVICE_FAILED
    }
};

export const deleteSupportServiceRequest = (data) => {
    return {
        type: ActionTypes.DELETE_SUPPORT_SERVICE_REQUEST,
        payload: data,
    }
};

export const deleteSupportServiceSuccess = (data) => {
    return {
        type: ActionTypes.DELETE_SUPPORT_SERVICE_SUCCESS,
        payload: data
    }
};

export const deleteSupportServiceFailed = () => {
    return {
        type: ActionTypes.DELETE_SUPPORT_SERVICE_FAILED,
    }
};

export const changeStatusDialog = () => {
    return {
        type: ActionTypes.CHANGE_STATUS_DIALOG,
    }
};

export const fetchSupportServiceRequest = (search, params) => {
    return {
        type: ActionTypes.FETCH_SUPPORT_SERVICE_REQUEST,
        search, params
    }
};

export const fetchSupportServiceSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_SUPPORT_SERVICE_SUCCESS,
        payload: data
    }
};

export const fetchSupportServiceFailed = () => {
    return {
        type: ActionTypes.FETCH_SUPPORT_SERVICE_FAILED,
    }
};