import _ from 'lodash';
import apiBase from "../../../../common/baseAPI";

const URL = 'spamfilter';

const getListLeadSource = (data) => {
	if (data !== undefined) {
		let newUrl = `lead-source?search=${data.search}&&limit=${data.limit}&&page=${data.page}`;
		return new Promise((resolve, reject) => {
			return apiBase
				.get(newUrl)
				.then((res) => resolve(res))
				.catch((err) => reject(err));
		});
	} else {
		let newUrl = `lead-source`;
		return new Promise((resolve, reject) => {
			return apiBase
				.get(newUrl)
				.then((res) => resolve(res))
				.catch((err) => reject(err));
		});
	}
};

const getCountLeadsSource = () => {
	return new Promise((resolve, reject) => {
		return apiBase
			.get(`source/count`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const createLeadSource = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.post(`lead-source`, data)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const editLeadSource = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.put(`lead-source/${data.id}`, data)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const deleteLeadSource = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.delete(`lead-source/${data}`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const getListLeadStatus = (data) => {
	if (data !== undefined) {
		let newUrl = `lead-status?search=${data.name}&&limit=${data.limit}&&page=${data.page}`;
		return new Promise((resolve, reject) => {
			return apiBase
				.get(newUrl)
				.then((res) => resolve(res))
				.catch((err) => reject(err));
		});
	} else {
		return new Promise((resolve, reject) => {
			let newUrl = `lead-status`;
			return apiBase
				.get(newUrl)
				.then((res) => resolve(res))
				.catch((err) => reject(err));
		});
	}
};

const getCountLeadsStatus = () => {
	return new Promise((resolve, reject) => {
		return apiBase
			.get(`status/count`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const createLeadStatus = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.post(`lead-status`, data)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const editLeadStatus = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.put(`lead-status/${data.id}`, data)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const deleteLeadStatus = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.delete(`lead-status/${data}`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const getLeadEmailIntegration = (data) => {
	const newData = _.pickBy(data, _.identity);
	let newUrl = 'lead-email-integration';
	Object.keys(newData).map((item, index) => newUrl = `${newUrl}${index === 0 ? '?' : ''}${data[item] ? `&${item}=${data[item]}` : ''}`);
	return new Promise((resolve, reject) => {
		return apiBase
			.get(newUrl)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const createLeadEmailIntegration = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.post(`lead-email-integration`, data)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const editLeadEmailIntegration = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.put(`lead-email-integration/${data.id}`, data)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const deleteLeadEmailIntegration = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.delete(`lead-email-integration/${data}`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const getListResponsible = () => {
	return new Promise((resolve, reject) => {
		return apiBase
			.get('staff')
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const getListWebLead = (data) => {
	const newData = _.pickBy(data, _.identity);
	let newUrl = 'webToLead';
	Object.keys(newData).map((item, index) => newUrl = `${newUrl}${index === 0 ? '?' : ''}${data[item] ? `&${item}=${data[item]}` : ''}`);
	return new Promise((resolve, reject) => {
		return apiBase
			.get(newUrl)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const getWebLeadById = (id) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.get(`webToLead/${id}`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const createWebLead = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.post(`webToLead`, data)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const editWebLead = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.put(`webToLead/${data.id}`, data)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const deleteWebLead = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.delete(`webToLead/${data}`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};


//spamfilter
const getAdminLeadsSpamFilterTypeofSender = () => {
	return new Promise((resolve, reject) => {
		return apiBase
			.get(`${URL}?search=&&limit=15&&page=1&&typeLead=sender`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const getAdminLeadsSpamFilterTypeofSubject = () => {
	return new Promise((resolve, reject) => {
		return apiBase
			.get(`${URL}?search=&&limit=15&&page=1&&typeLead=subject`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const getAdminLeadsSpamFilterTypeofPhrase = () => {
	return new Promise((resolve, reject) => {
		return apiBase
			.get(`${URL}?search=&&limit=15&&page=1&&typeLead=phrase`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const createAdminLeadsSpamFilter = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.post(`${URL}/lead`, data)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const editAdminLeadsSpamFilter = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.put(`${URL}/lead/${data.id}`, data)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const deleteAdminLeadsSpamFilter = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.delete(`${URL}/lead/${data}`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};
const fetchAdminLeadsSpamFilter = (action) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.get(`${URL}?search=${action.search}&&limit=${action.params.limit}&&page=${action.params.page}&&typeLead=${action.params.type}`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export {
	getListLeadSource,
	createLeadSource,
	editLeadSource,
	deleteLeadSource,
	getListLeadStatus,
	createLeadStatus,
	editLeadStatus,
	deleteLeadStatus,
	getLeadEmailIntegration,
	createLeadEmailIntegration,
	editLeadEmailIntegration,
	deleteLeadEmailIntegration,
	getListResponsible,
	getListWebLead,
	getWebLeadById,
	createWebLead,
	editWebLead,
	deleteWebLead,
	getAdminLeadsSpamFilterTypeofSender,
	getAdminLeadsSpamFilterTypeofSubject,
	getAdminLeadsSpamFilterTypeofPhrase,
	createAdminLeadsSpamFilter,
	editAdminLeadsSpamFilter,
	deleteAdminLeadsSpamFilter,
	fetchAdminLeadsSpamFilter,
	getCountLeadsSource,
	getCountLeadsStatus

}