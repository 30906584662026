import {
    FETCH_INVOICES_PROJECT,
    FETCH_INVOICES_PROJECT_SUCCESS,
    FETCH_INVOICES_PROJECT_FAILED,
    FETCH_FILTER_YEAR_INVOICE_PRJ,
    FETCH_FILTER_YEAR_INVOICE_PRJ_SUCCESS,
    FETCH_FILTER_YEAR_INVOICE_PRJ_FAILED,
    FETCH_TOTAL_INVOICE_PRJ,
    FETCH_TOTAL_INVOICE_PRJ_SUCCESS,
    FETCH_TOTAL_INVOICE_PRJ_FAILED,
    FETCH_STAFF_INVOICE_PRJ,
    FETCH_STAFF_INVOICE_PRJ_SUCCESS,
    FETCH_STAFF_INVOICE_PRJ_FAILED,
    FILTER_INVOICE_PRJ,
    FILTER_INVOICE_PRJ_SUCCESS,
    FILTER_INVOICE_PRJ_FAILED,
    FETCH_CURRENCIES,
    FETCH_CURRENCIES_SUCCESS,
    FETCH_CURRENCIES_FAILED,
} from './types';


//fetch invoice
export const fetchInvoicesProject = (data) => {
    return {
        type: FETCH_INVOICES_PROJECT,
        payload: data,
    };
};

export const fetchInvoicesProjectSuccess = (data) => {
    return {
        type: FETCH_INVOICES_PROJECT_SUCCESS,
        payload: data,
    };
};

export const fetchInvoicesProjectFailed = () => {
    return {
        type: FETCH_INVOICES_PROJECT_FAILED,
    };
};


//filter year
export const fetchFilterYearInvoicePrj = (data) => {
    return {
        type: FETCH_FILTER_YEAR_INVOICE_PRJ,
        payload: data,
    };
};

export const fetchFilterYearInvoicePrjSuccess = (data) => {
    return {
        type: FETCH_FILTER_YEAR_INVOICE_PRJ_SUCCESS,
        payload: data,
    };
};

export const fetchFilterYearInvoicePrjFailed = () => {
    return {
        type: FETCH_FILTER_YEAR_INVOICE_PRJ_FAILED,
    };
};

//filter total
export const fetchTotalInvoicePrj = (data) => {
    return {
        type: FETCH_TOTAL_INVOICE_PRJ,
        payload: data,
    };
};

export const fetchTotalInvoicePrjSuccess = (data) => {
    return {
        type: FETCH_TOTAL_INVOICE_PRJ_SUCCESS,
        payload: data,
    };
};

export const fetchTotalInvoicePrjFailed = () => {
    return {
        type: FETCH_TOTAL_INVOICE_PRJ_FAILED,
    };
};

//FETCH USER INVOICE
export const fetchStaffInvoicePrj = (projectId, data) => {
    return {
        type: FETCH_STAFF_INVOICE_PRJ,
    }
}

export const fetchStaffInvoicePrjSuccess = (data) => {
    return {
        type: FETCH_STAFF_INVOICE_PRJ_SUCCESS,
        payload: data
    }
}

export const fetchStaffInvoicePrjFailed = () => {
    return {
        type: FETCH_STAFF_INVOICE_PRJ_FAILED,
    }
}

//FILTER INVOICE
export const filterInvoice = (data) => {
    return {
        type: FILTER_INVOICE_PRJ,
        payload: data,
    }
}

export const filterInvoiceSuccess = (data) => {
    return {
        type: FILTER_INVOICE_PRJ_SUCCESS,
        payload: data
    }
}

export const filterInvoiceFailed = () => {
    return {
        type: FILTER_INVOICE_PRJ_FAILED,
    }
}

//fetch currency
export const fetchCurrencies = (data) => {
    return {
        type: FETCH_CURRENCIES,
        payload: data,
    };
};

export const fetchCurrenciesSuccess = (data) => {
    return {
        type: FETCH_CURRENCIES_SUCCESS,
        payload: data,
    };
};

export const fetchCurrenciesFailed = () => {
    return {
        type: FETCH_CURRENCIES_FAILED,
    };
};