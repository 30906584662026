// @flow
import { call, put, takeEvery } from 'redux-saga/effects';
import ActionTypes from './types';
import {
    getListPredefinedRepliesFailed,
    getListPredefinedRepliesSuccess,
    createPredefinedRepliesFailed,
    createPredefinedRepliesSuccess,
    editPredefinedRepliesFailed,
    editPredefinedRepliesSuccess,
    deletePredefinedRepliesFailed,
    deletePredefinedRepliesSuccess,
    getPredefinedRepliesEditFailed,
    getPredefinedRepliesEditSuccess,
    fetchPredefinedRepliesSuccess,
    fetchPredefinedRepliesFailed
} from './actions';
import { enqueueSnackbar } from '../../../../../redux/CommonReducer';
import {
    getListPredefinedReplies,
    createPredefinedReplies,
    editPredefinedReplies,
    deletePredefinedReplies,
    getPredefinedRepliesEdit,
    fetchPredefinedReplies
} from './api';
import { responeCode, typeMessage } from '../../../../../constants/Const';
import { throwErrorMessage, parseDataNotifications } from '../../../../../helpers/HandleError';

function* getListPredefinedRepliesSaga() {
    try {
        const response = yield call(getListPredefinedReplies);
        if (response && response.data && response.data.error_code === responeCode.success && response.data.result) {
            yield put(getListPredefinedRepliesSuccess(response.data.result));
        }
        else {
            yield put(getListPredefinedRepliesFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(getListPredefinedRepliesFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

function* getPredefinedRepliesEditSaga(action) {
    let id = action.payload
    try {
        const response = yield call(getPredefinedRepliesEdit, id);
        if (response && response.data && response.data.error_code === responeCode.success && response.data.result) {
            yield put(getPredefinedRepliesEditSuccess(response.data.result));
        }
        else {
            yield put(getPredefinedRepliesEditFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(getPredefinedRepliesEditFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

function* createPredefinedRepliesSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(createPredefinedReplies, payload);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(createPredefinedRepliesSuccess(response.data.result));
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
        }
        else {
            yield put(createPredefinedRepliesFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(createPredefinedRepliesFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

function* editPredefinedRepliesSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(editPredefinedReplies, payload);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(editPredefinedRepliesSuccess(response.data.result));
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));

        }
        else {
            yield put(editPredefinedRepliesFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(editPredefinedRepliesFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

function* deletePredefinedRepliesSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(deletePredefinedReplies, payload);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(deletePredefinedRepliesSuccess(payload));
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
        }
        else {
            yield put(deletePredefinedRepliesFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(deletePredefinedRepliesFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};
function* fetchPredefinedRepliesSaga(action) {
    try {
        const response = yield call(fetchPredefinedReplies, action);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(fetchPredefinedRepliesSuccess(response.data.result));
        }
        else {
            yield put(fetchPredefinedRepliesFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(fetchPredefinedRepliesFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

export function* adminSupportPredefinedRepliesSaga() {
    yield takeEvery(ActionTypes.GET_LIST_PREDEFINED_REPLIES_REQUEST, getListPredefinedRepliesSaga);
    yield takeEvery(ActionTypes.CREATE_PREDEFINED_REPLIES_REQUEST, createPredefinedRepliesSaga);
    yield takeEvery(ActionTypes.EDIT_PREDEFINED_REPLIES_REQUEST, editPredefinedRepliesSaga);
    yield takeEvery(ActionTypes.DELETE_PREDEFINED_REPLIES_REQUEST, deletePredefinedRepliesSaga);
    yield takeEvery(ActionTypes.FETCH_PREDEFINED_REPLIES_REQUEST, fetchPredefinedRepliesSaga);
    yield takeEvery(ActionTypes.GET_PREDEFINED_REPLIES_EDIT_REQUEST, getPredefinedRepliesEditSaga);

}


export default adminSupportPredefinedRepliesSaga;