import apiBase from "./../../../../common/baseAPI";
const CONTRACT_TYPE_URL = "contractType";

const getContractType = ({ params }) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(CONTRACT_TYPE_URL, {
                params: params,
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const addContractType = ({ body }) => {
    return new Promise((resolve, reject) => {
        return (
            apiBase

                //     Đường dẫn : api/contractType
                //         Body
                //         {
                //                 "name": "abc"
                //         }
                .post(CONTRACT_TYPE_URL, body)
                .then((res) => resolve(res))
                .catch((err) => reject(err))
        );
    });
};
const putContractType = ({ body, id }) => {
    return new Promise((resolve, reject) => {
        return (
            apiBase
                //             Phương thức: PUT
                //             Đường dẫn : api/contractType/{id}
                //             Body
                //             {
                //                 "name": "abc"
                //             }
                .put(`${CONTRACT_TYPE_URL}/${id}`, body)
                .then((res) => resolve(res))
                .catch((err) => reject(err))
        );
    });
};
const deleteContractType = ({ id }) => {
    return new Promise((resolve, reject) => {
        return (
            apiBase
                // api/contractType/{id}
                .delete(`${CONTRACT_TYPE_URL}/${id}`)
                .then((res) => resolve(res))
                .catch((err) => reject(err))
        );
    });
};

export {
    getContractType,
    addContractType,
    putContractType,
    deleteContractType,
};
