import _ from "lodash";
import apiBase from "./../../../../common/baseAPI";

const CUSTOM_FIELD = "customField";
const CUSTOM_FIELD_ACTIVE = "customField/active";

export const fetchListCustomFieldRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${CUSTOM_FIELD}?search=${action.value}&&limit=${action.params.limit}&&page=${action.params.page}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
export const editActiveCustomFieldRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .put(`${CUSTOM_FIELD_ACTIVE}/${action.id}`, action.value)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
export const addCustomFieldRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .post(`${CUSTOM_FIELD}`, action.data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
export const editCustomFieldRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .put(`${CUSTOM_FIELD}/${action.id}`, action.data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteCustomFieldRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .delete(`${CUSTOM_FIELD}/${action.id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
export const getCustomfiledRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${CUSTOM_FIELD}/${action.id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
