// @flow
import { call, put, takeEvery } from "redux-saga/effects";
import { enqueueSnackbar } from "../../../../redux/CommonReducer";
import { responeCode, typeMessage } from "../../../../constants/Const";
import {
  throwErrorMessage,
  parseDataNotifications,
} from "../../../../helpers/HandleError";
import ActionTypes from "./type";

import {
  fetchReminderCustomerSuccess,
  fetchReminderCustomerFailed,
  addReminderCustomerSuccess,
  addReminderCustomerFailed,
  deleteReminderCustomerSuccess,
  deleteReminderCustomerFailed,
} from "./actions";
import {
  fetchReminderCustomerRequest,
  addReminderCustomerRequest,
  deleteReminderCustomerRequest,
} from "./api";

function* fetchReminderCustomer(action) {
  try {
    const response = yield call(fetchReminderCustomerRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(fetchReminderCustomerSuccess(response.data.result));
    } else {
      yield put(fetchReminderCustomerFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchReminderCustomerFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* addReminderCustomer(action) {
  try {
    const response = yield call(addReminderCustomerRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(addReminderCustomerSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(addReminderCustomerFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(addReminderCustomerFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}
function* deleteReminderCustomer(action) {
  try {
    const response = yield call(deleteReminderCustomerRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(deleteReminderCustomerSuccess());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(deleteReminderCustomerFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(deleteReminderCustomerFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

export function* reminderCustomerSaga() {
  yield takeEvery(
    ActionTypes.FETCH_REMINDER_CUSTOMER_REQUEST,
    fetchReminderCustomer
  );
  yield takeEvery(
    ActionTypes.ADD_REMINDER_CUSTOMER_REQUEST,
    addReminderCustomer
  );
  yield takeEvery(
    ActionTypes.DELETE_REMINDER_CUSTOMER_REQUEST,
    deleteReminderCustomer
  );
}

export default reminderCustomerSaga;
