import apiBase from "../../../../common/baseAPI";
const PAYMENTS_SALE = "payment";
const PAYMENTS_MODE = "paymentModes";
export const getPaymentsSaleRequest = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(PAYMENTS_SALE, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
export const getPaymentsModeRequest = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(PAYMENTS_MODE)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
