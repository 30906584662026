// @flow
import { call, put, takeEvery } from "redux-saga/effects";
import { enqueueSnackbar } from "../../../../redux/CommonReducer";
import { responeCode, typeMessage } from "../../../../constants/Const";
import {
  throwErrorMessage,
  parseDataNotifications,
} from "../../../../helpers/HandleError";
import ActionTypes from "./type";

import {
  fetchNoteSuccess,
  fetchNoteFailed,
  newNotesFailed,
  newNotesSuccess,
  editNotesSuccess,
  editNotesFailed,
  deleteNotesSuccess,
  deleteNotesFailed,
} from "./actions";
import {
  fetchNotesCustomerRequest,
  addNotesRequest,
  editNotesRequest,
  deleteNotesRequest,
} from "./api";

function* fetchNotesCustomer(action) {
  try {
    const response = yield call(fetchNotesCustomerRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(fetchNoteSuccess(response.data.result));
    } else {
      yield put(fetchNoteFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchNoteFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* newNotesCustomer(action) {
  try {
    const response = yield call(addNotesRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(newNotesSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(newNotesFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(newNotesFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* editNotesCustomer(action) {
  try {
    const response = yield call(editNotesRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(editNotesSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(editNotesFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(editNotesFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* deleteNotesCustomer(action) {
  try {
    const response = yield call(deleteNotesRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(deleteNotesSuccess());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(deleteNotesFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(deleteNotesFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}
export function* notesCustomerSaga() {
  yield takeEvery(ActionTypes.FETCH_NOTES_REQUEST, fetchNotesCustomer);
  yield takeEvery(ActionTypes.NEW_NOTES_REQUEST, newNotesCustomer);
  yield takeEvery(ActionTypes.EDIT_NOTES_REQUEST, editNotesCustomer);
  yield takeEvery(ActionTypes.DELETE_NOTES_REQUEST, deleteNotesCustomer);
}

export default notesCustomerSaga;
