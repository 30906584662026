import apiBase from "../../../../common/baseAPI";

const CUSTOMER_NOTE = "note/customer";
const NOTE = "note";

export const fetchNotesCustomerRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${CUSTOMER_NOTE}/${action.id}?search=${action.value}&&limit=${action.params.limit}&&page=${action.params.page}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const addNotesRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .post(`${CUSTOMER_NOTE}/${action.id}`, action.value)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editNotesRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .put(`${NOTE}/${action.id}`, action.value)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteNotesRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .delete(`${NOTE}/${action.id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
