import { Grid } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import './ThcWidget.scss';

const ThcWidget = (props) => {
  const {
    lstSum
  } = props;
  return (
    <Grid className="list-summary">
        {lstSum && lstSum.map((item, index) => (
            <Grid key={`summary-${index}`} >
                <div className="card">
                  <div className="count" style={{ color: item.color }}> {item.count} </div>
                  <div className="title" style={{ color: item.color }}>{item.title}</div>
                  {item.subText && (
                      <div className="sub-title"> {item.subText}: {item.subCount}</div>
                    )
                  }
                </div>
            </Grid>
        ))}
    </Grid>
  )
}
const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ThcWidget);
