import ActionTypes from "./types";

const INIT_STATE = {
    listTicketStatus: [],
    isCloseDialog: false,
    isLoading: false,
    deleteTicketStatus: false,
    listCount: {},
};

const AdminSupportTicketStatusReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_LIST_SUPPORT_TICKET_STATUS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.GET_LIST_SUPPORT_TICKET_STATUS_SUCCESS:
            return {
                ...state,
                listTicketStatus: action.payload,
                isLoading: false,
            };

        case ActionTypes.GET_LIST_SUPPORT_TICKET_STATUS_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.CREATE_SUPPORT_TICKET_STATUS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.CREATE_SUPPORT_TICKET_STATUS_SUCCESS:
            state.listTicketStatus.data.unshift(action.payload);
            return {
                ...state,
                isCloseDialog: true,
                isLoading: false,
                listTicketStatus: state.listTicketStatus,
            };

        case ActionTypes.CREATE_SUPPORT_TICKET_STATUS_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.EDIT_SUPPORT_TICKET_STATUS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.EDIT_SUPPORT_TICKET_STATUS_SUCCESS:
            let itemIndex = state.listTicketStatus.data.findIndex(
                (item) => item.ticketstatusid === action.payload.ticketstatusid
            );
            state.listTicketStatus.data.splice(itemIndex, 1, action.payload);
            return {
                ...state,
                isCloseDialog: true,
                isLoading: false,
                listTicketStatus: state.listTicketStatus,
            };

        case ActionTypes.EDIT_SUPPORT_TICKET_STATUS_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.CHANGE_STATUS_DIALOG:
            return {
                ...state,
                isCloseDialog: false,
            };

        case ActionTypes.FETCH_SUPPORT_TICKET_STATUS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.FETCH_SUPPORT_TICKET_STATUS_SUCCESS:
            return {
                ...state,
                listTicketStatus: action.payload,
                isLoading: false,
                deleteTicketStatus: false,
            };

        case ActionTypes.FETCH_SUPPORT_TICKET_STATUS_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.GET_COUNT_SUPPORT_TICKET_STATUS_SUCCESS:
            return {
                ...state,
                listCount: action.payload,
            };

        case ActionTypes.GET_COUNT_SUPPORT_TICKET_STATUS_FAILED:
            return {
                ...state,
            };
        case ActionTypes.DELETE_SUPPORT_TICKET_STATUS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.DELETE_SUPPORT_TICKET_STATUS_SUCCESS:
            return {
                ...state,

                isLoading: false,
                deleteTicketStatus: false,
            };

        case ActionTypes.DELETE_SUPPORT_TICKET_STATUS_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return { ...state };
    }
};

export default AdminSupportTicketStatusReducer;
