import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { SnackbarProvider } from "notistack";
import "./i18n";
import TimeAgo from "javascript-time-ago";
import vi from "javascript-time-ago/locale/vi";

TimeAgo.addDefaultLocale(vi);

ReactDOM.render(
    <Provider store={configureStore()}>
        <SnackbarProvider maxSnack={5}>
            <App />
        </SnackbarProvider>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
