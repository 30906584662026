import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
    getCustomFieldProposalSuccess,
    getCustomFieldProposalFailed,
} from "./action";
import { enqueueSnackbar } from "../../../redux/CommonReducer";
import { getProposalRequest } from "./api";
import { responeCode, typeMessage } from "../../../constants/Const";
import ActionTypes from "./types";
import {
    throwErrorMessage,
    parseDataNotifications,
} from "../../../helpers/HandleError";

function* listProposal(data) {
    try {
        const response = yield call(getProposalRequest, data);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getCustomFieldProposalSuccess(response.data.result));
        } else {
            yield put(getCustomFieldProposalFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getCustomFieldProposalFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

export function* proposalSaga() {
    yield takeEvery(ActionTypes.GET_PROPOSAL_REQUEST, listProposal);
}

function* ProposalSaga() {
    yield all([fork(proposalSaga)]);
}

export default ProposalSaga;
