import apiBase from "./../../../../../common/baseAPI";

const URL = 'service';

const getListSupportService = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createSupportService = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(URL, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const editSupportService = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${URL}/${data.serviceid}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteSupportService = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${URL}/${data}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const fetchSupportService = (action) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${URL}?search=${action.search}&&limit=${action.params.limit}&&page=${action.params.page}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};


export {
    getListSupportService,
    createSupportService,
    editSupportService,
    deleteSupportService,
    fetchSupportService

}