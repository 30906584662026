import ActionTypes from "./types";

const INIT_STATE = {
  isLoading: false,
  listEstimateSale: [],
  listSelectCustomer: [],
  listCustomFieldEstimate: [],
};

const SaleReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ESTIMATES_SALE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_ESTIMATES_SALE_SUCCESS:
      return {
        ...state,
        listEstimateSale: action.payload,
        isLoading: false,
      };
    case ActionTypes.FETCH_ESTIMATES_SALE_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.LIST_SELECT_CUSTOMER_REQUEST:
      return {
        ...state,
      };
    case ActionTypes.LIST_SELECT_CUSTOMER_SUCCESS:
      return {
        ...state,
        listSelectCustomer: action.value,
      };
    case ActionTypes.LIST_SELECT_CUSTOMER_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.GET_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.GET_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        listCustomFieldEstimate: action.data,
        isLoading: false,
      };
    case ActionTypes.GET_CUSTOM_FIELD_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return { ...state };
  }
};

export default SaleReducer;
