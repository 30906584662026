import {
    GET_LIST_CREDIT_NOTE_FAILED,
    GET_LIST_CREDIT_NOTE_PENDING,
    GET_LIST_CREDIT_NOTE_SUCCESS,
} from "./type";

export const getListCreditNotePending = (params) => {
    return {
        type: GET_LIST_CREDIT_NOTE_PENDING,
        payload: {
            params: params,
        },
    };
};
export const getListCreditNoteSuccess = (data) => {
    return {
        type: GET_LIST_CREDIT_NOTE_SUCCESS,
        payload: {
            data: data,
        },
    };
};
export const getListCreditNoteFailed = () => {
    return {
        type: GET_LIST_CREDIT_NOTE_FAILED,
    };
};
