import {
  FETCH_EXPENSES_PROJECT,
  FETCH_EXPENSES_PROJECT_SUCCESS,
  FETCH_EXPENSES_PROJECT_FAILED,
  FETCH_FILTER_YEAR_EXPENSE_PRJ,
  FETCH_FILTER_YEAR_EXPENSE_PRJ_SUCCESS,
  FETCH_FILTER_YEAR_EXPENSE_PRJ_FAILED,
  FETCH_EXPENSE_CATEGORY,
  FETCH_EXPENSE_CATEGORY_SUCCESS,
  FETCH_EXPENSE_CATEGORY_FAILED,
  FETCH_PAYMENT_MODE,
  FETCH_PAYMENT_MODE_SUCCESS,
  FETCH_PAYMENT_MODE_FAILED,
  FETCH_TAX,
  FETCH_TAX_SUCCESS,
  FETCH_TAX_FAILED,
  FETCH_CREATE_EXPENSE_PRJ,
  FETCH_CREATE_EXPENSE_PRJ_SUCCESS,
  FETCH_CREATE_EXPENSE_PRJ_FAILED,
  FETCH_CUSTOMER,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAILED,
  FILTER_PROJECT_EXPENSES_FAILED,
  FILTER_PROJECT_EXPENSES_REQUEST,
  FILTER_PROJECT_EXPENSES_SUCCESS,
} from "./types";

const INIT_STATE = {
  isLoading: false,
  listExpenses: [],
  listCustomer: [],
  filterYearExpensePrj: {},
  listExpenseCategory: [],
  listPaymentMode: [],
  listTax: [],
  isCreateExpense: false,
};

const ExpenseProjectReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_EXPENSES_PROJECT:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_EXPENSES_PROJECT_SUCCESS:
      return {
        ...state,
        listExpenses: action.payload,
        isLoading: false,
        isCreateExpense: false,
      };

    case FETCH_EXPENSES_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_FILTER_YEAR_EXPENSE_PRJ:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_FILTER_YEAR_EXPENSE_PRJ_SUCCESS:
      return {
        ...state,
        filterYearExpensePrj: action.payload,
        isLoading: false,
      };

    case FETCH_FILTER_YEAR_EXPENSE_PRJ_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case FETCH_EXPENSE_CATEGORY:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_EXPENSE_CATEGORY_SUCCESS:
      return {
        ...state,
        listExpenseCategory: action.payload,
        isLoading: false,
      };

    case FETCH_EXPENSE_CATEGORY_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case FETCH_PAYMENT_MODE:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_PAYMENT_MODE_SUCCESS:
      return {
        ...state,
        listPaymentMode: action.payload,
        isLoading: false,
      };

    case FETCH_PAYMENT_MODE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_TAX:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_TAX_SUCCESS:
      return {
        ...state,
        listTax: action.payload,
        isLoading: false,
      };
    case FETCH_TAX_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_CREATE_EXPENSE_PRJ:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_CREATE_EXPENSE_PRJ_SUCCESS:
      return {
        ...state,
        isCreateExpense: true,
        isLoading: false,
      };
    case FETCH_CREATE_EXPENSE_PRJ_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_CUSTOMER:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        listCustomer: action.payload,
        isLoading: false,
      };

    case FETCH_CUSTOMER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case FILTER_PROJECT_EXPENSES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FILTER_PROJECT_EXPENSES_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        listExpenses: action.payload,
      };
    case FILTER_PROJECT_EXPENSES_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return { ...state };
  }
};

export default ExpenseProjectReducer;
