import ActionTypes from './types';

export const getListPredefinedRepliesRequest = () => {
    return {
        type: ActionTypes.GET_LIST_PREDEFINED_REPLIES_REQUEST
    }
};

export const getListPredefinedRepliesSuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_PREDEFINED_REPLIES_SUCCESS,
        payload: data
    }
};

export const getListPredefinedRepliesFailed = () => {
    return {
        type: ActionTypes.GET_LIST_PREDEFINED_REPLIES_FAILED,
    }
};

export const createPredefinedRepliesRequest = (data) => {
    return {
        type: ActionTypes.CREATE_PREDEFINED_REPLIES_REQUEST,
        payload: data,
    }
};

export const createPredefinedRepliesSuccess = (data) => {
    return {
        type: ActionTypes.CREATE_PREDEFINED_REPLIES_SUCCESS,
        payload: data
    }
};

export const createPredefinedRepliesFailed = () => {
    return {
        type: ActionTypes.CREATE_PREDEFINED_REPLIES_FAILED
    }
};

export const editPredefinedRepliesRequest = (data) => {
    return {
        type: ActionTypes.EDIT_PREDEFINED_REPLIES_REQUEST,
        payload: data,
    }
};

export const editPredefinedRepliesSuccess = (data) => {
    return {
        type: ActionTypes.EDIT_PREDEFINED_REPLIES_SUCCESS,
        payload: data
    }
};

export const editPredefinedRepliesFailed = () => {
    return {
        type: ActionTypes.EDIT_PREDEFINED_REPLIES_FAILED
    }
};

export const deletePredefinedRepliesRequest = (data) => {
    return {
        type: ActionTypes.DELETE_PREDEFINED_REPLIES_REQUEST,
        payload: data,
    }
};

export const deletePredefinedRepliesSuccess = (data) => {
    return {
        type: ActionTypes.DELETE_PREDEFINED_REPLIES_SUCCESS,
        payload: data
    }
};

export const deletePredefinedRepliesFailed = () => {
    return {
        type: ActionTypes.DELETE_PREDEFINED_REPLIES_FAILED,
    }
};

export const changeStatusDialog = () => {
    return {
        type: ActionTypes.CHANGE_STATUS_DIALOG,
    }
};

export const getPredefinedRepliesEditRequest = (data) => {
    return {
        type: ActionTypes.GET_PREDEFINED_REPLIES_EDIT_REQUEST,
        payload: data,
    }
};

export const getPredefinedRepliesEditSuccess = (data) => {
    return {
        type: ActionTypes.GET_PREDEFINED_REPLIES_EDIT_SUCCESS,
        payload: data
    }
};

export const getPredefinedRepliesEditFailed = () => {
    return {
        type: ActionTypes.GET_PREDEFINED_REPLIES_EDIT_FAILED,
    }
};

export const fetchPredefinedRepliesRequest = (search, params) => {
    return {
        type: ActionTypes.FETCH_PREDEFINED_REPLIES_REQUEST,
        search, params
    }
};

export const fetchPredefinedRepliesSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_PREDEFINED_REPLIES_SUCCESS,
        payload: data
    }
};

export const fetchPredefinedRepliesFailed = () => {
    return {
        type: ActionTypes.FETCH_PREDEFINED_REPLIES_FAILED,
    }
};