import ActionTypes from "./types";

export const getCustomFieldProposalRequest = (params) => {
    return {
        type: ActionTypes.GET_PROPOSAL_REQUEST,
        params,
    };
};

export const getCustomFieldProposalSuccess = (data) => {
    return {
        type: ActionTypes.GET_PROPOSAL_SUCCESS,
        payload: data,
    };
};

export const getCustomFieldProposalFailed = () => {
    return {
        type: ActionTypes.GET_PROPOSAL_FAILED,
    };
};
