import ActionTypes from "./type";

const INIT_STATE = {
  listReminder: [],
  isLoading: false,
  statusAddSucces: false,
};

const ExpensesCustomerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.ADD_EXPENSES_CUSTOMER_REQUEST:
      return {
        ...state,
      };
    case ActionTypes.ADD_EXPENSES_CUSTOMER_SUCCESS:
      return {
        ...state,
        statusAddSucces: !state.statusAddSucces,
      };
    case ActionTypes.ADD_EXPENSES_CUSTOMER_FAILED:
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};

export default ExpensesCustomerReducer;
