export default {
    administrator: {
        newStaff: "New Staff Member",
        fullName: "Full Name",
        role: "Role",
        lastLogin: "Last Login",
        active: "Active",
        view: "View",
        delete: "Delete",
        profile: "Profile",
        permissions: "Permissions",
        factorAuth: "Enable Two Factor Authentication",
        factorAuthTooltip:
            "Two factor authentication is provided by email, before enable two factor authentication make sure that your SMTP settings are properly configured and the system is able to send an email. Unique authentication key will be sent to mail upon login.",
        notStaff: "Not Staff Member",
        profileImage: "Profile image",
        firstName: "First Name",
        lastName: "Last Name",
        hourlyRate: "Hourly Rate",
        phone: "Phone",
        defaultLang: "Default Language",
        emailSign: "Email Signature",
        direction: "Direction",
        memberDepartment: "Member Departments",
        sendWelcome: "Send welcome email",
        admin: "Administrator",
        password: "Password",
        fieldRequired: "This field is required.",
        validEmail: "Please enter a valid email.",
        feature: "Feature",
        capabilities: "Capabilities",
        bulkPDF: "Bulk PDF Export",
        contracts: "Contracts",
        creditNotes: "Credit Notes",
        customers: "Customers",
        emailTemplate: "Email Templates",
        estimate: "Estimates",
        expenses: "Expenses",
        invoices: "Invoices",
        items: "Items",
        knowledgeBase: "Knowledge Base",
        payments: "Payments",
        projects: "Projects",
        proposals: "Proposals",
        reports: "Reports",
        staffRoles: "Staff Roles",
        settings: "Settings",
        staff: "Staff",
        subscriptions: "Subscriptions",
        tasks: "Tasks",
        taskCheckTemplate: "Task Checklist Templates",
        leads: "Leads",
        viewGlobal: "View (Global)",
        viewOwn: "View (Own)",
        create: "Create",
        edit: "Edit",
        newCustomerGroup: "New Customer Group",
        name: "Name",
        options: "Options",
        addNewCustomerTitle: "Add New Customer Group",
        editNewCustomerTitle: "Edit Customer Group",
        save: "Save",
        saveSetting: "Save Setting",
        close: "Close",
        totalLoggedTime: "Total Logged Time",
        lastMonthLoggedTime: "Last Month Logged Time",
        thisMonthLoggedTime: "This Month Logged Time",
        lastWeekLoggedTime: "Last Week Logged Time",
        thisWeekLoggedTime: "This Week Logged Time",
        noteTitle: "Notes",
        newNote: "New Note",
        addFrom: "Added From",
        dateAdd: "Date Added",
        timesheetTitle: "Timesheets & Reports",
        deleteCustomerGroup: "Delete customer group",
        deleteService: "Delete service",
        deleteSpamFilter: "Delete spam filter",
        deleteDepartment: "Delete department",
        deletePredefinedReply: "Delete predefined reply",
        deletePriority: "Delete Priority",
        apply: "Apply",
        period: "Period",
        task: "Task",
        startTime: "Start Time",
        endTime: "End Time",
        related: "Related",
        time: "Time",
        decimal: "Decimal",
        totalByHourlyRate: "Total By Hourly Rate",
        projectName: "Project Name",
        startDate: "Start Date",
        deadline: "Deadline",
        status: "Status",
        noteDescription: "Note description",
        newDepartment: "New Department",
        departmentEmail: "Department Email",
        googleCalendar: "Google Calendar ID",
        departmentName: "Department Name",
        hideClient: "Hide from client?",
        imapUsername: "IMAP Username",
        imapHost: "IMAP Host",
        encryption: "Encryption",
        noEncryption: "No Encryption",
        deleteMailAfterImport: "Delete mail after import?",
        testIMAP: "Test IMAP Connection",
        editDepartment: "Edit Department",
        radioYes: "Yes",
        radioNo: "No",
        require: "This field is required .",
        ///////////////////////////
        newTask: "New Task",
        id: "ID",
        taxName: "Tax Name",
        ratePercent: "Rate (percent)",
        editTask: "Edit Task",
        addNewTask: "Add New Task",
        newCurrency: "New Currency",
        symbol: "Symbol",
        decimalSeparator: "Decimal Separator",
        thousandSeparator: "Thousand Separator",
        currencyPlacement: "Currency Placement",
        beforeAmount: "Before Amount",
        afterAmount: "After Amount",
        alertValidCurrency: "Make sure to enter valid currency ISO code.",
        isoCode: "ISO Code",
        addNewCurrency: "Add New Currency",
        editCurrency: "Edit Currency",

        newPaymentMode: "New Payment Mode",
        addNewPaymentMode: "Add New Payment Mode",
        editPaymentMode: "Edit Payment Mode",
        notePaymentMode:
            "Note: Payment modes listed below are offline modes. Payment gateways can be configured in Setup-> Settings->Payment Gateways",
        paymentModeName: "Payment Mode Name",
        bankAcc_descriptions: "Bank Accounts / Description",
        showBankAcc_descriptionPDF:
            "Show Bank Accounts / Description on Invoice PDF",
        selectedByInvoice: "Selected by default on invoice",
        invoicesOnly: "Invoices Only",
        expensesOnly: "Expenses Only",

        newCategory: "New Category",
        editExpenseCategory: "Edit Expense Category",
        categoryName: "Category Name",
        categoryDescription: "Category Descriptions",
        descriptions: "Descriptions",
        support: {
            predefinedReplies: {
                newPredefinedReply: "New Predefined Reply",
                predefinedReplyName: "Predefined Reply Name",
                options: "Options",
                addNewPredefined: "Add New Predefined Reply",
                editPredefined: "Edit Predefined Reply",
            },
            ticketPriority: {
                newPriority: "New Priority",
                addPriority: "Add Priority",
                priorityName: "Priority Name",
                editPriority: "Edit Priority",
                low: "Low",
                medium: "Medium",
                high: "High",
            },
            ticketStatus: {
                newTicketStatus: "New Ticket Status",
                editTicketStatus: "Edit Ticket Status",
                ticketStatusName: "Ticket Status Name",
                pickColor: "Pick Color",
                statusOrder: "Status Order",
            },
            services: {
                newService: "New Service",
                serviceName: "Service Name",
                editTicketService: "Edit Ticket Service",
            },
        },
        taxRate: {
            newTax: "New Tax",
            id: "ID",
            taxName: "Tax Name",
            ratePercent: "Rate (percent)",
            taxRate: "Tax Rate (percent)",
            editTax: "Edit Tax",
            addNewTax: "Add New Tax",
        },
        // ///////////////////////////
        currencyCode: {
            newCurrency: "New Currency",
            symbol: "Symbol",
            currencyCode: "Currency Code",
            decimalSeparator: "Decimal Separator",
            thousandSeparator: "Thousand Separator",
            currencyPlacement: "Currency Placement",
            beforeAmount: "Before Amount",
            afterAmount: "After Amount",
            alertValidCurrency: "Make sure to enter valid currency ISO code.",
            isoCode: "ISO Code",
            addNewCurrency: "Add New Currency",
            editCurrency: "Edit Currency",
        },
        /////////////////////////////
        paymentMode: {
            newPaymentMode: "New Payment Mode",
            addNewPaymentMode: "Add New Payment Mode",
            editPaymentMode: "Edit Payment Mode",
            notePaymentMode:
                "Note: Payment modes listed below are offline modes. Payment gateways can be configured in Setup-> Settings->Payment Gateways",
            paymentModeName: "Payment Mode Name",
            bankAcc_descriptions: "Bank Accounts / Description",
            showBankAcc_descriptionPDF:
                "Show Bank Accounts / Description on Invoice PDF",
            selectedByInvoice: "Selected by default on invoice",
            invoicesOnly: "Invoices Only",
            expensesOnly: "Expenses Only",
        },
        ///////////////////////////
        category: {
            newCategory: "New Category",
            editExpenseCategory: "Edit Expense Category",
            categoryName: "Category Name",
            categoryDescription: "Category Descriptions",
            descriptions: "Descriptions",
        },
        lead: {
            source: {
                table: {
                    name: "Source Name",
                    options: "Options",
                    total: "Total Leads",
                },
                add: "New Source",
                edit: "Edit Source",
            },
            status: {
                table: {
                    name: "Status Name",
                    options: "Options",
                    total: "Total Leads",
                },
                add: "New Lead Status",
                edit: "Edit Status",
                name: "Status Name",
                color: "Color",
                order: "Order",
            },
            email: {
                emailTitle: "Email Integration",
                spamFilters: "Spam Filters",
                newSpamFilter: "NEW SPAM FILTER",
                addSpamFilter: "Add Spam Filter",
                editSpamFilter: "Edit Spam Filter",
                type: "Type",
                content: "Content",

                isActive: "Active",
                IMAP: "IMAP Server",
                defaultStatus: "Default Status",
                defaultSource: " Default Source",
                email: "Email address (Login)",
                password: "Password",
                responsible: "Responsible for new lead",
                encryption: "Encryption",
                TLS: "TLS",
                SSL: "SSL",
                noEncryption: "No Encryption",
                notification: "Notification settings",
                notify1: "Notify when lead imported",
                notify2: "Notify if lead send email multiple times",
                folder: "Folder",
                every: "Check Every (minutes)",
                radio1: "Staff members with roles ",
                radio2: "Specific Staff Members",
                redio3: "Responsible person",
                checkbox1: "  Only check non opened emails",
                checkbox2:
                    " Create task if email sender is already customer and assign to responsible staff member.",
                checkbox3: "Delete mail after import?",
                checkbox4: "Auto mark as public",
                arrowText1:
                    "The script will auto set the email to opened after check. This is used to prevent checking all the emails again and again. Its not recommended to uncheck this option if you have a lot emails and you have setup a lot forwarding to the email you setup for leads",
                arrowText2:
                    "Used for further review on the submission and take the necessary action",

                staffMember1: "Staff members with roles",
                staffMember2: "Specific Staff Members ",
                responsibleMember: "Responsible Person",
                staffNotify: "Staffs Member to Notify",
                rolesNotify: "Roles to Notify",
                imapConnection: "TEST IMAP CONNECTION",
            },
            web: {
                table: {
                    newForm: "NEW FORM",
                    formName: "Form Name",
                    totalSubmission: "Total Submission",
                    create: "Created",
                },
                title: " Create form first to be able to use the form builder.",
                source: "Source",
                language: "  Language",
                status: "Status",
                submitButtonText: " Submit button text",
                responsible: "Responsible (Assignee)",
                message: "Message to show after form is succcesfully submitted",
                notification: "Notification settings",
                notify1: "Notify when lead imported",
                autoMark: "Auto mark as public",
                allowDuplicate:
                    "Allow duplicate lead to be inserted into database?",
                staffMember1: "Staff members with roles",
                staffMember2: "Specific Staff Members",
                responsibleMember: "Responsible person",
                staffNotify: "Staff Members to Notify",
                arrowLang: "For Validation Language",
                arrowCreate:
                    "Used for further review on the submission and take the necessary action",
                prevent_duplicate: "Prevent duplicate on field",
                add_field:
                    "+ field (leave blank to track duplicates only by 1 field)",
                create_lead:
                    "Create duplicate lead data as task and assign to responsible staff member",
                // editform :
                editForm: {
                    formBuilder: "Form Builder",
                    formInfo: "Form Infomation",
                    intergrationCode: "Intergration Code",
                    titleEditForm:
                        "Copy & Paste the code anywhere in your site to show the form, additionally you can adjust the width and height px to fit for your website.",
                },
            },
        },
        setting: {
            general: {
                general: "General",
                companyLogoDark: "Company Logo Dark",
                favicon: "Favicon",
                companyName: "Company Name",
                companyMainDomain: "Company Main Domain",
                RTLAdmin: "RTL Admin Area (Right to Left)",
                RTLCustomer: "RTL Customers Area (Right to Left)",
                allowedFileTypes: "Allowed file types",
            },
            company: {
                company: "Company Information",
                textDescription:
                    "These information will be displayed on invoices/estimates/payments and other PDF documents where company info is required",
                companyName: "Company Name",
                address: "Address",
                city: "City",
                state: "State",
                countryCode: "Country Code",
                zipCode: "Zip Code",
                phone: "Phone",
                vatNumber: "VAT Number",
                PDFandHTML: "Company Information Format (PDF and HTML)",
                label: {
                    companyName: "{company_name}",
                    address: "{address}",
                    city: "{city}",
                    state: "{state}",
                    zipCode: "{zip_code}",
                    countryCode: "{country_code}",
                    phone: "{phone}",
                    vatNumber: "{vat_number}",
                    vatNumberWithLabel: "{vat_number_with_label}",
                },
            },
            localization: {
                localization: "Localization",
                dateFormat: "Date Format",
                timeFormat: "Time Format",
                defaultTimezone: "Default Timezone",
                defaultLanguage: "Default Language",
                disableLanguages: "Disable Languages",
                outputClient:
                    "Output client PDF documents from admin area in client language",
                textTooltip:
                    "If this options is set to yes and eq. the system default language is English and client have setup language french the pdf documents will be outputted in the client language",
            },
            email: {
                email: "Email",
                smtpSetting: "SMTP Settings",
                emailQueue: "Email Queue",
                setupMail: "Setup main email",
                mailEngine: "Mail Engine",
                phpMailer: "PHPMailer",
                codeIgniter: "codeIgniter",
                emailProtocol: "Email Protocol",
                smtp: "SMTP",
                sendMail: "Sendmail",
                mail: "Mail",
                emailEncryption: "Email Encryption",
                smtpHost: "SMTP Host",
                smtpPort: "SMTP Port",
                textTooltip:
                    "Fill only if your email client use username for SMTP login",
                smtpUsername: "SMTP Username",
                smtpPassword: "SMTP Password",
                emailCharset: "Email Charset",
                bcc: "BCC All Emails To",
                emailSignature: "Email Signature",
                predefinedHeader: "Predefined Header",
                predefinedFooter: "Predefined Footer",
                titleSendTestEmail: "Send Test Email",
                sendTestEmail:
                    "Send test email to make sure that your SMTP settings is set correctly.",
                textHeaderQueue:
                    "This feature requires a properly configured cron job. Before activating the feature, make sure that the",
                textCronJob: "cron job",
                textFooterQueue:
                    "is configured as explanation in the documentation.",
                toolTipEmailQueue:
                    "To speed up the emailling process, the system will add the emails in queue and will send them via cron job, make sure that the cron job is properly configured in order to use this feature.",
                enableEmailQueue: "Enable Email Queue",
                toolTipDoNotAdd:
                    "Most likely you will encounter problems with the email queue if the system needs to add big files to the queue. If you plan to use this option consult with your server administrator/hosting provider to increase the max_allowed_packet and wait_timeout options in your server config, otherwise when this option is set to yes the system won't add emails with attachments in the queue and will be sent immediately.",
                doNotAddEmail:
                    "Do not add emails with attachments in the queue?",
            },
            finance: {
                finance: "Finance",
                general: "General",
                generalSetting: "General settings",
                decimalSeparator: "Decimal Separator",
                thousandSeparator: "Thousand Separator",
                tooltipNumber: "Invoices , Estimates , Proposals",
                numberFormats: "Number padding zero's for prefix formats",
                noteNumberFormats:
                    "eq. If this value is 3 the number will be formatted: 005 or 025",
                showTAX: "Show TAX per item",
                tooltipRemove:
                    "eq. Item TAX 15% will be shown as 15% without the tax name (Not applied if multiple taxes with the same name and tax percent found for item)",
                removeTax: "Remove the tax name from item table row",
                defaultTax: "Default Tax",
                textRemoveDecimals:
                    "Remove decimals on numbers/money with zero decimals (2.00 will become 2, 2.25 will stay 2.25)",
                amountToWords: "Amount to words",
                noteAmountToWords:
                    "Output total amount to words in invoice/estimate/proposal",
                enable: "Enable",
                numberWordsIntoLowercase: "Number words into lowercase",
                fieldRequired: "This field is required.",
                validCurrency: "Please enter no more than 3 characters.",
                invoice: {
                    invoiceNumberPrefix: "Invoice Number Prefix",
                    tooltipNextInvoiceNumber:
                        "Set this field to 1 if you want to start from beginning",
                    nextInvoiceNumber: "Next Invoice Number",
                    tooltipInvoiceDueAfter: "Set zero to avoid calculation",
                    invoiceDueAfter: "Invoice due after (days)",
                    allowStaff:
                        "Allow staff members to view invoices where they are assigned to",
                    requireClient:
                        "Require client to be logged in to view invoice",
                    deleteInvoice:
                        "Delete invoice allowed only on last invoice",
                    tooltipDecrement:
                        "Do you want to decrement the invoice number when the last invoice is deleted? eq. If is set this option to YES and before invoice delete the next invoice number is 15 the next invoice number will decrement to 14. If is set to NO the number will remain to 15. If you have setup delete only on last invoice to NO you should set this option to NO too to keep the next invoice number not decremented.",
                    decrementInvoice: "Decrement invoice number on delete",
                    excludeInvoices:
                        "Exclude invoices with draft status from customers area",
                    showSale: "Show Sale Agent On Invoice",
                    showProject: "Show Project Name On Invoice",
                    showTotal: "Show Total Paid On Invoice",
                    showCredits: "Show Credits Applied On Invoice",
                    showAmount: "Show Amount Due On Invoice",
                    invoiceNumberFormat: "Invoice Number Format",
                    numberBased: "Number Based",
                    yearBased: "Year Based",
                    predefinedClientNote: "Predefined Client Note",
                    predefinedTerms: "Predefined Terms & Conditions",
                },
                creditNotes: {
                    creditNoteNumberPrefix: "Credit Note Number Prefix",
                    tooltipNextCreditNote:
                        "Set this field to 1 if you want to start from beginning",
                    nextCreditNoteNumber: "Next Credit Note Number",
                    creditNoteNumberFormat: "Credit Note Number Format",
                    tooltipDecrementCreditNote:
                        "Number will be decremented only if is last credit note created.",
                    decrementCreditNoteNumber:
                        "Decrement credit note number on delete.",
                    showProjectName: "Show Project Name On Credit Note",
                    predefinedClientNote: "Predefined Client Note",
                    predefinedTerms: "Predefined Terms & Conditions",
                },
                estimates: {
                    estimateNumberPrefix: "Estimate Number Prefix",
                    tooltipNextEstimate:
                        "Set this field to 1 if you want to start from beginning",
                    nextEstimateNumber: "Next estimate Number",
                    tooltipEstimateDue: "Set zero to avoid calculation",
                    estimateDueAfter: "Estimate Due After (days)",
                    deleteEstimate:
                        "Delete estimate allowed only on last invoice",
                    tooltipDecrement:
                        "Do you want to decrement the estimate number when the last estimate is deleted? eq. If is set this option to YES and before estimate delete the next estimate number is 15 the next estimate number will decrement to 14. If is set to NO the number will remain to 15.If you have setup delete only on last estimate to NO you should set this option to NO too to keep the next estimate number not decremented.",
                    decrementEstimate: "Decrement estimate number on delete",
                    allowStaff:
                        "Allow staff members to view estimates where they are assigned to",
                    requireClient:
                        "Require client to be logged in to view estimate",
                    showSale: "Show Sale Agent On Estimate",
                    showProject: "Show Project Name On Estimate",
                    autoConvert:
                        "Auto convert the estimate to invoice after client accept",
                    excludeEstimates:
                        "Exclude estimates with draft status from customers area",
                    estimateNumberFormat: "Estimate Number Format",
                    pipelineLimit: "Pipeline limit per status",
                    defaultPipelineSort: "Default pipeline sort",
                    ascending: "Ascending",
                    descending: "Descending",
                    predefinedClientNote: "Predefined Client Note",
                    predefinedTerms: "Predefined Terms & Conditions",
                },
                proposals: {
                    proposalNumberPrefix: "Proposal Number Prefix",
                    tooltipProposalDue: "Set zero to avoid calculation",
                    proposalDueAfter: "Proposal Due After (days)",
                    pipelineLimit: "Pipeline limit per status",
                    defaultPipelineSort: "Default pipeline sort",
                    ascending: "Ascending ",
                    descending: "Descending",
                    excludeProposals:
                        "Exclude proposals with draft status from customers area",
                    allowStaff:
                        "Allow staff members to view proposals where they are assigned to",
                    proposalInfoFormat: "Proposal Info Format (PDF and HTML)",
                    proposalTo: "{proposal_to}",
                    address: "{address}",
                    city: "{city}",
                    state: "{state}",
                    zipCode: "{zip_code}",
                    countryCode: "{country_code}",
                    countryName: "{country_name}",
                    phone: "{phone}",
                    email: "{email}",
                },
            },
            subscriptions: {
                subscriptions: "Subscriptions",
                tooltipSubscriptions:
                    "This option is valid only for the customer primary contact.",
                textShowSubscriptions: "Show subscriptions in customers area?",
                textAfterSucceeded: "After subscription payment is succeeded",
                invoiceAndPayment: "Send Invoice and Payment Receipt",
                invoice: "Send Invoice",
                payment: "Send Payment Receipt",
                doNothing: "Do Nothing",
                emailTemplate: "Email Template: ",
                contentEmailTemplate: "Subscription Payment Succeeded",
            },
            paymentGateways: {
                paymentGateways: "Payment Gateways",
                general: "General",
                paypalSmart: "Paypal Smart CheckOut",
                paypal: "Paypal",
                payU: "PayU Money",
                stripeCheck: "Stripe CheckOut",
                stripeIdeal: "Stripe iDEAL",
                _2check: "2Check Out",
                aim: "Authorize AIM",
                sim: "Authorize SIM",
                instamojo: "Instamojo",
                mollie: "Mollie",
                brainTree: "Brain Tree",
                generalTab: {
                    receiveNotify:
                        "Receive notification when customer pay invoice (built-in)",
                    allowModify:
                        "Allow customer to modify the amount to pay (for online payments)",
                },
                active: "Active",
                label: "Label",
                clientId: "Client ID",
                secret: "Secret",
                dashbord: "Gateway Dashbord Payment Description",
                currencies: "Currencies (coma separated)",
                currency: "Currency",
                enableTest: "Enable Test Mode",
                selectedInvoice: "Selected by default on invoice",
                devMode: "Developer Mode",
                enablePaypal: "Enable PayPal Payments",
                allowContact:
                    "Allow primary contact to update stored credit card token?",
                paypalUser: "PayPal API Username",
                paypalPass: "PayPal API Password",
                sign: "API Signature",
                payUKey: "PayU Money Key",
                payUSalt: "PayU Money Salt",
                stripeSecretKey: "Stripe API Secret Key",
                stripePublishKey: "Stripe Publishable Key",
                stateDesc:
                    "Statement Descriptor (shown in customer bank statement)",
                stateDescInfo:
                    "Statement descriptors are limited to 22 characters, cannot use the special characters ', and must not consist solely of numbers.",
                accNumber: "Account Number (Seller ID)",
                privateKey: "Private Key",
                publishKey: "Publish Key",
                secretKey: "Secret Key",
                _2checkTitle:
                    "SSL is required if you're using the 2Checkout payment API. It is required to safely call the tokenization and authorizations. The system will function without SSL, however, this will place you out of compliance, which risks deactivation of your API permissions.",
                aimTitle:
                    "SSL is required if you're using the Authorize.Net AIM payment API. Authorize.net only supports 1 currency per account. Make sure you add only 1 currency associated with your Authorize account in the currencies field.",
                supportCurr: "Currently supported currencies:",
                loginId: "API Login ID",
                transId: "API Transaction ID",
                ApiKey: "Private API Key",
                AuthToken: "Private Auth Token",
                apiKey: "API Key",
                merchantId: "Merchant ID",
            },

            customers: {
                customers: "Customers",
                defaultCustomers: "Default Customers Theme",
                defaultCountry: "Default Country",
                visibleTabs: "Visible Tabs(Profile)",
                companyField: "Company field is required?",
                companyVatNumber:
                    "Company requires the usage of the VAT Number field",
                customersRegister: "Allow customers to register",
                registrationConfirmation:
                    "Require registration confirmation from administrator after customer register",
                primaryContact:
                    "Allow primary contact to view/edit billing & shipping details",
                tooltipContact:
                    "If you share the file manually from customer profile to other contacts they wil be able to see the file.",
                contactUploaded:
                    "Contacts see only own files uploaded in customer area (files uploaded in customer profile)",
                contactDelete:
                    "Allow contacts to delete own files uploaded from customers area",
                tooltipKnowledge:
                    "If you allow this options knowledge base will be shown also on clients side",
                knowledgeBase: "Use Knowledge Base",
                knowledgeViewed:
                    "Allow knowledge base to be viewed without registration",
                contactPermissions: "Default contact permissions",
                tooltipInfoFormat: "Invoices, Estimates, Payments, Statement",
                infoFormat: "Customer Information Format (PDF and HTML)",
            },
            tasks: {
                tasks: "Tasks",
                limitTasks: "Limit tasks kan ban rows per status",
                allowAllStaff:
                    "Allow all staff to see all tasks related to projects (includes non-staff)",
                allowCustomerStaff:
                    "Allow customer/staff to add/edit task comments only in the first hour (administrators not applied)",
                autoAssignTasks:
                    "Auto assign task creator when new task is created",
                autoAddTask:
                    "Auto add task creator as task follower when new task is created",
                stopAll:
                    "Stop all other started timers when starting new timer",
                changeTaskStatus:
                    "Change task status to In Progress on timer started (valid only if task status is Not Started)",
                billableOption:
                    "Billable option is by default checked when new task is created? (only from admin area)",
                defaultStatus: "Default status when new task is created",
                defaultPriority: "Default Priority",
                modalWidthClass:
                    "Modal Width Class (modal-lg, modal-xl, modal-xxl)",
            },
            support: {
                support: "Support",
                useServices: "Use services",
                allowStaffDepartments:
                    "Allow staff to access only ticket that belongs to staff departments",
                tooltipReceiveNewTicket:
                    "All staff members which belong to the ticket department will receive notification that new ticket is opened",
                receiveNotificationNewTicket:
                    "Receive notification on new ticket opened",
                tooltipReceiveCustomerReply:
                    "All staff members which belong to the ticket department will receive notification when customer reply to a ticket",
                receiveNotificationCustomerReply:
                    "Receive notification when customer reply to a ticket",
                tooltipAllowStaff:
                    "If staff member don't have permission for customers VIEW only will be able to create new tickets from admin area to customer contacts where is assigned as customer admin.",
                allowStaffMembers:
                    "Allow staff members to open tickets to all contacts?",
                allowAccess: "Allow access to tickets for non staff members",
                allowNonAdmin:
                    "Allow non-admin staff members to delete ticket attachments",
                allowCustomer:
                    "Allow customer to change ticket status from customers area",
                inCustomers:
                    "In customers area only show tickets related to the logged in contact (Primary contact not applied)",
                tooltipTicket:
                    "The initial ticket message will be always shown as first",
                ticketRepliesOrder: "Ticket Replies Order",
                ascending: "Ascending",
                descending: "Descending",
                defaultStatus:
                    "Default status selected when replying to ticket",
                maximumTicket: "Maximum ticket attachments",
                allowedAttachments: "Allowed attachments file extensions",
                emailPiping: {
                    pipeOnly: "Pipe Only on Registered Users",
                    onlyReplies: "Only Replies Allowed by Email",
                    tryToImport:
                        "Try to import only the actual ticket reply (without quoted/forwarded message)",
                    defaultPriority: "Default priority on piped ticket",
                },
            },
            leads: {
                leads: "Leads",
                limitLeads: "Limit leads kan ban rows per status",
                defaultStatus: "Default status",
                defaultSource: "Default source",
                performValidation:
                    "Perform validation for duplicate lead on the following fields:",
                tooltipAutoAssign:
                    "If this option is set to YES the staff member that converted lead to customer will be auto assigned as customer admin. NOTE: This option will apply only on staff members that do not have permission for customers VIEW",
                autoAssign: "Auto assign as admin to customer after convert",
                allowNonAdmin: "Allow non-admin staff members to import leads",
                defaultLeads: "Default leads kan ban sort",
                ascending: "Ascending",
                descending: "Descending",
                doNotAllow:
                    "Do not allow leads to be edited after they are converted to customers (administrators not applied)",
                modalWidthClass:
                    "Modal Width Class (modal-lg, modal-xl, modal-xxl)",
            },
            sms: {
                sms: "SMS",
                only: "Only 1 active SMS gateway is allowed",
                clickatell: {
                    clickatell: "Clickatell",
                    titleClickatell:
                        "Clickatell SMS integration is one way messaging, means that your customers won't be able to reply to the SMS.",
                    apiKey: "API Key",
                    active: "Active",
                },
                msg91: {
                    msg91: "MSG91",
                    titleMsg:
                        "MSG91 SMS integration is one way messaging, means that your customers won't be able to reply to the SMS.",
                    senderID: "Sender ID",
                    authKey: "Auth Key",
                    active: "Active",
                },
                twilio: {
                    twilio: "Twilio",
                    title: "Twilio SMS integration is one way messaging, means that your customers won't be able to reply to the SMS. Phone numbers must be in format E.164. Click here to read more how phone numbers should be formatted.",
                    accountSID: "Account SID",
                    authToken: "Auth Token",
                    twilioPhone: "Twilio Phone Number",
                    active: "Active",
                },
                triggers: "Triggers",
                titleTrigger:
                    "Leave contents blank to disable specific trigger",
                invoiceOverdueNotice: "Invoice Overdue Notice",
                textInvoiceOverdueNotice:
                    "Trigger when invoice overdue notice is sent to customer contacts.",
                availableMergeFields: "Available merge fields",
                invoicePaymentRecorded: "Invoice Payment Recorded",
                textInvoicePaymentRecorded:
                    "Trigger when invoice payment is recorded.",
                estimate: "Estimate Expiration Reminder",
                textEstimate:
                    "Trigger when expiration reminder should be send to customer contacts.",
                proposalExpiration: "Proposal Expiration Reminder",
                textProposalExpiration:
                    "Trigger when expiration reminder should be send to proposal.",
                newCommentOnProposalCustomer:
                    "New Comment on Proposal (to customer)",
                textNewCommentOnProposalCustomer:
                    "Trigger when staff member comments on proposal, SMS will be sent to proposal number (customer/lead).",
                newCommentOnProposalStaff: "New Comment on Proposal (to staff)",
                textNewCommentOnProposalStaff:
                    "Trigger when customer/lead comments on proposal, SMS will be sent to proposal creator and assigned staff member.",
                newCommentOnContractCustomer:
                    "New Comment on Contract (to customer)",
                textNewCommentOnContractCustomer:
                    "Trigger when staff member add comment to contract, SMS will be sent customer contacts.",
                newCommentOnContractStaff: "New Comment on Contract (to staff)",
                textNewCommentOnContractStaff:
                    "Trigger when customer add comment to contract, SMS will be sent to contract creator.",
                contractExpirationReminder: "Contract Expiration Reminder",
                textContractExpirationReminder:
                    "Trigger when expiration reminder should be send via Cron Job to customer contacts.",
                staffReminder: "Staff Reminder",
                textStaffReminder:
                    "Trigger when staff is notified for a specific custom reminder.",
            },
            calendar: {
                calendar: "Calendar",
                general: "General",
                stylings: "Stylings",
                generalTab: {
                    events: "Calendar Events Limits (Months and Week View)",
                    view: "Default View",
                    firstDay: "First Day",
                    showCalendar: "Show On Calendar",
                    hideNotify: "Hide notified reminders from calendar",
                    leadReminder: "Lead Reminders",
                    customerReminder: "Customer Reminders",
                    estimateReminder: "Estimates Reminders",
                    invoiceReminder: "Invoice Reminders",
                    proposalReminder: "Proposal Reminders",
                    expenseReminder: "Expense Reminders",
                    creditNote: "Credit Note Reminders",
                    ticketReminder: "Ticket Reminders",
                    invoices: "Invoices",
                    estimates: "Estimates",
                    proposals: "Proposals",
                    contract: "Contracts",
                    tasks: "Tasks",
                    showOnlyTask:
                        "Show only tasks assigned to the logged in staff member",
                    projects: "Projects",
                },
                stylingTab: {
                    invoice: "Invoice Color",
                    estimates: "Estimates Color",
                    proposal: "Proposal Color",
                    reminder: "Reminder Color",
                    contract: "Contract Color",
                    project: "Project Color",
                },
            },
            pdf: {
                pdf: "PDF",
                general: "General",
                signature: "Signature",
                documentFormats: "Document Formats",
                generalTab: {
                    font: "PDF Font",
                    swapCompany:
                        "Swap Company/Customer Details (company details to right side, customer details to left side)",
                    fontSize: "Default Font size",
                    headingColor: "Items table heading color",
                    headingTextColor: "Items table heading text color",
                    logoUrl: "Custom PDF Company Logo URL",
                    logoWidth: "Logo Width (PX)",
                    showInvoice:
                        "Show Invoice/Estimate/Credit Note status on PDF documents",
                    showPayInvoice:
                        "Show Pay Invoice link to PDF (Not applied if invoice status is Cancelled)",
                    showEstimates:
                        "Show Invoice/Estimate/Credit Note status on PDF documents",
                    showPageNumber: "Show page number on PDF",
                },
                signatureTab: {
                    invoice: "Show PDF Signature on Invoice",
                    estimates: "Show PDF Signature on Estimates",
                    creditNote: "Show PDF Signature on Credit Note",
                    contract: "Show PDF Signature on Contract",
                    image: "Signature Image",
                },
                documentTab: {
                    invoice: "Invoice",
                    estimate: "Estimate",
                    proposal: "Proposal",
                    payment: "Payment",
                    creditNote: "Credit Note",
                    contract: "Contract",
                    statement: "Statement",
                },
            },
            eSign: {
                eSign: "E-Sign",
                proposal: "Proposal",
                requireDigital:
                    "Require digital signature and identity confirmation on accept",
                estimates: "Estimates",
                legal: "Legal Bound Text",
            },
            cronJob: {
                cronJob: "Cron Job",
                invoice: "Invoices",
                estimate: "Estimates",
                proposal: "Proposals",
                expense: "Expenses",
                contract: "Contracts",
                tasks: "Tasks",
                ticket: "Tickets",
                invoiceTab: {
                    hour: "Hour of day to perform automatic operations",
                    hourTooltip:
                        "Used for recurring invoices, overdue notices etc..",
                    sendDay: "Auto send reminder after (days)",
                    reSendDay: "Auto re-send reminder after (days)",
                    recuring: "Recurring Invoices",
                    recuringItem1:
                        "Generate and autosend the renewed invoice to the customer",
                    recuringItem2: "Generate a Unpaid Invoice",
                    recuringItem3: "Generate a Draft Invoice",
                    createInvoice:
                        " Create new invoice from recurring invoice only if the invoice is with status paid?",
                    createInvoiceTooltip:
                        "If this field is set to YES and the recurring invoices is not with status PAID, the new invoice will NOT be created. arrow",
                },
                estimateTab: {
                    sendExp: "Send expiration reminder before (DAYS)",
                    hourTooltip2:
                        "24 hours format eq. 9 for 9am or 15 for 3pm.",
                },
                taskTab: {
                    deadline: "Task deadline reminder before (Days)",
                    deadlineTooltip:
                        "Notify task assignees about deadline before X days.The notification/email is sent only to the assignees. If the difference between task start date and task due date is smaller then the reminders day no notification will be sent.",
                },
                ticketTab: {
                    hours: "Auto close ticket after (Hours)",
                    hourTooltip: "Set 0 to disable",
                },
            },
            tags: {
                tags: "Tags",
            },
            pusher: {
                pusher: "Pusher.com",
                id: "APP ID",
                key: "APP KEY",
                secret: "APP SECRET",
                cluster: "Cluster",
                realTime: "Enable Real Time Notifications",
                realTimeItem1:
                    "Generate and autosend the renewed invoice to the customer",
                realTimeItem2: "Generate a Unpaid Invoice",
                desktop: "Enable Desktop Notifications ",
                desktopItem1:
                    "Generate and autosend the renewed invoice to the customer",
                desktopItem2: "Generate a Unpaid Invoice",
                dismiss:
                    "Auto Dismiss Desktop Notifications After X Seconds (0 to disable)",
            },
            google: {
                google: "Google",
                key: "Google API Key",
                id: "Google API Client ID",
                siteKey: "Site key",
                secretKey: "Secret key",
                enableCaptcha:
                    "Enable reCAPTCHA on customers area (Login/Register)",
                calendar: "Calendar",
                calendarId: "Google Calendar ID",
                picker: "Google Picker",
                enablePicker: "Enable Google Picker",
            },
            misc: {
                misc: "Misc",
                table: "Table",
                inlineCreate: "Inline Create",
                miscTab: {
                    requireLogged:
                        "Require client to be logged in to view contract",
                    dropKey: "Dropbox APP Key",
                    fileSize: "Max file size upload in Media (MB)",
                    filePost: "Maximum files upload on post",
                    limitSearch: "Limit Top Search Bar Results to",
                    staffRole: "Default Staff Role",
                    systemActivity:
                        "Delete system activity log older then X months",
                    showSetup:
                        "Show setup menu item only when hover with mouse on main sidebar area",
                    showHelp: "Show help menu item on setup menu",
                    useFile:
                        "Use minified files version for css and js (only system files)",
                },
                tableTab: {
                    activeScroll: "Activate Scroll Responsive Tables",
                    saveOrder: "Save last order for tables",
                    showTable: "Show table export button",
                    showTableItem1: "To all staff members",
                    showTableItem2: "Only to administrators",
                    showTableItem3: "Hide export button for all staff members",
                    tablePag: "Tables Pagination Limit",
                },
                inlineCreateTab: {
                    allowLabel1:
                        "Allow non-admin staff members to create Lead Status in Lead create/edit area?",
                    allowLabel2:
                        "Allow non-admin staff members to create Lead Source in Lead create/edit area?",
                    allowLabel3:
                        "Allow non-admin staff members to create Expense Category in Expense create/edit area?",
                    allowLabel4:
                        "Allow non-admin staff members to create Customer Group in Customer create/edit area?",
                    allowLabel5:
                        "Allow non-admin staff members to create Service in Ticket create/edit area?",
                    allowLabel6:
                        "Allow non-admin staff members to save predefined replies from ticket message",
                    allowLabel7:
                        "Allow non-admin staff members to create Contract type in Contract create/edit area?",
                },
            },
            systemUpdate: {
                system: "System Update",
                purchaseKey: "Purchase Key",
                version: "Your Version",
                latestVer: "Latest Version",
                notify: "You are using the latest version",
            },
            systemServer: {
                systemInfo: "System/Server Info",
                variable: "Variable Name",
                value: "Value",
            },
        },
        contract: {
            newContractType: "New Contract Type",
            editContractType: "Edit Contract Type",
        },
        customFields: {
            btnNewCustomfield: "New Custom Field",
            name: "Name",
            belongs: "Belongs to",
            type: "Type",
            slug: "Slug",
            active: "Active",
            titleAdd: "Add new custom field",
            titleEdit: "Edit custom field",
            fieldBelongs: "Field Belongs to",
            fieldName: "Field Name",
            options: "Options",
            toolTipOptions:
                "Only use for Select, Checkbox types. Populate the field by separating the options by coma. eq. apple,orange,banana",
            order: "Order",
            sizeFields: "Grid (Bootstrap Column eq. 12) - Max is 12",
            disabled: "Disabled",
            restrict: "Restrict visibility for administrators only",
            required: "Required",
            visibility: "Visibility",
            showOnTable: "Show on table",
            nothingSelect: "Nothing Select",
            company: "Company",
            leads: "Leads",
            customers: "Customers",
            contacts: "Contacts",
            staff: "Staff",
            Contracts: "Contracts",
            tasks: "Tasks",
            expenses: "Expenses",
            invoice: "Invoice",
            items: "Items",
            creditNote: "Credit Note",
            estimate: "Estimate",
            proposal: "Proposal",
            projects: "Projects",
            tickets: "Tickets",
        },
        roles: {
            newRole: "New Role",
            addNewRole: "Add new role",
            editRole: "Edit role",
            roleName: "Role Name",
            staffUsingRole: "Staff members which are using this role",
            totalUsers: "Total Users:",
            textChangeRole:
                "Changing role permissions won't affected current staff members permissions that are using this role.",
            textUpdate:
                "Update all staff members permissions that are using this role",
            staffMemberRole: "Staff members which are using this role",
        },
    },
};
