import {
    GET_PAYMENT_FAILED,
    GET_PAYMENT_MODE_FAILED,
    GET_PAYMENT_MODE_REQUEST,
    GET_PAYMENT_MODE_SUCCESS,
    GET_PAYMENT_REQUEST,
    GET_PAYMENT_SUCCESS,
} from "./types";

export const getCustomFieldPaymentRequest = (params) => {
    return {
        type: GET_PAYMENT_REQUEST,
        params,
    };
};
export const getCustomFieldPaymentSuccess = (data) => {
    return {
        type: GET_PAYMENT_SUCCESS,
        payload: data,
    };
};
export const getCustomFieldPaymentFailed = () => {
    return {
        type: GET_PAYMENT_FAILED,
    };
};
export const getPaymentModeRequest = () => {
    return {
        type: GET_PAYMENT_MODE_REQUEST,
    };
};
export const getPaymentModeSuccess = (data) => {
    return {
        type: GET_PAYMENT_MODE_SUCCESS,
        payload: data,
    };
};
export const getPaymentModeFailed = () => {
    return {
        type: GET_PAYMENT_MODE_FAILED,
    };
};
