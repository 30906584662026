import {
    GET_PAYMENT_FAILED,
    GET_PAYMENT_MODE_FAILED,
    GET_PAYMENT_MODE_REQUEST,
    GET_PAYMENT_MODE_SUCCESS,
    GET_PAYMENT_REQUEST,
    GET_PAYMENT_SUCCESS,
} from "./types";

const INIT_STATE = {
    isLoading: false,
    listCustomFieldPayment: [],
    paymentMode: [],
};

const SalePaymentReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PAYMENT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_PAYMENT_SUCCESS:
            return {
                ...state,
                listCustomFieldPayment: action.payload,
                isLoading: false,
            };
        case GET_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case GET_PAYMENT_MODE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_PAYMENT_MODE_SUCCESS:
            return {
                ...state,
                paymentMode: action.payload,
                isLoading: false,
            };

        case GET_PAYMENT_MODE_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        default:
            return state;
    }
};

export default SalePaymentReducer;
