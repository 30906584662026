import ActionTypes from './types';

const INIT_STATE = {
    listSpamFilter: [],
    isCloseDialog: false,
    isLoading: false,
    typeOfSearchRequest: 'sender'
};

const AdminSupportSpamFilterReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_ALL_SUPPORT_SPAM_FILTER_REQUEST:
            return {
                ...state,
                isLoading: true
            }

        case ActionTypes.GET_ALL_SUPPORT_SPAM_FILTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listSpamFilter: action.payload
            }
        case ActionTypes.GET_ALL_SUPPORT_SPAM_FILTER_FAILED:
            return {
                ...state,
                isLoading: false
            }
        case ActionTypes.GET_LIST_SUPPORT_SPAM_FILTER_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case ActionTypes.GET_LIST_SUPPORT_SPAM_FILTER_SUCCESS:
            return {
                ...state,
                listSpamFilter: action.payload,
                isLoading: false,
            };

        case ActionTypes.GET_LIST_SUPPORT_SPAM_FILTER_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.CREATE_SUPPORT_SPAM_FILTER_REQUEST:
            return {
                ...state,
                isLoading: true,
                typeOfSearchRequest: action.payload.type
            }

        case ActionTypes.CREATE_SUPPORT_SPAM_FILTER_SUCCESS:
            return {
                ...state,
                isCloseDialog: true,
                isLoading: false,
                listSpamFilter: state.listSpamFilter
            };

        case ActionTypes.CREATE_SUPPORT_SPAM_FILTER_FAILED:
            return {
                ...state,
                isLoading: false,
                isCloseDialog: true,
            };

        case ActionTypes.EDIT_SUPPORT_SPAM_FILTER_REQUEST:
            return {
                ...state,
                isLoading: true,
                typeOfSearchRequest: action.payload.type
            }

        case ActionTypes.EDIT_SUPPORT_SPAM_FILTER_SUCCESS:

            return {
                ...state,
                isCloseDialog: true,
                isLoading: false,
            }

        case ActionTypes.EDIT_SUPPORT_SPAM_FILTER_FAILED:
            return {
                ...state,
                isLoading: false,
                isCloseDialog: true,
            };

        case ActionTypes.DELETE_SUPPORT_SPAM_FILTER_REQUEST:

            return {
                ...state,
                isLoading: true,
                typeOfSearchRequest: action.payload.type
            }

        case ActionTypes.DELETE_SUPPORT_SPAM_FILTER_SUCCESS:
            return {
                ...state,
                isCloseDialog: true,
                isLoading: false,
            };
        case ActionTypes.CHANGE_STATUS_DIALOG:
            return {
                ...state,
                isCloseDialog: false,
            }

        case ActionTypes.DELETE_SUPPORT_SPAM_FILTER_FAILED:
            return {
                ...state,
                isLoading: false,
                isCloseDialog: true,
            };

        case ActionTypes.FETCH_SUPPORT_SPAM_FILTER_REQUEST:
            return {
                ...state,
                isLoading: true,
                typeOfSearchRequest: action.params.type
            }
        case ActionTypes.FETCH_SUPPORT_SPAM_FILTER_SUCCESS:
            if (state.typeOfSearchRequest === "sender") {
                state.listSpamFilter.splice(0, 1, action.payload)
            } else if (state.typeOfSearchRequest === "subject") {
                state.listSpamFilter.splice(1, 1, action.payload)
            } else {
                state.listSpamFilter.splice(2, 1, action.payload)
            }
            return {
                ...state,
                isLoading: false,
                isCloseDialog: false
            };

        case ActionTypes.FETCH_SUPPORT_SPAM_FILTER_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        default: return { ...state };
    }
}

export default AdminSupportSpamFilterReducer;