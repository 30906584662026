export default {
  route: {
    dashboard: "Dashboard",
    customer: "Customer",
    lead: "Leads",
    project: "Project",
    task: "Tasks",
    sales: "Sales",
    proposal: "Proposal",
    plan: "Plan",
    store: "Store",
    product: "Products",
    support: "Support",
    expense: "Expense",
    contract: "Contract",
    utilities: "Utilities",
    report: "Report",
    setup: "Administrator",
    estimates: "Estimates",
    invoices: "Invoices",
    payments: "Payments",
    items: "Items",
    staff: "Staff",
    group: "Group",
    department: "Department",
    predefinedReply: "Predefined Reply",
    ticketPriority: "Ticket Priority",
    ticketStatus: "Ticket Status",
    service: "Service",
    spamFilter: "Spam Filter",
    source: "Source",
    status: "Status",
    emailIntegation: "Email Integration",
    webToLead: "Web to Lead",
    finance: "Finance",
    taxRate: "Tax Rate",
    currency: "Currency",
    paymentMode: "Payment Mode",
    expenseCategory: "Expense Category",
    contractType: "Contract Type",
    customFields: "Custom Fields",
    role: "Role",
    setting: "Setting",
    invoice: "Invoice",
    creditNotes: "Credit Notes",
  },
};
