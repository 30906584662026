import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import reducers from "./reducers";
import sagas from "./sagas";
// import {
//   getListCountry,
//   getListCurrency,
//   getListSource,
//   getListTags,
//   getListStatus,
//   getListStaff,
// } from "./CommonReducer";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

export function configureStore(initialState) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(sagas);
  // store.dispatch(getListStatus());
  // store.dispatch(getListCountry());
  // store.dispatch(getListCurrency());
  // store.dispatch(getListSource());
  // store.dispatch(getListTags());
  // store.dispatch(getListStaff());
  return store;
}
