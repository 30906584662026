import { all, call, fork, put, takeEvery } from "@redux-saga/core/effects";
import { responeCode, typeMessage } from "../../../constants/Const";
import {
    parseDataNotifications,
    throwErrorMessage,
} from "../../../helpers/HandleError";
import { enqueueSnackbar } from "../../../redux/CommonReducer";
import { getProjectTicketSummary } from "../../Project/redux/api";
import {
    fetchContactTicketsFailure,
    fetchContactTicketsSuccess,
    fetchDepartmentTicketsFailure,
    fetchDepartmentTicketsSuccess,
    fetchListTicketsFailure,
    fetchListTicketsSuccess,
    fetchReminderTicketFailure,
    fetchReminderTicketSuccess,
    fetchTasksTicketFailure,
    fetchTasksTicketSuccess,
    fetchTicketSummaryFailure,
    fetchTicketSummarySuccess,
    addReminderTicketFailure,
    addReminderTicketSuccess,
    fetchReminderTicketPending,
    getTicketNoteSuccess,
    getTicketNoteFailure,
    addTicketNoteSuccess,
    addTicketNoteFailure,
    getTicketNotePending,
    filterTaskInTicketSuccess,
    filterTaskInTicketFailure,
} from "./actions";
import {
    ADD_TICKET_NOTE_PENDING,
    ADD_TICKET_REMINDER_PENDING,
    FETCH_CONTACT_TICKETS_PENDING,
    FETCH_DEPARTMENT_TICKETS_PENDING,
    FETCH_LIST_TICKETS_PENDING,
    FETCH_REMINDER_TICKET_PENDING,
    FETCH_TASKS_TICKET_PENDING,
    FETCH_TICKET_SUMMARY_PENDING,
    FILTER_TASK_IN_TICKET_PENDING,
    GET_TICKET_NOTE_PENDING,
} from "./actionTypes";
import {
    filterTaskInTicket,
    getListTickets,
    getReminderTicket,
    getTasksTicket,
    getTicketContact,
    getTicketDepartment,
    getTicketNote,
    postTicketNote,
    postTicketReminder,
} from "./api";

function* fetchTicketSummarySaga() {
    try {
        const response = yield call(getProjectTicketSummary);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchTicketSummarySuccess(response.data.result));
        } else {
            yield put(fetchTicketSummaryFailure());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchTicketSummaryFailure());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* fetchListTicketsSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(getListTickets, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListTicketsSuccess(response.data));
        } else {
            yield put(fetchListTicketsFailure());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListTicketsFailure());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* fetchListContactSaga() {
    try {
        const response = yield call(getTicketContact);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchContactTicketsSuccess(response.data.result));
        } else {
            yield put(fetchContactTicketsFailure());
            enqueueSnackbar(
                parseDataNotifications(
                    response.data.error_mess,
                    typeMessage.warning
                )
            );
        }
    } catch (error) {
        yield put(fetchContactTicketsFailure());
        enqueueSnackbar(
            parseDataNotifications(throwErrorMessage(error), typeMessage.error)
        );
    }
}
function* fetchListDepartmentSaga() {
    try {
        const response = yield call(getTicketDepartment);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchDepartmentTicketsSuccess(response.data.result));
        } else {
            yield put(fetchDepartmentTicketsFailure());
            enqueueSnackbar(
                parseDataNotifications(
                    response.data.error_mess,
                    typeMessage.warning
                )
            );
        }
    } catch (error) {
        yield put(fetchDepartmentTicketsFailure());
        enqueueSnackbar(
            parseDataNotifications(throwErrorMessage(error), typeMessage.error)
        );
    }
}
function* fetchListTasksTicketSaga(action) {
    try {
        const response = yield call(getTasksTicket, action.payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchTasksTicketSuccess(response.data.result));
        } else {
            yield put(fetchContactTicketsFailure());
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(response.data.error_mess),
                    typeMessage.warning
                )
            );
        }
    } catch (error) {
        yield put(fetchTasksTicketFailure());
        enqueueSnackbar(
            parseDataNotifications(throwErrorMessage(error), typeMessage.error)
        );
    }
}
function* fetchListReminderTicketSaga(action) {
    try {
        const response = yield call(getReminderTicket, action.payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchReminderTicketSuccess(response.data.result));
        } else {
            yield put(fetchReminderTicketFailure());
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(response.data.error_mess),
                    typeMessage.warning
                )
            );
        }
    } catch (error) {
        yield put(fetchReminderTicketFailure());
        enqueueSnackbar(
            parseDataNotifications(throwErrorMessage(error), typeMessage.error)
        );
    }
}
function* addTicketReminderSaga({ payload }) {
    const { params, ticketId } = payload;
    try {
        const response = yield call(postTicketReminder, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(addReminderTicketSuccess());
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(response.data.error_mess),
                    typeMessage.success
                )
            );
            yield put(fetchReminderTicketPending(ticketId, params));
        } else {
            yield put(addReminderTicketFailure());
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(response.data.error_mess),
                    typeMessage.warning
                )
            );
        }
    } catch (error) {
        yield put(addReminderTicketFailure());
        enqueueSnackbar(
            parseDataNotifications(throwErrorMessage(error), typeMessage.error)
        );
    }
}
function* addTicketNoteSaga({ payload }) {
    const { params, ticketId } = payload;
    try {
        const response = yield call(postTicketNote, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(addTicketNoteSuccess());
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(response.data.error_mess),
                    typeMessage.success
                )
            );
            yield put(getTicketNotePending(ticketId, params));
        } else {
            yield put(addTicketNoteFailure());
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(response.data.error_mess),
                    typeMessage.warning
                )
            );
        }
    } catch (error) {
        yield put(addTicketNoteFailure());
        enqueueSnackbar(
            parseDataNotifications(throwErrorMessage(error), typeMessage.error)
        );
    }
}
function* getTicketNoteSaga({ payload }) {
    try {
        const response = yield call(getTicketNote, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getTicketNoteSuccess(response.data.result));
        } else {
            yield put(getTicketNoteFailure());
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(response.data.error_mess),
                    typeMessage.warning
                )
            );
        }
    } catch (error) {
        yield put(getTicketNoteFailure());
        enqueueSnackbar(
            parseDataNotifications(throwErrorMessage(error), typeMessage.error)
        );
    }
}
function* filterTaskInTicketSaga({ payload }) {
    try {
        const response = yield call(filterTaskInTicket, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(filterTaskInTicketSuccess(response.data.result));
        } else {
            yield put(filterTaskInTicketFailure());
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(response.data.error_mess),
                    typeMessage.warning
                )
            );
        }
    } catch (error) {
        yield put(filterTaskInTicketFailure());
        enqueueSnackbar(
            parseDataNotifications(throwErrorMessage(error), typeMessage.error)
        );
    }
}

export function* ticketSummarySaga() {
    yield takeEvery(FILTER_TASK_IN_TICKET_PENDING, filterTaskInTicketSaga);
    yield takeEvery(ADD_TICKET_REMINDER_PENDING, addTicketReminderSaga);
    yield takeEvery(ADD_TICKET_NOTE_PENDING, addTicketNoteSaga);
    yield takeEvery(GET_TICKET_NOTE_PENDING, getTicketNoteSaga);
    yield takeEvery(FETCH_TICKET_SUMMARY_PENDING, fetchTicketSummarySaga);
    yield takeEvery(FETCH_REMINDER_TICKET_PENDING, fetchListReminderTicketSaga);
}
export function* listTicketSaga() {
    yield takeEvery(FETCH_LIST_TICKETS_PENDING, fetchListTicketsSaga);
}
function* listContactSaga() {
    yield takeEvery(FETCH_CONTACT_TICKETS_PENDING, fetchListContactSaga);
}
function* listDepartmentSaga() {
    yield takeEvery(FETCH_DEPARTMENT_TICKETS_PENDING, fetchListDepartmentSaga);
}
function* listTasksTicketSaga() {
    yield takeEvery(FETCH_TASKS_TICKET_PENDING, fetchListTasksTicketSaga);
}
function* SupportSaga() {
    yield all([
        fork(ticketSummarySaga),
        fork(listTicketSaga),
        fork(listContactSaga),
        fork(listDepartmentSaga),
        fork(listTasksTicketSaga),
    ]);
}
export default SupportSaga;
