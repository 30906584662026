import ActionTypes from "./type";

const INIT_STATE = {
    listVault: [],
    isLoading: false,
    statusAddSucces: false,
};

const VaultCustomerReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.ADD_VAULT_CUSTOMER_REQUEST:
            return {
                ...state,
            };
        case ActionTypes.ADD_VAULT_CUSTOMER_SUCCESS:
            return {
                ...state,
                statusAddSucces: !state.statusAddSucces,
            };
        case ActionTypes.ADD_VAULT_CUSTOMER_FAILED:
            return {
                ...state,
            };

        default:
            return { ...state };
    }
};

export default VaultCustomerReducer;
