import apiBase from "../../../../common/baseAPI";

const ESTIMATE_PROJECT_URL = "estimate/project";
const FILTER_YEAR_ESTIMATE_PRJ_URL = "estimate/project/year";
const TOTAL_ESTIMATE_PRJ_URL = "estimate/project/total";
const FILTER_ESTIMATE_URL = "estimate/filter/project";

const getEstimatesProject = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${ESTIMATE_PROJECT_URL}/${data.id}?search=${data.search}&&limit=${data.limit}&&page=${data.page}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const filterYearEstimatePrj = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${FILTER_YEAR_ESTIMATE_PRJ_URL}/${data.id}?year=[${data.year}]`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const getTotalEstimatePrj = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${TOTAL_ESTIMATE_PRJ_URL}/${data}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const filterEstimatePrj = (data) => {
  let {
    limit,
    page,
    notSent,
    invoice,
    notInvoice,
    status,
    year,
    sale,
  } = data;
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${FILTER_ESTIMATE_URL}/${data.id}?limit=${limit}&&page=${page}&&notSent=${notSent}&&invoice=${invoice}&&notInvoice=${notInvoice}&&status=[${status}]&&year=[${year}]&&sale=[${sale}]`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export {
  getEstimatesProject,
  filterYearEstimatePrj,
  getTotalEstimatePrj,
  filterEstimatePrj,
};

