import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
    FETCH_LIST_CONTRACT,
    FETCH_CONTRACT,
    DELETE_CONTRACT,
    CREATE_CONTRACT,
    UPDATE_CONTRACT,
    FILTER_CONTRACT,
    FETCH_LIST_CUSTOMER_CONTRACT,
    FETCH_SUMMARY_CONTRACT,
    FETCH_LIST_ATTACHMENT_CONTRACT,
    CREATE_ATTACHMENT_CONTRACT,
    DELETE_ATTACHMENT_CONTRACT,
    FETCH_NOTE_CONTRACT,
    CREATE_NOTE_CONTRACT,
    DELETE_NOTE_CONTRACT,
    UPDATE_NOTE_CONTRACT,
    FETCH_LIST_COMMENT_CONTRACT,
    CREATE_COMMENT_CONTRACT,
    DELETE_COMMENT_CONTRACT,
    UPDATE_COMMENT_CONTRACT,
    FETCH_LIST_RENEWAL_CONTRACT,
    CREATE_RENEWAL_CONTRACT,
    DELETE_RENEWAL_CONTRACT,
    FETCH_LIST_TASK_CONTRACT,
    DELETE_TASK_CONTRACT,
    FETCH_CONTRACT_TYPE,
    CREATE_CONTRACT_TYPE,
    FETCH_CUSTOM_FIELD,
    FETCH_CONTRACT_BY_TYPE,
    FETCH_CONTRACT_VALUE_BY_TYPE,
    FETCH_YEAR,
    ADD_NEW_CONTRACT_PENDING,
} from "./types";
import {
    fetchListContract,
    fetchListContractSuccess,
    fetchListContractFailed,
    fetchContractSuccess,
    fetchContractFailed,
    deleteContractSuccess,
    deleteContractFailed,
    createContractSuccess,
    createContractFailed,
    fetchListCustomerContractSuccess,
    fetchListCustomerContractFailed,
    updateContractSuccess,
    updateContractFailed,
    setEditContract,
    filterContractSuccess,
    filterContractFailed,
    fetchSummaryContractSuccess,
    fetchSummaryContractFailed,
    fetchListAttachmentContractSuccess,
    fetchListAttachmentContractFailed,
    createAttachmentContractSuccess,
    createAttachmentContractFailed,
    deleteAttachmentContractSuccess,
    deleteAttachmentContractFailed,
    fetchNoteContractSuccess,
    fetchNoteContractFailed,
    createNoteContractFailed,
    createNoteContractSuccess,
    deleteNoteContractSuccess,
    deleteNoteContractFailed,
    updateNoteContractSuccess,
    updateNoteContractFailed,
    fetchListCommentContractSuccess,
    fetchListCommentContractFailed,
    createCommentContractFailed,
    createCommentContractSuccess,
    deleteCommentContractSuccess,
    deleteCommentContractFailed,
    updateCommentContractSuccess,
    updateCommentContractFailed,
    fetchListRenewalContractSuccess,
    fetchListRenewalContractFailed,
    createRenewalContractFailed,
    createRenewalContractSuccess,
    deleteRenewalContractSuccess,
    deleteRenewalContractFailed,
    fetchListTaskContractSuccess,
    fetchListTaskContractFailed,
    deleteTaskContractSuccess,
    deleteTaskContractFailed,
    fetchContractTypeSuccess,
    fetchContractTypeFailed,
    createContractTypeSuccess,
    createContractTypeFailed,
    fetchCustomFieldSuccess,
    fetchCustomFieldFailed,
    fetchContractByTypeSuccess,
    fetchContractByTypeFailed,
    fetchContractValueByTypeSuccess,
    fetchContractValueByTypeFailed,
    fetchYearSuccess,
    fetchYearFailed,
    addNewContractSuccess,
    addNewContractFailed,
} from "./actions";

import {
    getListContracts,
    getContract,
    deleteContract,
    createContract,
    getListCustomersContract,
    updateContract,
    filterContract,
    getSummaryContract,
    fetchListAttachmentContract,
    createAttachmentContract,
    deleteAttachmentContract,
    fetchNoteContract,
    createNoteContract,
    deleteNoteContract,
    updateNoteContract,
    fetchListCommentContract,
    createCommentContract,
    deleteCommentContract,
    updateCommentContract,
    fetchListRenewalContract,
    createRenewalContract,
    deleteRenewalContract,
    fetchListTaskContract,
    deleteTaskContract,
    getContractType,
    createContractType,
    fetchListCustomField,
    fetchContractByType,
    fetchContractValueByType,
    fetchListYear,
    addNewContract,
} from "./api";
import { enqueueSnackbar } from "../../../redux/CommonReducer";
import { responeCode, typeMessage } from "../../../constants/Const";
import {
    throwErrorMessage,
    parseDataNotifications,
} from "../../../helpers/HandleError";
import i18n from "../../../i18n";

function* fetchListContractSaga(action) {
    try {
        const response = yield call(getListContracts, action);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListContractSuccess(response.data.result));
        } else {
            yield put(fetchListContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchContractSaga(action) {
    try {
        const response = yield call(getContract, action.payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchContractSuccess(response.data.result));
        } else {
            yield put(fetchContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* createContractSaga(action) {
    try {
        const response = yield call(createContract, action.payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success &&
            response.data.result &&
            response.data.result.length > 0
        ) {
            const result = response.data.result[0];
            yield put(createContractSuccess(result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        `${i18n.t("project.createSuccess")}`,
                        typeMessage.success
                    )
                )
            );
            action.history.push(`/contract/edit/${result.id}`);
            yield put(setEditContract(result));
        } else {
            yield put(createContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(createContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* deleteContractSaga(action) {
    const { payload, history } = action;
    try {
        const response = yield call(deleteContract, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            if (history) {
                history.push("/contract");
                return;
            }
            yield put(deleteContractSuccess(payload));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        `${i18n.t("project.deleteSuccess")}`,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchListCustomerContractSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(getListCustomersContract, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListCustomerContractSuccess(response.data.result));
        } else {
            yield put(fetchListCustomerContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListCustomerContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* updateContractSaga(data) {
    const { payload } = data;

    try {
        const response = yield call(updateContract, payload.id, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(updateContractSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        `${i18n.t("project.updateSuccess")}`,
                        typeMessage.success
                    )
                )
            );
            yield put(fetchListContract("", {}));
        } else {
            yield put(updateContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(updateContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* filterContractSaga(data) {
    const { payload } = data;

    try {
        const response = yield call(filterContract, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(filterContractSuccess(response.data.result));
        } else {
            yield put(filterContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(filterContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchSummaryContractSaga() {
    try {
        const response = yield call(getSummaryContract);
        if (response && response.data && response.status === 200) {
            yield put(fetchSummaryContractSuccess(response.data));
        } else {
            yield put(fetchSummaryContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchSummaryContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchListAttachmentContractSaga(action) {
    const { payload } = action;
    try {
        const response = yield call(fetchListAttachmentContract, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListAttachmentContractSuccess(response.data.result));
            // yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
        } else {
            yield put(fetchListAttachmentContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListAttachmentContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* createAttachmentContractSaga(action) {
    const { payload, id } = action;
    try {
        const response = yield call(createAttachmentContract, id, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(createAttachmentContractSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(createAttachmentContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(createAttachmentContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* deleteAttachmentContractSaga(action) {
    const { payload } = action;
    try {
        const response = yield call(deleteAttachmentContract, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteAttachmentContractSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteAttachmentContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteAttachmentContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchNoteContractSaga(action) {
    const { payload } = action;
    try {
        const response = yield call(fetchNoteContract, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchNoteContractSuccess(response.data.result));
            // yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
        } else {
            yield put(fetchNoteContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchNoteContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* createNoteContractSaga(action) {
    const { payload, id } = action;
    try {
        const response = yield call(createNoteContract, id, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(createNoteContractSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        "Create note success",
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(createNoteContractFailed(response.data.error_mess));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(createNoteContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* deleteNoteContractSaga(action) {
    const { payload } = action;
    try {
        const response = yield call(deleteNoteContract, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteNoteContractSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteNoteContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteNoteContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* updateNoteContractSaga(action) {
    const { payload, id } = action;
    try {
        const response = yield call(updateNoteContract, id, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(updateNoteContractSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(updateNoteContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(updateNoteContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchListCommentContractSaga(action) {
    const { payload } = action;
    try {
        const response = yield call(fetchListCommentContract, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListCommentContractSuccess(response.data.result));
            // yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
        } else {
            yield put(fetchListCommentContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListCommentContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* createCommentContractSaga(action) {
    const { payload, id } = action;
    try {
        const response = yield call(createCommentContract, id, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(createCommentContractSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        "Create note success",
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(createCommentContractFailed(response.data.error_mess));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(createCommentContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* deleteCommentContractSaga(action) {
    const { payload } = action;
    try {
        const response = yield call(deleteCommentContract, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteCommentContractSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteCommentContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteCommentContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* updateCommentContractSaga(action) {
    const { payload, id } = action;
    try {
        const response = yield call(updateCommentContract, id, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(updateCommentContractSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(updateCommentContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(updateCommentContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchListRenewalContractSaga(action) {
    const { payload } = action;
    try {
        const response = yield call(fetchListRenewalContract, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListRenewalContractSuccess(response.data.result));
            // yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
        } else {
            yield put(fetchListRenewalContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListRenewalContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* createRenewalContractSaga(action) {
    const { payload, id } = action;
    try {
        const response = yield call(createRenewalContract, id, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(createRenewalContractSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        "Create note success",
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(createRenewalContractFailed(response.data.error_mess));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(createRenewalContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* deleteRenewalContractSaga(action) {
    const { payload } = action;
    try {
        const response = yield call(deleteRenewalContract, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteRenewalContractSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteRenewalContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteRenewalContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchListTaskContractSaga(action) {
    const { payload } = action;
    try {
        const response = yield call(fetchListTaskContract, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListTaskContractSuccess(response.data.result));
        } else {
            yield put(fetchListTaskContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListTaskContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* deleteTaskContractSaga(action) {
    const { payload } = action;
    try {
        const response = yield call(deleteTaskContract, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteTaskContractSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteTaskContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteTaskContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchContractTypeSaga() {
    try {
        const response = yield call(getContractType);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success &&
            response.data.result
        ) {
            yield put(fetchContractTypeSuccess(response.data.result));
        } else {
            yield put(fetchContractTypeFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchContractTypeFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* createContractTypeSaga(action) {
    const { payload } = action;
    try {
        const response = yield call(createContractType, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(createContractTypeSuccess());
        } else {
            yield put(createContractTypeFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(createContractTypeFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchListCustomFieldSaga() {
    try {
        const response = yield call(fetchListCustomField);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success &&
            response.data.result
        ) {
            yield put(fetchCustomFieldSuccess(response.data.result));
        } else {
            yield put(fetchCustomFieldFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchCustomFieldFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchContractByTypeSaga() {
    try {
        const response = yield call(fetchContractByType);
        // if (response && response.data && response.data.error_code === responeCode.success && response.data.result) {
        if (response && response.data) {
            yield put(fetchContractByTypeSuccess(response.data));
        } else {
            yield put(fetchContractByTypeFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchContractByTypeFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchContractValueByTypeSaga() {
    try {
        const response = yield call(fetchContractValueByType);
        // if (response && response.data && response.data.error_code === responeCode.success && response.data.result) {
        if (response && response.data) {
            yield put(fetchContractValueByTypeSuccess(response.data));
        } else {
            yield put(fetchContractValueByTypeFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchContractValueByTypeFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchYearSaga() {
    try {
        const response = yield call(fetchListYear);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success &&
            response.data.result
        ) {
            yield put(fetchYearSuccess(response.data.result));
        } else {
            yield put(fetchYearFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchYearFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* addNewContractSaga(action) {
    try {
        const response = yield call(addNewContract, action.payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success &&
            response.data.result
        ) {
            yield put(addNewContractSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(addNewContractFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(addNewContractFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

export function* contractSaga() {
    yield takeEvery(ADD_NEW_CONTRACT_PENDING, addNewContractSaga);
    yield takeEvery(FETCH_LIST_CONTRACT, fetchListContractSaga);
    yield takeEvery(FETCH_CONTRACT, fetchContractSaga);
    yield takeEvery(DELETE_CONTRACT, deleteContractSaga);
    yield takeEvery(CREATE_CONTRACT, createContractSaga);
    yield takeEvery(
        FETCH_LIST_CUSTOMER_CONTRACT,
        fetchListCustomerContractSaga
    );
    yield takeEvery(UPDATE_CONTRACT, updateContractSaga);
    yield takeEvery(FILTER_CONTRACT, filterContractSaga);
    yield takeEvery(FETCH_SUMMARY_CONTRACT, fetchSummaryContractSaga);
    yield takeEvery(
        FETCH_LIST_ATTACHMENT_CONTRACT,
        fetchListAttachmentContractSaga
    );
    yield takeEvery(CREATE_ATTACHMENT_CONTRACT, createAttachmentContractSaga);
    yield takeEvery(DELETE_ATTACHMENT_CONTRACT, deleteAttachmentContractSaga);
    yield takeEvery(FETCH_NOTE_CONTRACT, fetchNoteContractSaga);
    yield takeEvery(CREATE_NOTE_CONTRACT, createNoteContractSaga);
    yield takeEvery(DELETE_NOTE_CONTRACT, deleteNoteContractSaga);
    yield takeEvery(UPDATE_NOTE_CONTRACT, updateNoteContractSaga);
    yield takeEvery(FETCH_LIST_COMMENT_CONTRACT, fetchListCommentContractSaga);
    yield takeEvery(CREATE_COMMENT_CONTRACT, createCommentContractSaga);
    yield takeEvery(DELETE_COMMENT_CONTRACT, deleteCommentContractSaga);
    yield takeEvery(UPDATE_COMMENT_CONTRACT, updateCommentContractSaga);
    yield takeEvery(FETCH_LIST_RENEWAL_CONTRACT, fetchListRenewalContractSaga);
    yield takeEvery(CREATE_RENEWAL_CONTRACT, createRenewalContractSaga);
    yield takeEvery(DELETE_RENEWAL_CONTRACT, deleteRenewalContractSaga);
    yield takeEvery(FETCH_LIST_TASK_CONTRACT, fetchListTaskContractSaga);
    yield takeEvery(DELETE_TASK_CONTRACT, deleteTaskContractSaga);
    yield takeEvery(FETCH_CONTRACT_TYPE, fetchContractTypeSaga);
    yield takeEvery(CREATE_CONTRACT_TYPE, createContractTypeSaga);
    yield takeEvery(FETCH_CUSTOM_FIELD, fetchListCustomFieldSaga);
    yield takeEvery(FETCH_CONTRACT_BY_TYPE, fetchContractByTypeSaga);
    yield takeEvery(FETCH_CONTRACT_VALUE_BY_TYPE, fetchContractValueByTypeSaga);
    yield takeEvery(FETCH_YEAR, fetchYearSaga);
}

function* ContractSaga() {
    yield all([fork(contractSaga)]);
}

export default ContractSaga;
