import { GET_SALE_INVOICE_FAILED, GET_SALE_INVOICE_REQUEST, GET_SALE_INVOICE_SUCCESS } from "./types";


export const getCustomFieldInvoiceRequest = (params) => {
    return {
        type: GET_SALE_INVOICE_REQUEST,
        params,
    };
};

export const getCustomFieldInvoiceSuccess = (data) => {
    return {
        type: GET_SALE_INVOICE_SUCCESS,
        payload: data,
    };
};

export const getCustomFieldInvoiceFailed = () => {
    return {
        type: GET_SALE_INVOICE_FAILED,
    };
};