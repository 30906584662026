import ActionTypes from "./type";

const INIT_STATE = {
  listNote: [],
  isLoading: false,
  statusAddNote: false,
  statusDelete: false,
  statusEditNote: "",
};

const NotesCustomerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_NOTES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_NOTES_SUCCESS:
      return {
        ...state,
        listNote: action.data,
        isLoading: false,
      };
    case ActionTypes.FETCH_NOTES_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.NEW_NOTES_REQUEST:
      return {
        ...state,
      };
    case ActionTypes.NEW_NOTES_SUCCESS:
      return {
        ...state,
        statusAddNote: !state.statusAddNote,
      };
    case ActionTypes.NEW_NOTES_FAILED:
      return {
        ...state,
      };

    case ActionTypes.EDIT_NOTES_REQUEST:
      return {
        ...state,
        isLoading: true,
        statusEditNote: "",
      };
    case ActionTypes.EDIT_NOTES_SUCCESS:
      const indexEdit = state.listNote.data.findIndex(
        (item) => item.id === action.data[0].id
      );
      let dataEdit = { ...state.listNote };
      dataEdit.data.splice(indexEdit, 1, action.data[0]);
      return {
        ...state,
        isLoading: false,
        listNote: dataEdit,
        statusEditNote: action.data[0].id,
      };
    case ActionTypes.EDIT_NOTES_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.DELETE_NOTES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.DELETE_NOTES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        statusDelete: !state.statusDelete,
      };
    case ActionTypes.DELETE_NOTES_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return { ...state };
  }
};

export default NotesCustomerReducer;
