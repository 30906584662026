import apiBase from "../../../../common/baseAPI";

const CUSTOMER_REMINDER = "reminder/customer";
const REMINDER = "reminder";

export const fetchReminderCustomerRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${CUSTOMER_REMINDER}/${action.id}?search=${action.value}&&limit=${action.params.limit}&&page=${action.params.page}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
export const addReminderCustomerRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .post(`${CUSTOMER_REMINDER}/${action.id}`, action.data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
export const deleteReminderCustomerRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .delete(`${REMINDER}/${action.id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
