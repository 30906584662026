import {
    GET_LIST_STAFF_REQUEST,
    GET_LIST_STAFF_FAILED,
    GET_LIST_STAFF_SUCCESS,
    CREATE_STAFF_REQUEST,
    CREATE_STAFF_FAILED,
    CREATE_STAFF_SUCCESS,
    EDIT_STAFF_REQUEST,
    EDIT_STAFF_FAILED,
    EDIT_STAFF_SUCCESS,
    DELETE_STAFF_REQUEST,
    DELETE_STAFF_FAILED,
    DELETE_STAFF_SUCCESS,
    FILTER_STAFF_REQUEST,
    STAFF_STATUS,
    FILTER_STAFF_FAILED,
    FILTER_STAFF_SUCCESS,
    GET_DEPARTMENTS_REQUEST,
    GET_DEPARTMENTS_SUCCESS,
    GET_DEPARTMENTS_FAILED,
    GET_STAFF_EDIT_REQUEST,
    GET_STAFF_EDIT_SUCCESS,
    GET_STAFF_EDIT_FAILED,
    GET_STAFF_NOTE_PENDING,
    GET_STAFF_NOTE_SUCCESS,
    GET_STAFF_NOTE_FAILURE,
    GET_STAFF_TIMESHEET_PENDING,
    GET_STAFF_TIMESHEET_SUCCESS,
    GET_STAFF_TIMESHEET_FAILURE,
    SET_STAFF_STATUS_PENDING,
    SET_STAFF_STATUS_SUCCESS,
    SET_STAFF_STATUS_FAILURE,
} from "./types";

const INIT_STATE = {
    listStaff: [],
    staffItem: [],
    staffNote: [],
    statusStaff: false,
    listDepartment: [],
    isLoading: false,
    goBackHome: false,
    deleteStaff: false,
    staffTimesheet: [
        {
            task: "Hiep Hoang",

            startTime: "22-01-2021 10:04",

            endTime: "29-01-2021 10:04",

            related: "#8 - phát triển CRM sme - BHOME",

            hourlyRate: "0.00đ",

            timeHour: "00:00",

            timeDecimal: "0",
        },
    ],
};

const AdministratorStaffReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_STAFF_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_LIST_STAFF_SUCCESS:
            return {
                ...state,
                listStaff: action.payload,
                isLoading: false,
                goBackHome: false,
            };

        case GET_LIST_STAFF_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case CREATE_STAFF_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case CREATE_STAFF_SUCCESS:
            // state.listStaff.data.splice(0, 1, action.payload);
            return {
                ...state,
                isLoading: false,
                goBackHome: true,
                // listStaff: state.listStaff,
                statusStaff: false,
            };

        case CREATE_STAFF_FAILED:
            return {
                ...state,
                isLoading: false,
                statusStaff: false,
            };
        case EDIT_STAFF_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case EDIT_STAFF_SUCCESS:
            let itemIndex = state.listStaff.data.findIndex(
                (item) => item.staff_id === action.payload.staff_id
            );
            if (itemIndex !== -1) {
                state.listStaff.data.splice(itemIndex, 1, action.payload);
            }
            return {
                ...state,
                isLoading: false,
                listStaff: state.listStaff,
                goBackHome: true,
                staffItem: {},
                statusStaff: false,
            };

        case EDIT_STAFF_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case DELETE_STAFF_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case DELETE_STAFF_SUCCESS:
            return {
                ...state,
                isLoading: false,
                deleteStaff: true,
            };

        case DELETE_STAFF_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case FILTER_STAFF_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case FILTER_STAFF_SUCCESS:
            return {
                ...state,
                listStaff: action.payload,
                isLoading: false,
                goBackHome: false,
                deleteStaff: false,
            };

        case FILTER_STAFF_FAILED:
            return {
                ...state,
                isLoading: false,
                deleteStaff: false,
            };

        case STAFF_STATUS:
            state.statusStaff = !state.statusStaff;
            return state;

        case GET_DEPARTMENTS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_DEPARTMENTS_SUCCESS:
            return {
                ...state,
                listDepartment: action.payload,
                isLoading: false,
            };
        case GET_DEPARTMENTS_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case GET_STAFF_EDIT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_STAFF_EDIT_SUCCESS:
            return {
                ...state,
                staffItem: action.payload,
                isLoading: false,
            };
        case GET_STAFF_EDIT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case GET_STAFF_NOTE_PENDING:
            return {
                ...state,
                isLoading: true,
            };
        case GET_STAFF_NOTE_SUCCESS:
            return {
                ...state,
                staffNote: action.payload,
                isLoading: false,
            };
        case GET_STAFF_NOTE_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case GET_STAFF_TIMESHEET_PENDING:
            return {
                ...state,
                isLoading: true,
            };
        case GET_STAFF_TIMESHEET_SUCCESS:
            return {
                ...state,
                staffTimesheet: action.payload,
                isLoading: false,
            };
        case GET_STAFF_TIMESHEET_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case SET_STAFF_STATUS_PENDING:
            return {
                ...state,
                isLoading: true,
            };
        case SET_STAFF_STATUS_SUCCESS:
            return {
                ...state,
                // staffTimesheet: action.payload,
                isLoading: false,
            };
        case SET_STAFF_STATUS_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return { ...state };
    }
};

export default AdministratorStaffReducer;
