const ActionTypes = {
  FETCH_ESTIMATES_SALE_REQUEST: "FETCH_ESTIMATES_SALE_REQUEST",
  FETCH_ESTIMATES_SALE_SUCCESS: "FETCH_ESTIMATES_SALE_SUCCESS",
  FETCH_ESTIMATES_SALE_FAILED: "FETCH_ESTIMATES_SALE_FAILED",

  LIST_SELECT_CUSTOMER_REQUEST: "LIST_SELECT_CUSTOMER_REQUEST",
  LIST_SELECT_CUSTOMER_SUCCESS: "LIST_SELECT_CUSTOMER_SUCCESS",
  LIST_SELECT_CUSTOMER_FAILED: "LIST_SELECT_CUSTOMER_FAILED",

  GET_CUSTOM_FIELD_REQUEST: "GET_CUSTOM_FIELD_REQUEST",
  GET_CUSTOM_FIELD_SUCCESS: "GET_CUSTOM_FIELD_SUCCESS",
  GET_CUSTOM_FIELD_FAILED: "GET_CUSTOM_FIELD_FAILED",
};
export default ActionTypes;
