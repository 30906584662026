import apiBase from "./../../../../../common/baseAPI";

const URL = 'predefinedReplies';

const getListPredefinedReplies = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createPredefinedReplies = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(URL, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const editPredefinedReplies = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${URL}/${data.id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deletePredefinedReplies = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${URL}/${data}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const fetchPredefinedReplies = (action) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${URL}?search=${action.search}&&limit=${action.params.limit}&&page=${action.params.page}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getPredefinedRepliesEdit = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${URL}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export {
    getListPredefinedReplies,
    createPredefinedReplies,
    editPredefinedReplies,
    deletePredefinedReplies,
    fetchPredefinedReplies,
    getPredefinedRepliesEdit

}