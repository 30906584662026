
import apiBase from "../../../../common/baseAPI";
const INVOICE_SALE = "invoice";

export const getInvoiceSaleRequest = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(INVOICE_SALE, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};