import {
    FETCH_CONTACT_TICKETS_FAILURE,
    FETCH_CONTACT_TICKETS_PENDING,
    FETCH_CONTACT_TICKETS_SUCCESS,
    FETCH_DEPARTMENT_TICKETS_FAILURE,
    FETCH_DEPARTMENT_TICKETS_PENDING,
    FETCH_DEPARTMENT_TICKETS_SUCCESS,
    FETCH_LIST_TICKETS_FAILURE,
    FETCH_LIST_TICKETS_PENDING,
    FETCH_LIST_TICKETS_SUCCESS,
    FETCH_REMINDER_TICKET_FAILURE,
    FETCH_REMINDER_TICKET_PENDING,
    FETCH_REMINDER_TICKET_SUCCESS,
    FETCH_TASKS_TICKET_FAILURE,
    FETCH_TASKS_TICKET_PENDING,
    FETCH_TASKS_TICKET_SUCCESS,
    FETCH_TICKET_SUMMARY_FAILURE,
    FETCH_TICKET_SUMMARY_PENDING,
    FETCH_TICKET_SUMMARY_SUCCESS,
    ADD_TICKET_REMINDER_PENDING,
    ADD_TICKET_REMINDER_SUCCESS,
    ADD_TICKET_REMINDER_FAILURE,
    ADD_TICKET_NOTE_PENDING,
    ADD_TICKET_NOTE_SUCCESS,
    ADD_TICKET_NOTE_FAILURE,
    GET_TICKET_NOTE_PENDING,
    GET_TICKET_NOTE_SUCCESS,
    GET_TICKET_NOTE_FAILURE,
    FILTER_TASK_IN_TICKET_PENDING,
    FILTER_TASK_IN_TICKET_SUCCESS,
    FILTER_TASK_IN_TICKET_FAILURE,
} from "./actionTypes";

export const fetchTicketSummaryPending = (data) => {
    return {
        type: FETCH_TICKET_SUMMARY_PENDING,
        payload: data,
    };
};

export const fetchTicketSummarySuccess = (data) => {
    return {
        type: FETCH_TICKET_SUMMARY_SUCCESS,
        payload: data,
    };
};

export const fetchTicketSummaryFailure = () => {
    return {
        type: FETCH_TICKET_SUMMARY_FAILURE,
    };
};
export const fetchListTicketsPending = (data) => {
    return {
        type: FETCH_LIST_TICKETS_PENDING,
        payload: data,
    };
};

export const fetchListTicketsSuccess = (data) => {
    return {
        type: FETCH_LIST_TICKETS_SUCCESS,
        payload: data,
    };
};

export const fetchListTicketsFailure = () => {
    return {
        type: FETCH_LIST_TICKETS_FAILURE,
    };
};
// DEPARTMENT
export const fetchDepartmentTicketsPending = () => {
    return {
        type: FETCH_DEPARTMENT_TICKETS_PENDING,
    };
};

export const fetchDepartmentTicketsSuccess = (data) => {
    return {
        type: FETCH_DEPARTMENT_TICKETS_SUCCESS,
        payload: data,
    };
};

export const fetchDepartmentTicketsFailure = () => {
    return {
        type: FETCH_DEPARTMENT_TICKETS_FAILURE,
    };
};
//CONTACT
export const fetchContactTicketsPending = () => {
    return {
        type: FETCH_CONTACT_TICKETS_PENDING,
    };
};

export const fetchContactTicketsSuccess = (data) => {
    return {
        type: FETCH_CONTACT_TICKETS_SUCCESS,
        payload: data,
    };
};

export const fetchContactTicketsFailure = () => {
    return {
        type: FETCH_CONTACT_TICKETS_FAILURE,
    };
};
export const fetchTasksTicketPending = (ticketId, params) => {
    return {
        type: FETCH_TASKS_TICKET_PENDING,
        payload: {
            ticketId: ticketId,
            params: params,
        },
    };
};

export const fetchTasksTicketSuccess = (data) => {
    return {
        type: FETCH_TASKS_TICKET_SUCCESS,
        payload: data,
    };
};

export const fetchTasksTicketFailure = () => {
    return {
        type: FETCH_TASKS_TICKET_FAILURE,
    };
};
export const fetchReminderTicketPending = (ticketId, params) => {
    return {
        type: FETCH_REMINDER_TICKET_PENDING,
        payload: {
            ticketId: ticketId,
            params: params,
        },
    };
};

export const fetchReminderTicketSuccess = (data) => {
    return {
        type: FETCH_REMINDER_TICKET_SUCCESS,
        payload: data,
    };
};

export const fetchReminderTicketFailure = () => {
    return {
        type: FETCH_REMINDER_TICKET_FAILURE,
    };
};

export const addReminderTicketPending = (ticketId, body, params) => {
    return {
        type: ADD_TICKET_REMINDER_PENDING,
        payload: {
            ticketId: ticketId,
            body: body,
            params: params,
        },
    };
};

export const addReminderTicketSuccess = () => {
    return {
        type: ADD_TICKET_REMINDER_SUCCESS,
    };
};

export const addReminderTicketFailure = () => {
    return {
        type: ADD_TICKET_REMINDER_FAILURE,
    };
};
export const addTicketNotePending = (ticketId, body, params) => {
    return {
        type: ADD_TICKET_NOTE_PENDING,
        payload: {
            ticketId: ticketId,
            body: body,
            params: params,
        },
    };
};

export const addTicketNoteSuccess = () => {
    return {
        type: ADD_TICKET_NOTE_SUCCESS,
    };
};

export const addTicketNoteFailure = () => {
    return {
        type: ADD_TICKET_NOTE_FAILURE,
    };
};
export const getTicketNotePending = (ticketId, params) => {
    return {
        type: GET_TICKET_NOTE_PENDING,
        payload: {
            ticketId: ticketId,

            params: params,
        },
    };
};

export const getTicketNoteSuccess = (data) => {
    return {
        type: GET_TICKET_NOTE_SUCCESS,
        payload: data,
    };
};

export const getTicketNoteFailure = () => {
    return {
        type: GET_TICKET_NOTE_FAILURE,
    };
};
export const filterTaskInTicketPending = (ticketId, body) => {
    return {
        type: FILTER_TASK_IN_TICKET_PENDING,
        payload: {
            ticketId: ticketId,
            body: body,
        },
    };
};

export const filterTaskInTicketSuccess = (data) => {
    return {
        type: FILTER_TASK_IN_TICKET_SUCCESS,
        payload: data,
    };
};

export const filterTaskInTicketFailure = () => {
    return {
        type: FILTER_TASK_IN_TICKET_FAILURE,
    };
};
