import i18n from "../../i18n";
export const columnsSupport = [
    {
        field: "id",
        name: `#`,
        isSort: true,
        width: 10,
    },
    {
        field: "subject",
        name: `${i18n.t("support.tableSupport.subject")}`,
        isSort: true,
        width: 280,
    },
    {
        field: "tags",
        name: `${i18n.t("support.tableSupport.tags")}`,
        isSort: true,
    },
    {
        field: "department",
        name: `${i18n.t("support.tableSupport.department")}`,
        isSort: true,
        width: 180,
    },
    {
        field: "service",
        name: `${i18n.t("support.tableSupport.service")}`,
        isSort: true,
    },
    {
        field: "contact",
        name: `${i18n.t("support.tableSupport.contact")}`,
        isSort: true,
        width: 180,
    },
    {
        field: "status",
        name: `${i18n.t("support.tableSupport.status")}`,
        isSort: true,
    },
    {
        field: "priority",
        name: `${i18n.t("support.tableSupport.priority")}`,
        isSort: true,
    },

    {
        field: "lastReply",
        name: `${i18n.t("support.tableSupport.lastReply")}`,
        isSort: true,
    },

    {
        field: "created",
        name: `${i18n.t("support.tableSupport.created")}`,
        isSort: true,
    },
];
export const listTabTicket = [
    {
        id: "ADD_REPLY",
        tab: `${i18n.t("project.ticket.settingTicket.addReply")}`,
    },
    {
        id: "ADD_NOTE",
        tab: `${i18n.t("project.ticket.settingTicket.addNote")}`,
    },
    {
        id: "REMINDERS",
        tab: `${i18n.t("project.ticket.settingTicket.reminders")}`,
    },
    {
        id: "OTHER_TICKETS",
        tab: `${i18n.t("project.ticket.settingTicket.otherTickets")}`,
    },
    {
        id: "TASKS",
        tab: `${i18n.t("project.ticket.settingTicket.tasks")}`,
    },
    {
        id: "SETTINGS",
        tab: `${i18n.t("project.ticket.settingTicket.settings")}`,
    },
];
export const columnsReminders = [
    {
        field: "id",
        name: `ID`,
        isSort: true,
    },
    {
        field: "description",
        name: `${i18n.t("support.tabReminders.description")}`,
        isSort: true,
    },
    {
        field: "date",
        name: `${i18n.t("support.tabReminders.date")}`,
        isSort: true,
    },
    {
        field: "remind",
        name: `${i18n.t("support.tabReminders.remind")}`,
        isSort: true,
    },
    {
        field: "isnotified",
        name: `${i18n.t("support.tabReminders.isNotified")}`,
        isSort: true,
    },
];

export const columnsTasks = [
    {
        field: "id",
        name: "#",
        isSort: true,
    },
    {
        field: "name",
        name: `${i18n.t("support.tabTasks.name")}`,
        isSort: true,
    },
    {
        field: "status",
        name: `${i18n.t("support.tabTasks.status")}`,
        isSort: true,
    },
    {
        field: "startDate",
        name: `${i18n.t("support.tabTasks.startDate")}`,
        isSort: true,
    },
    {
        field: "dueDate",
        name: `${i18n.t("support.tabTasks.dueDate")}`,
        isSort: true,
    },
    {
        field: "assignedTo",
        name: `${i18n.t("support.tabTasks.assignedTo")}`,
        isSort: true,
    },
    {
        field: "tags",
        name: `${i18n.t("support.tabTasks.tags")}`,
        isSort: true,
    },
    {
        field: "priority",
        name: `${i18n.t("support.tabTasks.priority")}`,
        isSort: true,
    },
];
export const columnsOtherTickets = [
    {
        field: "id",
        name: "#",
        isSort: true,
        width: 10,
    },
    {
        field: "subject",
        name: `${i18n.t("support.tabOtherTickets.subject")}`,
        isSort: true,
        width: 250,
    },
    {
        field: "tags",
        name: `${i18n.t("support.tabOtherTickets.tags")}`,
        isSort: true,
    },
    {
        field: "department",
        name: `${i18n.t("support.tabOtherTickets.department")}`,
        isSort: true,
        width: 180,
    },
    {
        field: "service",
        name: `${i18n.t("support.tabOtherTickets.service")}`,
        isSort: true,
    },
    {
        field: "contact",
        name: `${i18n.t("support.tabOtherTickets.contact")}`,
        isSort: true,
        width: 180,
    },
    {
        field: "status",
        name: `${i18n.t("support.tabOtherTickets.status")}`,
        isSort: true,
        width: 20,
    },
    {
        field: "priority",
        name: `${i18n.t("support.tabOtherTickets.priority")}`,
        isSort: true,
        width: 20,
    },
    {
        field: "lastReply",
        name: `${i18n.t("support.tabOtherTickets.lastReply")}`,
        isSort: true,
        width: 20,
    },
    {
        field: "created",
        name: `${i18n.t("support.tabOtherTickets.created")}`,
        isSort: true,
        width: 50,
    },
];
export const ticketComponent = {
    ADD_REPLY: "ADD_REPLY",
    ADD_NOTE: "ADD_NOTE",
    REMINDERS: "REMINDERS",
    OTHER_TICKETS: "OTHER_TICKETS",
    TASKS: "TASKS",
    SETTINGS: "SETTINGS",
};
