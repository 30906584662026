// @flow
import { call, put, takeEvery } from "redux-saga/effects";
import { enqueueSnackbar } from "../../../../redux/CommonReducer";
import { responeCode, typeMessage } from "../../../../constants/Const";
import {
  throwErrorMessage,
  parseDataNotifications,
} from "../../../../helpers/HandleError";
import ActionTypes from "./type";

import {
  addContactFailed,
  addContactSuccess,
  deleteContactsFailed,
  deleteContactsSuccess,
  editActiveFailed,
  editActiveSuccess,
  editContactFailed,
  editContactSuccess,
  fetchContactFailed,
  fetchContactSuccess,
} from "./actions";
import {
  fetchContactCustomerRequest,
  addContactRequest,
  editContactRequest,
  editActiveRequest,
  deleteContactRequest,
} from "./api";

function* fetchContact(action) {
  try {
    const response = yield call(fetchContactCustomerRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(fetchContactSuccess(response.data.result));
    } else {
      yield put(fetchContactFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchContactFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* editChangeActive(action) {
  try {
    const response = yield call(editActiveRequest, action);

    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(editActiveSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(editActiveFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(editActiveFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* addContact(action) {
  try {
    const response = yield call(addContactRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(addContactSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(addContactFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(addContactFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* editContact(action) {
  try {
    const response = yield call(editContactRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(editContactSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(editContactFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(editContactFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* deleteContact(action) {
  try {
    const response = yield call(deleteContactRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(deleteContactsSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(deleteContactsFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(deleteContactsFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}
export function* contactsCustomerSaga() {
  yield takeEvery(ActionTypes.FETCH_CONTACT_REQUEST, fetchContact);
  yield takeEvery(ActionTypes.ADD_CONTACT_REQUEST, addContact);
  yield takeEvery(ActionTypes.EDIT_CONTACT_REQUEST, editContact);
  yield takeEvery(ActionTypes.DELETE_CONTACT_REQUEST, deleteContact);
  yield takeEvery(
    ActionTypes.EDIT_ACTIVE_CUSTOMER_CONTACT_REQUEST,
    editChangeActive
  );
}

export default contactsCustomerSaga;
