import {
    ADD_NEW_ITEM_FAILURE,
    ADD_NEW_ITEM_PENDING,
    ADD_NEW_ITEM_SUCCESS,
    FETCH_LIST_ITEM_GROUPS_FAILURE,
    FETCH_LIST_ITEM_GROUPS_PENDING,
    FETCH_LIST_ITEM_GROUPS_SUCCESS,
    FETCH_SALES_ITEM_FAILURE,
    FETCH_SALES_ITEM_PENDING,
    FETCH_SALES_ITEM_SUCCESS,
    FETCH_SALES_TAX_FAILURE,
    FETCH_SALES_TAX_PENDING,
    FETCH_SALES_TAX_SUCCESS,
} from "./types";

export const fetchListItemsPending = (data) => {
    return {
        type: FETCH_SALES_ITEM_PENDING,
        payload: data,
    };
};

export const fetchListItemsSuccess = (data) => {
    return {
        type: FETCH_SALES_ITEM_SUCCESS,
        payload: data,
    };
};

export const fetchListItemsFailure = () => {
    return {
        type: FETCH_SALES_ITEM_FAILURE,
    };
};

export const fetchListTaxPending = (data) => {
    return {
        type: FETCH_SALES_TAX_PENDING,
        payload: data,
    };
};

export const fetchListTaxSuccess = (data) => {
    return {
        type: FETCH_SALES_TAX_SUCCESS,
        payload: data,
    };
};

export const fetchListTaxFailure = () => {
    return {
        type: FETCH_SALES_TAX_FAILURE,
    };
};
// item-group actions
export const fetchListItemGroupsPending = (data) => {
    return {
        type: FETCH_LIST_ITEM_GROUPS_PENDING,
        payload: data,
    };
};

export const fetchListItemGroupsSuccess = (data) => {
    return {
        type: FETCH_LIST_ITEM_GROUPS_SUCCESS,
        payload: data,
    };
};

export const fetchListItemGroupsFailure = () => {
    return {
        type: FETCH_LIST_ITEM_GROUPS_FAILURE,
    };
};
export const addNewItemPending = (data, params) => {
    return {
        type: ADD_NEW_ITEM_PENDING,
        payload: data,
        params: params,
    };
};

export const addNewItemSuccess = (data) => {
    return {
        type: ADD_NEW_ITEM_SUCCESS,
        payload: data,
    };
};

export const addNewItemFailure = () => {
    return {
        type: ADD_NEW_ITEM_FAILURE,
    };
};
