export const FETCH_LIST_EXPENSES = "FETCH_LIST_EXPENSES";
export const FETCH_LIST_EXPENSES_FAILED = "FETCH_LIST_EXPENSES_FAILED";
export const FETCH_LIST_EXPENSES_SUCCESS = "FETCH_LIST_EXPENSES_SUCCESS";

export const GET_EXPENSE_ITEM = "GET_EXPENSE_ITEM";

export const SET_ISSUCCESS = "SET_ISSUCCESS";

export const FETCH_EXPENSE_BY_ID = "FETCH_EXPENSE_BY_ID";
export const FETCH_EXPENSE_BY_ID_FAILED = "FETCH_EXPENSE_BY_ID_FAILED";
export const FETCH_EXPENSE_BY_ID_SUCCESS = "FETCH_EXPENSE_BY_ID_SUCCESS";

export const FETCH_DELETE_EXPENSE = "FETCH_DELETE_EXPENSE";
export const FETCH_DELETE_EXPENSE_FAILED = "FETCH_ DELETE_EXPENSE_FAILED";
export const FETCH_DELETE_EXPENSE_SUCCESS = "FETCH_DELETE_EXPENSE_SUCCESS";

export const FETCH_PAYMENT_MODE = "FETCH_PAYMENT_MODE";
export const FETCH_PAYMENT_MODE_FAILED = "FETCH_PAYMENT_MODE_FAILED";
export const FETCH_PAYMENT_MODE_SUCCESS = "FETCH_PAYMENT_MODE_SUCCESS";

export const FETCH_LIST_CUSTOMER = "FETCH_LIST_CUSTOMER";
export const FETCH_LIST_CUSTOMER_FAILED = "FETCH_LIST_CUSTOMER_FAILED";
export const FETCH_LIST_CUSTOMER_SUCCESS = "FETCH_LIST_CUSTOMER_SUCCESS";

export const FETCH_LIST_EXPENSES_CATEGORY = "FETCH_LIST_EXPENSES_CATEGORY";
export const FETCH_LIST_EXPENSES_CATEGORY_FAILED =
    "FETCH_LIST_EXPENSES_CATEGORY_FAILED";
export const FETCH_LIST_EXPENSES_CATEGORY_SUCCESS =
    "FETCH_LIST_EXPENSES_CATEGORY_SUCCESS";

export const FETCH_CREATE_EXPENSES = "FETCH_CREATE_EXPENSES";
export const FETCH_CREATE_EXPENSES_FAILED = "FETCH_CREATE_EXPENSES_FAILED";
export const FETCH_CREATE_EXPENSES_SUCCESS = "FETCH_CREATE_EXPENSES_SUCCESS";

export const FETCH_EDIT_EXPENSES = "FETCH_EDIT_EXPENSES";
export const FETCH_EDIT_EXPENSES_FAILED = "FETCH_EDIT_EXPENSES_FAILED";
export const FETCH_EDIT_EXPENSES_SUCCESS = "FETCH_EDIT_EXPENSES_SUCCESS";

export const FETCH_LIST_PROJECT = "FETCH_LIST_PROJECT";
export const FETCH_LIST_PROJECT_FAILED = "FETCH_LIST_PROJECT_FAILED";
export const FETCH_LIST_PROJECT_SUCCESS = "FETCH_LIST_PROJECT_SUCCESS";

export const FETCH_LIST_SUMMARY = "FETCH_LIST_SUMMARY";
export const FETCH_LIST_SUMMARY_FAILED = "FETCH_LIST_SUMMARY_FAILED";
export const FETCH_LIST_SUMMARY_SUCCESS = "FETCH_LIST_SUMMARY_SUCCESS";