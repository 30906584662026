import {
    GET_LIST_STAFF_REQUEST,
    GET_LIST_STAFF_FAILED,
    GET_LIST_STAFF_SUCCESS,
    CREATE_STAFF_REQUEST,
    CREATE_STAFF_FAILED,
    CREATE_STAFF_SUCCESS,
    EDIT_STAFF_REQUEST,
    EDIT_STAFF_FAILED,
    EDIT_STAFF_SUCCESS,
    DELETE_STAFF_REQUEST,
    DELETE_STAFF_FAILED,
    DELETE_STAFF_SUCCESS,
    STAFF_STATUS,
    FILTER_STAFF_REQUEST,
    FILTER_STAFF_FAILED,
    FILTER_STAFF_SUCCESS,
    EDIT_STAFF_ITEM,
    GET_DEPARTMENTS_REQUEST,
    GET_DEPARTMENTS_SUCCESS,
    GET_DEPARTMENTS_FAILED,
    GET_STAFF_EDIT_REQUEST,
    GET_STAFF_EDIT_SUCCESS,
    GET_STAFF_EDIT_FAILED,
    ADD_STAFF_NOTE_PENDING,
    ADD_STAFF_NOTE_SUCCESS,
    ADD_STAFF_NOTE_FAILURE,
    GET_STAFF_NOTE_PENDING,
    GET_STAFF_NOTE_SUCCESS,
    GET_STAFF_NOTE_FAILURE,
    GET_STAFF_TIMESHEET_PENDING,
    GET_STAFF_TIMESHEET_SUCCESS,
    GET_STAFF_TIMESHEET_FAILURE,
    SET_STAFF_STATUS_PENDING,
    SET_STAFF_STATUS_FAILURE,
    SET_STAFF_STATUS_SUCCESS,
} from "./types";

export const getListStaffRequest = () => {
    return {
        type: GET_LIST_STAFF_REQUEST,
    };
};

export const getListStaffSuccess = (data) => {
    return {
        type: GET_LIST_STAFF_SUCCESS,
        payload: data,
    };
};

export const getListStaffFailed = () => {
    return {
        type: GET_LIST_STAFF_FAILED,
    };
};

export const createNewStaffRequest = (data) => {
    return {
        type: CREATE_STAFF_REQUEST,
        payload: data,
    };
};

export const createNewStaffSuccess = () => {
    return {
        type: CREATE_STAFF_SUCCESS,
        // payload: data,
    };
};

export const createNewStaffFailed = () => {
    return {
        type: CREATE_STAFF_FAILED,
    };
};

export const editStaffRequest = (data) => {
    return {
        type: EDIT_STAFF_REQUEST,
        payload: data,
    };
};

export const editStaffSuccess = (data) => {
    return {
        type: EDIT_STAFF_SUCCESS,
        payload: data,
    };
};

export const editStaffFailed = () => {
    return {
        type: EDIT_STAFF_FAILED,
    };
};

export const deleteStaffRequest = (data) => {
    return {
        type: DELETE_STAFF_REQUEST,
        payload: data,
    };
};

export const deleteStaffSuccess = (data) => {
    return {
        type: DELETE_STAFF_SUCCESS,
        payload: data,
    };
};

export const deleteStaffFailed = () => {
    return {
        type: DELETE_STAFF_FAILED,
    };
};

export const staffStatus = () => {
    return {
        type: STAFF_STATUS,
    };
};

export const editStaffItem = (data) => {
    return {
        type: EDIT_STAFF_ITEM,
        payload: data,
    };
};

export const filterStaffRequest = (search, params) => {
    return {
        type: FILTER_STAFF_REQUEST,
        search,
        params,
    };
};

export const filterStaffSuccess = (data) => {
    return {
        type: FILTER_STAFF_SUCCESS,
        payload: data,
    };
};

export const filterStaffFailed = () => {
    return {
        type: FILTER_STAFF_FAILED,
    };
};

export const getDepartmentsRequest = () => {
    return {
        type: GET_DEPARTMENTS_REQUEST,
    };
};

export const getDepartmentsSuccess = (data) => {
    return {
        type: GET_DEPARTMENTS_SUCCESS,
        payload: data,
    };
};

export const getDepartmentsFailed = () => {
    return {
        type: GET_DEPARTMENTS_FAILED,
    };
};

export const getStaffEditRequest = (id) => {
    return {
        type: GET_STAFF_EDIT_REQUEST,
        payload: id,
    };
};

export const getStaffEditSuccess = (data) => {
    return {
        type: GET_STAFF_EDIT_SUCCESS,
        payload: data,
    };
};

export const getStaffEditFailed = () => {
    return {
        type: GET_STAFF_EDIT_FAILED,
    };
};

// staff view
export const getStaffNotePending = (id) => {
    return {
        type: GET_STAFF_NOTE_PENDING,
        payload: { id: id },
    };
};

export const getStaffNoteSuccess = (data) => {
    return {
        type: GET_STAFF_NOTE_SUCCESS,
        payload: data,
    };
};
export const getStaffNoteFailure = () => {
    return {
        type: GET_STAFF_NOTE_FAILURE,
    };
};
export const addNoteStaffPending = (id, description) => {
    return {
        type: ADD_STAFF_NOTE_PENDING,
        payload: {
            id: id,
            params: { description: description },
        },
    };
};

export const addNoteStaffSuccess = (data) => {
    return {
        type: ADD_STAFF_NOTE_SUCCESS,
        payload: data,
    };
};
export const addNoteStaffFailure = () => {
    return {
        type: ADD_STAFF_NOTE_FAILURE,
    };
};

export const getStaffTimesheetPending = (id, time_sheet) => {
    return {
        type: GET_STAFF_TIMESHEET_PENDING,
        payload: {
            id: id,
            params: { time_sheet: time_sheet },
        },
    };
};

export const getStaffTimesheetSuccess = (data) => {
    return {
        type: GET_STAFF_TIMESHEET_SUCCESS,
        payload: data,
    };
};
export const getStaffTimesheetFailure = () => {
    return {
        type: GET_STAFF_TIMESHEET_FAILURE,
    };
};
export const setStaffStatusPending = (id, status) => {
    return {
        type: SET_STAFF_STATUS_PENDING,
        payload: {
            id: id,
            params: { active: status },
        },
    };
};

export const setStaffStatusSuccess = () => {
    return {
        type: SET_STAFF_STATUS_SUCCESS,
    };
};
export const setStaffStatusFailure = () => {
    return {
        type: SET_STAFF_STATUS_FAILURE,
    };
};
