// @flow
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import ActionTypes from "./types";

import {
  editProjectActivitySuccess,
  editProjectActivityFailed,
  getProjectActivitySuccess,
  getProjectActivityFailed,
} from "./actions";
import { enqueueSnackbar } from "../../../redux/CommonReducer";
import { getProjectActivity, editProjectActivity } from "./api";
import { responeCode, typeMessage } from "../../../constants/Const";
import {
  throwErrorMessage,
  parseDataNotifications,
} from "../../../helpers/HandleError";

// Project activity
function* getProjectActivitySaga(action) {
  const { payload } = action;
  try {
    const response = yield call(getProjectActivity, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(getProjectActivitySuccess(response.data.result));
    } else {
      yield put(getProjectActivityFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(getProjectActivityFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* editProjectActivitySaga(action) {
  try {
    const response = yield call(editProjectActivity, action.payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(editProjectActivitySuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(editProjectActivityFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(editProjectActivityFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

export function* projectActivitySaga() {
  yield takeEvery(
    ActionTypes.GET_PROJECT_ACTIVITY_REQUEST,
    getProjectActivitySaga
  );
  yield takeEvery(
    ActionTypes.EDIT_PROJECT_ACTIVITY_REQUEST,
    editProjectActivitySaga
  );
}

function* ProjectActivitySaga() {
  yield all([fork(projectActivitySaga)]);
}

export default ProjectActivitySaga;
