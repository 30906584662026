import {
    ADD_PAYMENT_CUSTOMER_FAILED,
    ADD_PAYMENT_CUSTOMER_REQUEST,
    ADD_PAYMENT_CUSTOMER_SUCCESS,
    FETCH_PAYMENT_CUSTOMER_FAILED,
    FETCH_PAYMENT_CUSTOMER_REQUEST,
    FETCH_PAYMENT_CUSTOMER_SUCCESS,
} from "./type";
export const fetchPaymentCustomer = (id, params) => {
    return {
        type: FETCH_PAYMENT_CUSTOMER_REQUEST,
        payload: {
            id: id,
            params: params,
        },
    };
};

export const fetchPaymentCustomerSuccess = (data) => {
    return {
        type: FETCH_PAYMENT_CUSTOMER_SUCCESS,
        data,
    };
};

export const fetchPaymentCustomerFailed = () => {
    return {
        type: FETCH_PAYMENT_CUSTOMER_FAILED,
    };
};
export const addPaymentCustomerRequest = (id, body, params) => {
    return {
        type: ADD_PAYMENT_CUSTOMER_REQUEST,
        payload: {
            id: id,
            body: body,
            params: params,
        },
    };
};

export const addPaymentCustomerSuccess = () => {
    return {
        type: ADD_PAYMENT_CUSTOMER_SUCCESS,
    };
};

export const addPaymentCustomerFailed = () => {
    return {
        type: ADD_PAYMENT_CUSTOMER_FAILED,
    };
};
