import ActionTypes from "./types";

//project note
export const getProjectNotesRequest = (data) => {
  return {
    type: ActionTypes.GET_PROJECT_NOTES_REQUEST,
    payload: data,
  };
};

export const getProjectNotesSuccess = (data) => {
  return {
    type: ActionTypes.GET_PROJECT_NOTES_SUCCESS,
    payload: data,
  };
};

export const getProjectNotesFailed = () => {
  return {
    type: ActionTypes.GET_PROJECT_NOTES_FAILED,
  };
};

export const editProjectNotesRequest = (data) => {
  return {
    type: ActionTypes.EDIT_PROJECT_NOTES_REQUEST,
    payload: data,
  };
};

export const editProjectNotesSuccess = (data) => {
  return {
    type: ActionTypes.EDIT_PROJECT_NOTES_SUCCESS,
    payload: data,
  };
};

export const editProjectNotesFailed = () => {
  return {
    type: ActionTypes.EDIT_PROJECT_NOTES_FAILED,
  };
};

export const deleteProjectNotesRequest = (data) => {
  return {
    type: ActionTypes.DELETE_PROJECT_NOTES_REQUEST,
    payload: data,
  };
};

export const deleteProjectNotesSuccess = (data) => {
  return {
    type: ActionTypes.DELETE_PROJECT_NOTES_SUCCESS,
    payload: data,
  };
};

export const deleteProjectNotesFailed = () => {
  return {
    type: ActionTypes.DELETE_PROJECT_NOTES_FAILED,
  };
};

export const createProjectNotesRequest = (projectId, data) => {
  return {
    type: ActionTypes.CREATE_PROJECT_NOTES_REQUEST,
    data,
    projectId,
  };
};

export const createProjectNotesSuccess = (data) => {
  return {
    type: ActionTypes.CREATE_PROJECT_NOTES_SUCCESS,
    payload: data,
  };
};

export const createProjectNotesFailed = () => {
  return {
    type: ActionTypes.CREATE_PROJECT_NOTES_FAILED,
  };
};
