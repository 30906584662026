import apiBase from "./../../../../../common/baseAPI";

const URL = 'ticketsPriority';

const getListTicketPriority = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createTicketPriority = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(URL, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const editTicketPriority = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${URL}/${data.priorityid}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteTicketPriority = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${URL}/${data}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const fetchTicketPriority = (action) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${URL}?search=${action.search}&&limit=${action.params.limit}&&page=${action.params.page}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};


export {
    getListTicketPriority,
    createTicketPriority,
    editTicketPriority,
    deleteTicketPriority,
    fetchTicketPriority

}