// import _ from "lodash";
import apiBase from "../../../common/baseAPI";

const CUSTOMER = "customer";
const CUSTOMER_COUNT = "customer/count";
const CUSTOMERGROUP = "customer-group";
const CUSTOMER_ACTIVE = "/customer/active";
const ESTIMATE_CUSTOMER = "/estimate/customer";

export const getSummaryCustomerRequest = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(CUSTOMER_COUNT)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
export const getListCustomerRequest = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(CUSTOMER)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const fetchListCustomerRequest = (action) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(
                `${CUSTOMER}?search=${action.search}&&limit=${action.params.limit}&&page=${action.params.page}`
            )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const deleteItemCustomerRequest = (value) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${CUSTOMER}/${value.payload}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const getListGroupRequest = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(CUSTOMERGROUP)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const addGroupRequest = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(CUSTOMERGROUP, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const getCustomerById = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${CUSTOMER}/${data}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};


export const addCustomerRequest = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(CUSTOMER, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const editActiveRequest = (action) => {
    const data = {
        active: action.value ? 1 : 0,
    };
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${CUSTOMER_ACTIVE}/${action.id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const fetchListEstimateCustomerRequest = (action) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(
                `${ESTIMATE_CUSTOMER}/${action.id}?search=${action.params.search}&&limit=${action.params.limit}&&page=${action.params.page}`
            )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

//api CRUD reminder chưa đúng url
// export const getListReminder = (data) => {
//   const newData = _.pickBy(data, _.identity); // remove value null , undefind , '', 0
//   let newUrl = "reminder";
//   Object.keys(newData).map(
//     (item, index) =>
//       (newUrl = `${newUrl}${index === 0 ? "?" : ""}${
//         data[item] ? `&${item}=${data[item]}` : ""
//       }`)
//   );
//   return new Promise((resolve, reject) => {
//     return apiBase
//       .get(newUrl)
//       .then((res) => resolve(res))
//       .catch((err) => reject(err));
//   });
// };

// export const createReminder = (body) => {
//   return new Promise((resolve, reject) => {
//     return apiBase
//       .post("reminder", body)
//       .then((res) => resolve(res))
//       .catch((err) => reject(err));
//   });
// };

// export const editReminder = (id, body) => {
//   return new Promise((resolve, reject) => {
//     return apiBase
//       .put(`${"reminder"}/${id}`, body)
//       .then((res) => resolve(res))
//       .catch((err) => reject(err));
//   });
// };

// export const deleteReminder = (id) => {
//   return new Promise((resolve, reject) => {
//     return apiBase
//       .delete(`${"reminder"}/${id}`)
//       .then((res) => resolve(res))
//       .catch((err) => reject(err));
//   });
// };

export const getCustomerProject = (data) => {
    const { clientId, body } = data;

    return new Promise((resolve, reject) => {
        return (
            apiBase
                // api/project/customer/{customer_id}
                .get(`project/customer/${clientId}`, {
                    params: body,
                })
                .then((res) => resolve(res))
                .catch((err) => reject(err))
        );
    });
};
export const addCustomerFile = (data) => {
    const { clientId, body } = data;
    let newFormData = new FormData();
    newFormData.append("file_name", body);

    const header = { "Content-Type": "multipart/form-data" };
    return new Promise((resolve, reject) => {
        return (
            apiBase
                // api/project/customer/{customer_id}
                .post(`file/customer/${clientId}`, newFormData, header)

                .then((res) => resolve(res))
                .catch((err) => reject(err))
        );
    });
};
export const addCustomerVault = (data) => {
    const { clientId, body } = data;

    return new Promise((resolve, reject) => {
        return (
            apiBase
                //api/vault/customer/{customer_id}
                .post(`vault/customer/${clientId}`, body)

                .then((res) => resolve(res))
                .catch((err) => reject(err))
        );
    });
};
