import {
    FETCH_LIST_EXPENSES,
    FETCH_LIST_EXPENSES_SUCCESS,
    FETCH_LIST_EXPENSES_FAILED,
    GET_EXPENSE_ITEM,
    SET_ISSUCCESS,
    FETCH_EXPENSE_BY_ID,
    FETCH_EXPENSE_BY_ID_SUCCESS,
    FETCH_EXPENSE_BY_ID_FAILED,
    FETCH_DELETE_EXPENSE,
    FETCH_DELETE_EXPENSE_SUCCESS,
    FETCH_DELETE_EXPENSE_FAILED,
    FETCH_PAYMENT_MODE,
    FETCH_PAYMENT_MODE_SUCCESS,
    FETCH_PAYMENT_MODE_FAILED,
    FETCH_LIST_CUSTOMER,
    FETCH_LIST_CUSTOMER_SUCCESS,
    FETCH_LIST_CUSTOMER_FAILED,
    FETCH_LIST_EXPENSES_CATEGORY,
    FETCH_LIST_EXPENSES_CATEGORY_SUCCESS,
    FETCH_LIST_EXPENSES_CATEGORY_FAILED,
    FETCH_CREATE_EXPENSES,
    FETCH_CREATE_EXPENSES_SUCCESS,
    FETCH_CREATE_EXPENSES_FAILED,
    FETCH_EDIT_EXPENSES,
    FETCH_EDIT_EXPENSES_SUCCESS,
    FETCH_EDIT_EXPENSES_FAILED,
    FETCH_LIST_PROJECT,
    FETCH_LIST_PROJECT_SUCCESS,
    FETCH_LIST_PROJECT_FAILED,
    FETCH_LIST_SUMMARY,
    FETCH_LIST_SUMMARY_FAILED,
    FETCH_LIST_SUMMARY_SUCCESS,
} from "./types";

const INIT_STATE = {
    isLoading: false,
    isSuccess: false,
    listExpenses: {},
    expenseItem: {},
    expenseById: {},
    listPayment: [],
    listProject: [],
    listExpensesCategory: [],
    listCustomer: [],
    listSummary: []
};

const ExpenseReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_LIST_EXPENSES:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_LIST_EXPENSES_SUCCESS:
            return {
                ...state,
                listExpenses: action.payload,
                isLoading: false,
            };

        case FETCH_LIST_EXPENSES_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case GET_EXPENSE_ITEM:
            return {
                ...state,
                expenseById: action.payload,
            };

        case SET_ISSUCCESS:
            return {
                ...state,
                isSuccess: action.payload,
            };

        case FETCH_EXPENSE_BY_ID:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_EXPENSE_BY_ID_SUCCESS:
            return {
                ...state,
                expenseById: action.payload,
                isLoading: false,
            };

        case FETCH_EXPENSE_BY_ID_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case FETCH_DELETE_EXPENSE:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };

        case FETCH_DELETE_EXPENSE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
            };

        case FETCH_DELETE_EXPENSE_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case FETCH_PAYMENT_MODE:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_PAYMENT_MODE_SUCCESS:
            return {
                ...state,
                listPayment: action.payload,
                isLoading: false,
            };

        case FETCH_PAYMENT_MODE_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case FETCH_LIST_CUSTOMER:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_LIST_CUSTOMER_SUCCESS:
            return {
                ...state,
                listCustomer: action.payload,
                isLoading: false,
            };

        case FETCH_LIST_CUSTOMER_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case FETCH_LIST_EXPENSES_CATEGORY:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_LIST_EXPENSES_CATEGORY_SUCCESS:
            return {
                ...state,
                listExpensesCategory: action.payload,
                isLoading: false,
            };

        case FETCH_LIST_EXPENSES_CATEGORY_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case FETCH_CREATE_EXPENSES:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };

        case FETCH_CREATE_EXPENSES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
            };

        case FETCH_CREATE_EXPENSES_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case FETCH_EDIT_EXPENSES:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };

        case FETCH_EDIT_EXPENSES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
            };

        case FETCH_EDIT_EXPENSES_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case FETCH_LIST_PROJECT:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_LIST_PROJECT_SUCCESS:
            return {
                ...state,
                listProject: action.payload,
                isLoading: false,
            };

        case FETCH_LIST_PROJECT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        
        case FETCH_LIST_SUMMARY:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_LIST_SUMMARY_SUCCESS:
            return {
                ...state,
                listSummary: action.payload,
                isLoading: false,
            };

        case FETCH_LIST_SUMMARY_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        
        default:
            return { ...state };
    }
};

export default ExpenseReducer;
