// @flow
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import ActionTypes from "./types";

import {
  getProjectNotesFailed,
  createProjectNotesFailed,
  editProjectNotesFailed,
  deleteProjectNotesFailed,
  createProjectNotesSuccess,
  getProjectNotesSuccess,
  editProjectNotesSuccess,
  deleteProjectNotesSuccess,
} from "./actions";
import { enqueueSnackbar } from "../../../../redux/CommonReducer";
import {
  createProjectNotes,
  editProjectNotes,
  deleteProjectNotes,
  getProjectNotes,
} from "./api";
import { responeCode, typeMessage } from "../../../../constants/Const";
import {
  throwErrorMessage,
  parseDataNotifications,
} from "../../../../helpers/HandleError";

//project notes
function* createProjectNotesSaga(action) {
  try {
    const response = yield call(
      createProjectNotes,
      action.projectId,
      action.data
    );
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(createProjectNotesSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(createProjectNotesFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(createProjectNotesFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}
function* getProjectNotesSaga(action) {
  const { payload } = action;
  try {
    const response = yield call(getProjectNotes, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(getProjectNotesSuccess(response.data.result));
    } else {
      yield put(getProjectNotesFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(getProjectNotesFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* editProjectNotesSaga(action) {
  try {
    const response = yield call(editProjectNotes, action.payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(editProjectNotesSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(editProjectNotesFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(editProjectNotesFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* deleteProjectNotesSaga(action) {
  try {
    const response = yield call(deleteProjectNotes, action.payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(deleteProjectNotesSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(deleteProjectNotesFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(deleteProjectNotesFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

export function* projectNotesSaga() {
  yield takeEvery(
    ActionTypes.CREATE_PROJECT_NOTES_REQUEST,
    createProjectNotesSaga
  );
  yield takeEvery(ActionTypes.GET_PROJECT_NOTES_REQUEST, getProjectNotesSaga);
  yield takeEvery(ActionTypes.EDIT_PROJECT_NOTES_REQUEST, editProjectNotesSaga);
  yield takeEvery(
    ActionTypes.DELETE_PROJECT_NOTES_REQUEST,
    deleteProjectNotesSaga
  );
}

function* ProjectNotesSaga() {
  yield all([fork(projectNotesSaga)]);
}

export default ProjectNotesSaga;
