import React from "react";
import { PrivateRoute } from "./PrivateRoute";
import i18n from "../i18n.js";
import { customer, lead } from "../constants/Route.js";
import { Route } from "react-router-dom";

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const ForgetPassword = React.lazy(() => import("../pages/account/ForgetPassword"));
const Register = React.lazy(() => import("../pages/account/Register"));
const ConfirmAccount = React.lazy(() => import("../pages/account/Confirm"));

export const authsRoute = [
    { path: "/login", name: "Login", component: Login, route: Route },
    { path: "/logout", name: "Logout", component: Logout, route: Route },
    {
        path: "/forget-password",
        name: "Forget Password",
        component: ForgetPassword,
        route: Route,
    },
    { path: "/register", name: "Register", component: Register, route: Route },
    {
        path: "/confirm",
        name: "Confirm",
        component: ConfirmAccount,
        route: Route,
    },
]