export default {
    task: {
        attachFiles: "Tập tin đính kèm",
        summary: "Tóm lược phân công",
        export: "XUẤT IN",
        bulk: "BULK ACTION",
        name: "Tên",
        status: "Trạng thái",
        startDate: "Ngày bắt đầu",
        dueDate: "Ngày chốt",
        assignedTo: "Đã chỉ định cho",
        tags: "Các thẻ",
        priority: "Mức độ ưu tiên",
        delete: "Xóa",
        recurringTask: "Phân công định kỳ",
        markAs: "Đánh dấu là",
        notStarted: "Chưa bắt đầu",
        inProgress: "Đang tiến hành",
        testing: "Đang kiểm tra",
        awaitingFeedback: "Đang chờ phản hồi",
        complete: "Đã hoàn thành",
        allStatus: "Tất cả trạng thái",
        low: "Thấp",
        medium: "Bình thường",
        high: "Cao",
        urgent: "Cấp bách",
        edit: "Chỉnh sửa",
        public: "Công khai",
        billable: "Có thể xuất biên nhận",
        subject: "Chủ đề",
        hourlyRate: "Tiền công theo giờ",
        repeatEvery: "Lặp lại mỗi",
        totalCycles: "Total Cycles",
        infinity: "Liên tục",
        relatedTo: "Liên quan đến",
        contract: "Hợp đồng",
        taskDescription: "Mô tả công việc",
        description: "Mô tả",
        checklistItem: "Đánh dấu tiến trình",
        checklistItemNone: "Không tìm thấy tiến trình nào được đánh dấu",
        comment: "Bình luận",
        addComment: "Thêm bình luận",
        taskInfo: "Thông tin phân công",
        createdAt: "Thời gian tạo",
        billableAmount: "Billable Amount",
        userLoggedTime: "Thời gian đăng nhập của bạn",
        totalLoggedTime: "Tổng thời gian đăng nhập",
        reminder: "Lời nhắc",
        createReminder: "Tạo lời nhắc",
        reminderNone: "Chưa có lời nhắc cho phân công này",
        assignee: "Người được phân công",
        assignTaskTo: "Ấn định cho",
        follower: "Người theo dõi",
        addFollower: "Thêm người theo dõi",
        followersNone: "Không có người theo dõi cho phân công này",
        uploadFile: "Thả tập tin vào đây để tải lên",
        member: "Thành viên",
        timeStart: "Thời gian bắt đầu",
        timeEnd: "Thời gian kết thúc",
        timeSpent: "Thời gian thực hiện",
        timerNone: "Không tìm thấy mục đếm thời gian nào",
        note: "Ghi chú",
        confirm: "XÁC NHẬN",
        close: "ĐÓNG",
        save: "LƯU LẠI",
        deleteTitle: "Xóa công việc",
        deleteConfirm: "Bạn có chắc bạn muốn xóa",
        cancel: "HỦY",
        noRepeat: "Không lặp lại",
        week: "Tuần",
        day: "Ngày",
        month: "Tháng",
        year: "Năm",
        twoWeeks: "2 Tuần",
        oneMonth: "1 Tháng",
        twoMonths: "2 Tháng",
        threeMonths: "3 Tháng",
        sixMonths: "6 Tháng",
        oneYear: "1 Năm",
        custom: "Tùy chỉnh",
        haveRelate: "Có liên quan đến",
        noSelect: "Không lựa chọn",
        project: "Dự án",
        invoice: "Hóa đơn",
        customer: "Khách hàng",
        estimate: "Báo giá",
        ticket: "Yêu cầu",
        expense: "Chi phí",
        lead: "Khách tiềm năng",
        proposal: "Đề xuất kế hoạch",
        createTaskTitle: "Thêm phân công mới",
        taskOverviewTitle: "Tổng quan phân công",
        allMonth: "Tất cả các tháng",
        jan: "Tháng 1",
        feb: "Tháng 2",
        march: "Tháng 3",
        april: "Tháng 4",
        may: "Tháng 5",
        june: "Tháng 6",
        july: "Tháng 7",
        aug: "Tháng 8",
        sept: "Tháng 9",
        oct: "Tháng 10",
        nov: "Tháng 11",
        dec: "Tháng 12",
        backToTask: "QUAY LẠI DANH SÁCH PHÂN CÔNG",
        totalAttachment: "Tổng số tập tin đính kèm đã thêm",
        totalComments: "Tổng số bình luận",
        finishOn: "Đã hoàn thành trước hẹn?",
        markAsComplete: "Hoàn thành",
        markAsInProgress: "Đang tiến hành",
        statistical: "Thông kê",
        timeChart: "Biểu đồ thời gian",
        reload: "Tải lại",
        action: "Hành động",
        dateNotified: "Ngày cần thông báo",
        setReminder: "Chuyển nhắc nhở sang",
        sendMailToReminder: "Gửi email cho cả nhắc nhở này",
        filter: "Lọc",
        all: "Tất cả",
        inDayTask: "Phân công trong ngày",
        overTerm: "Quá hạn",
        upcomingTask: "Phân công sắp tới",
        myTask: "Các phân công được chỉ định cho tôi",
        myFollowingTask: "Các phân công tôi đang theo dõi",
        notAssigned: "Chưa được phân công",
        recurring: "Định kỳ",
        billed: "Đã xuất",
        notBilled: "Không xuất",
        byAssignedMember: "Bởi thành viên được chỉ định",
        copy: "Copy",
        validation: {
            requirement: "Không được bỏ trống.",
        },
        newTask: "Phân công mới",
        assignedMember: "Bởi thành viên được chỉ định",
        userTask: "Các phân công được chỉ định cho tôi",
        followingTask: "Các phân công tôi đang theo dõi",
        files: "Tệp",
        // table: {
        //   name: 'Tên',
        //   status: 'Trạng thái',
        //   startDate: 'Ngày bắt đầu',
        //   dueDate: 'Ngày chốt',
        //   assignedTo: 'Đã chỉ định cho',
        //   tags: 'Các thẻ',
        //   priority: 'Mức độ ưu tiên',
        // },
        // name: {
        //   edit: 'Sửa',
        //   delete: 'Xóa',
        //   recurring: 'Phân công định kỳ'
        // },
        // status: {
        //   markAs: 'Đánh dấu là',
        //   notStarted: 'Chưa bắt đầu',
        //   inProgress: 'Đang tiến hành',
        //   testing: 'Đang kiểm tra',
        //   awaitingFeedback: 'Đang chờ phản hồi',
        //   complete: 'Đã hoàn thành',
        //   all: 'Tất cả trạng thái',
        // },
        // priority: {
        //   low: 'Thấp',
        //   medium: 'Bình thường',
        //   high: 'Cao',
        //   urgent: 'Cấp bách',
        // },
        // edit: {
        //   label: 'Chỉnh sửa',
        //   public: 'Công khai',
        //   billable: 'Có thể xuất biên nhận',
        //   subject: 'Chủ đề',
        //   hourlyRate: 'Tiền công theo giờ',
        //   startDate: 'Ngày bắt đầu',
        //   dueDate: 'Hạn chót',
        //   priority: 'Mức độ ưu tiên',
        //   repeatEvery: 'Lặp lại mỗi',
        //   totalCycles: 'Total Cycles',
        //   infinity: 'Liên tục',
        //   relatedTo: 'Liên quan đến',
        //   contract: 'Hợp đồng',
        //   tags: 'Các thẻ',
        //   taskDescription: 'Mô tả công việc',
        //   description: 'Mô tả',
        //   checklistItem: 'Đánh dấu tiến trình',
        //   checklistItemNone: 'Không tìm thấy tiến trình nào được đánh dấu',
        //   comment: 'Bình luận',
        //   addComment: 'Thêm bình luận',
        //   taskInfo: 'Thông tin phân công',
        //   createdAt: 'Thời gian tạo',
        //   status: 'Trạng thái',
        //   billableAmount: 'Billable Amount',
        //   userLoggedTime: 'Thời gian đăng nhập của bạn',
        //   totalLoggedTime: 'Tổng thời gian đăng nhập',
        //   reminder: 'Lời nhắc',
        //   createReminder: 'Tạo lời nhắc',
        //   reminderNone: 'Chưa có lời nhắc cho phân công này',
        //   assignee: 'Người được phân công',
        //   assignTask: 'Ấn định cho',
        //   followers: 'Người theo dõi',
        //   addFollowers: 'Thêm người theo dõi',
        //   followersNone: 'Không có người theo dõi cho phân công này',
        //   upload: 'Thả tập tin vào đây để tải lên',
        //   member: 'Thành viên',
        //   timeStart: 'Thời gian bắt đầu',
        //   timeEnd: 'Thời gian kết thúc',
        //   timeSpent: 'Thời gian thực hiện',
        //   timeChartNull: 'Không tìm thấy mục đếm thời gian nào',
        //   note: 'Ghi chú',
        //   confirm: 'XÁC NHẬN',
        //   close: 'ĐÓNG',
        //   save: 'LƯU LẠI',
        // },
        // delete: {
        //   title: 'Xóa công việc',
        //   confirm: 'Bạn có chắc bạn muốn xóa',
        //   cancel: 'HỦY',
        //   yes: 'XÓA',
        // },
        // taskRepeat: {
        //   nothing: 'Không lặp lại',
        //   week: 'Tuần',
        //   day: 'Ngày',
        //   month: 'Tháng',
        //   year: 'Năm',
        //   twoWeeks: '2 Tuần',
        //   oneMonth: '1 Tháng',
        //   twoMonths: '2 Tháng',
        //   threeMonths: '3 Tháng',
        //   sixMonths: '6 Tháng',
        //   oneYear: '1 Năm',
        //   custom: 'Tùy chỉnh',
        // },
        // related: {
        //   title: 'Có liên quan đến',
        //   nothing: 'Không lựa chọn',
        //   project: 'Dự án',
        //   invoice: 'Hóa đơn',
        //   customer: 'Khách hàng',
        //   estimate: 'Báo giá',
        //   contract: 'Hợp đồng',
        //   ticket: 'Yêu cầu',
        //   expense: 'Chi phí',
        //   lead: 'Khách tiềm năng',
        //   proposal: 'Đề xuất kế hoạch',
        // },
        // addNew: {
        //   title: 'Thêm phân công mới'
        // },
        // taskOverview: {
        //   title: 'Tổng quan phân công',
        //   back: 'QUAY LẠI DANH SÁCH PHÂN CÔNG',
        //   allStaff: 'Tất cả nhân viên',
        //   month: {
        //     allMonth: 'Tất cả các tháng',
        //     jan: 'Tháng 1',
        //     feb: 'Tháng 2',
        //     march: 'Tháng 3',
        //     april: 'Tháng 4',
        //     may: 'Tháng 5',
        //     june: 'Tháng 6',
        //     july: 'Tháng 7',
        //     aug: 'Tháng 8',
        //     sept: 'Tháng 9',
        //     oct: 'Tháng 10',
        //     nov: 'Tháng 11',
        //     dec: 'Tháng 12',
        //   },
        //   table: {
        //     name: 'Tên',
        //     startDate: 'Ngày bắt đầu',
        //     dueDate: 'Ngày chốt',
        //     status: 'Trạng thái',
        //     totalAttachment: 'Tổng số tập tin đính kèm đã thêm',
        //     totalComments: 'Tổng số bình luận',
        //     checklistItems: 'Đánh dấu tiến trình',
        //     totalLoggedTime: 'Tổng thời gian đăng nhập',
        //     finishOn: 'Đã hoàn thành trước hẹn?',
        //     assignedTo: 'Đã chỉ định cho',
        //   }
        // },
        // filter: {
        //   title: 'Lọc',
        //   all: 'Tất cả',
        //   todayTask: "Phân công trong ngày",
        //   dueDate: 'Quá hạn',
        //   upcomingTask: 'Phân công sắp tới',
        //   userTask: 'Các phân công được chỉ định cho tôi',
        //   followingTask: "Các phân công tôi đang theo dõi",
        //   notAssigned: 'Chưa được phân công',
        //   recurring: 'Định kỳ',
        //   billed: 'Đã xuất',
        //   notBilled: 'Không xuất',
        //   assignedMember: 'Bởi thành viên được chỉ định',
        // },
        // tooltip: {
        //   markAsComplete: 'Đánh dấu hoàn thành',
        //   markAsInProgress: 'Đánh dấu đang tiến hành',
        //   statistical: 'Thông kê',
        //   timeChart: 'Biểu đồ thời gian',
        //   edit: 'Chỉnh sửa',
        //   reload: 'Tải lại',
        //   delete: 'Xóa',
        // },
        // action: {
        //   title: 'Hành động',
        //   edit: 'Chỉnh sửa',
        //   copy: 'Copy',
        //   delete: 'Xóa',
        // },
        // reminder: {
        //   dateNotified: 'Ngày cần thông báo',
        //   setReminder: 'Chuyển nhắc nhở sang',
        //   description: 'Mô tả',
        //   sendMail: 'Gửi email cho cả nhắc nhở này',
        //   createReminder: 'TẠO LỜI NHẮC',
        // },
        // validation: {
        //   requirement: 'Không được bỏ trống.',
        // },
    },
};
