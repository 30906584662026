import ActionTypes from "./type";

const INIT_STATE = {
    isLoading: false,
    listInvoice: [],
    listInvoiceSummary: {},
};

const InvoicesCustomerReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_INVOICE_CUSTOMER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.FETCH_INVOICE_CUSTOMER_SUCCESS:
            return {
                ...state,
                listInvoice: action.data,
                isLoading: false,
            };
        case ActionTypes.FETCH_INVOICE_CUSTOMER_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.FILTER_YEAR_INVOICE_CUSTOMER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.FILTER_YEAR_INVOICE_CUSTOMER_SUCCESS:
            return {
                ...state,
                listInvoiceSummary: action.payload,
                isLoading: false,
            };
        case ActionTypes.FILTER_YEAR_INVOICE_CUSTOMER_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        default:
            return { ...state };
    }
};

export default InvoicesCustomerReducer;
