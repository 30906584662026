// @flow
import { call, put, takeEvery } from "redux-saga/effects";
import { enqueueSnackbar } from "../../../../redux/CommonReducer";
import { responeCode, typeMessage } from "../../../../constants/Const";
import {
    throwErrorMessage,
    parseDataNotifications,
} from "../../../../helpers/HandleError";
import ActionTypes from "./type";
import {
    fetchInvoiceCustomerFailed,
    fetchInvoiceCustomerSuccess,
    filterYearInvoiceCustomerFailed,
    filterYearInvoiceCustomerSuccess,
} from "./actions";
import {
    fetchInvoiceCustomerRequest,
    fetchYearInvoiceCustomerRequest,
} from "./api";

function* fetchInvoice(action) {
    try {
        const response = yield call(fetchInvoiceCustomerRequest, action);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchInvoiceCustomerSuccess(response.data.result));
        } else {
            yield put(fetchInvoiceCustomerFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchInvoiceCustomerFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* fetchYearInvoice(action) {
    try {
        const response = yield call(fetchYearInvoiceCustomerRequest, action);
        if (response && response.data) {
            yield put(filterYearInvoiceCustomerSuccess(response.data));
        } else {
            yield put(filterYearInvoiceCustomerFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(filterYearInvoiceCustomerFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

export function* invoicesCustomerSaga() {
    yield takeEvery(ActionTypes.FETCH_INVOICE_CUSTOMER_REQUEST, fetchInvoice);
    yield takeEvery(
        ActionTypes.FILTER_YEAR_INVOICE_CUSTOMER_REQUEST,
        fetchYearInvoice
    );
}

export default invoicesCustomerSaga;
