export const GET_LIST_STAFF_REQUEST = "FETCH_LIST_STAFF";
export const GET_LIST_STAFF_FAILED = "GET_LIST_STAFF_FAILED";
export const GET_LIST_STAFF_SUCCESS = "GET_LIST_STAFF_SUCCESS";
export const CREATE_STAFF_REQUEST = "CREATE_STAFF_REQUEST";
export const CREATE_STAFF_FAILED = "CREATE_STAFF_FAILED";
export const CREATE_STAFF_SUCCESS = "CREATE_STAFF_SUCCESS";
export const EDIT_STAFF_REQUEST = "EDIT_STAFF_REQUEST";
export const EDIT_STAFF_FAILED = "EDIT_STAFF_FAILED";
export const EDIT_STAFF_SUCCESS = "EDIT_STAFF_SUCCESS";
export const DELETE_STAFF_REQUEST = "DELETE_STAFF_REQUEST";
export const DELETE_STAFF_FAILED = "DELETE_STAFF_FAILED";
export const DELETE_STAFF_SUCCESS = "DELETE_STAFF_SUCCESS";
export const STAFF_STATUS = "STAFF_STATUS";
export const EDIT_STAFF_ITEM = "EDIT_STAFF_ITEM";
export const FILTER_STAFF_REQUEST = "FILTER_STAFF_REQUEST";
export const FILTER_STAFF_FAILED = "FILTER_STAFF_FAILED";
export const FILTER_STAFF_SUCCESS = "FILTER_STAFF_SUCCESS";
export const GET_DEPARTMENTS_REQUEST = "GET_DEPARTMENTS_REQUEST";
export const GET_DEPARTMENTS_FAILED = "GET_DEPARTMENTS_FAILED";
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
export const GET_STAFF_EDIT_REQUEST = "GET_STAFF_EDIT_REQUEST";
export const GET_STAFF_EDIT_FAILED = "GET_STAFF_EDIT_FAILED";
export const GET_STAFF_EDIT_SUCCESS = "GET_STAFF_EDIT_SUCCESS";

export const GET_STAFF_NOTE_PENDING = "GET_STAFF_NOTE_PENDING";
export const GET_STAFF_NOTE_SUCCESS = "GET_STAFF_NOTE_SUCCESS";
export const GET_STAFF_NOTE_FAILURE = "GET_STAFF_NOTE_FAILURE";

export const ADD_STAFF_NOTE_PENDING = "ADD_STAFF_NOTE_PENDING";
export const ADD_STAFF_NOTE_SUCCESS = "ADD_STAFF_NOTE_SUCCESS";
export const ADD_STAFF_NOTE_FAILURE = "ADD_STAFF_NOTE_FAILURE";

export const GET_STAFF_TIMESHEET_PENDING = "GET_STAFF_TIMESHEET_PENDING";
export const GET_STAFF_TIMESHEET_SUCCESS = "GET_STAFF_TIMESHEET_SUCCESS";
export const GET_STAFF_TIMESHEET_FAILURE = "GET_STAFF_TIMESHEET_FAILURE";

export const SET_STAFF_STATUS_PENDING = "SET_STAFF_STATUS_PENDING";
export const SET_STAFF_STATUS_SUCCESS = "SET_STAFF_STATUS_SUCCESS";
export const SET_STAFF_STATUS_FAILURE = "SET_STAFF_STATUS_FAILURE";
