const ActionTypes = {
    GET_LIST_PREDEFINED_REPLIES_REQUEST: 'GET_LIST_PREDEFINED_REPLIES_REQUEST',
    GET_LIST_PREDEFINED_REPLIES_FAILED: 'GET_LIST_PREDEFINED_REPLIES_FAILED',
    GET_LIST_PREDEFINED_REPLIES_SUCCESS: 'GET_LIST_PREDEFINED_REPLIES_SUCCESS',
    GET_PREDEFINED_REPLIES_EDIT_REQUEST: 'GET_PREDEFINED_REPLIES_EDIT_REQUEST',
    GET_PREDEFINED_REPLIES_EDIT_FAILED: 'GET_PREDEFINED_REPLIES_EDIT_FAILED',
    GET_PREDEFINED_REPLIES_EDIT_SUCCESS: 'GET_PREDEFINED_REPLIES_EDIT_SUCCESS',
    CREATE_PREDEFINED_REPLIES_REQUEST: 'CREATE_PREDEFINED_REPLIES_REQUEST',
    CREATE_PREDEFINED_REPLIES_FAILED: 'CREATE_PREDEFINED_REPLIES_FAILED',
    CREATE_PREDEFINED_REPLIES_SUCCESS: 'CREATE_PREDEFINED_REPLIES_SUCCESS',
    EDIT_PREDEFINED_REPLIES_REQUEST: 'EDIT_PREDEFINED_REPLIES_REQUEST',
    EDIT_PREDEFINED_REPLIES_FAILED: 'EDIT_PREDEFINED_REPLIES_FAILED',
    EDIT_PREDEFINED_REPLIES_SUCCESS: 'EDIT_PREDEFINED_REPLIES_SUCCESS',
    DELETE_PREDEFINED_REPLIES_REQUEST: 'DELETE_PREDEFINED_REPLIES_REQUEST',
    DELETE_PREDEFINED_REPLIES_FAILED: 'DELETE_PREDEFINED_REPLIES_FAILED',
    DELETE_PREDEFINED_REPLIES_SUCCESS: 'DELETE_PREDEFINED_REPLIES_SUCCESS',
    FETCH_PREDEFINED_REPLIES_REQUEST: 'FETCH_PREDEFINED_REPLIES_REQUEST',
    FETCH_PREDEFINED_REPLIES_FAILED: 'FETCH_PREDEFINED_REPLIES_FAILED',
    FETCH_PREDEFINED_REPLIES_SUCCESS: 'FETCH_PREDEFINED_REPLIES_SUCCESS',
};
export default ActionTypes;
