const ActionTypes = {
  //project Activity
  GET_PROJECT_ACTIVITY_REQUEST: "GET_PROJECT_ACTIVITY_REQUEST",
  GET_PROJECT_ACTIVITY_SUCCESS: "GET_PROJECT_ACTIVITY_SUCCESS",
  GET_PROJECT_ACTIVITY_FAILED: "GET_PROJECT_ACTIVITY_FAILED",
  EDIT_PROJECT_ACTIVITY_REQUEST: "EDIT_PROJECT_ACTIVITY_REQUEST",
  EDIT_PROJECT_ACTIVITY_SUCCESS: "EDIT_PROJECT_ACTIVITY_SUCCESS",
  EDIT_PROJECT_ACTIVITY_FAILED: "EDIT_PROJECT_ACTIVITY_FAILED",
};
export default ActionTypes;
