import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Box,
  Divider,
} from "@material-ui/core";
import "./index.scss";

const ScrollDialog = (props) => {
  const {
    isOpen,
    onClose,
    children,
    title,
    buttonActions,
    buttonClose,
    isFullScreen,
    size,
  } = props;

  const renderContentButton = () => {
    if (buttonClose) {
      return buttonClose;
    }
    return (
      <Box>
        <IconButton onClick={() => onClose()}>
          <i className="ti-close font-size-16"></i>
        </IconButton>
      </Box>
    );
  };

  return (
    <div>
      <Dialog
        fullScreen={isFullScreen || false}
        open={isOpen}
        scroll={"body"}
        onClose={() => onClose()}
        fullWidth={true}
        maxWidth={size ? size : "md"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Box
          paddingLeft={2}
          paddingRight={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className="dialog-header"
        >
          <h5 className="dialog-text">{title}</h5>
          {renderContentButton()}
        </Box>
        <Divider />
        <Box>{children}</Box>
        <DialogActions>{buttonActions}</DialogActions>
      </Dialog>
    </div>
  );
};

export default ScrollDialog;
