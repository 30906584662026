import { combineReducers } from "redux";
import Auth from "./auth/reducers";
import CommonReducer from "./CommonReducer";
import CommonSales from "../pages/Sales/redux/reducer";
import reducerRegistry from "./../helpers/ReducerRegistry";
//reducer Customer
import CustomerReducer from "../pages/Customer/redux/reducer";
import NotesCustomerReducer from "../pages/Customer/Notes/redux/reducer";
import StatementCustomerReducer from "../pages/Customer/Statement/redux/reducer";
import ContactCustomerReducer from "../pages/Customer/Contacts/redux/reducer";
import InvoiceCustomerReducer from "../pages/Customer/Invoices/redux/reducer";
import PaymentCustomerReducer from "../pages/Customer/Payments/redux/reducer";
import ProposalsCustomerReducer from "../pages/Proposal/Customer/redux/reducer";
import CreditNotesReducer from "../pages/CreditNote/redux/reducer";
import SaleInvoiceReducer from "../pages/Sales/Invoice/redux/reducer";
import ReminderCustomerReducer from "../pages/Customer/Reminders/redux/reducer";
import LeadReducer from "../pages/Lead/redux/reducer";
import ProjectReducer from "../pages/Project/redux/reducer";
import TaskReducer from "../pages/Task/redux/reducer";
// import ProposalReducer from '../pages/Sales/Proposal/redux/reducer';
import ContractReducer from "../pages/Contract/redux/reducer";
// import ExpenseReducer from '../pages/Expense/redux/reducer';
import ExpenseReducer from "../pages/Expense/redux/reducer";
import SaleEstimateReducer from "../pages/Estimates/redux/reducer";
import AdminLeadsReducer from "../pages/Administrator/Leads/redux/reducer";
import AdministratorStaffReducer from "../pages/Staff/redux/reducer";
import AdminCustomerGroupReducer from "../pages/Administrator/CustomerGroup/redux/reducer";
import AdminSupportDepartmentReducer from "../pages/Administrator/Support/Department/redux/reducer";
import AdminSupportTicketPriorityReducer from "../pages/Administrator/Support/TicketPriority/redux/reducer";
import AdminSupportPredefinedRepliesReducer from "../pages/Administrator/Support/PredefinedReplies/redux/reducer";
import AdminSupportServiceReducer from "../pages/Administrator/Support/Services/redux/reducer";
import AdminSupportSpamFilterReducer from "../pages/Administrator/Support/SpamFilters/redux/reducer";
import AdminSupportTicketStatusReducer from "../pages/Administrator/Support/TicketStatus/redux/reducer";
import CustomFieldsReducer from "../pages/Administrator/CustomFields/redux/reducer";
import AdminFinanceReducer from "../pages/Administrator/Finance/redux/reducer";
import InvoiceProjectReducer from "../pages/Project/Invoice/redux/reducer";
import EstimateProjectReducer from "../pages/Project/Estimate/redux/reducer";
import ExpenseProjectReducer from "../pages/Project/Expenses/redux/reducer";
import SalePaymentReducer from "../pages/Sales/Payments/redux/reducer";
import ProposalReducer from "../pages/Proposal/redux/reducer";
import SupportReducer from "../pages/Support/redux/reducer";
import AdmintratorContractTypeReducer from "../pages/Administrator/Contracts/redux/reducer";
import VaultCustomerReducer from "../pages/Customer/Vault/redux/reducer";
import ExpensesCustomerReducer from "../pages/Customer/Expenses/redux/reducer";
import ItemsReducer from "../pages/Sales/Items/redux/reducer";
import DiscussionProjectReducer from "../pages/Project/Discussion/redux/reducer";
import ProjectNotesReducer from "../pages/Project/Note/redux/reducer";
import ProjectActivityReducer from "../pages/Activity/redux/reducer";

reducerRegistry.register("Auth", Auth);
// reducer module customer
reducerRegistry.register("CustomerReducer", CustomerReducer);
reducerRegistry.register("NotesCustomerReducer", NotesCustomerReducer);
reducerRegistry.register("StatementCustomerReducer", StatementCustomerReducer);
reducerRegistry.register("ContactCustomerReducer", ContactCustomerReducer);
reducerRegistry.register("InvoiceCustomerReducer", InvoiceCustomerReducer);
reducerRegistry.register("PaymentCustomerReducer", PaymentCustomerReducer);
reducerRegistry.register("ProposalsCustomerReducer", ProposalsCustomerReducer);
reducerRegistry.register("ReminderCustomerReducer", ReminderCustomerReducer);
// reducer module lead
reducerRegistry.register("LeadReducer", LeadReducer);
reducerRegistry.register("ProjectReducer", ProjectReducer);
reducerRegistry.register("CommonReducer", CommonReducer);
reducerRegistry.register("CommonSales", CommonSales);
reducerRegistry.register("TaskReducer", TaskReducer);
reducerRegistry.register("ProposalReducer", ProposalReducer);
reducerRegistry.register("ExpenseReducer", ExpenseReducer);
reducerRegistry.register("ContractReducer", ContractReducer);
reducerRegistry.register("AdminLeadsReducer", AdminLeadsReducer);
reducerRegistry.register("SaleEstimateReducer", SaleEstimateReducer);
reducerRegistry.register("VaultCustomerReducer", VaultCustomerReducer);
reducerRegistry.register("SaleInvoiceReducer", SaleInvoiceReducer);
reducerRegistry.register("ExpensesCustomerReducer", ExpensesCustomerReducer);
reducerRegistry.register("AdministratorStaffReducer", AdministratorStaffReducer);
reducerRegistry.register("AdminCustomerGroupReducer", AdminCustomerGroupReducer);
reducerRegistry.register("AdminSupportDepartmentReducer", AdminSupportDepartmentReducer);
reducerRegistry.register("AdminSupportTicketPriorityReducer", AdminSupportTicketPriorityReducer);
reducerRegistry.register("AdminSupportPredefinedRepliesReducer", AdminSupportPredefinedRepliesReducer);
reducerRegistry.register("AdminSupportServiceReducer", AdminSupportServiceReducer );
reducerRegistry.register("AdminSupportSpamFilterReducer", AdminSupportSpamFilterReducer);
reducerRegistry.register("AdminSupportTicketStatusReducer", AdminSupportTicketStatusReducer);
reducerRegistry.register("AdmintratorContractTypeReducer", AdmintratorContractTypeReducer);
reducerRegistry.register("CustomFieldsReducer", CustomFieldsReducer);
reducerRegistry.register("AdminFinanceReducer", AdminFinanceReducer);
reducerRegistry.register("InvoiceProjectReducer", InvoiceProjectReducer);
reducerRegistry.register("EstimateProjectReducer", EstimateProjectReducer);
reducerRegistry.register("ExpenseProjectReducer", ExpenseProjectReducer);
reducerRegistry.register("SalePaymentReducer", SalePaymentReducer);
reducerRegistry.register("ProposalReducer", ProposalReducer);
reducerRegistry.register("SupportReducer", SupportReducer);
reducerRegistry.register("CreditNotesReducer", CreditNotesReducer);
reducerRegistry.register("ItemsReducer", ItemsReducer);
reducerRegistry.register("DiscussionProjectReducer", DiscussionProjectReducer);
reducerRegistry.register("ProjectNotesReducer", ProjectNotesReducer);
reducerRegistry.register("ProjectActivityReducer", ProjectActivityReducer);

export default combineReducers({
  ...reducerRegistry.getReducers(),
});
