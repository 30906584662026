// @flow
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
    FETCH_LIST_EXPENSES,
    FETCH_EXPENSE_BY_ID,
    FETCH_DELETE_EXPENSE,
    FETCH_LIST_CUSTOMER,
    FETCH_LIST_EXPENSES_CATEGORY,
    FETCH_CREATE_EXPENSES,
    FETCH_EDIT_EXPENSES,
    FETCH_LIST_PROJECT,
    FETCH_LIST_SUMMARY,
} from "./types";
import {
    fetchListExpensesFailed,
    fetchListExpensesSuccess,
    fetchExpenseByIdSuccess,
    fetchExpenseByIdFailed,
    fetchDeleteExpenseSuccess,
    fetchDeleteExpenseFailed,
    fetchListCustomerSuccess,
    fetchListCustomerFailed,
    fetchListExpensesCategorySuccess,
    fetchListExpensesCategoryFailed,
    fetchCreateExpensesSuccess,
    fetchCreateExpensesFailed,
    fetchEditExpensesSuccess,
    fetchEditExpensesFailed,
    fetchListProjectSuccess,
    fetchListProjectFailed,
    fetchListSummarySuccess,
    fetchListSummaryFailed,
} from "./actions";
import { enqueueSnackbar } from "../../../redux/CommonReducer";
import {
    getListExpenses,
    getExpenseById,
    deleteExpense,
    getListCustomer,
    getListExpensesCategory,
    createExpense,
    editExpense,
    getListProject,
    getListSummary
} from "./api";
import { responeCode, typeMessage } from "../../../constants/Const";
import {
    throwErrorMessage,
    parseDataNotifications,
} from "../../../helpers/HandleError";

function* fetchListExpenseSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(getListExpenses, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListExpensesSuccess(response.data.result));
        } else {
            yield put(fetchListExpensesFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListExpensesFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchExpenseByIdSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(getExpenseById, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchExpenseByIdSuccess(response.data.result));
        } else {
            yield put(fetchExpenseByIdFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchExpenseByIdFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchDeleteExpenseSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(deleteExpense, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchDeleteExpenseSuccess(response.data.result));
        } else {
            yield put(fetchDeleteExpenseFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchDeleteExpenseFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchListCustomerSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(getListCustomer, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListCustomerSuccess(response.data.result));
        } else {
            yield put(fetchListCustomerFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListCustomerFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchListExpensesCategorySaga(data) {
    const { payload } = data;
    try {
        const response = yield call(getListExpensesCategory, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListExpensesCategorySuccess(response.data.result));
        } else {
            yield put(fetchListExpensesCategoryFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListExpensesCategoryFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchCreateExpensesSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(createExpense, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchCreateExpensesSuccess(response.data.result));
        } else {
            yield put(fetchCreateExpensesFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchCreateExpensesFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchEditExpensesSaga(data) {
    try {
        const response = yield call(editExpense, data.id, data.data);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchEditExpensesSuccess(response.data.result));
        } else {
            yield put(fetchEditExpensesFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchEditExpensesFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchListProjectSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(getListProject, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListProjectSuccess(response.data.result));
        } else {
            yield put(fetchListProjectFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListProjectFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchListSummarySaga(data) {
    console.log ("4");
    console.log (data);
    const { payload } = data;
    try {
        const response = yield call(getListSummary, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListSummarySuccess(response.data.result));
        } else {
            yield put(fetchListSummaryFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListSummaryFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

export function* expenseSaga() {
    yield takeEvery(FETCH_LIST_EXPENSES, fetchListExpenseSaga);
    yield takeEvery(FETCH_EXPENSE_BY_ID, fetchExpenseByIdSaga);
    yield takeEvery(FETCH_DELETE_EXPENSE, fetchDeleteExpenseSaga);

    yield takeEvery(FETCH_LIST_CUSTOMER, fetchListCustomerSaga);
    yield takeEvery(
        FETCH_LIST_EXPENSES_CATEGORY,
        fetchListExpensesCategorySaga
    );
    yield takeEvery(FETCH_CREATE_EXPENSES, fetchCreateExpensesSaga);
    yield takeEvery(FETCH_EDIT_EXPENSES, fetchEditExpensesSaga);
    yield takeEvery(FETCH_LIST_PROJECT, fetchListProjectSaga);
    yield takeEvery(FETCH_LIST_SUMMARY, fetchListSummarySaga);
}

function* ExpenseSaga() {
    yield all([fork(expenseSaga)]);
}

export default ExpenseSaga;
