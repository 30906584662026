import ActionTypes from "./types";

export const createCustomerProjectRequest = (clientId, body) => {
  return {
    type: ActionTypes.CREATE_PROJECT_CUSTOMER_REQUEST,
    payload: { clientId: clientId, body: body },
  };
};

export const createCustomerProjectSuccess = (data) => {
  return {
    type: ActionTypes.CREATE_PROJECT_CUSTOMER_SUCCESS,
    payload: data,
  };
};

export const createCustomerProjectFailed = () => {
  return {
    type: ActionTypes.CREATE_PROJECT_CUSTOMER_FAILED,
  };
};

export const fetchListProjects = (data) => {
  return {
    type: ActionTypes.FETCH_LIST_PROJECTS,
    payload: data,
  };
};

export const fetchListProjectsSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_LIST_PROJECTS_SUCCESS,
    payload: data,
  };
};

export const fetchListProjectsFailed = () => {
  return {
    type: ActionTypes.FETCH_LIST_PROJECTS_FAILED,
  };
};

export const getProject = (id) => {
  return {
    type: ActionTypes.GET_PROJECT_ID,
    payload: id,
  };
};

export const fetchProjectById = (data) => {
  return {
    type: ActionTypes.FETCH_PROJECT_BY_ID,
    payload: data,
  };
};

export const fetchProjectByIdSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_PROJECT_BY_ID_SUCCESS,
    payload: data,
  };
};

export const fetchProjectByIdFailed = () => {
  return {
    type: ActionTypes.FETCH_PROJECT_BY_ID_FAILED,
  };
};

export const fetchTimesheetProject = (data) => {
  return {
    type: ActionTypes.FETCH_TIMESHEET_PROJECT,
    payload: data,
  };
};

export const fetchTimesheetProjectSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_TIMESHEET_PROJECT_SUCCESS,
    payload: data,
  };
};

export const fetchTimesheetProjectFailed = () => {
  return {
    type: ActionTypes.FETCH_TIMESHEET_PROJECT_FAILED,
  };
};

export const fetchDeleteTimesheetProject = (data) => {
  return {
    type: ActionTypes.FETCH_DELETE_TIMESHEET_PROJECT,
    payload: data,
  };
};

export const fetchDeleteTimesheetProjectSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_DELETE_TIMESHEET_PROJECT_SUCCESS,
    payload: data,
  };
};

export const fetchDeleteTimesheetProjectFailed = (data) => {
  return {
    type: ActionTypes.FETCH_DELETE_TIMESHEET_PROJECT_FAILED,
  };
};

export const fetchTaskByProjectTimesheet = (data) => {
  return {
    type: ActionTypes.FETCH_TASK_BY_PROJECT_TIMESHEET,
    payload: data,
  };
};

export const fetchTaskByProjectTimesheetSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_TASK_BY_PROJECT_TIMESHEET_SUCCESS,
    payload: data,
  };
};

export const fetchTaskByProjectTimesheetFailed = (data) => {
  return {
    type: ActionTypes.FETCH_TASK_BY_PROJECT_TIMESHEET_FAILED,
  };
};
export const fetchStaffByTaskTimesheet = (taskId, projectId) => {
  return {
    type: ActionTypes.FETCH_STAFF_BY_TASK_TIMESHEET,
    payload: {
      projectId: projectId,
      taskId: taskId,
    },
  };
};

export const fetchStaffByTaskTimesheetSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_STAFF_BY_TASK_TIMESHEET_SUCCESS,
    payload: data,
  };
};

export const fetchStaffByTaskTimesheetFailed = (data) => {
  return {
    type: ActionTypes.FETCH_STAFF_BY_TASK_TIMESHEET_FAILED,
  };
};

export const fetchCreateTimesheetProject = (data, projectId) => {
  return {
    type: ActionTypes.FETCH_CREATE_TIMESHEET_PROJECT,
    payload: { data: data, projectId: projectId },
  };
};

export const fetchCreateTimesheetProjectSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_CREATE_TIMESHEET_PROJECT_SUCCESS,
    payload: data,
  };
};

export const fetchCreateTimesheetProjectFailed = () => {
  return {
    type: ActionTypes.FETCH_CREATE_TIMESHEET_PROJECT_FAILED,
  };
};

export const fetchEditTimesheetProject = (data, projectId) => {
  return {
    type: ActionTypes.FETCH_EDIT_TIMESHEET_PROJECT,
    payload: {
      data: data,
      projectId: projectId,
    },
  };
};

export const fetchEditTimesheetProjectSuccess = () => {
  return {
    type: ActionTypes.FETCH_EDIT_TIMESHEET_PROJECT_SUCCESS,
    // payload: data,
  };
};

export const fetchEditTimesheetProjectFailed = () => {
  return {
    type: ActionTypes.FETCH_EDIT_TIMESHEET_PROJECT_FAILED,
  };
};

export const fetchFilterByMemberTimesheetProject = (data) => {
  return {
    type: ActionTypes.FETCH_FILTER_BY_MEMBER_TIMESHEET_PROJECT,
    payload: data,
  };
};

export const fetchFilterByMemberTimesheetProjectSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_FILTER_BY_MEMBER_TIMESHEET_PROJECT_SUCCESS,
    payload: data,
  };
};

export const fetchFilterByMemberTimesheetProjectFailed = () => {
  return {
    type: ActionTypes.FETCH_FILTER_BY_MEMBER_TIMESHEET_PROJECT_FAILED,
  };
};

export const downloadFileOfProjectPending = (fileId, fileName) => {
  return {
    type: ActionTypes.DOWNLOAD_FILE_OF_PROJECT_PENDING,
    payload: {
      fileId: fileId,
      fileName: fileName,
    },
  };
};

export const downloadFileOfProjectSuccess = () => {
  return {
    type: ActionTypes.DOWNLOAD_FILE_OF_PROJECT_SUCCESS,
  };
};

export const downloadFileOfProjectFailure = () => {
  return {
    type: ActionTypes.DOWNLOAD_FILE_OF_PROJECT_FAILURE,
  };
};

export const filterDiscussion = (data, projectId) => {
  return {
    type: ActionTypes.FILTER_DISCUSSION,
    payload: data,
    id: projectId,
  };
};

export const filterDiscussionSuccess = (data) => {
  return {
    type: ActionTypes.FILTER_DISCUSSION_SUCCESS,
    payload: data,
  };
};

export const filterDiscussionFailed = (data) => {
  return {
    type: ActionTypes.FILTER_DISCUSSION_FAILED,
    payload: data,
  };
};

export const fetchSubscriptionsProject = (data) => {
  return {
    type: ActionTypes.FETCH_SUBSCRIPTIONS_PROJECT,
    payload: data,
  };
};

export const fetchSubscriptionsProjectSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_SUBSCRIPTIONS_PROJECT_SUCCESS,
    payload: data,
  };
};

export const fetchSubscriptionsProjectFailed = () => {
  return {
    type: ActionTypes.FETCH_SUBSCRIPTIONS_PROJECT_FAILED,
  };
};

export const fetchTicketsProject = (data) => {
  return {
    type: ActionTypes.FETCH_TICKETS_PROJECT,
    payload: data,
  };
};

export const fetchTicketsProjectSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_TICKETS_PROJECT_SUCCESS,
    payload: data,
  };
};

export const fetchTicketsProjectFailed = () => {
  return {
    type: ActionTypes.FETCH_TICKETS_PROJECT_FAILED,
  };
};

export const fetchFilterByStatusTicketProject = (data) => {
  return {
    type: ActionTypes.FETCH_FILTER_BY_STATUS_TICKET_PROJECT,
    payload: data,
  };
};

export const fetchFilterByStatusTicketProjectSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_FILTER_BY_STATUS_TICKET_PROJECT_SUCCESS,
    payload: data,
  };
};

export const fetchFilterByStatusTicketProjectFailed = () => {
  return {
    type: ActionTypes.FETCH_FILTER_BY_STATUS_TICKET_PROJECT_FAILED,
  };
};

//Milestone

export const getProjectMilestoneRequest = (id) => {
  return {
    type: ActionTypes.GET_PROJECT_MILESTONE_REQUEST,
    payload: id,
  };
};

export const getProjectMilestoneSuccess = (data) => {
  return {
    type: ActionTypes.GET_PROJECT_MILESTONE_SUCCESS,
    payload: data,
  };
};

export const getProjectMilestoneFailed = () => {
  return {
    type: ActionTypes.GET_PROJECT_MILESTONE_FAILED,
  };
};

export const createProjectMilestoneRequest = (projectId, data) => {
  return {
    type: ActionTypes.CREATE_PROJECT_MILESTONE_REQUEST,
    projectId,
    data,
  };
};

export const createProjectMilestoneSuccess = (data) => {
  return {
    type: ActionTypes.CREATE_PROJECT_MILESTONE_SUCCESS,
    payload: data,
  };
};

export const createProjectMilestoneFailed = () => {
  return {
    type: ActionTypes.CREATE_PROJECT_MILESTONE_FAILED,
  };
};

export const editProjectMilestoneRequest = (data) => {
  return {
    type: ActionTypes.EDIT_PROJECT_MILESTONE_REQUEST,
    payload: data,
  };
};

export const editProjectMilestoneSuccess = (data) => {
  return {
    type: ActionTypes.EDIT_PROJECT_MILESTONE_SUCCESS,
    payload: data,
  };
};

export const editProjectMilestoneFailed = () => {
  return {
    type: ActionTypes.EDIT_PROJECT_MILESTONE_FAILED,
  };
};

export const deleteProjectMilestoneRequest = (id) => {
  return {
    type: ActionTypes.DELETE_PROJECT_MILESTONE_REQUEST,
    payload: id,
  };
};

export const deleteProjectMilestoneSuccess = (data) => {
  return {
    type: ActionTypes.DELETE_PROJECT_MILESTONE_SUCCESS,
    payload: data,
  };
};

export const deleteProjectMilestoneFailed = () => {
  return {
    type: ActionTypes.DELETE_PROJECT_MILESTONE_FAILED,
  };
};

export const fetchProjectMilestoneRequest = (projectId, params) => {
  return {
    type: ActionTypes.FETCH_PROJECT_MILESTONE_REQUEST,
    projectId,
    params,
  };
};

export const fetchProjectMilestoneSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_PROJECT_MILESTONE_SUCCESS,
    payload: data,
  };
};

export const fetchProjectMilestoneFailed = () => {
  return {
    type: ActionTypes.FETCH_PROJECT_MILESTONE_FAILED,
  };
};

export const changeStatusSuccessMilestone = () => {
  return {
    type: ActionTypes.CHANGE_STATUS_SUCCESS_MILESTONE,
  };
};

//GET TAG
export const getProjectTagRequest = () => {
  return {
    type: ActionTypes.GET_PROJECT_TAG_REQUEST,
  };
};

export const getProjectTagSuccess = (data) => {
  return {
    type: ActionTypes.GET_PROJECT_TAG_SUCCESS,
    payload: data,
  };
};

export const getProjectTagFailed = () => {
  return {
    type: ActionTypes.GET_PROJECT_TAG_FAILED,
  };
};

//GET TASK MÀN OVERVIEW

export const getProjectOverViewTaskRequest = (projectId) => {
  return {
    type: ActionTypes.GET_PROJECT_OVERVIEW_TASK_REQUEST,
    payload: projectId,
  };
};

export const getProjectOverViewTaskSuccess = (data) => {
  return {
    type: ActionTypes.GET_PROJECT_OVERVIEW_TASK_SUCCESS,
    payload: data,
  };
};

export const getProjectOverViewTaskFailed = () => {
  return {
    type: ActionTypes.GET_PROJECT_OVERVIEW_TASK_FAILED,
  };
};

//GET TASK MÀN OVERVIEW

export const getProjectOverViewCountRequest = (projectId) => {
  return {
    type: ActionTypes.GET_PROJECT_OVERVIEW_COUNT_REQUEST,
    payload: projectId,
  };
};

export const getProjectOverViewCountSuccess = (data) => {
  return {
    type: ActionTypes.GET_PROJECT_OVERVIEW_COUNT_SUCCESS,
    payload: data,
  };
};

export const getProjectOverViewCountFailed = () => {
  return {
    type: ActionTypes.GET_PROJECT_OVERVIEW_COUNT_FAILED,
  };
};

//GET SUMMARY PROJECT

export const getProjectSummaryRequest = (projectId) => {
  return {
    type: ActionTypes.GET_PROJECT_SUMMARY_REQUEST,
    payload: projectId,
  };
};

export const getProjectSummarySuccess = (data) => {
  return {
    type: ActionTypes.GET_PROJECT_SUMMARY_SUCCESS,
    payload: data,
  };
};

export const getProjectSummaryFailed = () => {
  return {
    type: ActionTypes.GET_PROJECT_SUMMARY_FAILED,
  };
};

export const createProjectRequest = (data) => {
  return {
    type: ActionTypes.CREATE_PROJECT_REQUEST,
    payload: data,
  };
};

export const createProjectSuccess = (data) => {
  return {
    type: ActionTypes.CREATE_PROJECT_SUCCESS,
    payload: data,
  };
};

export const createProjectFailed = () => {
  return {
    type: ActionTypes.CREATE_PROJECT_FAILED,
  };
};

export const editProjectRequest = (data) => {
  return {
    type: ActionTypes.EDIT_PROJECT_REQUEST,
    payload: data,
  };
};

export const editProjectSuccess = (data) => {
  return {
    type: ActionTypes.EDIT_PROJECT_SUCCESS,
    payload: data,
  };
};

export const editProjectFailed = () => {
  return {
    type: ActionTypes.EDIT_PROJECT_FAILED,
  };
};

export const deleteProjectRequest = (projectId) => {
  return {
    type: ActionTypes.DELETE_PROJECT_REQUEST,
    payload: projectId,
  };
};

export const deleteProjectSuccess = (data) => {
  return {
    type: ActionTypes.DELETE_PROJECT_SUCCESS,
    payload: data,
  };
};

export const deleteProjectFailed = () => {
  return {
    type: ActionTypes.DELETE_PROJECT_FAILED,
  };
};

export const searchProjectRequest = (params) => {
  return {
    type: ActionTypes.SEARCH_PROJECT_REQUEST,
    payload: params,
  };
};

export const searchProjectSuccess = (data) => {
  return {
    type: ActionTypes.SEARCH_PROJECT_SUCCESS,
    payload: data,
  };
};

export const searchProjectFailed = () => {
  return {
    type: ActionTypes.SEARCH_PROJECT_FAILED,
  };
};

export const copyProjectRequest = (projectId, data) => {
  return {
    type: ActionTypes.COPY_PROJECT_REQUEST,
    projectId,
    data,
  };
};

export const copyProjectSuccess = (data) => {
  return {
    type: ActionTypes.COPY_PROJECT_SUCCESS,
    payload: data,
  };
};

export const copyProjectFailed = () => {
  return {
    type: ActionTypes.COPY_PROJECT_FAILED,
  };
};

//GET ALL NEW PROJECT
export const getAllNewProjectRequest = () => {
  return {
    type: ActionTypes.GET_ALL_NEW_PROJECT_REQUEST,
  };
};

export const getAllNewProjectSuccess = (listTag, listStaff) => {
  return {
    type: ActionTypes.GET_ALL_NEW_PROJECT_SUCCESS,
    listTag,
    listStaff,
  };
};

export const getAllNewProjectFailed = () => {
  return {
    type: ActionTypes.GET_ALL_NEW_PROJECT_FAILED,
  };
};

//project note
export const getProjectNotesRequest = (data) => {
  return {
    type: ActionTypes.GET_PROJECT_NOTES_REQUEST,
    payload: data,
  };
};

export const getProjectNotesSuccess = (data) => {
  return {
    type: ActionTypes.GET_PROJECT_NOTES_SUCCESS,
    payload: data,
  };
};

export const getProjectNotesFailed = () => {
  return {
    type: ActionTypes.GET_PROJECT_NOTES_FAILED,
  };
};

export const createProjectNotesRequest = (projectId, data) => {
  return {
    type: ActionTypes.CREATE_PROJECT_NOTES_REQUEST,
    data,
    projectId,
  };
};

export const createProjectNotesSuccess = (data) => {
  return {
    type: ActionTypes.CREATE_PROJECT_NOTES_SUCCESS,
    payload: data,
  };
};

export const createProjectNotesFailed = () => {
  return {
    type: ActionTypes.CREATE_PROJECT_NOTES_FAILED,
  };
};

export const editProjectNotesRequest = (data) => {
  return {
    type: ActionTypes.EDIT_PROJECT_NOTES_REQUEST,
    payload: data,
  };
};

export const editProjectNotesSuccess = (data) => {
  return {
    type: ActionTypes.EDIT_PROJECT_NOTES_SUCCESS,
    payload: data,
  };
};

export const editProjectNotesFailed = () => {
  return {
    type: ActionTypes.EDIT_PROJECT_NOTES_FAILED,
  };
};

export const deleteProjectNotesRequest = (data) => {
  return {
    type: ActionTypes.DELETE_PROJECT_NOTES_REQUEST,
    payload: data,
  };
};

export const deleteProjectNotesSuccess = (data) => {
  return {
    type: ActionTypes.DELETE_PROJECT_NOTES_SUCCESS,
    payload: data,
  };
};

export const deleteProjectNotesFailed = () => {
  return {
    type: ActionTypes.DELETE_PROJECT_NOTES_FAILED,
  };
};
export const fetchProjectNotesRequest = (projectId, params) => {
    return {
        type: ActionTypes.FETCH_PROJECT_NOTES_REQUEST,
        projectId,
        params,
    };
};

export const fetchProjectNotesSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_PROJECT_NOTES_SUCCESS,
        payload: data,
    };
};

export const fetchProjectNotesFailed = () => {
    return {
        type: ActionTypes.FETCH_PROJECT_NOTES_FAILED,
    };
};
//project file

export const getProjectFilesRequest = (projectId) => {
  return {
    type: ActionTypes.GET_PROJECT_FILES_REQUEST,
    payload: projectId,
  };
};

export const getProjectFilesSuccess = (data) => {
  return {
    type: ActionTypes.GET_PROJECT_FILES_SUCCESS,
    payload: data,
  };
};

export const getProjectFilesFailed = () => {
  return {
    type: ActionTypes.GET_PROJECT_FILES_FAILED,
  };
};

export const createProjectFilesRequest = (projectId, data) => {
  return {
    type: ActionTypes.CREATE_PROJECT_FILES_REQUEST,
    projectId,
    data,
  };
};

export const createProjectFilesSuccess = (data) => {
  return {
    type: ActionTypes.CREATE_PROJECT_FILES_SUCCESS,
    payload: data,
  };
};

export const createProjectFilesFailed = () => {
  return {
    type: ActionTypes.CREATE_PROJECT_FILES_FAILED,
  };
};

export const changeProjectFilesVisibleRequest = (data) => {
  return {
    type: ActionTypes.CHANGE_FILES_VISIBLE_REQUEST,
    payload: data,
  };
};

export const changeProjectFilesVisibleSuccess = (data) => {
  return {
    type: ActionTypes.CHANGE_FILES_VISIBLE_SUCCESS,
    payload: data,
  };
};

export const changeProjectFilesVisibleFailed = () => {
  return {
    type: ActionTypes.CHANGE_FILES_VISIBLE_FAILED,
  };
};

export const deleteProjectFilesRequest = (data) => {
  return {
    type: ActionTypes.DELETE_PROJECT_FILES_REQUEST,
    payload: data,
  };
};

export const deleteProjectFilesSuccess = (data) => {
  return {
    type: ActionTypes.DELETE_PROJECT_FILES_SUCCESS,
    payload: data,
  };
};

export const deleteProjectFilesFailed = () => {
  return {
    type: ActionTypes.DELETE_PROJECT_FILES_FAILED,
  };
};

export const searchProjectFilesRequest = (projectId, params) => {
  return {
    type: ActionTypes.SEARCH_PROJECT_FILES_REQUEST,
    projectId,
    params,
  };
};

export const searchProjectFilesSuccess = (data) => {
  return {
    type: ActionTypes.SEARCH_PROJECT_FILES_SUCCESS,
    payload: data,
  };
};

export const searchProjectFilesFailed = () => {
  return {
    type: ActionTypes.SEARCH_PROJECT_FILES_FAILED,
  };
};
export const getAllProjectViewRequest = (projectId) => {
  return {
    type: ActionTypes.GET_ALL_PROJECT_VIEW_REQUEST,
    payload: projectId,
  };
};

export const getAllProjectViewSuccess = (
  listProject,
  projectById,
  openTask,
  dayLeft,
  projectId
) => {
  return {
    type: ActionTypes.GET_ALL_PROJECT_VIEW_SUCCESS,
    listProject,
    projectById,
    openTask,
    dayLeft,
    projectId,
  };
};
export const getAllProjectViewFailed = () => {
  return {
    type: ActionTypes.GET_ALL_PROJECT_VIEW_FAILED,
  };
};
//get all new ticket trong màn project view
export const getAllProjectViewNewTicketRequest = (projectId) => {
  return {
    type: ActionTypes.GET_ALL_PROJECT_VIEW_NEW_TICKET_REQUEST,
    payload: projectId,
  };
};

export const getAllProjectViewNewTicketSuccess = (data) => {
  return {
    type: ActionTypes.GET_ALL_PROJECT_VIEW_NEW_TICKET_SUCCESS,
    payload: data,
  };
};
export const getAllProjectViewNewTicketFailed = () => {
  return {
    type: ActionTypes.GET_ALL_PROJECT_VIEW_NEW_TICKET_FAILED,
  };
};

//PROJECT -TICKET
export const createProjectNewTicketRequest = (projectId, ticket, file) => {
  return {
    type: ActionTypes.CREATE_PROJECT_NEW_TICKET_REQUEST,
    projectId,
    ticket,
    file,
  };
};

export const createProjectNewTicketSuccess = (data) => {
  return {
    type: ActionTypes.CREATE_PROJECT_NEW_TICKET_SUCCESS,
    payload: data,
  };
};
export const createProjectNewTicketFailed = () => {
  return {
    type: ActionTypes.CREATE_PROJECT_NEW_TICKET_FAILED,
  };
};

export const deleteProjectTicketRequest = (id) => {
  return {
    type: ActionTypes.DELETE_PROJECT_TICKET_REQUEST,
    payload: id,
  };
};

export const deleteProjectTicketSuccess = (data) => {
  return {
    type: ActionTypes.DELETE_PROJECT_TICKET_SUCCESS,
    payload: data,
  };
};
export const deleteProjectTicketFailed = () => {
  return {
    type: ActionTypes.DELETE_PROJECT_TICKET_FAILED,
  };
};

export const getProjectTicketSummaryRequest = (id) => {
  return {
    type: ActionTypes.GET_PROJECT_TICKET_SUMMARY_REQUEST,
    payload: id,
  };
};

export const getProjectTicketSummarySuccess = (data) => {
  return {
    type: ActionTypes.GET_PROJECT_TICKET_SUMMARY_SUCCESS,
    payload: data,
  };
};
export const getProjectTicketSummaryFailed = () => {
  return {
    type: ActionTypes.GET_PROJECT_TICKET_SUMMARY_FAILED,
  };
};

export const addProjectMemberPending = (projectId, members) => {
  return {
    type: ActionTypes.ADD_MEMBER_PROJECT_PENDING,
    payload: { projectId, members },
  };
};

export const addProjectMemberSuccess = () => {
  return {
    type: ActionTypes.ADD_MEMBER_PROJECT_SUCCESS,
    // payload: data,
  };
};

export const addProjectMemberFailure = () => {
  return {
    type: ActionTypes.ADD_MEMBER_PROJECT_FAILURE,
  };
};
export const deleteProjectMemberPending = (projectId, staffId) => {
  return {
    type: ActionTypes.DELETE_MEMBER_PROJECT_PENDING,
    payload: { projectId: projectId, staffId: staffId },
  };
};

export const deleteProjectMemberSuccess = () => {
  return {
    type: ActionTypes.DELETE_MEMBER_PROJECT_SUCCESS,
    // payload: data,
  };
};

export const deleteProjectMemberFailure = () => {
  return {
    type: ActionTypes.DELETE_MEMBER_PROJECT_FAILURE,
  };
};
export const fetchStaffsOfProjectPending = (projectId, taskId) => {
  return {
    type: ActionTypes.FETCH_STAFFS_OF_PROJECT_PENDING,
    payload: {
      projectId: projectId,
      taskId: taskId,
    },
  };
};

export const fetchStaffsOfProjectSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_STAFFS_OF_PROJECT_SUCCESS,
    payload: data,
  };
};

export const fetchStaffsOfProjectFailure = () => {
  return {
    type: ActionTypes.FETCH_STAFFS_OF_PROJECT_FAILURE,
  };
};
export const getChartLogTimePrj = (projectId) => {
  return {
    type: ActionTypes.GET_CHART_LOG_TIME_PROJECT,
    payload: projectId,
  };
};

export const getChartLogTimePrjSuccess = (data) => {
  return {
    type: ActionTypes.GET_CHART_LOG_TIME_PROJECT_SUCCESS,
    payload: data,
  };
};

export const getChartLogTimePrjFailed = () => {
  return {
    type: ActionTypes.GET_CHART_LOG_TIME_PROJECT_FAILED,
  };
};
