const ActionTypes = {
    GET_LIST_SUPPORT_SERVICE_REQUEST: 'GET_LIST_SUPPORT_SERVICE_REQUEST',
    GET_LIST_SUPPORT_SERVICE_FAILED: 'GET_LIST_SUPPORT_SERVICE_FAILED',
    GET_LIST_SUPPORT_SERVICE_SUCCESS: 'GET_LIST_SUPPORT_SERVICE_SUCCESS',

    CREATE_SUPPORT_SERVICE_REQUEST: 'CREATE_SUPPORT_SERVICE_REQUEST',
    CREATE_SUPPORT_SERVICE_FAILED: 'CREATE_SUPPORT_SERVICE_FAILED',
    CREATE_SUPPORT_SERVICE_SUCCESS: 'CREATE_SUPPORT_SERVICE_SUCCESS',

    EDIT_SUPPORT_SERVICE_REQUEST: 'EDIT_SUPPORT_SERVICE_REQUEST',
    EDIT_SUPPORT_SERVICE_FAILED: 'EDIT_SUPPORT_SERVICE_FAILED',
    EDIT_SUPPORT_SERVICE_SUCCESS: 'EDIT_SUPPORT_SERVICE_SUCCESS',

    DELETE_SUPPORT_SERVICE_REQUEST: 'DELETE_SUPPORT_SERVICE_REQUEST',
    DELETE_SUPPORT_SERVICE_FAILED: 'DELETE_SUPPORT_SERVICE_FAILED',
    DELETE_SUPPORT_SERVICE_SUCCESS: 'DELETE_SUPPORT_SERVICE_SUCCESS',

    FETCH_SUPPORT_SERVICE_REQUEST: 'FETCH_SUPPORT_SERVICE_REQUEST',
    FETCH_SUPPORT_SERVICE_FAILED: 'FETCH_SUPPORT_SERVICE_FAILED',
    FETCH_SUPPORT_SERVICE_SUCCESS: 'FETCH_SUPPORT_SERVICE_SUCCESS',
    CHANGE_STATUS_DIALOG: 'CHANGE_STATUS_DIALOG'
};
export default ActionTypes;
