const ActionTypes = {
  FETCH_LIST_CUSTOM_FIELD_REQUEST: "FETCH_LIST_CUSTOM_FIELD_REQUEST",
  FETCH_LIST_CUSTOM_FIELD_SUCCESS: "FETCH_LIST_CUSTOM_FIELD_SUCCESS",
  FETCH_LIST_CUSTOM_FIELD_FAILED: "FETCH_LIST_CUSTOM_FIELD_FAILED",

  EDIT_ACTIVE_CUSTOM_FIELD_REQUEST: "EDIT_ACTIVE_CUSTOM_FIELD_REQUEST",
  EDIT_ACTIVE_CUSTOM_FIELD_SUCCESS: "EDIT_ACTIVE_CUSTOM_FIELD_SUCCESS",
  EDIT_ACTIVE_CUSTOM_FIELD_FAILED: "EDIT_ACTIVE_CUSTOM_FIELD_FAILED",

  ADD_CUSTOM_FIELD_REQUEST: "ADD_CUSTOM_FIELD_REQUEST",

  DELETE_CUSTOM_FIELD_REQUEST: "DELETE_CUSTOM_FIELD_REQUEST",
  DELETE_CUSTOM_FIELD_SUCCESS: "DELETE_CUSTOM_FIELD_SUCCESS",
  DELETE_CUSTOM_FIELD_FAILED: "DELETE_CUSTOM_FIELD_FAILED",

  GET_ITEM_CUSTOM_FIELD_REQUEST: "GET_ITEM_CUSTOM_FIELD_REQUEST",
  GET_ITEM_CUSTOM_FIELD_SUCCESS: "GET_ITEM_CUSTOM_FIELD_SUCCESS",
  GET_ITEM_CUSTOM_FIELD_FAILED: "GET_ITEM_CUSTOM_FIELD_FAILED",

  EDIT_CUSTOM_FIELD_REQUEST: "EDIT_CUSTOM_FIELD_REQUEST",
  EDIT_CUSTOM_FIELD_SUCCESS: "EDIT_CUSTOM_FIELD_SUCCESS",
  EDIT_CUSTOM_FIELD_FAILED: "EDIT_CUSTOM_FIELD_FAILED",
};
export default ActionTypes;
