// @flow
import { call, put, takeEvery } from 'redux-saga/effects';
import ActionTypes from './types';
import {
    getListTicketPriorityFailed,
    getListTicketPrioritySuccess,
    createTicketPriorityFailed,
    createTicketPrioritySuccess,
    editTicketPriorityFailed,
    editTicketPrioritySuccess,
    deleteTicketPriorityFailed,
    deleteTicketPrioritySuccess,
    fetchTicketPrioritySuccess,
    fetchTicketPriorityFailed,
} from './actions';
import { enqueueSnackbar } from '../../../../../redux/CommonReducer';
import {
    getListTicketPriority,
    createTicketPriority,
    editTicketPriority,
    deleteTicketPriority,
    fetchTicketPriority
} from './api';
import { responeCode, typeMessage } from '../../../../../constants/Const';
import { throwErrorMessage, parseDataNotifications } from '../../../../../helpers/HandleError';

function* getListTicketPrioritySaga() {
    try {
        const response = yield call(getListTicketPriority);
        if (response && response.data && response.data.error_code === responeCode.success && response.data.result) {
            yield put(getListTicketPrioritySuccess(response.data.result));
        }
        else {
            yield put(getListTicketPriorityFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(getListTicketPriorityFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

function* createTicketPrioritySaga(data) {
    const { payload } = data;
    try {
        const response = yield call(createTicketPriority, payload);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(createTicketPrioritySuccess(response.data.result));
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
        }
        else {
            yield put(createTicketPriorityFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(createTicketPriorityFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

function* editTicketPrioritySaga(data) {
    const { payload } = data;
    try {
        const response = yield call(editTicketPriority, payload);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(editTicketPrioritySuccess(response.data.result));
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));

        }
        else {
            yield put(editTicketPriorityFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(editTicketPriorityFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

function* deleteTicketPrioritySaga(data) {
    const { payload } = data;

    try {
        const response = yield call(deleteTicketPriority, payload);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(deleteTicketPrioritySuccess(payload));
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
        }
        else {
            yield put(deleteTicketPriorityFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(deleteTicketPriorityFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};
function* fetchTicketPrioritySaga(action) {
    try {
        const response = yield call(fetchTicketPriority, action);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(fetchTicketPrioritySuccess(response.data.result));
        }
        else {
            yield put(fetchTicketPriorityFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(fetchTicketPriorityFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

export function* adminSupportTicketPrioritySaga() {
    yield takeEvery(ActionTypes.GET_LIST_TICKET_PRIORITY_REQUEST, getListTicketPrioritySaga);
    yield takeEvery(ActionTypes.CREATE_TICKET_PRIORITY_REQUEST, createTicketPrioritySaga);
    yield takeEvery(ActionTypes.EDIT_TICKET_PRIORITY_REQUEST, editTicketPrioritySaga);
    yield takeEvery(ActionTypes.DELETE_TICKET_PRIORITY_REQUEST, deleteTicketPrioritySaga);
    yield takeEvery(ActionTypes.FETCH_TICKET_PRIORITY_REQUEST, fetchTicketPrioritySaga);

}


export default adminSupportTicketPrioritySaga;