export const GET_TASK = "GET_TASK";
export const GET_TASK_SUCCESS = "GET_TASK_SUCCESS";
export const GET_TASK_FAILED = "GET_TASK_FAILED";

export const GET_TASK_BY_LEAD = "GET_TASK_BY_LEAD";
export const GET_TASK_BY_LEAD_SUCCESS = "GET_TASK_BY_LEAD_SUCCESS";
export const GET_TASK_BY_LEAD_FAILED = "GET_TASK_BY_LEAD_FAILED";

export const CREATE_NEW_TASK = "CREATE_NEW_TASK";
export const CREATE_NEW_TASK_SUCCESS = "CREATE_NEW_TASK_SUCCESS";
export const CREATE_NEW_TASK_FAILED = "CREATE_NEW_TASK_FAILED";

export const FETCH_STATUS_LEADS = "FETCH_STATUS_LEADS";
export const FETCH_STATUS_LEADS_SUCCESS = "FETCH_STATUS_LEADS_SUCCESS";
export const FETCH_STATUS_LEADS_FAILED = "FETCH_STATUS_LEADS_FAILED";

export const CHANGE_TASK_PRIORITY = "CHANGE_TASK_PRIORITY";
export const CHANGE_TASK_PRIORITY_SUCCESS = "CHANGE_TASK_PRIORITY_SUCCESS";
export const CHANGE_TASK_PRIORITY_FAILED = "CHANGE_TASK_PRIORITY_FAILED";

export const CHANGE_TASK_STATUS = "CHANGE_TASK_STATUS";
export const CHANGE_TASK_STATUS_SUCCESS = "CHANGE_TASK_STATUS_SUCCESS";
export const CHANGE_TASK_STATUS_FAILED = "CHANGE_TASK_STATUS_FAILED";

export const GET_COUNT_TASK = "GET_COUNT_TASK";
export const GET_COUNT_TASK_SUCCESS = "GET_COUNT_TASK_SUCCESS";
export const GET_COUNT_TASK_FAILED = "GET_COUNT_TASK_FAILED";

export const EDIT_TASK = "EDIT_TASK";
export const EDIT_TASK_SUCCESS = "EDIT_TASK_SUCCESS";
export const EDIT_TASK_FAILED = "EDIT_TASK_FAILED";

export const DELETE_TASK = "DELETE_TASK";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_FAILED = "DELETE_TASK_FAILED";

export const SEARCH_RELATED = "SEARCH_RELATED";
export const SEARCH_RELATED_SUCCESS = "SEARCH_RELATED_SUCCESS";
export const SEARCH_RELATED_FAILED = "SEARCH_RELATED_FAILED";

export const SEARCH_RELATED_BY_FILTER = "SEARCH_RELATED_BY_FILTER";
export const SEARCH_RELATED_BY_FILTER_SUCCESS =
    "SEARCH_RELATED_BY_FILTER_SUCCESS";
export const SEARCH_RELATED_BY_FILTER_FAILED =
    "SEARCH_RELATED_BY_FILTER_FAILED";

export const GET_LIST_STAFF = "GET_LIST_STAFF";
export const GET_LIST_STAFF_SUCCESS = "GET_LIST_STAFF_SUCCESS";
export const GET_LIST_STAFF_FAILED = "GET_LIST_STAFF_FAILED";

export const GET_TASK_BY_ID = "GET_TASK_BY_ID";
export const GET_TASK_BY_ID_SUCCESS = "GET_TASK_BY_ID_SUCCESS";
export const GET_TASK_BY_ID_FAILED = "GET_TASK_BY_ID_FAILED";

export const REDIRECT_TO_TASK_EDIT = "REDIRECT_TO_TASK_EDIT";

export const ADD_NEW_TIMER = "ADD_NEW_TIMER";
export const ADD_NEW_TIMER_SUCCESS = "ADD_NEW_TIMER_SUCCESS";
export const ADD_NEW_TIMER_FAILED = "ADD_NEW_TIMER_FAILED";

export const DELETE_TIMER = "DELETE_TIMER";
export const DELETE_TIMER_SUCCESS = "DELETE_TIMER_SUCCESS";
export const DELETE_TIMER_FAILED = "DELETE_TIMER_FAILED";

export const ADD_NEW_CHECKLIST = "ADD_NEW_CHECKLIST";
export const ADD_NEW_CHECKLIST_SUCCESS = "ADD_NEW_CHECKLIST_SUCCESS";
export const ADD_NEW_CHECKLIST_FAILED = "ADD_NEW_CHECKLIST_FAILED";

export const DELETE_CHECKLIST = "DELETE_CHECKLIST";
export const DELETE_CHECKLIST_SUCCESS = "DELETE_CHECKLIST_SUCCESS";
export const DELETE_CHECKLIST_FAILED = "DELETE_CHECKLIST_FAILED";

export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const ADD_COMMENT_FAILED = "ADD_COMMENT_FAILED";

export const EDIT_COMMENT = "EDIT_COMMENT";
export const EDIT_COMMENT_SUCCESS = "EDIT_COMMENT_SUCCESS";
export const EDIT_COMMENT_FAILED = "EDIT_COMMENT_FAILED";

export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILED = "DELETE_COMMENT_FAILED";

export const ADD_REMINDER = "ADD_REMINDER";
export const ADD_REMINDER_SUCCESS = "ADD_REMINDER_SUCCESS";
export const ADD_REMINDER_FAILED = "ADD_REMINDER_FAILED";

export const EDIT_REMINDER = "EDIT_REMINDER";
export const EDIT_REMINDER_SUCCESS = "EDIT_REMINDER_SUCCESS";
export const EDIT_REMINDER_FAILED = "EDIT_REMINDER_FAILED";

export const DELETE_REMINDER = "DELETE_REMINDER";
export const DELETE_REMINDER_SUCCESS = "DELETE_REMINDER_SUCCESS";
export const DELETE_REMINDER_FAILED = "DELETE_REMINDER_FAILED";

export const ADD_ASSIGN = "ADD_ASSIGN";
export const ADD_ASSIGN_SUCCESS = "ADD_ASSIGN_SUCCESS";
export const ADD_ASSIGN_FAILED = "ADD_ASSIGN_FAILED";

export const DELETE_ASSIGN = "DELETE_ASSIGN";
export const DELETE_ASSIGN_SUCCESS = "DELETE_ASSIGN_SUCCESS";
export const DELETE_ASSIGN_FAILED = "DELETE_ASSIGN_FAILED";

export const ADD_FOLLOWER = "ADD_FOLLOWER";
export const ADD_FOLLOWER_SUCCESS = "ADD_FOLLOWER_SUCCESS";
export const ADD_FOLLOWER_FAILED = "ADD_FOLLOWER_FAILED";

export const DELETE_FOLLOWER = "DELETE_FOLLOWER";
export const DELETE_FOLLOWER_SUCCESS = "DELETE_FOLLOWER_SUCCESS";
export const DELETE_FOLLOWER_FAILED = "DELETE_FOLLOWER_FAILED";

//Open, close task-edit
export const OPEN_TASK_EDIT = "OPEN_TASK_EDIT";
export const CLOSE_TASK_EDIT = "CLOSE_TASK_EDIT";

//Open, close task-detail
export const OPEN_TASK_DETAIL = "OPEN_TASK_DETAIL";
export const CLOSE_TASK_DETAIL = "CLOSE_TASK_DETAIL";

//Open, close task delete
export const OPEN_POPUP_DELETE = "OPEN_POPUP_DELETE";
export const CLOSE_POPUP_DELETE = "CLOSE_POPUP_DELETE";

//Open, close task create
export const OPEN_TASK_CREATE = "OPEN_TASK_CREATE";
export const CLOSE_TASK_CREATE = "CLOSE_TASK_CREATE";

//Task by Profile
export const GET_TASK_BY_PROJECT = "GET_TASK_BY_PROJECT";
export const GET_TASK_BY_PROJECT_SUCCESS = "GET_TASK_BY_PROJECT_SUCCESS";
export const GET_TASK_BY_PROJECT_FAILED = "GET_TASK_BY_PROJECT_FAILED";

//Tásk summary by project
export const GET_TASK_SUMMARY_BY_PROJECT_REQUEST =
    "GET_TASK_SUMMARY_BY_PROJECT_REQUEST";
export const GET_TASK_SUMMARY_BY_PROJECT_SUCCESS =
    "GET_TASK_SUMMARY_BY_PROJECT_SUCCESS";
export const GET_TASK_SUMMARY_BY_PROJECT_FAILED =
    "GET_TASK_SUMMARY_BY_PROJECT_FAILED";
//filter Task by Project
export const FILTER_TASK_BY_PROJECT_REQUEST = "FILTER_TASK_BY_PROJECT_REQUEST";
export const FILTER_TASK_BY_PROJECT_SUCCESS = "FILTER_TASK_BY_PROJECT_SUCCESS";
export const FILTER_TASK_BY_PROJECT_FAILED = "FILTER_TASK_BY_PROJECT_FAILED";
export const CLOSE_TASK_BY_LEADS = "CLOSE_TASK_BY_LEADS";

//filter Task by Task
export const FILTER_TASK_REQUEST = "FILTER_TASK_REQUEST";
export const FILTER_TASK_SUCCESS = "FILTER_TASK_SUCCESS";
export const FILTER_TASK_FAILED = "FILTER_TASK_FAILED";
// Copy task
export const COPY_TASK_PENDING = "COPY_TASK_PENDING";
export const COPY_TASK_SUCCESS = "COPY_TASK_SUCCESS";
export const COPY_TASK_FAILURE = "COPY_TASK_FAILURE";
// getfile
export const GET_FILE_IN_TASK_PENDING = "GET_FILE_IN_TASK_PENDING";
export const GET_FILE_IN_TASK_SUCCESS = "GET_FILE_IN_TASK_SUCCESS";
export const GET_FILE_IN_TASK_FAILURE = "GET_FILE_IN_TASK_FAILURE";
//delete tag in task
export const DELETE_TAG_IN_TASK_PENDING = "DELETE_TAG_IN_TASK_PENDING";
export const DELETE_TAG_IN_TASK_SUCCESS = "DELETE_TAG_IN_TASK_SUCCESS";
export const DELETE_TAG_IN_TASK_FAILURE = "DELETE_TAG_IN_TASK_FAILURE";
