import {
    ADD_CONTRACT_TYPE_FAILED,
    ADD_CONTRACT_TYPE_PENDING,
    ADD_CONTRACT_TYPE_SUCCESS,
    DELETE_CONTRACT_TYPE_FAILED,
    DELETE_CONTRACT_TYPE_PENDING,
    DELETE_CONTRACT_TYPE_SUCCESS,
    GET_CONTRACT_TYPE_FAILED,
    GET_CONTRACT_TYPE_PENDING,
    GET_CONTRACT_TYPE_SUCCESS,
    PUT_CONTRACT_TYPE_FAILED,
    PUT_CONTRACT_TYPE_PENDING,
    PUT_CONTRACT_TYPE_SUCCESS,
} from "./type";

const INIT_STATE = {
    listContractType: [],
    isLoading: false,
    closeDialog: false,
    deleteGroup: false,
};
const AdmintratorContractTypeReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CONTRACT_TYPE_PENDING:
            return { ...state, isLoading: true };
        case GET_CONTRACT_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listContractType: action.payload.data,
            };
        case GET_CONTRACT_TYPE_FAILED:
            return { ...state, isLoading: false };
        case ADD_CONTRACT_TYPE_PENDING:
            return { ...state, isLoading: true };
        case ADD_CONTRACT_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case ADD_CONTRACT_TYPE_FAILED:
            return { ...state, isLoading: false };
        case PUT_CONTRACT_TYPE_PENDING:
            return { ...state, isLoading: true };
        case PUT_CONTRACT_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case PUT_CONTRACT_TYPE_FAILED:
            return { ...state, isLoading: false };
        case DELETE_CONTRACT_TYPE_PENDING:
            return { ...state, isLoading: true };
        case DELETE_CONTRACT_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case DELETE_CONTRACT_TYPE_FAILED:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};
export default AdmintratorContractTypeReducer;
