// ticket summary
export const FETCH_TICKET_SUMMARY_PENDING = "FETCH_TICKET_SUMMARY_PENDING";
export const FETCH_TICKET_SUMMARY_SUCCESS = "FETCH_TICKET_SUMMARY_SUCCESS";
export const FETCH_TICKET_SUMMARY_FAILURE = "FETCH_TICKET_SUMMARY_FAILURE";
// ticket
export const FETCH_LIST_TICKETS_PENDING = "FETCH_LIST_TICKETS_PENDING";
export const FETCH_LIST_TICKETS_SUCCESS = "FETCH_LIST_TICKETS_SUCCESS";
export const FETCH_LIST_TICKETS_FAILURE = "FETCH_LIST_TICKETS_FAILURE";
//contact
export const FETCH_CONTACT_TICKETS_PENDING = "FETCH_CONTACT_TICKETS_PENDING";
export const FETCH_CONTACT_TICKETS_SUCCESS = "FETCH_CONTACT_TICKETS_SUCCESS";
export const FETCH_CONTACT_TICKETS_FAILURE = "FETCH_CONTACT_TICKETS_FAILURE";
//department
export const FETCH_DEPARTMENT_TICKETS_PENDING =
    "FETCH_DEPARTMENT_TICKETS_PENDING";
export const FETCH_DEPARTMENT_TICKETS_SUCCESS =
    "FETCH_DEPARTMENT_TICKETS_SUCCESS";
export const FETCH_DEPARTMENT_TICKETS_FAILURE =
    "FETCH_DEPARTMENT_TICKETS_FAILURE";
//ticket task
export const FETCH_TASKS_TICKET_PENDING = "FETCH_TASKS_TICKET_PENDING";
export const FETCH_TASKS_TICKET_SUCCESS = "FETCH_TASKS_TICKET_SUCCESS";
export const FETCH_TASKS_TICKET_FAILURE = "FETCH_TASKS_TICKET_FAILURE";
//get reminder ticket
export const FETCH_REMINDER_TICKET_PENDING = "FETCH_REMINDER_TICKET_PENDING";
export const FETCH_REMINDER_TICKET_SUCCESS = "FETCH_REMINDER_TICKET_SUCCESS";
export const FETCH_REMINDER_TICKET_FAILURE = "FETCH_REMINDER_TICKET_FAILURE";
//add ticket reminder
export const ADD_TICKET_REMINDER_PENDING = "ADD_TICKET_REMINDER_PENDING";
export const ADD_TICKET_REMINDER_SUCCESS = "ADD_TICKET_REMINDER_SUCCESS";
export const ADD_TICKET_REMINDER_FAILURE = "ADD_TICKET_REMINDER_FAILURE";
//add note in ticket
export const ADD_TICKET_NOTE_PENDING = "ADD_TICKET_NOTE_PENDING";
export const ADD_TICKET_NOTE_SUCCESS = "ADD_TICKET_NOTE_SUCCESS";
export const ADD_TICKET_NOTE_FAILURE = "ADD_TICKET_NOTE_FAILURE";
//add note in ticket
export const GET_TICKET_NOTE_PENDING = "GET_TICKET_NOTE_PENDING";
export const GET_TICKET_NOTE_SUCCESS = "GET_TICKET_NOTE_SUCCESS";
export const GET_TICKET_NOTE_FAILURE = "GET_TICKET_NOTE_FAILURE";
//filter task in ticket
export const FILTER_TASK_IN_TICKET_PENDING = "FILTER_TASK_IN_TICKET_PENDING";
export const FILTER_TASK_IN_TICKET_SUCCESS = "FILTER_TASK_IN_TICKET_SUCCESS";
export const FILTER_TASK_IN_TICKET_FAILURE = "FILTER_TASK_IN_TICKET_FAILURE";
