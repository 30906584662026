// @flow
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
    // GET_LIST_COUNTRY,
    GET_LIST_CURRENCY,
    GET_LIST_SOURCE,
    GET_LIST_TAGS,
    GET_LIST_STATUS,
    GET_LIST_STAFF,
    GET_LIST_ITEM,
    GET_LIST_TAX,
    GET_LIST_ITEM_GROUP,
    ADD_ITEM_TABLE,
    enqueueSnackbar,
    // getListSourceSuccess,
    // getListSourceFailed,
    getListTagsSuccess,
    getListTagsFailed,
    getListCountryFailed,
    getListCountrySuccess,
    getListCurrencyFailed,
    getListCurrencySuccess,
    getListStatusSuccess,
    getListStatusFailed,
    getListStaffSuccess,
    getListStaffFailed,
    getListItemSuccess,
    getListItemFailed,
    getListTaxSuccess,
    getListTaxFailed,
    getListItemGroupSuccess,
    getListItemGroupFailed,
    addItemTableSuccess,
    addItemTableFailed,
    getListContactSuccess,
    getListContactFailed,
    GET_LIST_CONTACT_PENDING,
} from "./CommonReducer";
import {
    // getListCreatedBy,
    // getListSource,
    getListTags,
    getListCountries,
    getListCurrency,
    getListStatus,
    getListStaff,
    getListItem,
    getListTax,
    getListItemGroup,
    addItemTable,
    getListContact,
} from "./CommonApi";
import { responeCode, typeMessage } from "../constants/Const";
import {
    throwErrorMessage,
    parseDataNotifications,
} from "../helpers/HandleError";

// function* getListCreatedBySaga() {

//     try {
//         const response = yield call(getListCreatedBy);

//         if (response && response.data && response.data.error_code === responeCode.success) {
//             yield put(getListCreatedBySuccess(response.data.result));
//         }
//         else {
//             yield put(getListCreatedByFailed());
//             yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
//         }

//     } catch (error) {
//         yield put(getListCreatedByFailed());
//         yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
//     }
// };

// function* getListSourceSaga() {
//   try {
//     const response = yield call(getListSource);

//     if (
//       response &&
//       response.data &&
//       response.data.error_code === responeCode.success
//     ) {
//       yield put(getListSourceSuccess(response.data.result));
//     } else {
//       yield put(getListSourceFailed());
//       yield put(
//         enqueueSnackbar(
//           parseDataNotifications(response.data.error_mess, typeMessage.error)
//         )
//       );
//     }
//   } catch (error) {
//     yield put(getListSourceFailed());
//     yield put(
//       enqueueSnackbar(
//         parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
//       )
//     );
//   }
// }

function* getListTagsSaga() {
    try {
        const response = yield call(getListTags);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListTagsSuccess(response.data.result));
        } else {
            yield put(getListTagsFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListTagsFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* getListContactsSaga() {
    try {
        const response = yield call(getListContact);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListContactSuccess(response.data.result));
        } else {
            yield put(getListContactFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListContactFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getListTaxSaga() {
    try {
        const response = yield call(getListTax);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListTaxSuccess(response.data.result));
        } else {
            yield put(getListTaxFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListTaxFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getListCountriesSaga() {
    try {
        const response = yield call(getListCountries);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListCountrySuccess(response.data.result));
        } else {
            yield put(getListCountryFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListCountryFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getListCurrencySaga() {
    try {
        const response = yield call(getListCurrency);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListCurrencySuccess(response.data.result));
        } else {
            yield put(getListCurrencyFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListCurrencyFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getListStatusSaga() {
    try {
        const response = yield call(getListStatus);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListStatusSuccess(response.data.result));
        } else {
            yield put(getListStatusFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListStatusFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getListStaffSaga() {
    try {
        const response = yield call(getListStaff);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListStaffSuccess(response.data.result));
        } else {
            yield put(getListStaffFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListStaffFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getListItemSaga() {
    try {
        const response = yield call(getListItem);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListItemSuccess(response.data.result));
        } else {
            yield put(getListItemFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListItemFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* getListItemGroupSaga() {
    try {
        const response = yield call(getListItemGroup);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListItemGroupSuccess(response.data.result));
        } else {
            yield put(getListItemGroupFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListItemGroupFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* addItemTableSaga(action) {
    try {
        const response = yield call(addItemTable, action.data);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(addItemTableSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(addItemTableFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(addItemTableFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
export function* commonSaga() {
    // yield takeEvery(FETCH_LIST_LEADS, getListCreatedBySaga);
    yield takeEvery(GET_LIST_STATUS, getListStatusSaga);
    // yield takeEvery(GET_LIST_COUNTRY, getListSourceSaga);
    yield takeEvery(GET_LIST_TAGS, getListTagsSaga);
    yield takeEvery(GET_LIST_TAX, getListTaxSaga);
    yield takeEvery(GET_LIST_ITEM_GROUP, getListItemGroupSaga);
    yield takeEvery(GET_LIST_SOURCE, getListCountriesSaga);
    yield takeEvery(GET_LIST_CURRENCY, getListCurrencySaga);
    yield takeEvery(GET_LIST_STAFF, getListStaffSaga);
    yield takeEvery(GET_LIST_ITEM, getListItemSaga);
    yield takeEvery(ADD_ITEM_TABLE, addItemTableSaga);
    yield takeEvery(GET_LIST_CONTACT_PENDING, getListContactsSaga);
}

function* CommonSaga() {
    yield all([fork(commonSaga)]);
}

export default CommonSaga;
