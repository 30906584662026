import React from "react";
import { Redirect } from "react-router-dom";
import { isUserAuthenticated, getLoggedInUser } from "../helpers/authUtils.js";
import i18n from "../i18n.js";
import { customer, lead, project, task, store } from "../constants/Route.js";
import { PrivateRoute } from "./PrivateRoute.js";
import { salesRoute } from "./salesRoute.js";
import { administratorsRoute } from "./administratorRoute.js";
import { customersRoute } from "./customersRoute.js";
import { projectsRoute } from "./projectsRoute.js";
import { authsRoute } from "./authsRoute.js";
import { supportRoute } from "./supportsRoute.js";
import { tasksRoute } from "./tasksRoute.js";
// import TaxRate from "./pages/Administrator/Finance/TaxRate";
// import CustomerNotes from "./pages/Customer/CustomerNotes/CustomerNotes";
// import CustomerInvoices from "./pages/Customer/CustomerInvoices";

// lazy load all the views
const Dashboard = React.lazy(() => import("../pages/Dashboard/index.js"));

const NewCreditNote = React.lazy(() => import("../pages/CreditNote/NewCreditNotes/index.js"));
const EditCreditNote = React.lazy(() => import("../pages/CreditNote/EditCreditNotes/index.js"));

//customer expenses
const CustomerExpenses = React.lazy(() => import("../pages/Customer/Expenses/index.js"));
const CustomerNewExpense = React.lazy(() => import("../pages/Customer/Expenses/RecordExpense.js"));

const ExpenseView = React.lazy(() => import("../pages/Expense/View/index.js"));
// const ExpenseNew = React.lazy(() => import("./pages/Expense/NewExpense.js"));

//sales

const ViewEstimate = React.lazy(() => import("../pages/Estimates/View/index.js"));

const RecurringInvoice = React.lazy(() => import("../pages/Sales/Invoice/Recurring.js"));

const routes = [
    // auth and account
    ...authsRoute,
    // other pages
    {
        path: "/dashboard",
        name: `${i18n.t("route.dashboard")}`,
        component: Dashboard,
        route: PrivateRoute,
        roles: ["Admin", "MENU"],
        title: "Dashboard",
    },
    {
        path: "/",
        exact: true,
        roles: ["Admin", "Redirect"],
        component: () => <Redirect to="/dashboard" />,
        route: PrivateRoute,
    },
    {
        path: "/invoices/recurring",
        name: "invoices_recurring",
        component: RecurringInvoice,
        route: PrivateRoute,
        roles: ["Admin", "view"],
        title: `${i18n.t("route.invoices")}`,
        exact: true,
    },
    {
        path: "/estimate/view",
        name: `${i18n.t("route.estimates")}`,
        component: ViewEstimate,
        route: PrivateRoute,
        roles: ["Admin", "view"],
        title: `${i18n.t("route.estimates")}`,
        exact: true,
    },
    
    {
        path: "/expense/new",
        name: "expense_new",
        component: CustomerNewExpense,
        route: PrivateRoute,
        roles: ["Admin", "view"],
        title: `${i18n.t("route.invoices")}`,
        exact: true,
    },
    {
        path: "/credit_notes/new",
        name: `New Credit_Note`,
        component: NewCreditNote,
        route: PrivateRoute,
        roles: ["Admin", "credit_note"],
        title: "New Credit_Note",
        exact: true,
    },
    {
        path: "/credit_notes/edit/:id",
        name: `Edit_Credit_Note`,
        component: EditCreditNote,
        route: PrivateRoute,
        roles: ["Admin", "credit_note"],
        title: "Edit_Credit_Note",
        exact: true,
    },
    {
        path: "/customer/expenses/:id",
        name: `Customer Expenses`,
        component: CustomerExpenses,
        route: PrivateRoute,
        roles: ["Admin", "expenses"],
        title: "Customer Expenses",
        exact: true,
    },
    {
        path: "/expense/view/:id",
        name: `View Expense`,
        component: ExpenseView,
        route: PrivateRoute,
        roles: ["Admin", "expense"],
        title: "View Expense",
        exact: true,
    },
    
    
    ////////////////////////////////////////////////////////////
    
    ...projectsRoute,
    ...tasksRoute,
    ...customersRoute,
    ...salesRoute,
    ...supportRoute,
    ...administratorsRoute,
];
export { routes, PrivateRoute };
