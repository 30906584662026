import ActionTypes from "./types";

export const fetchListEstimateSaleRequest = (value, params) => {
  return {
    type: ActionTypes.FETCH_ESTIMATES_SALE_REQUEST,
    value,
    params,
  };
};

export const fetchListEstimateSaleSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_ESTIMATES_SALE_SUCCESS,
    payload: data,
  };
};

export const fetchListEstimateSaleFailed = () => {
  return {
    type: ActionTypes.FETCH_ESTIMATES_SALE_FAILED,
  };
};

export const listSelectCustomerRequest = (value) => {
  return {
    type: ActionTypes.LIST_SELECT_CUSTOMER_REQUEST,
    value,
  };
};
export const listSelectCustomerSuccess = (value) => {
  return {
    type: ActionTypes.LIST_SELECT_CUSTOMER_SUCCESS,
    value,
  };
};
export const listSelectCustomerFailed = (value) => {
  return {
    type: ActionTypes.LIST_SELECT_CUSTOMER_FAILED,
    value,
  };
};

export const getCustomFieldEstimateRequest = () => {
  return {
    type: ActionTypes.GET_CUSTOM_FIELD_REQUEST,
  };
};
export const getCustomFieldEstimateSuccess = (data) => {
  return {
    type: ActionTypes.GET_CUSTOM_FIELD_SUCCESS,
    data,
  };
};
export const getCustomFieldEstimateFailed = () => {
  return {
    type: ActionTypes.GET_CUSTOM_FIELD_REQUEST_FAILED,
  };
};
