import _ from "lodash";
import apiBase from "../../../common/baseAPI";
import fileDownload from "js-file-download";
const PROJECTS_URL = "project";
const TIMESHEET_PROJECT_URL = "task/timer";
const TASK_PROJECT_TIMESHEET_URL = "task/project";
const DISCUSSION_URL = "project/discussions";
const SUBSCRIPTIONS_PROJECT_URL = "subscription/project";
const TICKETS_PROJECT_URL = "ticket/project";
const MILESTONES_URL = "project/milestone";
const SUMMARY_PROJECT = "project/count/status";
const URL_TAG = "tag";
const OVERVIEW_OPENTASK = "project/task";
const OVERVIEW_DAYLEFT = "project/count";
const STAFF_URL = "staff";
const PROJECT_FILE_URL = "file/project";
const EDIT_PROJECT_FILE_URL = "file/changeVisibleToCustomer";
const URL_SERVICE = "service";
const URL_DEPARTMENT = "department";
const URL_PREDEFINED = "predefinedReplies";
const URL_PRIORITY = "ticketsPriority";
const URL_CONTACTS = "project/contact";
const URL_CUSTOM_FIELDS = "customField/fieldto/ticket";
const URL_KNOWLEDGEBASE = "knowledgeBase";
const TICKET_SUMMARY_URL = "ticket/project/count";
const URL_CHART_LOG_TIME = "task-timer/project/chartLog";

const getListProjects = (data) => {
  const newData = _.pickBy(data, _.identity); // remove value null , undefind , '', 0
  let newUrl = PROJECTS_URL;
  Object.keys(newData).map(
    (item, index) =>
      (newUrl = `${newUrl}${index === 0 ? "?" : ""}${
        data[item]
          ? `&${
              item === "status"
                ? item + "=[" + data[item] + "]"
                : item + "=" + data[item]
            }`
          : ""
      }`)
  );

  return new Promise((resolve, reject) => {
    return apiBase
      .get(newUrl)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const getProjectById = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${PROJECTS_URL}/${data}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const getTimesheetProject = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${TIMESHEET_PROJECT_URL}/${data.id}?limit=${data.limit}&&page=${data.page}&&search=${data.search}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const deleteTimesheetProject = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .delete(`${TIMESHEET_PROJECT_URL}/${data}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const getTaskByProjectTimesheet = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${TASK_PROJECT_TIMESHEET_URL}/${data}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const getStaffByTaskTimesheet = ({ projectId, taskId }) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`/task/staff/${projectId}/${taskId}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const createTimesheetProject = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .post(`${TIMESHEET_PROJECT_URL}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const editTimesheetProject = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .put(`${TIMESHEET_PROJECT_URL}/${data.id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const filterByMemberTimesheetProject = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${TIMESHEET_PROJECT_URL}/${data.id}?staff=[${data.staff}]&&limit=15&&search=&&page=1`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const getDiscussionProject = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${DISCUSSION_URL}/${data}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const createDiscussionProject = (data, id) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .post(`${DISCUSSION_URL}/${id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const deleteDiscussionProject = (id) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .delete(`${DISCUSSION_URL}/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const updateDiscussionProject = (params) => {
  let { data, id } = params;
  return new Promise((resolve, reject) => {
    return apiBase
      .put(`${DISCUSSION_URL}/${id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const filterDiscussionProject = (name, productId) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${DISCUSSION_URL}/${productId}?subject=${name}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const getSubscriptionsProject = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${SUBSCRIPTIONS_PROJECT_URL}/${data.id}?search=${data.search}&&limit=${data.limit}&&page=${data.page}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const getTicketsProject = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${TICKETS_PROJECT_URL}/${data.id}?status=${data.status}&&search=${data.search}&&limit=${data.limit}&&page=${data.page}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const filterByStatusTicketProject = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${TICKETS_PROJECT_URL}/${data.id}?status=${data.status}&&limit=${data.limit}&&page=${data.page}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const getProjectMilestone = (id) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${MILESTONES_URL}/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const createProjectMilestone = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .post(`${MILESTONES_URL}/${action.projectId}`, action.data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const editProjectMilestone = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .put(`${MILESTONES_URL}/${data.id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const deleteProjectMilestone = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .delete(`${MILESTONES_URL}/${data}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const fetchProjectMilestone = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${MILESTONES_URL}/${action.projectId}?search=${action.params.search}&limit=${action.params.limit}&page=${action.params.page}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const getProjectTag = () => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(URL_TAG)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const getProjectOverViewTask = (projectId) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${OVERVIEW_OPENTASK}/${projectId}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const getProjectOverViewCount = (projectId) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${OVERVIEW_DAYLEFT}/${projectId}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const getProjectSummary = () => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(SUMMARY_PROJECT)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

//PROJECT

const createProject = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .post(PROJECTS_URL, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const editProject = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .put(`${PROJECTS_URL}/${data.id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const deleteProject = (id) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .delete(`${PROJECTS_URL}/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const copyProject = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .post(`${PROJECTS_URL}/copy/${action.projectId}`, action.data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
//get staff new project
const getStaffProject = () => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(STAFF_URL)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

//project files
const createProjectFiles = (id, data) => {
  const newFormData = new FormData();
  newFormData.append("file_name", data.file_name);
  newFormData.append("visible_to_customer", data.visible_to_customer);
  const header = { "Content-Type": "multipart/form-data" };
  return new Promise((resolve, reject) => {
    return apiBase
      .post(`${PROJECT_FILE_URL}/${id}`, newFormData, header)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const changeProjectFilesVisible = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .put(`${EDIT_PROJECT_FILE_URL}/${data.id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const deleteProjectFiles = (id) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .delete(`project/files/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const getProjectFiles = (id) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${PROJECT_FILE_URL}/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const searchProjectFiles = (projectId, params) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${PROJECT_FILE_URL}/${projectId}?search=${params.search}&limit=${params.limit}&page=${params.page}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const getProjectNewTicketService = () => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${URL_SERVICE}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const getProjectNewTicketDepartment = () => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${URL_DEPARTMENT}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const getProjectNewTicketPredefined = () => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${URL_PREDEFINED}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const getProjectNewTicketPriority = () => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${URL_PRIORITY}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const getProjectNewTicketContacts = (projectId) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${URL_CONTACTS}/${projectId}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const getProjectNewTicketCustomField = () => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(URL_CUSTOM_FIELDS)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const getProjectNewTicketKnowledgebase = () => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(URL_KNOWLEDGEBASE)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const createProjectNewTicket = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .post(`${TICKETS_PROJECT_URL}/${action.projectId}`, action.ticket)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const deleteProjectTicket = (id) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .delete(`${PROJECTS_URL}/tickets/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const getProjectTicketSummary = (id) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${TICKET_SUMMARY_URL}/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const addProjectMember = (data) => {
  const { projectId, members } = data;

  const params = {
    members: members,
  };
  return new Promise((resolve, reject) => {
    return apiBase
      .post(`${PROJECTS_URL}/${STAFF_URL}/${projectId}`, params)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const deleteProjectMemberApi = (data) => {
  const { projectId, staffId } = data;

  return new Promise((resolve, reject) => {
    return apiBase
      .delete(`${PROJECTS_URL}/${STAFF_URL}/${projectId}/${staffId}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const getStaffsOfProject = ({ projectId, taskId }) => {
  // task/staff/{project_id}/{task_id}
  return new Promise((resolve, reject) => {
    return apiBase

      .get(`/task/staff/${projectId}/${taskId}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const downloadFileOfProject = (data) => {
  // file/project/download/{file_id}
  const { fileId, fileName } = data;
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`file/project/download/${fileId}`, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, fileName);
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

const createCustomerProject = (data) => {
  const { clientId, body } = data;

  return new Promise((resolve, reject) => {
    return apiBase
      .post(`project/customer/${clientId}`, body)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getChartLogTimePrj = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${URL_CHART_LOG_TIME}/${data}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export {
  downloadFileOfProject,
  getStaffsOfProject,
  deleteProjectMemberApi,
  addProjectMember,
  getListProjects,
  getProjectById,
  getTimesheetProject,
  deleteTimesheetProject,
  getTaskByProjectTimesheet,
  getStaffByTaskTimesheet,
  createTimesheetProject,
  editTimesheetProject,
  filterByMemberTimesheetProject,
  getDiscussionProject,
  createDiscussionProject,
  deleteDiscussionProject,
  updateDiscussionProject,
  filterDiscussionProject,
  getSubscriptionsProject,
  getTicketsProject,
  filterByStatusTicketProject,
  getProjectMilestone,
  createProjectMilestone,
  fetchProjectMilestone,
  deleteProjectMilestone,
  editProjectMilestone,
  getProjectTag,
  getProjectOverViewTask,
  getProjectSummary,
  getProjectOverViewCount,
  createProject,
  editProject,
  deleteProject,
  getStaffProject,
  copyProject,
  createProjectFiles,
  getProjectFiles,
  changeProjectFilesVisible,
  deleteProjectFiles,
  searchProjectFiles,
  getProjectNewTicketPriority,
  getProjectNewTicketPredefined,
  getProjectNewTicketDepartment,
  getProjectNewTicketService,
  createProjectNewTicket,
  getProjectNewTicketContacts,
  getProjectNewTicketCustomField,
  getProjectNewTicketKnowledgebase,
  deleteProjectTicket,
  getProjectTicketSummary,
  createCustomerProject,
};
