import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import { isUserAuthenticated, getLoggedInUser } from "../helpers/authUtils";

export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            const isAuthTokenValid = isUserAuthenticated();
            if (!isAuthTokenValid) {
                // not logged in so redirect to login page with the return url
                return (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                );
            }

            const loggedInUser = getLoggedInUser();

            
            // i18n.use(initReactI18next).init({
            //     resources,
            //     fallbackLng,
            //     detection: {
            //     checkWhitelist: true,
            //     },
            //     debug: false,
            //     whitelist: availableLanguages,
            //     interpolation: {
            //     escapeValue: false,
            //     },
            // });

            // check if route is restricted by role
            if (roles && !roles.includes(loggedInUser.role)) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: "/" }} />;
            }
            // authorised so return component
            return <Component {...props} />;
        }}
    />
);