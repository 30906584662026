const ActionTypes = {
    //Finance-Tax Rate
    CREATE_FINANCE_TAX_RATE_REQUEST: 'CREATE_FINANCE_TAX_RATE_REQUEST',
    CREATE_FINANCE_TAX_RATE_FAILED: 'CREATE_FINANCE_TAX_RATE_FAILED',
    CREATE_FINANCE_TAX_RATE_SUCCESS: 'CREATE_FINANCE_TAX_RATE_SUCCESS',
    EDIT_FINANCE_TAX_RATE_REQUEST: 'EDIT_FINANCE_TAX_RATE_REQUEST',
    EDIT_FINANCE_TAX_RATE_FAILED: 'EDIT_FINANCE_TAX_RATE_FAILED',
    EDIT_FINANCE_TAX_RATE_SUCCESS: 'EDIT_FINANCE_TAX_RATE_SUCCESS',
    DELETE_FINANCE_TAX_RATE_REQUEST: 'DELETE_FINANCE_TAX_RATE_REQUEST',
    DELETE_FINANCE_TAX_RATE_FAILED: 'DELETE_FINANCE_TAX_RATE_FAILED',
    DELETE_FINANCE_TAX_RATE_SUCCESS: 'DELETE_FINANCE_TAX_RATE_SUCCESS',
    FETCH_FINANCE_TAX_RATE_REQUEST: 'FETCH_FINANCE_TAX_RATE_REQUEST',
    FETCH_FINANCE_TAX_RATE_FAILED: 'FETCH_FINANCE_TAX_RATE_FAILED',
    FETCH_FINANCE_TAX_RATE_SUCCESS: 'FETCH_FINANCE_TAX_RATE_SUCCESS',

    //Finance-curency

    CREATE_FINANCE_CURRENCY_REQUEST: 'CREATE_FINANCE_CURRENCY_REQUEST',
    CREATE_FINANCE_CURRENCY_FAILED: 'CREATE_FINANCE_CURRENCY_FAILED',
    CREATE_FINANCE_CURRENCY_SUCCESS: 'CREATE_FINANCE_CURRENCY_SUCCESS',
    EDIT_FINANCE_CURRENCY_REQUEST: 'EDIT_FINANCE_CURRENCY_REQUEST',
    EDIT_FINANCE_CURRENCY_FAILED: 'EDIT_FINANCE_CURRENCY_FAILED',
    EDIT_FINANCE_CURRENCY_SUCCESS: 'EDIT_FINANCE_CURRENCY_SUCCESS',
    DELETE_FINANCE_CURRENCY_REQUEST: 'DELETE_FINANCE_CURRENCY_REQUEST',
    DELETE_FINANCE_CURRENCY_FAILED: 'DELETE_FINANCE_CURRENCY_FAILED',
    DELETE_FINANCE_CURRENCY_SUCCESS: 'DELETE_FINANCE_CURRENCY_SUCCESS',
    FETCH_FINANCE_CURRENCY_REQUEST: 'FETCH_FINANCE_CURRENCY_REQUEST',
    FETCH_FINANCE_CURRENCY_FAILED: 'FETCH_FINANCE_CURRENCY_FAILED',
    FETCH_FINANCE_CURRENCY_SUCCESS: 'FETCH_FINANCE_CURRENCY_SUCCESS',

    //Finance-PaymentMode

    CREATE_FINANCE_PAYMENT_MODE_REQUEST: 'CREATE_FINANCE_PAYMENT_MODE_REQUEST',
    CREATE_FINANCE_PAYMENT_MODE_FAILED: 'CREATE_FINANCE_PAYMENT_MODE_FAILED',
    CREATE_FINANCE_PAYMENT_MODE_SUCCESS: 'CREATE_FINANCE_PAYMENT_MODE_SUCCESS',
    EDIT_FINANCE_PAYMENT_MODE_REQUEST: 'EDIT_FINANCE_PAYMENT_MODE_REQUEST',
    EDIT_FINANCE_PAYMENT_MODE_FAILED: 'EDIT_FINANCE_PAYMENT_MODE_FAILED',
    EDIT_FINANCE_PAYMENT_MODE_SUCCESS: 'EDIT_FINANCE_PAYMENT_MODE_SUCCESS',
    DELETE_FINANCE_PAYMENT_MODE_REQUEST: 'DELETE_FINANCE_PAYMENT_MODE_REQUEST',
    DELETE_FINANCE_PAYMENT_MODE_FAILED: 'DELETE_FINANCE_PAYMENT_MODE_FAILED',
    DELETE_FINANCE_PAYMENT_MODE_SUCCESS: 'DELETE_FINANCE_PAYMENT_MODE_SUCCESS',
    FETCH_FINANCE_PAYMENT_MODE_REQUEST: 'FETCH_FINANCE_PAYMENT_MODE_REQUEST',
    FETCH_FINANCE_PAYMENT_MODE_FAILED: 'FETCH_FINANCE_PAYMENT_MODE_FAILED',
    FETCH_FINANCE_PAYMENT_MODE_SUCCESS: 'FETCH_FINANCE_PAYMENT_MODE_SUCCESS',
    CHANGE_ACTIVE_STATUS_PAYMENT_MODE_REQUEST: "CHANGE_ACTIVE_STATUS_PAYMENT_MODE_REQUEST",
    CHANGE_ACTIVE_STATUS_PAYMENT_MODE_REQUEST: "CHANGE_ACTIVE_STATUS_PAYMENT_MODE_REQUEST",
    CHANGE_ACTIVE_STATUS_PAYMENT_MODE_SUCCESS: "CHANGE_ACTIVE_STATUS_PAYMENT_MODE_SUCCESS",
    CHANGE_ACTIVE_STATUS_PAYMENT_MODE_FAILED: "CHANGE_ACTIVE_STATUS_PAYMENT_MODE_FAILED",

    //Finance- Expense

    CREATE_FINANCE_EXPENSES_REQUEST: 'CREATE_FINANCE_EXPENSES_REQUEST',
    CREATE_FINANCE_EXPENSES_FAILED: 'CREATE_FINANCE_EXPENSES_FAILED',
    CREATE_FINANCE_EXPENSES_SUCCESS: 'CREATE_FINANCE_EXPENSES_SUCCESS',
    EDIT_FINANCE_EXPENSES_REQUEST: 'EDIT_FINANCE_EXPENSES_REQUEST',
    EDIT_FINANCE_EXPENSES_FAILED: 'EDIT_FINANCE_EXPENSES_FAILED',
    EDIT_FINANCE_EXPENSES_SUCCESS: 'EDIT_FINANCE_EXPENSES_SUCCESS',
    DELETE_FINANCE_EXPENSES_REQUEST: 'DELETE_FINANCE_EXPENSES_REQUEST',
    DELETE_FINANCE_EXPENSES_FAILED: 'DELETE_FINANCE_EXPENSES_FAILED',
    DELETE_FINANCE_EXPENSES_SUCCESS: 'DELETE_FINANCE_EXPENSES_SUCCESS',
    FETCH_FINANCE_EXPENSES_REQUEST: 'FETCH_FINANCE_EXPENSES_REQUEST',
    FETCH_FINANCE_EXPENSES_FAILED: 'FETCH_FINANCE_EXPENSES_FAILED',
    FETCH_FINANCE_EXPENSES_SUCCESS: 'FETCH_FINANCE_EXPENSES_SUCCESS',

    CHANGE_STATUS_DIALOG_FINANCE: 'CHANGE_STATUS_DIALOG_FINANCE',
}
export default ActionTypes;
