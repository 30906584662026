import {
	FETCH_LIST_LEAD_SOURCE,
	FETCH_LIST_LEAD_SOURCE_SUCCESS,
	FETCH_LIST_LEAD_SOURCE_FAILED,
	FETCH_CREATE_LEAD_SOURCE,
	FETCH_CREATE_LEAD_SOURCE_SUCCESS,
	FETCH_CREATE_LEAD_SOURCE_FAILED,
	FETCH_EDIT_LEAD_SOURCE,
	FETCH_EDIT_LEAD_SOURCE_SUCCESS,
	FETCH_EDIT_LEAD_SOURCE_FAILED,
	FETCH_DELETE_LEAD_SOURCE,
	FETCH_DELETE_LEAD_SOURCE_SUCCESS,
	FETCH_DELETE_LEAD_SOURCE_FAILED,
	FETCH_LIST_LEAD_STATUS,
	FETCH_LIST_LEAD_STATUS_SUCCESS,
	FETCH_LIST_LEAD_STATUS_FAILED,
	FETCH_CREATE_LEAD_STATUS,
	FETCH_CREATE_LEAD_STATUS_SUCCESS,
	FETCH_CREATE_LEAD_STATUS_FAILED,
	FETCH_EDIT_LEAD_STATUS,
	FETCH_EDIT_LEAD_STATUS_SUCCESS,
	FETCH_EDIT_LEAD_STATUS_FAILED,
	FETCH_DELETE_LEAD_STATUS,
	FETCH_DELETE_LEAD_STATUS_SUCCESS,
	FETCH_DELETE_LEAD_STATUS_FAILED,
	FETCH_LEAD_EMAIL_INTEGRATION,
	FETCH_LEAD_EMAIL_INTEGRATION_SUCCESS,
	FETCH_LEAD_EMAIL_INTEGRATION_FAILED,
	FETCH_CREATE_LEAD_EMAIL_INTEGRATION,
	FETCH_CREATE_LEAD_EMAIL_INTEGRATION_SUCCESS,
	FETCH_CREATE_LEAD_EMAIL_INTEGRATION_FAILED,
	FETCH_EDIT_LEAD_EMAIL_INTEGRATION,
	FETCH_EDIT_LEAD_EMAIL_INTEGRATION_SUCCESS,
	FETCH_EDIT_LEAD_EMAIL_INTEGRATION_FAILED,
	FETCH_DELETE_LEAD_EMAIL_INTEGRATION,
	FETCH_DELETE_LEAD_EMAIL_INTEGRATION_SUCCESS,
	FETCH_DELETE_LEAD_EMAIL_INTEGRATION_FAILED,
	FETCH_LIST_RESPONSIBLE,
	FETCH_LIST_RESPONSIBLE_SUCCESS,
	FETCH_LIST_RESPONSIBLE_FAILED,
	FETCH_LIST_WEB_LEAD,
	FETCH_LIST_WEB_LEAD_SUCCESS,
	FETCH_LIST_WEB_LEAD_FAILED,
	FETCH_WEB_LEAD_BY_ID,
	FETCH_WEB_LEAD_BY_ID_SUCCESS,
	FETCH_WEB_LEAD_BY_ID_FAILED,
	FETCH_CREATE_WEB_LEAD,
	FETCH_CREATE_WEB_LEAD_SUCCESS,
	FETCH_CREATE_WEB_LEAD_FAILED,
	FETCH_EDIT_WEB_LEAD,
	FETCH_EDIT_WEB_LEAD_SUCCESS,
	FETCH_EDIT_WEB_LEAD_FAILED,
	FETCH_DELETE_WEB_LEAD,
	FETCH_DELETE_WEB_LEAD_SUCCESS,
	FETCH_DELETE_WEB_LEAD_FAILED,
	SET_LEAD_SOURCE,
	SET_ISSUCCESS,
	SET_WEB_LEAD,
	GET_ALL_ADMIN_LEADS_SPAM_FILTER_REQUEST,
	GET_ALL_ADMIN_LEADS_SPAM_FILTER_FAILED,
	GET_ALL_ADMIN_LEADS_SPAM_FILTER_SUCCESS,
	CREATE_ADMIN_LEADS_SPAM_FILTER_REQUEST,
	CREATE_ADMIN_LEADS_SPAM_FILTER_FAILED,
	CREATE_ADMIN_LEADS_SPAM_FILTER_SUCCESS,
	EDIT_ADMIN_LEADS_SPAM_FILTER_REQUEST,
	EDIT_ADMIN_LEADS_SPAM_FILTER_FAILED,
	EDIT_ADMIN_LEADS_SPAM_FILTER_SUCCESS,
	DELETE_ADMIN_LEADS_SPAM_FILTER_REQUEST,
	DELETE_ADMIN_LEADS_SPAM_FILTER_FAILED,
	DELETE_ADMIN_LEADS_SPAM_FILTER_SUCCESS,
	FETCH_ADMIN_LEADS_SPAM_FILTER_REQUEST,
	FETCH_ADMIN_LEADS_SPAM_FILTER_FAILED,
	FETCH_ADMIN_LEADS_SPAM_FILTER_SUCCESS,
	CHANGE_STATUS_DIALOG,
	GET_COUNT_LEADS_SOURCE_REQUEST,
	GET_COUNT_LEADS_SOURCE_FAILED,
	GET_COUNT_LEADS_SOURCE_SUCCESS,
	GET_COUNT_LEADS_STATUS_REQUEST,
	GET_COUNT_LEADS_STATUS_FAILED,
	GET_COUNT_LEADS_STATUS_SUCCESS,
} from './types';

export const setWebLead = (data) => {
	return {
		type: SET_WEB_LEAD,
		payload: data
	}
};

export const setIssuccess = (data) => {
	return {
		type: SET_ISSUCCESS,
		payload: data
	}
};

export const setLeadSource = (data) => {
	return {
		type: SET_LEAD_SOURCE,
		payload: data
	}
};

export const fetchListLeadSource = (data) => {
	return {
		type: FETCH_LIST_LEAD_SOURCE,
		payload: data
	}
};

export const fetchListLeadSourceSuccess = (data) => {
	return {
		type: FETCH_LIST_LEAD_SOURCE_SUCCESS,
		payload: data
	}
};

export const fetchListLeadSourceFailed = () => {
	return {
		type: FETCH_LIST_LEAD_SOURCE_FAILED
	}
};

export const fetchCreateLeadSource = (data) => {
	return {
		type: FETCH_CREATE_LEAD_SOURCE,
		payload: data,
	}
};

export const fetchCreateLeadSourceSuccess = (data) => {
	return {
		type: FETCH_CREATE_LEAD_SOURCE_SUCCESS,
		payload: data
	}
};

export const fetchCreateLeadSourceFailed = () => {
	return {
		type: FETCH_CREATE_LEAD_SOURCE_FAILED
	}
};

export const fetchEditLeadSource = (data) => {
	return {
		type: FETCH_EDIT_LEAD_SOURCE,
		payload: data,
	}
};

export const fetchEditLeadSourceSuccess = (data) => {
	return {
		type: FETCH_EDIT_LEAD_SOURCE_SUCCESS,
		payload: data
	}
};

export const fetchEditLeadSourceFailed = () => {
	return {
		type: FETCH_EDIT_LEAD_SOURCE_FAILED
	}
};

export const fetchDeleteLeadSource = (data) => {
	return {
		type: FETCH_DELETE_LEAD_SOURCE,
		payload: data,
	}
};

export const fetchDeleteLeadSourceSuccess = (data) => {
	return {
		type: FETCH_DELETE_LEAD_SOURCE_SUCCESS,
		payload: data
	}
};

export const fetchDeleteLeadSourceFailed = () => {
	return {
		type: FETCH_DELETE_LEAD_SOURCE_FAILED
	}
};

export const fetchListLeadStatus = (data) => {
	return {
		type: FETCH_LIST_LEAD_STATUS,
		payload: data
	}
};

export const fetchListLeadStatusSuccess = (data) => {
	return {
		type: FETCH_LIST_LEAD_STATUS_SUCCESS,
		payload: data
	}
};

export const fetchListLeadStatusFailed = () => {
	return {
		type: FETCH_LIST_LEAD_STATUS_FAILED
	}
};

export const fetchCreateLeadStatus = (data) => {
	return {
		type: FETCH_CREATE_LEAD_STATUS,
		payload: data,
	}
};

export const fetchCreateLeadStatusSuccess = (data) => {
	return {
		type: FETCH_CREATE_LEAD_STATUS_SUCCESS,
		payload: data
	}
};

export const fetchCreateLeadStatusFailed = () => {
	return {
		type: FETCH_CREATE_LEAD_STATUS_FAILED
	}
};

export const fetchEditLeadStatus = (data) => {
	return {
		type: FETCH_EDIT_LEAD_STATUS,
		payload: data,
	}
};

export const fetchEditLeadStatusSuccess = (data) => {
	return {
		type: FETCH_EDIT_LEAD_STATUS_SUCCESS,
		payload: data
	}
};

export const fetchEditLeadStatusFailed = () => {
	return {
		type: FETCH_EDIT_LEAD_STATUS_FAILED
	}
};

export const fetchDeleteLeadStatus = (data) => {
	return {
		type: FETCH_DELETE_LEAD_STATUS,
		payload: data,
	}
};

export const fetchDeleteLeadStatusSuccess = (data) => {
	return {
		type: FETCH_DELETE_LEAD_STATUS_SUCCESS,
		payload: data
	}
};

export const fetchDeleteLeadStatusFailed = () => {
	return {
		type: FETCH_DELETE_LEAD_STATUS_FAILED
	}
};

export const fetchLeadEmailIntegration = (data) => {
	return {
		type: FETCH_LEAD_EMAIL_INTEGRATION,
		payload: data
	}
};

export const fetchLeadEmailIntegrationSuccess = (data) => {
	return {
		type: FETCH_LEAD_EMAIL_INTEGRATION_SUCCESS,
		payload: data
	}
};

export const fetchLeadEmailIntegrationFailed = () => {
	return {
		type: FETCH_LEAD_EMAIL_INTEGRATION_FAILED
	}
};

export const fetchCreateLeadEmailIntegration = (data) => {
	return {
		type: FETCH_CREATE_LEAD_EMAIL_INTEGRATION,
		payload: data,
	}
};

export const fetchCreateLeadEmailIntegrationSuccess = (data) => {
	return {
		type: FETCH_CREATE_LEAD_EMAIL_INTEGRATION_SUCCESS,
		payload: data
	}
};

export const fetchCreateLeadEmailIntegrationFailed = () => {
	return {
		type: FETCH_CREATE_LEAD_EMAIL_INTEGRATION_FAILED
	}
};

export const fetchEditLeadEmailIntegration = (data) => {
	return {
		type: FETCH_EDIT_LEAD_EMAIL_INTEGRATION,
		payload: data,
	}
};

export const fetchEditLeadEmailIntegrationSuccess = (data) => {
	return {
		type: FETCH_EDIT_LEAD_EMAIL_INTEGRATION_SUCCESS,
		payload: data
	}
};

export const fetchEditLeadEmailIntegrationFailed = () => {
	return {
		type: FETCH_EDIT_LEAD_EMAIL_INTEGRATION_FAILED
	}
};

export const fetchDeleteLeadEmailIntegration = (data) => {
	return {
		type: FETCH_DELETE_LEAD_EMAIL_INTEGRATION,
		payload: data,
	}
};

export const fetchDeleteLeadEmailIntegrationSuccess = (data) => {
	return {
		type: FETCH_DELETE_LEAD_EMAIL_INTEGRATION_SUCCESS,
		payload: data
	}
};

export const fetchDeleteLeadEmailIntegrationFailed = () => {
	return {
		type: FETCH_DELETE_LEAD_EMAIL_INTEGRATION_FAILED
	}
};

export const fetchListResponsible = (data) => {
	return {
		type: FETCH_LIST_RESPONSIBLE,
		payload: data
	}
};

export const fetchListResponsibleSuccess = (data) => {
	return {
		type: FETCH_LIST_RESPONSIBLE_SUCCESS,
		payload: data
	}
};

export const fetchListResponsibleFailed = () => {
	return {
		type: FETCH_LIST_RESPONSIBLE_FAILED
	}
};

export const fetchListWebLead = (data) => {
	return {
		type: FETCH_LIST_WEB_LEAD,
		payload: data
	}
};

export const fetchListWebLeadSuccess = (data) => {
	return {
		type: FETCH_LIST_WEB_LEAD_SUCCESS,
		payload: data
	}
};

export const fetchListWebLeadFailed = () => {
	return {
		type: FETCH_LIST_WEB_LEAD_FAILED
	}
};

export const fetchWebLeadById = (data) => {
	return {
		type: FETCH_WEB_LEAD_BY_ID,
		payload: data
	}
};

export const fetchWebLeadByIdSuccess = (data) => {
	return {
		type: FETCH_WEB_LEAD_BY_ID_SUCCESS,
		payload: data
	}
};

export const fetchWebLeadByIdFailed = () => {
	return {
		type: FETCH_WEB_LEAD_BY_ID_FAILED
	}
};

export const fetchCreateWebLead = (data) => {
	return {
		type: FETCH_CREATE_WEB_LEAD,
		payload: data,
	}
};

export const fetchCreateWebLeadSuccess = (data) => {
	return {
		type: FETCH_CREATE_WEB_LEAD_SUCCESS,
		payload: data
	}
};

export const fetchCreateWebLeadFailed = () => {
	return {
		type: FETCH_CREATE_WEB_LEAD_FAILED
	}
};

export const fetchEditWebLead = (data) => {
	return {
		type: FETCH_EDIT_WEB_LEAD,
		payload: data,
	}
};

export const fetchEditWebLeadSuccess = (data) => {
	return {
		type: FETCH_EDIT_WEB_LEAD_SUCCESS,
		payload: data
	}
};

export const fetchEditWebLeadFailed = () => {
	return {
		type: FETCH_EDIT_WEB_LEAD_FAILED
	}
};

export const fetchDeleteWebLead = (data) => {
	return {
		type: FETCH_DELETE_WEB_LEAD,
		payload: data,
	}
};

export const fetchDeleteWebLeadSuccess = (data) => {
	return {
		type: FETCH_DELETE_WEB_LEAD_SUCCESS,
		payload: data
	}
};

export const fetchDeleteWebLeadFailed = () => {
	return {
		type: FETCH_DELETE_WEB_LEAD_FAILED
	}
};

// action SpamFilter của Web to lead 

export const createAdminLeadsSpamFilterRequest = (data) => {
	return {
		type: CREATE_ADMIN_LEADS_SPAM_FILTER_REQUEST,
		payload: data,
	}
};

export const createAdminLeadsSpamFilterSuccess = (data) => {
	return {
		type: CREATE_ADMIN_LEADS_SPAM_FILTER_SUCCESS,
		payload: data
	}
};

export const createAdminLeadsSpamFilterFailed = () => {
	return {
		type: CREATE_ADMIN_LEADS_SPAM_FILTER_FAILED
	}
};

export const editAdminLeadsSpamFilterRequest = (data) => {
	return {
		type: EDIT_ADMIN_LEADS_SPAM_FILTER_REQUEST,
		payload: data,
	}
};

export const editAdminLeadsSpamFilterSuccess = (data) => {
	return {
		type: EDIT_ADMIN_LEADS_SPAM_FILTER_SUCCESS,
		payload: data
	}
};

export const editAdminLeadsSpamFilterFailed = () => {
	return {
		type: EDIT_ADMIN_LEADS_SPAM_FILTER_FAILED
	}
};

export const deleteAdminLeadsSpamFilterRequest = (data) => {
	return {
		type: DELETE_ADMIN_LEADS_SPAM_FILTER_REQUEST,
		payload: data,
	}
};

export const deleteAdminLeadsSpamFilterSuccess = (data) => {
	return {
		type: DELETE_ADMIN_LEADS_SPAM_FILTER_SUCCESS,
		payload: data
	}
};

export const deleteAdminLeadsSpamFilterFailed = () => {
	return {
		type: DELETE_ADMIN_LEADS_SPAM_FILTER_FAILED,
	}
};

export const changeStatusDialog = () => {
	return {
		type: CHANGE_STATUS_DIALOG,
	}
};

export const fetchAdminLeadsSpamFilterRequest = (search, params) => {
	return {
		type: FETCH_ADMIN_LEADS_SPAM_FILTER_REQUEST,
		search, params
	}
};

export const fetchAdminLeadsSpamFilterSuccess = (data) => {
	return {
		type: FETCH_ADMIN_LEADS_SPAM_FILTER_SUCCESS,
		payload: data
	}
};

export const fetchAdminLeadsSpamFilterFailed = () => {
	return {
		type: FETCH_ADMIN_LEADS_SPAM_FILTER_FAILED,
	}
};


//GET ALL SPAM FILTER 
export const getAllAdminLeadsSpamFilterRequest = () => {
	return {
		type: GET_ALL_ADMIN_LEADS_SPAM_FILTER_REQUEST

	}
};

export const getAllAdminLeadsSpamFilterSuccess = (data) => {
	return {
		type: GET_ALL_ADMIN_LEADS_SPAM_FILTER_SUCCESS,
		payload: data
	}
};

export const getAllAdminLeadsSpamFilterFailed = () => {
	return {
		type: GET_ALL_ADMIN_LEADS_SPAM_FILTER_FAILED,
	}
};


//Action get count leads source

export const getCountLeadsSourceRequest = () => {
	return {
		type: GET_COUNT_LEADS_SOURCE_REQUEST,
	}
};

export const getCountLeadsSourceSuccess = (data) => {
	return {
		type: GET_COUNT_LEADS_SOURCE_SUCCESS,
		payload: data
	}
};

export const getCountLeadsSourceFailed = () => {
	return {
		type: GET_COUNT_LEADS_SOURCE_FAILED,
	}
};

//action get count leads status
export const getCountLeadsStatusRequest = () => {
	return {
		type: GET_COUNT_LEADS_STATUS_REQUEST,
	}
};

export const getCountLeadsStatusSuccess = (data) => {
	return {
		type: GET_COUNT_LEADS_STATUS_SUCCESS,
		payload: data
	}
};

export const getCountLeadsStatusFailed = () => {
	return {
		type: GET_COUNT_LEADS_STATUS_FAILED,
	}
};






