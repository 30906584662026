import ActionTypes from './types';

const INIT_STATE = {
    listService: [],
    isCloseDialog: false,
    isLoading: false,
    deleteService: false
};

const AdminSupportServiceReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_LIST_SUPPORT_SERVICE_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case ActionTypes.GET_LIST_SUPPORT_SERVICE_SUCCESS:
            return {
                ...state,
                listService: action.payload,
                isLoading: false,
            };

        case ActionTypes.GET_LIST_SUPPORT_SERVICE_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.CREATE_SUPPORT_SERVICE_REQUEST:
            return {
                ...state,
                isLoading: true
            }

        case ActionTypes.CREATE_SUPPORT_SERVICE_SUCCESS:
            state.listService.data.unshift(action.payload)
            return {
                ...state,
                isCloseDialog: true,
                isLoading: false,
                listService: state.listService
            };

        case ActionTypes.CREATE_SUPPORT_SERVICE_FAILED:
            return {
                ...state,
                isLoading: false,
                isCloseDialog: true,
            };

        case ActionTypes.EDIT_SUPPORT_SERVICE_REQUEST:
            return {
                ...state,
                isLoading: true
            }

        case ActionTypes.EDIT_SUPPORT_SERVICE_SUCCESS:
            let itemIndex = state.listService.data.findIndex(item => item.serviceid === action.payload.serviceid)
            state.listService.data.splice(itemIndex, 1, action.payload)
            return {
                ...state,
                isCloseDialog: true,
                isLoading: false,
                listService: state.listService
            }

        case ActionTypes.EDIT_SUPPORT_SERVICE_FAILED:
            return {
                ...state,
                isLoading: false,
                isCloseDialog: true,
            };

        case ActionTypes.DELETE_SUPPORT_SERVICE_REQUEST:
            return {
                ...state,
                isLoading: true
            }

        case ActionTypes.DELETE_SUPPORT_SERVICE_SUCCESS:
            return {
                ...state,
                isCloseDialog: true,
                isLoading: false,
                deleteService: true
            };
        case ActionTypes.CHANGE_STATUS_DIALOG:
            return {
                ...state,
                isCloseDialog: false
            }

        case ActionTypes.DELETE_SUPPORT_SERVICE_FAILED:
            return {
                ...state,
                isLoading: false,
                isCloseDialog: true,
            };

        case ActionTypes.FETCH_SUPPORT_SERVICE_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case ActionTypes.FETCH_SUPPORT_SERVICE_SUCCESS:
            return {
                ...state,
                listService: action.payload,
                isLoading: false,
                deleteService: false
            };

        case ActionTypes.FETCH_SUPPORT_SERVICE_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        default: return { ...state };
    }
}

export default AdminSupportServiceReducer;