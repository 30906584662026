import _ from "lodash";
import apiBase from "../../../common/baseAPI";
import { ParseSimpleEndpoint } from "../../../helpers/ParseEndpoint";

const LEADS_URL = "lead";
const STATUS_URL = "lead-status";
const STAFF_URL = "staff";
const LEAD_SOURCE = "lead-source";
const COUNTRY_URL = "country";

const PROPOSAL_URL = "proposal";
const TASK_URL = "task";
const NOTE_URL = "note";
const ACTIVITY_LOG_URL = "lead-activity-log";
const CURRENCY_URL = "currencies";
const ITEMS_URL = "item";
const ITEM_GROUPS_URL = "item-group";
const PROPOSAL_LEAD_URL = "proposal/lead";
const REMINDER_LEAD_URL = "lead-reminder";
const FILE_LEAD_URL = "file";

const getListLeads = (data) => {
    const newData = _.pickBy(data, _.identity); // remove value null , undefind , '', 0
    let newUrl = LEADS_URL;

    if (data["status"] === []) {
        Object.keys(newData).map((item, index) => {
            return (newUrl = `${newUrl}${index === 0 ? "?" : ""}${
                data[item] ? `&${item}=${data[item]}` : ""
            }`);
        });
    } else {
        Object.keys(newData).map((item, index) => {
            return (newUrl = `${newUrl}${index === 0 ? "?" : ""}${
                item === "status"
                    ? `&${item}=[${data[item]}]`
                    : `&${item}=${data[item]}`
            }`);
        });
    }

    return new Promise((resolve, reject) => {
        return apiBase
            .get(newUrl)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListCurrency = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(CURRENCY_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createLead = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(LEADS_URL, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListStatus = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(STATUS_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const updateStatusLead = (data) => {
    const { status, id } = data;
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${LEADS_URL}/${id}/${status}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const updateLead = (id, data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${LEADS_URL}/${id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListCreatedBy = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(STAFF_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListSource = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(LEAD_SOURCE)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteLead = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${LEADS_URL}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListCountries = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${COUNTRY_URL}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const getListProposal = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${PROPOSAL_URL}?${ParseSimpleEndpoint(data)}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListProposalBylead = (data) => {
    const { id, paging } = data;
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${PROPOSAL_URL}/lead/${id}?${ParseSimpleEndpoint(paging)}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListTaskBylead = (action) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(
                `${TASK_URL}/lead/${action.leadId}?${ParseSimpleEndpoint(
                    action.data
                )}`
            )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListNoteBylead = (data) => {
    const { id, paging } = data;
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${NOTE_URL}/lead/${id}?${ParseSimpleEndpoint(paging)}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListActivityLogBylead = (data) => {
    const { id, paging } = data;
    return new Promise((resolve, reject) => {
        return apiBase
            .get(
                `${ACTIVITY_LOG_URL}/lead/${id}?${ParseSimpleEndpoint(paging)}`
            )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createProposal = (payload) => {
    const { data } = payload;
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${PROPOSAL_URL}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const setIsNoContact = (payload) => {
    const { id } = payload;
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${PROPOSAL_URL}/lead/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const setIsTrash = (payload) => {
    const { id } = payload;
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${PROPOSAL_URL}/lead/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const switchToCustomer = (payload) => {
    const { id } = payload;
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${PROPOSAL_URL}/lead/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createTask = (payload) => {
    const { id } = payload;
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${PROPOSAL_URL}/lead/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

// const saveActivity = (payload) => {
//     const { id } = payload;
//     return new Promise((resolve, reject) => {
//         return apiBase
//             .put(`${REMIND_URL}/lead/${id}`)
//             .then((res) => resolve(res))
//             .catch((err) => reject(err));
//     });
// };

const getListItems = (payload) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(ITEMS_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListGroupsItem = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(ITEM_GROUPS_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const fetchListProposalLead = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${PROPOSAL_LEAD_URL}/${data}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListStaff = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(STAFF_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const fetchListReminderLeadAPI = (data) => {
    const { id, paging } = data;
    return new Promise((resolve, reject) => {
        return apiBase
            .get(
                `${REMINDER_LEAD_URL}/lead/${id}?${ParseSimpleEndpoint(paging)}`
            )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createReminderLead = (id, data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${REMINDER_LEAD_URL}/lead/${id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteReminderLead = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`reminder/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const updateReminderLead = (id, data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${REMINDER_LEAD_URL}/${id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

// api fetch note lead
const fetchNoteLead = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${NOTE_URL}/lead/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createNoteLead = (id, data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${NOTE_URL}/lead/${id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteNoteLead = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${NOTE_URL}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const updateNoteLead = (id, data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${NOTE_URL}/${id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const fetchListAttachmentLead = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${FILE_LEAD_URL}/lead/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createAttachmentLead = (id, data) => {
    const newFormData = new FormData();
    newFormData.append("file_name", data);
    const header = { "Content-Type": "multipart/form-data" };
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${FILE_LEAD_URL}/lead/${id}`, newFormData, header)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteAttachmentLead = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${FILE_LEAD_URL}/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const fetchListTaskLead = (id) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${TASK_URL}/lead/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const convertToCustomer = ({ leadId, body }) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`/lead/convert-to-customer/${leadId}`, body)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
export {
    convertToCustomer,
    getListLeads,
    getListStatus,
    updateStatusLead,
    getListCreatedBy,
    getListSource,
    deleteLead,
    createLead,
    getListCountries,
    updateLead,
    getListProposalBylead,
    getListTaskBylead,
    getListNoteBylead,
    getListActivityLogBylead,
    createProposal,
    setIsNoContact,
    setIsTrash,
    switchToCustomer,
    createTask,
    // saveActivity,
    getListCurrency,
    getListItems,
    getListGroupsItem,
    fetchListProposalLead,
    getListStaff,
    fetchListReminderLeadAPI,
    createReminderLead,
    deleteReminderLead,
    updateReminderLead,
    fetchNoteLead,
    createNoteLead,
    deleteNoteLead,
    updateNoteLead,
    fetchListAttachmentLead,
    createAttachmentLead,
    deleteAttachmentLead,
    fetchListTaskLead,
};
