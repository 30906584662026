import ActionTypes from "./type";
import _ from "lodash";
const INIT_STATE = {
    isLoading: false,
    isSuccess: false,
    listCustomerProject: [],
    listCustomer: [],
    listGroups: [],
    listReminder: [],
    statusAddGroup: false,
    statusUpdateListCustomer: false,
    listSummaryCustomer: [],
    listEstimateCustomer: [],
    projectId: "",
    dataProfile: {
        company: "",
        vat: null,
        phonenumber: null,
        country: null,
        city: null,
        zip: null,
        state: null,
        address: null,
        website: null,
        default_language: null,
        default_currency: null, //defalut currency VND
        groups: [],
        billing_street: null,
        billing_city: null,
        billing_state: null,
        billing_zip: null,
        billing_country: 0,
        shipping_street: null,
        shipping_city: null,
        shipping_state: null,
        shipping_zip: null,
        shipping_country: 0,
    },
    customerById: null,
    
};

const CustomerReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_CUSTOMER_ID:
            return {
                ...state,
                customerId: action.payload,
            };

        case ActionTypes.FETCH_CUSTOMER_BY_ID:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_CUSTOMER_BY_ID_SUCCESS:
            let dataCustomer = action.payload;

            for (let item in dataCustomer) {
                if (!_.isArray(dataCustomer[item]) && dataCustomer[item] === null) {
                    dataCustomer[item] = "";
                }
            }

            let data = action.payload;
            data.Customer = dataCustomer;

            return {
                ...state,
                customerById: data,
                isLoading: false,
            };


        case ActionTypes.FETCH_CUSTOMER_BY_ID_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.GET_SUMMARY_CUSTOMER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.GET_SUMMARY_CUSTOMER_SUCCESS:
            return {
                ...state,
                listSummaryCustomer: [action.payload],
                isLoading: false,
            };

        case ActionTypes.GET_SUMMARY_CUSTOMER_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_CUSTOMER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_LIST_CUSTOMER_SUCCESS:
            return {
                ...state,
                listCustomer: action.payload,
                isLoading: false,
                statusUpdateListCustomer: false,
            };

        case ActionTypes.FETCH_LIST_CUSTOMER_FAILED:
            return {
                ...state,
                isLoading: false,
                statusUpdateListCustomer: false,
            };

        case ActionTypes.DELETE_ITEM_CUSTOMER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.DELETE_ITEM_CUSTOMER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                statusUpdateListCustomer: true,
            };

        case ActionTypes.DELETE_ITEM_CUSTOMER_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.GET_LIST_GROUPS_REQUEST:
            return {
                ...state,
            };

        case ActionTypes.GET_LIST_GROUPS_SUCCESS:
            return {
                ...state,
                listGroups: action.payload,
            };

        case ActionTypes.GET_LIST_GROUPS_FAILED:
            return {
                ...state,
            };
        case ActionTypes.ADD_GROUP_REQUEST:
            return {
                ...state,
            };

        case ActionTypes.ADD_GROUP_SUCCESS:
            return {
                ...state,
                statusAddGroup: !state.statusAddGroup,
            };

        case ActionTypes.ADD_GROUP_FAILED:
            return {
                ...state,
            };
        case ActionTypes.CHANGE_DATA_PROFILE:
            const valueData = state.dataProfile;
            valueData[action.name] = action.value;
            return {
                ...state,
                dataProfile: { ...valueData },
            };

        case ActionTypes.ADD_CUSTOMER_REQUEST:
            return {
                ...state,
            };

        case ActionTypes.ADD_CUSTOMER_SUCCESS:
            return {
                ...state,
                dataProfile: {
                    company: "",
                    vat: null,
                    phonenumber: null,
                    country: null,
                    city: null,
                    zip: null,
                    state: null,
                    address: null,
                    website: null,
                    default_language: null,
                    default_currency: null,
                    groups: [],

                    billing_street: null,
                    billing_city: null,
                    billing_state: null,
                    billing_zip: null,
                    billing_country: 0,
                    shipping_street: null,
                    shipping_city: null,
                    shipping_state: null,
                    shipping_zip: null,
                    shipping_country: 0,
                },
            };

        case ActionTypes.ADD_CUSTOMER_FAILED:
            return {
                ...state,
            };
        case ActionTypes.EDIT_ACTIVE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.EDIT_ACTIVE_SUCCESS:
            let dataChangeActive = { ...state.listCustomer };
            const indexChange = dataChangeActive.data.findIndex(
                (item) => item.clientId === action.payload.clientId
            );
            dataChangeActive.data.splice(indexChange, 1, action.payload);
            return {
                ...state,
                isLoading: false,
                listCustomer: dataChangeActive,
            };

        case ActionTypes.EDIT_ACTIVE_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.GET_LIST_REMINDER:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.GET_LIST_REMINDER_SUCCESS:
            return {
                ...state,
                listReminder: action.payload,
                isLoading: false,
            };

        case ActionTypes.GET_LIST_REMINDER_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.CREATE_REMINDER:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.CREATE_REMINDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.CREATE_REMINDER_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.EDIT_REMINDER:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.EDIT_REMINDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.EDIT_REMINDER_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.DELETE_REMINDER:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.DELETE_REMINDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.DELETE_REMINDER_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.FETCH_LIST_CUSTOMER_ESTIMATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FETCH_LIST_CUSTOMER_ESTIMATE_SUCCESS:
            return {
                ...state,
                listEstimateCustomer: action.data,
                isLoading: false,
            };

        case ActionTypes.FETCH_LIST_CUSTOMER_ESTIMATE_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.CREATE_PROJECT_CUSTOMER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.CREATE_PROJECT_CUSTOMER_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                projectId: action.payload.result.id,
                isLoading: false,
            };

        case ActionTypes.CREATE_PROJECT_CUSTOMER_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.GET_PROJECT_CUSTOMER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.GET_PROJECT_CUSTOMER_SUCCESS:
            return {
                ...state,

                listCustomerProject: action.payload,
                isLoading: false,
            };

        case ActionTypes.GET_PROJECT_CUSTOMER_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        default:
            return { ...state };
    }
};

export default CustomerReducer;
