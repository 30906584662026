import {
  FETCH_DISCUSSION,
  FETCH_DISCUSSION_SUCCESS,
  FETCH_DISCUSSION_FAILED,
  CREATE_DISCUSSION,
  CREATE_DISCUSSION_FAILED,
  CREATE_DISCUSSION_SUCCESS,
  DELETE_DISCUSSION,
  DELETE_DISCUSSION_FAILED,
  DELETE_DISCUSSION_SUCCESS,
  UPDATE_DISCUSSION,
  UPDATE_DISCUSSION_FAILED,
  UPDATE_DISCUSSION_SUCCESS,
  SET_EDIT_DISCUSSION,
} from "./types";

const INIT_STATE = {
  isLoading: false,
  dataDiscussionProject: {},
  isEdit: false,
  isAdd: false,
  isDelete: false,
  discussionEditing: {},
};

const DiscussionProjectReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_DISCUSSION:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_DISCUSSION_SUCCESS:
      return {
        ...state,
        dataDiscussionProject: action.payload,
        isLoading: false,
        isEdit: false,
        isAdd: false,
        isDelete: false,
      };

    case FETCH_DISCUSSION_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case CREATE_DISCUSSION:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_DISCUSSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAdd: true,
      };

    case CREATE_DISCUSSION_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case DELETE_DISCUSSION:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_DISCUSSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDelete: true,
      };

    case DELETE_DISCUSSION_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case UPDATE_DISCUSSION:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_DISCUSSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEdit: true,
      };
    case UPDATE_DISCUSSION_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SET_EDIT_DISCUSSION:
      return {
        ...state,
        isLoading: false,
        discussionEditing: action.payload,
      };
    default:
      return { ...state };
  }
};

export default DiscussionProjectReducer;
