export default {
    expense: {
        index: {
            add: "New project",
            summary: 'Summary',
            confirmDelete: "Are you want to delete?",
            titleFilter: "Filter",
            keywordFind: "Search",
            assigned: "Assigned",
            delete: "Delete",
            cancel: "Cancel",
            status: "Status",
            source: "Source",
            view: "View",
            edit: "Edit",
            yes: "Yes",
            no: "No"
        },
        common: {
            id: "Id",
            name: "Tên",
            category: "Phân loại",
            amount: "Số tiền",
            expenseReceipt: "Hóa đơn chi",
            date: "Ngày",
            project: "Dự án",
            customer: "Khác hàng",
            bill: "Hóa đơn",
        },
        new: {
            title: "Add new expense",
            
        }
    }

}