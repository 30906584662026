import apiBase from "../../../../common/baseAPI";
const PROJECT_NOTE_URL = "project/notes";

//project-view note
const createProjectNotes = (id, data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .post(`${PROJECT_NOTE_URL}/${id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const getProjectNotes = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${PROJECT_NOTE_URL}/${data.id}?page=${data.page}&&limit=${data.limit}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const editProjectNotes = (data) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .put(`${PROJECT_NOTE_URL}/${data.id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const deleteProjectNotes = (id) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .delete(`${PROJECT_NOTE_URL}/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export {
  createProjectNotes,
  getProjectNotes,
  editProjectNotes,
  deleteProjectNotes,
};
