import apiBase from "./../../../../common/baseAPI";

const URL = 'taxes';
const URL_CURRENCY = "currencies"
const URL_PAYMENT = "paymentModes"
const URL_EXPENSES = "expenses-category"



const createFinanceTaxRate = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.post(URL, data)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const editFinanceTaxRate = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.put(`${URL}/${data.id}`, data)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const deleteFinanceTaxRate = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.delete(`${URL}/${data}`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};
const fetchFinanceTaxRate = (params) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.get(`${URL}?search=${params.search}&&limit=${params.limit}&&page=${params.page}`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

//Finance-curency
const createFinanceCurrency = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.post(URL_CURRENCY, data)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const editFinanceCurrency = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.put(`${URL_CURRENCY}/${data.id}`, data)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const deleteFinanceCurrency = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.delete(`${URL_CURRENCY}/${data}`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};
const fetchFinanceCurrency = (params) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.get(`${URL_CURRENCY}?search=${params.search}&&limit=${params.limit}&&page=${params.page}`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

//Finance-PaymentMode
const createFinancePaymentMode = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.post(URL_PAYMENT, data)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const editFinancePaymentMode = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.put(`${URL_PAYMENT}/${data.id}`, data)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const deleteFinancePaymentMode = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.delete(`${URL_PAYMENT}/${data}`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};
const fetchFinancePaymentMode = (params) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.get(`${URL_PAYMENT}?search=${params.search}&&limit=${params.limit}&&page=${params.page}`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const changeActiveStatusPaymentMode = (action) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.put(`${URL_PAYMENT}/active/${action.id}`, { active: action.active })
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};
//Finance-expenses
const createFinanceExpenses = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.post(URL_EXPENSES, data)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const editFinanceExpenses = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.put(`${URL_EXPENSES}/${data.id}`, data)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const deleteFinanceExpenses = (data) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.delete(`${URL_EXPENSES}/${data}`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};
const fetchFinanceExpenses = (params) => {
	return new Promise((resolve, reject) => {
		return apiBase
			.get(`${URL_EXPENSES}?search=${params.search}&&limit=${params.limit}&&page=${params.page}`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};



export {
	fetchFinanceTaxRate,
	createFinanceTaxRate,
	editFinanceTaxRate,
	deleteFinanceTaxRate,
	createFinanceCurrency,
	editFinanceCurrency,
	deleteFinanceCurrency,
	fetchFinanceCurrency,
	createFinancePaymentMode,
	editFinancePaymentMode,
	deleteFinancePaymentMode,
	fetchFinancePaymentMode,
	changeActiveStatusPaymentMode,
	createFinanceExpenses,
	editFinanceExpenses,
	deleteFinanceExpenses,
	fetchFinanceExpenses,
}