import ActionTypes from "./type";

export const fetchContactRequest = (id, value, params) => {
  return {
    type: ActionTypes.FETCH_CONTACT_REQUEST,
    id,
    value,
    params,
  };
};

export const fetchContactSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_CONTACT_SUCCESS,
    data,
  };
};

export const fetchContactFailed = () => {
  return {
    type: ActionTypes.FETCH_CONTACT_FAILED,
  };
};

export const addContactRequest = (id, data) => {
  return {
    type: ActionTypes.ADD_CONTACT_REQUEST,
    id,
    data,
  };
};

export const addContactSuccess = (data) => {
  return {
    type: ActionTypes.ADD_CONTACT_SUCCESS,
    data,
  };
};

export const addContactFailed = () => {
  return {
    type: ActionTypes.ADD_CONTACT_FAILED,
  };
};

export const editActiveRequest = (id, value) => {
  return {
    type: ActionTypes.EDIT_ACTIVE_CUSTOMER_CONTACT_REQUEST,
    id,
    value,
  };
};

export const editActiveSuccess = (data) => {
  return {
    type: ActionTypes.EDIT_ACTIVE_CUSTOMER_CONTACT_SUCCESS,
    data,
  };
};

export const editActiveFailed = () => {
  return {
    type: ActionTypes.EDIT_ACTIVE_CUSTOMER_CONTACT_FAILED,
  };
};

export const editContactRequest = (id, data) => {
  return {
    type: ActionTypes.EDIT_CONTACT_REQUEST,
    id,
    data,
  };
};

export const editContactSuccess = (data) => {
  return {
    type: ActionTypes.EDIT_CONTACT_SUCCESS,
    data,
  };
};

export const editContactFailed = () => {
  return {
    type: ActionTypes.EDIT_CONTACT_FAILED,
  };
};

export const deleteContactsRequest = (id) => {
  return {
    type: ActionTypes.DELETE_CONTACT_REQUEST,
    id,
  };
};

export const deleteContactsSuccess = () => {
  return {
    type: ActionTypes.DELETE_CONTACT_SUCCESS,
  };
};

export const deleteContactsFailed = () => {
  return {
    type: ActionTypes.DELETE_CONTACT_FAILED,
  };
};
