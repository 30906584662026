import ActionTypes from "./types";

export const fetchListCustomFieldRequest = (value, params) => {
  return {
    type: ActionTypes.FETCH_LIST_CUSTOM_FIELD_REQUEST,
    value,
    params,
  };
};

export const fetchListCustomFieldSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_LIST_CUSTOM_FIELD_SUCCESS,
    payload: data,
  };
};

export const fetchListCustomFieldFailed = () => {
  return {
    type: ActionTypes.FETCH_LIST_CUSTOM_FIELD_FAILED,
  };
};

export const editActiveCustomFieldRequest = (id, value) => {
  return {
    type: ActionTypes.EDIT_ACTIVE_CUSTOM_FIELD_REQUEST,
    id,
    value,
  };
};

export const editActiveCustomFieldSuccess = (data) => {
  return {
    type: ActionTypes.EDIT_ACTIVE_CUSTOM_FIELD_SUCCESS,
    data,
  };
};

export const editActiveCustomFieldFailed = () => {
  return {
    type: ActionTypes.EDIT_ACTIVE_CUSTOM_FIELD_FAILED,
  };
};

export const addCustomFieldRequest = (data, history) => {
  return {
    type: ActionTypes.ADD_CUSTOM_FIELD_REQUEST,
    data,
    history,
  };
};
export const deleteCustomFieldRequest = (id) => {
  return {
    type: ActionTypes.DELETE_CUSTOM_FIELD_REQUEST,
    id,
  };
};
export const deleteCustomFieldSuccess = () => {
  return {
    type: ActionTypes.DELETE_CUSTOM_FIELD_SUCCESS,
  };
};
export const deleteCustomFieldFailed = () => {
  return {
    type: ActionTypes.DELETE_CUSTOM_FIELD_FAILED,
  };
};

export const getItemCustomfieldRequest = (id) => {
  return {
    type: ActionTypes.GET_ITEM_CUSTOM_FIELD_REQUEST,
    id,
  };
};
export const getItemCustomfieldSuccess = (item) => {
  return {
    type: ActionTypes.GET_ITEM_CUSTOM_FIELD_SUCCESS,
    item,
  };
};
export const getItemCustomfieldFailed = () => {
  return {
    type: ActionTypes.GET_ITEM_CUSTOM_FIELD_FAILED,
  };
};

export const editCustomFieldRequest = (id, data) => {
  return {
    type: ActionTypes.EDIT_CUSTOM_FIELD_REQUEST,
    id,
    data,
  };
};
export const editCustomFieldSuccess = (item) => {
  return {
    type: ActionTypes.EDIT_CUSTOM_FIELD_SUCCESS,
    item,
  };
};
export const editCustomFieldFailed = () => {
  return {
    type: ActionTypes.EDIT_CUSTOM_FIELD_FAILED,
  };
};
