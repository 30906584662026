import {
    Avatar,
    Box,
    Chip,
    Divider,
    Grid,
    Link,
    makeStyles,
    Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
    Collapse,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import CustomDialog from "../../common/CustomDialog";
import CustomTable from "../../common/CustomTable";
import ThcWidget from '../../components/Summary/ThcWidget';
// import proposalsCustomerSaga from "../Customer/Proposals/redux/saga";
import { columnsSupport } from "./Const";
import "./index.scss";
import {
    fetchListTicketsPending,
    fetchTicketSummaryPending,
} from "./redux/actions";

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: 12,
    },
}));

const support = (props) => {
    // props
    const {
        ticketSummary,
        fetchTicketSummaryPending,
        listTickets,
        fetchListTicketsPending,
    } = props;
    const { data } = ticketSummary;
    const { result } = listTickets;
    let total;
    if (listTickets) {
        total = listTickets.length;
    }
    let slicedArray;
    if (data) {
        slicedArray = data.slice(0, 5);
    }
    const [params, setParams] = useState({
        limit: 15,
        search: "",
        page: 1,
    });
    const [filterTicketBody, setFilterTicketBody] = useState({
        member: 0,
        status: [],
    });
    const renderTitle = () => {
        return (
            <div className="custom-title">
                {t("support.dialogBulkActions.bulkActions")}
            </div>
        );
    };
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const handelShowDialog = () => {
        setIsOpenDialog(true);
    };
    const classes = useStylesBootstrap();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const styleTooltip = useStylesBootstrap();
    const toggle = () => setDropdownOpen(!dropdownOpen);
    const [showOpen, setOpen] = useState(false);
    const { t } = useTranslation();
    let history = useHistory();
    const [isOpenOut, setIsOpenOut] = useState(false);
    const toggleDropdownOut = () => {
        setIsOpenOut(!isOpenOut);
    };
    const ticketsSummary = [
        { count: 3, title: t("project.ticket.open"), color: "#ff2d42" },
        { count: 1, title: t("project.ticket.inProgress"), color: "#84c529" },
        { count: 3, title: t("project.ticket.answered"), color: "#0000ff" },
        { count: 0, title: t("project.onHold"), color: "#c0c0c0" },
        { count: 0, title: t("project.ticket.closed"), color: "#03a9f4" },
    ];
    // const testArray = [
    //     {
    //         id: "5",
    //         subject: "Giảm giá 5% các mặt hàng",
    //         tags: "",
    //         department: "Nguyễn Thanh Tâm",
    //         service: "",
    //         contact: "Nguyễn Ngọc Hưng (Ngân hàng Quân đội)",
    //         status: "Open",
    //         priority: "Medium",
    //         lastReply: "No Reply Yet",
    //         created: "2021-03-01 11:38:46",
    //     },
    // ];
    const parseData = (field, item) => {
        switch (field) {
            case "id":
                return (
                    <Box>
                        <Grid
                            className="custom-id"
                            onClick={() =>
                                changeRoute({
                                    type: "viewTicket",
                                    id: item.ticketid,
                                })
                            }
                        >
                            <span>{item.ticketid}</span>
                        </Grid>
                    </Box>
                );
            case "subject":
                return (
                    <Box className="ticket-columns-subject">
                        <Tooltip
                            title="Le Son"
                            placement="top"
                            arrow
                            classes={classes}
                        >
                            <Avatar className="custom-avatar-table" />
                        </Tooltip>
                        <Box className="info-column-subject">
                            <span className="custom-subject">
                                {item.subject}
                            </span>
                            <Box className="ticket-table-action">
                                <Box display="flex" flexDirection="row">
                                    <Link
                                        component="button"
                                        className="view"
                                        onClick={() =>
                                            changeRoute({
                                                type: "viewTicket",
                                                id: item.ticketid,
                                            })
                                        }
                                    >
                                        {t(
                                            "customer.contracts.nameAction.view"
                                        )}
                                    </Link>
                                    <Link
                                        component="button"
                                        className="edit"
                                        onClick={() =>
                                            changeRoute({ type: "editTicket" })
                                        }
                                    >
                                        |
                                        {t(
                                            "customer.contracts.nameAction.edit"
                                        )}
                                    </Link>
                                    <Link component="button" className="delete">
                                        |
                                        {t(
                                            "customer.contracts.nameAction.delete"
                                        )}
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                );
            case "tags":
                return (
                    <Box className="table-tags d-flex flex-wrap">
                      {item.tags
                        ? item.tags.map((tag, index) => (
                            <Chip
                              key={`tags-${index}`}
                              label={tag.name}
                              variant="outlined"
                              size="small"
                              className="tags m-1"
                            />
                          ))
                        : "--"}
                    </Box>
                  );
            case "department":
                return <span>{item.department}</span>;
            case "service":
                return (
                    <span>{item.services && item.services.name && "---"}</span>
                );
            case "contact":
                return (
                    <span className="custom-contact-ticket" onClick={() => changeRoute({ type: "infoContact" })}>{item.contact && item.contact.first_name + " " + item.contact.last_name}
                    </span>
                );
            case "status":
                return (
                    <span className="custom-status-ticket">{item.status}</span>
                );
            case "priority":
                return <span> {item.priority && "---"} </span>;
            case "lastReply":
                return <span> {item.lastReply && "---"} </span>;
            case "created":
                return <span> {item.date} </span>;

            default:
                return item[field] ? item[field] : "--";
        }
    };
    const changeRoute = (obj) => {
        const { type, id } = obj;
        switch (type) {
            case "createTicket":
                history.push("/support/ticket/new");
                break;
            case "viewTicket":
                history.push(`/ticket/${id}`);
                break;
            case "editTicket":
                history.push("/ticket");
                break;
            case "infoContact":
                history.push("/customer/contacts");
                break;
            default:
                break;
        }
    };
    useEffect(() => {
        fetchTicketSummaryPending();
    }, []);
    useEffect(() => {
        fetchListTicketsPending(params);
    }, []);

    return (
        <Box padding={2} marginTop={2} className="support">
            {/* header */}
            <Grid className="btn-list">
                <Grid>
                    <button type="button" className="btn btn-primary waves-effect width-md waves-light btn-ticket" onClick={() => changeRoute({ type: "createTicket" })}>
                        {t("support.newTicket")}
                    </button>
                    <Tooltip title={t("support.filterBy")} placement="top" arrow classes={styleTooltip}>
                        <button
                            className="btn btn-icon waves-effect btn-secondary btn-chart"
                            onClick={() => setOpen(!showOpen)}
                        >
                            <i class="fas fa-chart-bar" />
                        </button>
                    </Tooltip>
                    <Collapse isOpen={showOpen}>
                        <div>chart</div>
                    </Collapse>
                </Grid>
                <Grid>
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <Tooltip
                            title={t("support.filterBy")}
                            placement="top"
                            arrow
                            classes={styleTooltip}
                        >
                            <DropdownToggle
                                type="button"
                                className="btn btn-icon waves-effect waves-light  search-filter"
                            >
                                <i className="fas fa-filter"></i>
                            </DropdownToggle>
                        </Tooltip>
                        <DropdownMenu right>
                            <DropdownItem>
                                {t("support.btnFilter.all")}
                            </DropdownItem>
                            <DropdownItem style={{backgroundColor: "#03a9f4"}}>
                                {t("support.btnFilter.assigned")}
                            </DropdownItem>
                            <DropdownItem>
                                {t("support.btnFilter.open")}
                            </DropdownItem>
                            <DropdownItem>
                                {t("support.btnFilter.inProgress")}
                            </DropdownItem>
                            <DropdownItem>
                                {t("support.btnFilter.answered")}
                            </DropdownItem>
                            <DropdownItem>
                                {t("support.btnFilter.onHold")}
                            </DropdownItem>
                            <DropdownItem>
                                {t("support.btnFilter.close")}
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </Grid>
            </Grid>
            <Grid>
                <Divider />
            </Grid>
            <Grid className="content-ticket">
                {/* header */}
                <Grid className="title-header-ticket">
                    <h4 className="custom-header-ticket">
                        {t("project.ticket.ticketsSummary")}
                    </h4>
                </Grid>
                {/* list tickets summary */}
                {/* <Grid container className="list-tickets-summary">
          {ticketsSummary.map((item, index) =>
            <Grid key={`summary-${index}`}>
              <div className="card">
                <div className="count"> {item.count} </div>
                <div className="title" style={{ color: item.color }}> {item.title} </div>
              </div>
            </Grid>)}
        </Grid> */}
                <Grid container direction="row" justifyContent="space-between">
                    {slicedArray &&
                        slicedArray.map((item, index) => (
                            <Grid key={`summary-${index}`}>
                                <ThcWidget mainCount={item.count} mainText={item.title} mainColor={item.color} />
                            </Grid>
                        ))}
                </Grid>
                <Grid>
                    <Divider />
                </Grid>
                {/* Export, bulk actions and search */}
                <Grid className="btn-list" container>
                    <Grid item xs={12} md={7}>
                        <Grid className="btn-group">
                            <Box className="customer-filter">
                                <Dropdown
                                    isOpen={isOpenOut}
                                    toggle={toggleDropdownOut}
                                >
                                    <DropdownToggle
                                        caret
                                        onClick={toggleDropdownOut}
                                        data-toggle="dropdown"
                                        type="button"
                                        className="btn btn-export waves-effect width-xs waves-info"
                                        aria-expanded="false"
                                    >
                                        {t("customer.reminder.export")}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Excel</DropdownItem>
                                        <DropdownItem>CSV</DropdownItem>
                                        <DropdownItem>PDF</DropdownItem>
                                        <DropdownItem>
                                            {t(
                                                "customer.reminder.dropdownExport"
                                            )}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </Box>
                            {/* Dialog Button Bulk Actions */}
                            <CustomDialog
                                isOpen={isOpenDialog}
                                onClose={() => setIsOpenDialog(false)}
                                title={renderTitle()}
                                className="customer-new-contacts"
                            >
                                <Box
                                    padding={2}
                                    className="dialog-bulk-actions"
                                >
                                    <Grid className="form-group mass-delete">
                                        <input
                                            type="checkbox"
                                            id="checkbox6"
                                        ></input>
                                        <label for="checkbox6">
                                            {t(
                                                "support.dialogBulkActions.massDelete"
                                            )}
                                        </label>
                                    </Grid>
                                    <Grid>
                                        
                                        <Divider />
                                    </Grid>
                                    <Grid
                                        item
                                        className="form-group change-status"
                                    >
                                        <label>
                                            {t(
                                                "support.dialogBulkActions.changeStatus"
                                            )}
                                        </label>
                                        <Select
                                            placeholder=""
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            name="color"
                                            options={""}
                                        />
                                    </Grid>
                                    <Grid item className="form-group">
                                        <label>
                                            {t(
                                                "support.dialogBulkActions.department"
                                            )}
                                        </label>
                                        <Select
                                            placeholder=""
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            name="color"
                                            options={""}
                                        />
                                    </Grid>
                                    <Grid item className="form-group">
                                        <label>
                                            {t(
                                                "support.dialogBulkActions.ticketPriority"
                                            )}
                                        </label>
                                        <Select
                                            placeholder=""
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            name="color"
                                            options={""}
                                        />
                                    </Grid>
                                    <Grid item className="form-group tags">
                                        <label>
                                            <i className=" fas fa-tag" />
                                            {t(
                                                "support.dialogBulkActions.tags"
                                            )}
                                        </label>
                                        <Select
                                            placeholder=""
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            name="color"
                                            options={""}
                                        />
                                    </Grid>
                                    <Grid item className="form-group service">
                                        <label>
                                            {t(
                                                "support.dialogBulkActions.service"
                                            )}
                                        </label>
                                        <Select
                                            placeholder=""
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            name="color"
                                            options={""}
                                        />
                                    </Grid>
                                    <Grid>
                                        
                                        <Divider />
                                    </Grid>
                                    <Grid className="button-list">
                                        <button
                                            type="button"
                                            className="btn btn-secondary waves-effect width-md btn-close"
                                        >
                                            {t(
                                                "support.dialogBulkActions.close"
                                            )}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary waves-effect width-md waves-light btn-confirm"
                                        >
                                            {t(
                                                "support.dialogBulkActions.confirm"
                                            )}
                                        </button>
                                    </Grid>
                                </Box>
                            </CustomDialog>
                            <Box className="btn-bulk-actions">
                                <span onClick={() => handelShowDialog()}>
                                    BULK ACTIONS
                                </span>
                            </Box>
                            <Tooltip
                                title={t("customer.reminder.buttonReload")}
                                placement="top"
                                arrow
                                classes={styleTooltip}
                            >
                                <button className="btn btn-icon waves-effect btn-reload">
                                    <i className="fas fa-sync"></i>
                                </button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Grid
                            id="DataTables_Table_0_filter"
                            className="dataTables_filter"
                        >
                            <label>
                                <Grid className="input-group">
                                    <span className="btn btn-icon waves-effect btn-lighten-secondary btn-search">
                                        <span className="fa fa-search"></span>
                                    </span>
                                    <input
                                        type="search"
                                        className="form-control input-search"
                                        placeholder="Search..."
                                        aria-controls="DataTables_Table_0"
                                    ></input>
                                </Grid>
                            </label>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Table */}
                <Grid className="custom-table">
                    <CustomTable
                        data={result}
                        columns={columnsSupport}
                        parseFunction={parseData}
                        keySelect="id"
                        isShowPaging={true}
                        totalCountData={total}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        ticketSummary: state.SupportReducer.ticketSummary,
        listTickets: state.SupportReducer.listTickets,
    };
};

const mapDispatchToProps = {
    fetchTicketSummaryPending,
    fetchListTicketsPending,
};
export default connect(mapStateToProps, mapDispatchToProps)(support);
