import apiBase from "./../../../../../common/baseAPI";

const URL = "ticketsStatus";

const getListSupportTicketStatus = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createSupportTicketStatus = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(URL, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const editSupportTicketStatus = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${URL}/${data.ticketstatusid}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const fetchSupportTicketStatus = (action) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(
                `${URL}?search=${action.search}&&limit=${action.params.limit}&&page=${action.params.page}`
            )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getCountSupportTicketStatus = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`ticket/count`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const deleteSupportTicketStatus = ({ id }) => {
    return new Promise((resolve, reject) => {
        return (
            apiBase
                //api/ticketsStatus/{id}
                .delete(`ticketsStatus/${id}`)
                .then((res) => resolve(res))
                .catch((err) => reject(err))
        );
    });
};

export {
    deleteSupportTicketStatus,
    getListSupportTicketStatus,
    createSupportTicketStatus,
    editSupportTicketStatus,
    fetchSupportTicketStatus,
    getCountSupportTicketStatus,
};
