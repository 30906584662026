import ActionTypes from './types';

const INIT_STATE = {
	listTaxRate: [],
	listCurrency: [],
	listPaymentMode: [],
	listExpenses: [],
	isCloseDialog: false,
	isLoading: false,
	isSuccess: false
};

const AdminFinanceReducer = (state = INIT_STATE, action) => {
	switch (action.type) {

		case ActionTypes.CREATE_FINANCE_TAX_RATE_REQUEST:
			return {
				...state,
				isLoading: true
			}

		case ActionTypes.CREATE_FINANCE_TAX_RATE_SUCCESS:
			return {
				...state,
				isCloseDialog: true,
				isLoading: false,
				listTaxRate: state.listTaxRate,
				isSuccess: true
			};

		case ActionTypes.CREATE_FINANCE_TAX_RATE_FAILED:
			return {
				...state,
				isLoading: false,
				isCloseDialog: true,
				isSuccess: false
			};

		case ActionTypes.EDIT_FINANCE_TAX_RATE_REQUEST:
			return {
				...state,
				isLoading: true
			}

		case ActionTypes.EDIT_FINANCE_TAX_RATE_SUCCESS:
			return {
				...state,
				isCloseDialog: true,
				isLoading: false,
				isSuccess: true
			}

		case ActionTypes.EDIT_FINANCE_TAX_RATE_FAILED:
			return {
				...state,
				isLoading: false,
				isCloseDialog: true,
				isSuccess: false
			};

		case ActionTypes.DELETE_FINANCE_TAX_RATE_REQUEST:
			return {
				...state,
				isLoading: true
			}

		case ActionTypes.DELETE_FINANCE_TAX_RATE_SUCCESS:
			return {
				...state,
				isCloseDialog: true,
				isLoading: false,
				isSuccess: true
			};

		case ActionTypes.DELETE_FINANCE_TAX_RATE_FAILED:
			return {
				...state,
				isLoading: false,
				isCloseDialog: true,
				isSuccess: false
			};

		case ActionTypes.FETCH_FINANCE_TAX_RATE_REQUEST:
			return {
				...state,
				isLoading: true,
				isCloseDialog: false
			}
		case ActionTypes.FETCH_FINANCE_TAX_RATE_SUCCESS:
			return {
				...state,
				listTaxRate: action.payload,
				isLoading: false,
			};

		case ActionTypes.FETCH_FINANCE_TAX_RATE_FAILED:
			return {
				...state,
				isLoading: false,
			};

		//CURRENCY:
		case ActionTypes.CREATE_FINANCE_CURRENCY_REQUEST:
			return {
				...state,
				isLoading: true
			}

		case ActionTypes.CREATE_FINANCE_CURRENCY_SUCCESS:
			return {
				...state,
				isCloseDialog: true,
				isLoading: false,
				listCurrency: state.listCurrency,
				isSuccess: true
			};

		case ActionTypes.CREATE_FINANCE_CURRENCY_FAILED:
			return {
				...state,
				isLoading: false,
				isCloseDialog: true,
				isSuccess: false
			};

		case ActionTypes.EDIT_FINANCE_CURRENCY_REQUEST:
			return {
				...state,
				isLoading: true
			}

		case ActionTypes.EDIT_FINANCE_CURRENCY_SUCCESS:
			return {
				...state,
				isCloseDialog: true,
				isLoading: false,
				isSuccess: true
			}

		case ActionTypes.EDIT_FINANCE_CURRENCY_FAILED:
			return {
				...state,
				isLoading: false,
				isCloseDialog: true,
				isSuccess: false
			};

		case ActionTypes.DELETE_FINANCE_CURRENCY_REQUEST:
			return {
				...state,
				isLoading: true
			}

		case ActionTypes.DELETE_FINANCE_CURRENCY_SUCCESS:
			return {
				...state,
				isCloseDialog: true,
				isLoading: false,
				isSuccess: true
			};

		case ActionTypes.DELETE_FINANCE_CURRENCY_FAILED:
			return {
				...state,
				isLoading: false,
				isCloseDialog: true,
				isSuccess: false
			};

		case ActionTypes.FETCH_FINANCE_CURRENCY_REQUEST:
			return {
				...state,
				isLoading: true,
				isCloseDialog: false
			}
		case ActionTypes.FETCH_FINANCE_CURRENCY_SUCCESS:
			return {
				...state,
				listCurrency: action.payload,
				isLoading: false,
			};

		case ActionTypes.FETCH_FINANCE_CURRENCY_FAILED:
			return {
				...state,
				isLoading: false,
			};

		//PAYMENT:
		case ActionTypes.CREATE_FINANCE_PAYMENT_MODE_REQUEST:
			return {
				...state,
				isLoading: true
			}

		case ActionTypes.CREATE_FINANCE_PAYMENT_MODE_SUCCESS:
			return {
				...state,
				isCloseDialog: true,
				isLoading: false,
				listPaymentMode: state.listPaymentMode,
				isSuccess: true
			};

		case ActionTypes.CREATE_FINANCE_PAYMENT_MODE_FAILED:
			return {
				...state,
				isLoading: false,
				isCloseDialog: true,
				isSuccess: false
			};

		case ActionTypes.EDIT_FINANCE_PAYMENT_MODE_REQUEST:
			return {
				...state,
				isLoading: true
			}

		case ActionTypes.EDIT_FINANCE_PAYMENT_MODE_SUCCESS:
			return {
				...state,
				isCloseDialog: true,
				isLoading: false,
				isSuccess: true
			}

		case ActionTypes.EDIT_FINANCE_PAYMENT_MODE_FAILED:
			return {
				...state,
				isLoading: false,
				isCloseDialog: true,
				isSuccess: false
			};

		case ActionTypes.DELETE_FINANCE_PAYMENT_MODE_REQUEST:
			return {
				...state,
				isLoading: true
			}

		case ActionTypes.DELETE_FINANCE_PAYMENT_MODE_SUCCESS:
			return {
				...state,
				isCloseDialog: true,
				isLoading: false,
				isSuccess: true
			};

		case ActionTypes.DELETE_FINANCE_PAYMENT_MODE_FAILED:
			return {
				...state,
				isLoading: false,
				isCloseDialog: true,
				isSuccess: false
			};

		case ActionTypes.FETCH_FINANCE_PAYMENT_MODE_REQUEST:
			return {
				...state,
				isLoading: true,
				isCloseDialog: false
			}
		case ActionTypes.FETCH_FINANCE_PAYMENT_MODE_SUCCESS:
			return {
				...state,
				listPaymentMode: action.payload,
				isLoading: false,
			};

		case ActionTypes.FETCH_FINANCE_PAYMENT_MODE_FAILED:
			return {
				...state,
				isLoading: false,
			};

		//EXPENSES:
		case ActionTypes.CREATE_FINANCE_EXPENSES_REQUEST:
			return {
				...state,
				isLoading: true
			}

		case ActionTypes.CREATE_FINANCE_EXPENSES_SUCCESS:
			return {
				...state,
				isCloseDialog: true,
				isLoading: false,
				listExpenses: state.listExpenses,
				isSuccess: true
			};

		case ActionTypes.CREATE_FINANCE_EXPENSES_FAILED:
			return {
				...state,
				isLoading: false,
				isCloseDialog: true,
				isSuccess: false
			};

		case ActionTypes.EDIT_FINANCE_EXPENSES_REQUEST:
			return {
				...state,
				isLoading: true
			}

		case ActionTypes.EDIT_FINANCE_EXPENSES_SUCCESS:
			return {
				...state,
				isCloseDialog: true,
				isLoading: false,
				isSuccess: true
			}

		case ActionTypes.EDIT_FINANCE_EXPENSES_FAILED:
			return {
				...state,
				isLoading: false,
				isCloseDialog: true,
				isSuccess: false
			};

		case ActionTypes.DELETE_FINANCE_EXPENSES_REQUEST:
			return {
				...state,
				isLoading: true
			}

		case ActionTypes.DELETE_FINANCE_EXPENSES_SUCCESS:
			return {
				...state,
				isCloseDialog: true,
				isLoading: false,
				isSuccess: true
			};

		case ActionTypes.DELETE_FINANCE_EXPENSES_FAILED:
			return {
				...state,
				isLoading: false,
				isCloseDialog: true,
				isSuccess: false
			};

		case ActionTypes.FETCH_FINANCE_EXPENSES_REQUEST:
			return {
				...state,
				isLoading: true,
				isCloseDialog: false
			}
		case ActionTypes.FETCH_FINANCE_EXPENSES_SUCCESS:
			return {
				...state,
				listExpenses: action.payload,
				isLoading: false,
			};

		case ActionTypes.FETCH_FINANCE_EXPENSES_FAILED:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.CHANGE_STATUS_DIALOG_FINANCE:
			return {
				...state,
				isCloseDialog: false
			}

		case ActionTypes.CHANGE_ACTIVE_STATUS_PAYMENT_MODE_REQUEST:
			return {
				...state,
				isLoading: true,
			};

		case ActionTypes.CHANGE_ACTIVE_STATUS_PAYMENT_MODE_SUCCESS:
			return {
				...state,
				isLoading: false,
				isCloseDialog: true,
				isSuccess: true
			};

		case ActionTypes.CHANGE_ACTIVE_STATUS_PAYMENT_MODE_FAILED:
			return {
				...state,
				isCloseDialog: true,
				isSuccess: false,
				isLoading: false,
			}

		default: return { ...state };
	}
}

export default AdminFinanceReducer;