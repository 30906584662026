import ActionTypes from './types';

export const getListTicketPriorityRequest = () => {
    return {
        type: ActionTypes.GET_LIST_TICKET_PRIORITY_REQUEST
    }
};

export const getListTicketPrioritySuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_TICKET_PRIORITY_SUCCESS,
        payload: data
    }
};

export const getListTicketPriorityFailed = () => {
    return {
        type: ActionTypes.GET_LIST_TICKET_PRIORITY_FAILED,
    }
};

export const createTicketPriorityRequest = (data) => {
    return {
        type: ActionTypes.CREATE_TICKET_PRIORITY_REQUEST,
        payload: data,
    }
};

export const createTicketPrioritySuccess = (data) => {
    return {
        type: ActionTypes.CREATE_TICKET_PRIORITY_SUCCESS,
        payload: data
    }
};

export const createTicketPriorityFailed = () => {
    return {
        type: ActionTypes.CREATE_TICKET_PRIORITY_FAILED
    }
};

export const editTicketPriorityRequest = (data) => {
    return {
        type: ActionTypes.EDIT_TICKET_PRIORITY_REQUEST,
        payload: data,
    }
};

export const editTicketPrioritySuccess = (data) => {
    return {
        type: ActionTypes.EDIT_TICKET_PRIORITY_SUCCESS,
        payload: data
    }
};

export const editTicketPriorityFailed = () => {
    return {
        type: ActionTypes.EDIT_TICKET_PRIORITY_FAILED
    }
};

export const deleteTicketPriorityRequest = (data) => {
    return {
        type: ActionTypes.DELETE_TICKET_PRIORITY_REQUEST,
        payload: data,
    }
};

export const deleteTicketPrioritySuccess = (data) => {
    return {
        type: ActionTypes.DELETE_TICKET_PRIORITY_SUCCESS,
        payload: data
    }
};

export const deleteTicketPriorityFailed = () => {
    return {
        type: ActionTypes.DELETE_TICKET_PRIORITY_FAILED,
    }
};

export const changeStatusDialog = () => {
    return {
        type: ActionTypes.CHANGE_STATUS_DIALOG,
    }
};

export const fetchTicketPriorityRequest = (search, params) => {
    return {
        type: ActionTypes.FETCH_TICKET_PRIORITY_REQUEST,
        search, params
    }
};

export const fetchTicketPrioritySuccess = (data) => {
    return {
        type: ActionTypes.FETCH_TICKET_PRIORITY_SUCCESS,
        payload: data
    }
};

export const fetchTicketPriorityFailed = () => {
    return {
        type: ActionTypes.FETCH_TICKET_PRIORITY_FAILED,
    }
};