import React from "react";
import { IconButton } from "@material-ui/core";
// import {CloseIcon } from '@material-ui/icons';
import { useDispatch } from "react-redux";
import { closeSnackbar as closeSnackbarAction } from "./CommonReducer";

export default function ButtonCloseSnackbar(props) {
  //   const { key } = props;

  const dispatch = useDispatch();
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  return (
    <div>
      <IconButton onClick={() => closeSnackbar()}>
        <i
          style={{ fontSize: "12px", height: "12px" }}
          className=" dripicons-cross text-white"
        />
      </IconButton>
    </div>
  );
}
