// @flow
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
    FETCH_LIST_LEADS,
    FETCH_LIST_STATUS_LEADS,
    UPDATE_STATUS_LEAD,
    GET_LIST_CREATED_BY,
    GET_LIST_SOURCE,
    DELETE_LEAD,
    CREATE_NEW_LEAD,
    GET_LIST_COUNTRY,
    UPDATE_LEAD,
    GET_LIST_PROPOSAL_BY_LEAD,
    // GET_TASK_BY_LEAD,
    CREATE_PROPOSAL,
    SET_IS_NO_CONTACT,
    SET_IS_TRASH,
    SWITCH_TO_CUSTOMER,
    CREATE_TASK,
    GET_LIST_CURRENCY,
    GET_LIST_ITEMS,
    GET_LIST_GROUP_ITEMS,
    GET_LIST_PROPOSAL,
    FETCH_LIST_PROPOSAL_LEAD,
    FETCH_LIST_STAFF,
    FETCH_LIST_REMINDER_LEAD,
    CREATE_REMINDER_LEAD,
    DELETE_REMINDER_LEAD,
    UPDATE_REMINDER_LEAD,
    FETCH_NOTE_LEAD,
    CREATE_NOTE_LEAD,
    DELETE_NOTE_LEAD,
    UPDATE_NOTE_LEAD,
    FETCH_LIST_ATTACHMENT_LEAD,
    CREATE_ATTACHMENT_LEAD,
    DELETE_ATTACHMENT_LEAD,
    FETCH_LIST_TASK_LEAD,
    CONVERT_TO_CUSTOMER_LEAD_PENDING,
} from "./types";
import {
    fetchListLeadFailed,
    fetchListLeadSuccess,
    createNewLeadFailed,
    createNewLeadSuccess,
    fetchListStatusLeadSuccess,
    fetchListStatusLeadFailed,
    updateStatusLeadSuccess,
    updateStatusLeadFailed,
    getListCreatedBySuccess,
    getListCreatedByFailed,
    getListSourceSuccess,
    getListSourceFailed,
    deleteLeadSuccess,
    deleteLeadFailed,
    // getListTagsSuccess,
    // getListTagsFailed,
    getListCountryFailed,
    getListCountrySuccess,
    updateLeadSuccess,
    updateLeadFailed,
    getListProposalByLeadSuccess,
    getListProposalByLeadFailed,
    // getTaskByLeadSuccess,
    // getTaskByLeadFailed,
    createProposalSuccess,
    createProposalFailed,
    setIsNoContactFailed,
    setIsNoContactSuccess,
    setIsTrashSuccess,
    setIsTrashFailed,
    switchToCustomerFailed,
    switchToCustomerSuccess,
    createTaskSuccess,
    createTaskFailed,
    getListCurrencyFailed,
    getListCurrencySuccess,
    getListItemsSuccess,
    getListItemsFailed,
    getListGroupItemsSuccess,
    getListGroupItemsFailed,
    getListProposalSuccess,
    getListProposalFailed,
    fetchListProposalLeadSuccess,
    fetchListProposalLeadFailed,
    fetchListStaffSuccess,
    fetchListStaffFailed,
    fetchListReminderLead,
    fetchListReminderLeadSuccess,
    fetchListReminderLeadFailed,
    createReminderLeadSuccess,
    createReminderLeadFailed,
    deleteReminderLeadSuccess,
    deleteReminderLeadFailed,
    updateReminderLeadSuccess,
    updateReminderLeadFailed,

    // Note Lead Actions
    fetchNoteLeadSuccess,
    fetchNoteLeadFailed,
    createNoteLeadFailed,
    createNoteLeadSuccess,
    deleteNoteLeadSuccess,
    deleteNoteLeadFailed,
    updateNoteLeadSuccess,
    updateNoteLeadFailed,
    fetchListAttachmentLeadSuccess,
    fetchListAttachmentLeadFailed,
    createAttachmentLeadSuccess,
    createAttachmentLeadFailed,
    deleteAttachmentLeadSuccess,
    deleteAttachmentLeadFailed,
    fetchListTaskLeadSuccess,
    fetchListTaskLeadFailed,
    convertToCustomerLeadSuccess,
    convertToCustomerLeadFailed,
} from "./actions";
import { enqueueSnackbar } from "../../../redux/CommonReducer";
import {
    getListLeads,
    getListStatus,
    updateStatusLead,
    getListCreatedBy,
    getListSource,
    deleteLead,
    createLead,
    // getListTags,
    getListCountries,
    updateLead,
    getListProposalBylead,
    // getListTaskBylead,
    createProposal,
    setIsNoContact,
    setIsTrash,
    switchToCustomer,
    createTask,
    getListCurrency,
    getListItems,
    getListGroupsItem,
    getListProposal,
    fetchListProposalLead,
    getListStaff,
    fetchListReminderLeadAPI,
    createReminderLead,
    deleteReminderLead,
    updateReminderLead,
    fetchNoteLead,
    createNoteLead,
    deleteNoteLead,
    updateNoteLead,
    fetchListAttachmentLead,
    createAttachmentLead,
    deleteAttachmentLead,
    fetchListTaskLead,
    convertToCustomer,
} from "./api";
import { responeCode, typeMessage } from "../../../constants/Const";
import {
    throwErrorMessage,
    parseDataNotifications,
} from "../../../helpers/HandleError";
import i18n from "../../../i18n";

function* fetchListLeadSaga(data) {
    const { payload } = data;

    try {
        const response = yield call(getListLeads, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListLeadSuccess(response.data.result));
        } else {
            yield put(fetchListLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* createNewLeadSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(createLead, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(createNewLeadSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        `${i18n.t("lead.common.createLeadSuccess")}`,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(createNewLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(createNewLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchListStatusSaga() {
    try {
        const response = yield call(getListStatus);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListStatusLeadSuccess(response.data.result));
        } else {
            yield put(fetchListStatusLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListStatusLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* updateStatusLeadSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(updateStatusLead, payload);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(updateStatusLeadSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        "Update success",
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(updateStatusLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(updateStatusLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getListCreatedBySaga() {
    try {
        const response = yield call(getListCreatedBy);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListCreatedBySuccess(response.data.result));
        } else {
            yield put(getListCreatedByFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListCreatedByFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getListSourceSaga() {
    try {
        const response = yield call(getListSource);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListSourceSuccess(response.data.result));
        } else {
            yield put(getListSourceFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListSourceFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* deleteLeadSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(deleteLead, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteLeadSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        `${i18n.t("lead.common.deleteLeadSuccess")}`,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getListCountriesSaga() {
    try {
        const response = yield call(getListCountries);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListCountrySuccess(response.data.result));
        } else {
            yield put(getListCountryFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListCountryFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* updateLeadSaga(data) {
    const { payload, id } = data;
    try {
        const response = yield call(updateLead, id, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(updateLeadSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        `${i18n.t("lead.common.updateLeadSuccess")}`,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(updateLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(updateLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getListProposalSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(getListProposal, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListProposalSuccess(response.data.result));
        } else {
            yield put(getListProposalFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListProposalFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getListProposalByLeadSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(getListProposalBylead, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListProposalByLeadSuccess(response.data.result));
        } else {
            yield put(getListProposalByLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListProposalByLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

// function* getListTaskByLeadSaga(action) {
//     try {
//         const response = yield call(getListTaskBylead, action);
//         if (response && response.data && response.data.error_code === responeCode.success) {
//             yield put(getTaskByLeadSuccess(response.data.result));
//         }
//         else {
//             yield put(getTaskByLeadFailed(response.data.error_mess));
//             yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
//         }
//     } catch (error) {
//         yield put(getTaskByLeadFailed());
//         yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
//     }
// }

function* createProposalSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(createProposal, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(createProposalSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        "Create proposal success",
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(createProposalFailed(response.data.error_mess));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(createProposalFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* setIsNoContactSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(setIsNoContact, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(setIsNoContactSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        "Set no contact success",
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(setIsNoContactFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(setIsNoContactFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* setIsTrashSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(setIsTrash, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(setIsTrashSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        "Set is trash success",
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(setIsTrashFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(setIsTrashFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* switchToCustomerSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(switchToCustomer, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(switchToCustomerSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        "Create remind success",
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(switchToCustomerFailed(response.data.error_mess));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(switchToCustomerFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* createTaskSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(createTask, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(createTaskSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        "Create task success",
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(createTaskFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(createTaskFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getListCurrencySaga() {
    try {
        const response = yield call(getListCurrency);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListCurrencySuccess(response.data.result));
        } else {
            yield put(getListCurrencyFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListCurrencyFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getListItemsSaga() {
    try {
        const response = yield call(getListItems);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListItemsSuccess(response.data.result));
        } else {
            yield put(getListItemsFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListItemsFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getListGroupsItemSaga() {
    try {
        const response = yield call(getListGroupsItem);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListGroupItemsSuccess(response.data.result));
        } else {
            yield put(getListGroupItemsFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListGroupItemsFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
// Fetch list Proposal Lead

function* getListProposalLeadSaga(data) {
    try {
        const response = yield call(fetchListProposalLead, data.payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListProposalLeadSuccess(response.data.result));
            if (response.data.length > 0) {
                yield put(
                    enqueueSnackbar(
                        parseDataNotifications(
                            "fetch list proposal lead success",
                            typeMessage.success
                        )
                    )
                );
            }
        } else {
            yield put(fetchListProposalLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListProposalLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getListStaffSaga(data) {
    try {
        const response = yield call(getListStaff, data.payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListStaffSuccess(response.data.result));
        } else {
            yield put(fetchListStaffFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListStaffFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchListReminderLeadSaga(action) {
    const { payload } = action;
    try {
        const response = yield call(fetchListReminderLeadAPI, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListReminderLeadSuccess(response.data.result));
        } else {
            yield put(fetchListReminderLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListReminderLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* createReminderLeadSaga(action) {
    const { payload, id } = action;
    try {
        const response = yield call(createReminderLead, id, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(createReminderLeadSuccess(payload));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(createReminderLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(createReminderLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* deleteReminderLeadSaga(action) {
    const { payload } = action;
    try {
        const response = yield call(deleteReminderLead, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteReminderLeadSuccess(payload));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteReminderLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteReminderLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* updateReminderLeadSaga(action) {
    const { payload, id, params } = action;
    try {
        const response = yield call(updateReminderLead, id, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(updateReminderLeadSuccess());
            yield put(
                fetchListReminderLead({ id: payload.rel_id, paging: params })
            );
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(updateReminderLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(updateReminderLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchNoteLeadSaga(action) {
    const { payload } = action;
    try {
        const response = yield call(fetchNoteLead, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchNoteLeadSuccess(response.data.result));
            // yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
        } else {
            yield put(fetchNoteLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchNoteLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* createNoteLeadSaga(action) {
    const { payload, id } = action;
    try {
        const response = yield call(createNoteLead, id, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(createNoteLeadSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        "Create note success",
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(createNoteLeadFailed(response.data.error_mess));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(createNoteLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* deleteNoteLeadSaga(action) {
    const { payload } = action;
    try {
        const response = yield call(deleteNoteLead, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteNoteLeadSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteNoteLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteNoteLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* updateNoteLeadSaga(action) {
    const { payload, id } = action;
    try {
        const response = yield call(updateNoteLead, id, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(updateNoteLeadSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(updateNoteLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(updateNoteLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchListAttachmentLeadSaga(action) {
    const { payload } = action;
    try {
        const response = yield call(fetchListAttachmentLead, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListAttachmentLeadSuccess(response.data.result));
        } else {
            yield put(fetchListAttachmentLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListAttachmentLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* createAttachmentLeadSaga(action) {
    const { payload, id } = action;
    try {
        const response = yield call(createAttachmentLead, id, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(createAttachmentLeadSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(createAttachmentLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(createAttachmentLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* deleteAttachmentLeadSaga(action) {
    const { payload } = action;
    try {
        const response = yield call(deleteAttachmentLead, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(deleteAttachmentLeadSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteAttachmentLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteAttachmentLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
// saga detail task module lead

function* fetchListTaskLeadSaga(action) {
    const { payload } = action;
    try {
        const response = yield call(fetchListTaskLead, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListTaskLeadSuccess(response.data.result));
        } else {
            yield put(fetchListTaskLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListTaskLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* convertToCustomerSaga({ payload }) {
    // const { params } = payload;
    try {
        const response = yield call(convertToCustomer, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(convertToCustomerLeadSuccess());
            // yield put(fetchListTaskLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(fetchListTaskLeadFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(convertToCustomerLeadFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

export function* leadSaga() {
    yield takeEvery(CONVERT_TO_CUSTOMER_LEAD_PENDING, convertToCustomerSaga);
    yield takeEvery(FETCH_LIST_LEADS, fetchListLeadSaga);
    yield takeEvery(CREATE_NEW_LEAD, createNewLeadSaga);
    yield takeEvery(FETCH_LIST_STATUS_LEADS, fetchListStatusSaga);
    yield takeEvery(UPDATE_STATUS_LEAD, updateStatusLeadSaga);
    yield takeEvery(GET_LIST_CREATED_BY, getListCreatedBySaga);
    yield takeEvery(GET_LIST_SOURCE, getListSourceSaga);
    yield takeEvery(DELETE_LEAD, deleteLeadSaga);

    yield takeEvery(GET_LIST_COUNTRY, getListCountriesSaga);
    yield takeEvery(UPDATE_LEAD, updateLeadSaga);
    yield takeEvery(GET_LIST_PROPOSAL_BY_LEAD, getListProposalByLeadSaga);
    yield takeEvery(GET_LIST_PROPOSAL, getListProposalSaga);
    // yield takeEvery(GET_TASK_BY_LEAD, getListTaskByLeadSaga);
    yield takeEvery(CREATE_PROPOSAL, createProposalSaga);
    yield takeEvery(SET_IS_NO_CONTACT, setIsNoContactSaga);
    yield takeEvery(SET_IS_TRASH, setIsTrashSaga);
    yield takeEvery(SWITCH_TO_CUSTOMER, switchToCustomerSaga);
    yield takeEvery(CREATE_TASK, createTaskSaga);
    // yield takeEvery(SAVE_ACTIVITY, saveActivitySaga);
    yield takeEvery(GET_LIST_CURRENCY, getListCurrencySaga);
    yield takeEvery(GET_LIST_ITEMS, getListItemsSaga);
    yield takeEvery(GET_LIST_GROUP_ITEMS, getListGroupsItemSaga);
    yield takeEvery(FETCH_LIST_PROPOSAL_LEAD, getListProposalLeadSaga);
    yield takeEvery(FETCH_LIST_STAFF, getListStaffSaga);
    yield takeEvery(FETCH_LIST_REMINDER_LEAD, fetchListReminderLeadSaga);
    yield takeEvery(CREATE_REMINDER_LEAD, createReminderLeadSaga);
    yield takeEvery(DELETE_REMINDER_LEAD, deleteReminderLeadSaga);
    yield takeEvery(UPDATE_REMINDER_LEAD, updateReminderLeadSaga);
    yield takeEvery(FETCH_NOTE_LEAD, fetchNoteLeadSaga);
    yield takeEvery(CREATE_NOTE_LEAD, createNoteLeadSaga);
    yield takeEvery(DELETE_NOTE_LEAD, deleteNoteLeadSaga);
    yield takeEvery(UPDATE_NOTE_LEAD, updateNoteLeadSaga);
    yield takeEvery(FETCH_LIST_ATTACHMENT_LEAD, fetchListAttachmentLeadSaga);
    yield takeEvery(CREATE_ATTACHMENT_LEAD, createAttachmentLeadSaga);
    yield takeEvery(DELETE_ATTACHMENT_LEAD, deleteAttachmentLeadSaga);

    yield takeEvery(FETCH_LIST_TASK_LEAD, fetchListTaskLeadSaga);
}

function* LeadSaga() {
    yield all([fork(leadSaga)]);
}

export default LeadSaga;
