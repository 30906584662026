import React from "react";
import PropTypes from "prop-types";
import {
    TableHead,
    TableSortLabel,
    TableCell,
    Checkbox,
    TableRow,
} from "@material-ui/core";

const TableHeader = (props) => {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        columns,
        checkboxSelection,
        keySelect
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {checkboxSelection && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ "aria-label": "select all desserts" }}
                            color="primary"
                        />
                    </TableCell>
                )}

                {columns.map((item) => (
                    <TableCell
                        key={item.field}
                        align={item.numeric ? "right" : "left"}
                        padding={item.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === item.field ? order : false}
                        style={{ minWidth: item.width}}
                    >
                        {item.isSort ? (
                            <TableSortLabel
                                active={orderBy === item.field}
                                direction={orderBy === item.field ? order : "asc"}
                                onClick={createSortHandler(item.field)}
                            >
                                {item.name}
                                {orderBy === item.field ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        ):(
                            <span>{item.name}</span>
                        )}

                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

TableHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

export default TableHeader;