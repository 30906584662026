import _ from "lodash";
import apiBase from "../../../common/baseAPI";

const EXPENSES_URL = "expenses";
const EXPENSES_YEAR_URL = "expenses/year";
const getListExpenses = (data) => {
    const newData = _.pickBy(data, _.identity); // remove value null , undefind , '', 0
    let newUrl = EXPENSES_URL;
    Object.keys(newData).map(
        (item, index) =>
            (newUrl = `${newUrl}${index === 0 ? "?" : ""}${
                data[item] ? `&${item}=${data[item]}` : ""
            }`)
    );
    return new Promise((resolve, reject) => {
        return apiBase
            .get(newUrl)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getExpenseById = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${EXPENSES_URL}/${data}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteExpense = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${EXPENSES_URL}/${data}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListCustomer = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`customer`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListExpensesCategory = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`expenses-category`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createExpense = (body) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(EXPENSES_URL, body)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const editExpense = (id, body) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${EXPENSES_URL}/${id}`, body)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getListProject = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`project`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const getListSummary = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${EXPENSES_YEAR_URL}?year=[${data.name}]`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export {
    getListExpenses,
    getExpenseById,
    deleteExpense,
    getListCustomer,
    getListExpensesCategory,
    createExpense,
    editExpense,
    getListProject,
    getListSummary,
};
