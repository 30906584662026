import ActionTypes from './types';

const INIT_STATE = {
    listTicketPriority: [],
    isCloseDialog: false,
    isLoading: false,
    deleteTicket: false
};

const AdminSupportTicketPriorityReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_LIST_TICKET_PRIORITY_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case ActionTypes.GET_LIST_TICKET_PRIORITY_SUCCESS:
            return {
                ...state,
                listTicketPriority: action.payload,
                isLoading: false,
            };

        case ActionTypes.GET_LIST_TICKET_PRIORITY_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.CREATE_TICKET_PRIORITY_REQUEST:
            return {
                ...state,
                isLoading: true
            }

        case ActionTypes.CREATE_TICKET_PRIORITY_SUCCESS:
            state.listTicketPriority.data.unshift(action.payload)
            return {
                ...state,
                isCloseDialog: true,
                isLoading: false,
                listTicketPriority: state.listTicketPriority
            };

        case ActionTypes.CREATE_TICKET_PRIORITY_FAILED:
            return {
                ...state,
                isLoading: false,
                isCloseDialog: true,
            };

        case ActionTypes.EDIT_TICKET_PRIORITY_REQUEST:
            return {
                ...state,
                isLoading: true
            }

        case ActionTypes.EDIT_TICKET_PRIORITY_SUCCESS:
            let itemIndex = state.listTicketPriority.data.findIndex(item => item.priorityid === action.payload.priorityid)
            state.listTicketPriority.data.splice(itemIndex, 1, action.payload)
            return {
                ...state,
                isCloseDialog: true,
                isLoading: false,
                listTicketPriority: state.listTicketPriority
            }

        case ActionTypes.EDIT_TICKET_PRIORITY_FAILED:
            return {
                ...state,
                isLoading: false,
                isCloseDialog: true,
            };

        case ActionTypes.DELETE_TICKET_PRIORITY_REQUEST:
            return {
                ...state,
                isLoading: true
            }

        case ActionTypes.DELETE_TICKET_PRIORITY_SUCCESS:
            return {
                ...state,
                isCloseDialog: true,
                isLoading: false,
                deleteTicket: true
            };
        case ActionTypes.CHANGE_STATUS_DIALOG:
            return {
                ...state,
                isCloseDialog: false
            }

        case ActionTypes.DELETE_TICKET_PRIORITY_FAILED:
            return {
                ...state,
                isLoading: false,
                isCloseDialog: true,
            };

        case ActionTypes.FETCH_TICKET_PRIORITY_REQUEST:
            return {
                ...state,
                isLoading: true,
                isCloseDialog: false,
            }
        case ActionTypes.FETCH_TICKET_PRIORITY_SUCCESS:
            return {
                ...state,
                listTicketPriority: action.payload,
                isLoading: false,
                deleteTicket: false
            };

        case ActionTypes.FETCH_TICKET_PRIORITY_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        default: return { ...state };
    }
}

export default AdminSupportTicketPriorityReducer;