import apiBase from "../../../../common/baseAPI";

const CUSTOMER_EXPENSES = "reminder/customer";
const EXPENSES = "expenses";

export const addExpensesCustomerRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .post(`${CUSTOMER_EXPENSES}/${action.id}`, action.data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
