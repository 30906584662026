import apiBase from "./../../../../../common/baseAPI";

const PATH_URL = 'department';

const getListSupportDepartment = () => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(PATH_URL)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createSupportDepartment = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(PATH_URL, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const editSupportDepartment = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${PATH_URL}/${data.departmentid}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteSupportDepartment = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${PATH_URL}/${data}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const fetchSupportDepartment = (action) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${PATH_URL}?search=${action.search}&&limit=${action.params.limit}&&page=${action.params.page}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};


export {
    getListSupportDepartment,
    createSupportDepartment,
    editSupportDepartment,
    deleteSupportDepartment,
    fetchSupportDepartment
}