import ActionTypes from './types';

export const getListSupportSpamFilterRequest = () => {
    return {
        type: ActionTypes.GET_LIST_SUPPORT_SPAM_FILTER_REQUEST
    }
};

export const getListSupportSpamFilterSuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_SUPPORT_SPAM_FILTER_SUCCESS,
        payload: data
    }
};

export const getListSupportSpamFilterFailed = () => {
    return {
        type: ActionTypes.GET_LIST_SUPPORT_SPAM_FILTER_FAILED,
    }
};

export const createSupportSpamFilterRequest = (data) => {
    return {
        type: ActionTypes.CREATE_SUPPORT_SPAM_FILTER_REQUEST,
        payload: data,
    }
};

export const createSupportSpamFilterSuccess = (data) => {
    return {
        type: ActionTypes.CREATE_SUPPORT_SPAM_FILTER_SUCCESS,
        payload: data
    }
};

export const createSupportSpamFilterFailed = () => {
    return {
        type: ActionTypes.CREATE_SUPPORT_SPAM_FILTER_FAILED
    }
};

export const editSupportSpamFilterRequest = (data) => {
    return {
        type: ActionTypes.EDIT_SUPPORT_SPAM_FILTER_REQUEST,
        payload: data,
    }
};

export const editSupportSpamFilterSuccess = (data) => {
    return {
        type: ActionTypes.EDIT_SUPPORT_SPAM_FILTER_SUCCESS,
        payload: data
    }
};

export const editSupportSpamFilterFailed = () => {
    return {
        type: ActionTypes.EDIT_SUPPORT_SPAM_FILTER_FAILED
    }
};

export const deleteSupportSpamFilterRequest = (data) => {
    return {
        type: ActionTypes.DELETE_SUPPORT_SPAM_FILTER_REQUEST,
        payload: data,
    }
};

export const deleteSupportSpamFilterSuccess = (data) => {
    return {
        type: ActionTypes.DELETE_SUPPORT_SPAM_FILTER_SUCCESS,
        payload: data
    }
};

export const deleteSupportSpamFilterFailed = () => {
    return {
        type: ActionTypes.DELETE_SUPPORT_SPAM_FILTER_FAILED,
    }
};

export const changeStatusDialog = () => {
    return {
        type: ActionTypes.CHANGE_STATUS_DIALOG,
    }
};

export const fetchSupportSpamFilterRequest = (search, params) => {
    return {
        type: ActionTypes.FETCH_SUPPORT_SPAM_FILTER_REQUEST,
        search, params
    }
};

export const fetchSupportSpamFilterSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_SUPPORT_SPAM_FILTER_SUCCESS,
        payload: data
    }
};

export const fetchSupportSpamFilterFailed = () => {
    return {
        type: ActionTypes.FETCH_SUPPORT_SPAM_FILTER_FAILED,
    }
};


//GET ALL SPAM FILTER 
export const getAllSupportSpamFilterRequest = () => {
    return {
        type: ActionTypes.GET_ALL_SUPPORT_SPAM_FILTER_REQUEST

    }
};

export const getAllSupportSpamFilterSuccess = (data) => {
    return {
        type: ActionTypes.GET_ALL_SUPPORT_SPAM_FILTER_SUCCESS,
        payload: data
    }
};

export const getAllSupportSpamFilterFailed = () => {
    return {
        type: ActionTypes.GET_ALL_SUPPORT_SPAM_FILTER_FAILED,
    }
};

