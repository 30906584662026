import { GET_SALE_INVOICE_FAILED, GET_SALE_INVOICE_REQUEST, GET_SALE_INVOICE_SUCCESS } from "./types";

const INIT_STATE = {
    isLoading: false,
    listCustomFieldInvoice: [],
};

const SaleInvoiceReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SALE_INVOICE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_SALE_INVOICE_SUCCESS:

            return {
                ...state,
                listCustomFieldInvoice: action.payload,
                isLoading: false,
            };
        case GET_SALE_INVOICE_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return {...state};
    }
};

export default SaleInvoiceReducer;