import ActionTypes from "./type";

const INIT_STATE = {
  listReminder: [],
  isLoading: false,
  statusAddSucces: false,
  statusDeleteSucces: false,
};

const NotesCustomerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_REMINDER_CUSTOMER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_REMINDER_CUSTOMER_SUCCESS:
      return {
        ...state,
        listReminder: action.data,
        isLoading: false,
      };
    case ActionTypes.FETCH_REMINDER_CUSTOMER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.ADD_REMINDER_CUSTOMER_REQUEST:
      return {
        ...state,
      };
    case ActionTypes.ADD_REMINDER_CUSTOMER_SUCCESS:
      return {
        ...state,
        statusAddSucces: !state.statusAddSucces,
      };
    case ActionTypes.ADD_REMINDER_CUSTOMER_FAILED:
      return {
        ...state,
      };
    case ActionTypes.DELETE_REMINDER_CUSTOMER_REQUEST:
      return {
        ...state,
      };
    case ActionTypes.DELETE_REMINDER_CUSTOMER_SUCCESS:
      return {
        ...state,
        statusDeleteSucces: !state.statusDeleteSucces,
      };
    case ActionTypes.DELETE_REMINDER_CUSTOMER_FAILED:
      return {
        ...state,
      };

    default:
      return { ...state };
  }
};

export default NotesCustomerReducer;
