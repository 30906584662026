import { all, call, fork, put, takeEvery } from "@redux-saga/core/effects";
import { responeCode, typeMessage } from "../../../../constants/Const";
import {
    parseDataNotifications,
    throwErrorMessage,
} from "../../../../helpers/HandleError";
import { enqueueSnackbar } from "../../../../redux/CommonReducer";

import {
    addNewItemFailure,
    addNewItemSuccess,
    fetchListItemGroupsFailure,
    fetchListItemGroupsSuccess,
    fetchListItemsFailure,
    fetchListItemsPending,
    fetchListItemsSuccess,
    fetchListTaxFailure,
    fetchListTaxSuccess,
} from "./actions";
import {
    addNewItem,
    getListItemGroups,
    getListItemsAPI,
    getListTax,
} from "./apis";
import {
    ADD_NEW_ITEM_PENDING,
    FETCH_LIST_ITEM_GROUPS_PENDING,
    FETCH_SALES_ITEM_PENDING,
    FETCH_SALES_TAX_PENDING,
} from "./types";
// import { getListItemsAPI, getListTax } from "./apis";
// import { FETCH_SALES_ITEM_PENDING, FETCH_SALES_TAX_PENDING } from "./types";

function* fetchListItemsSaga(data) {
    const { payload } = data;

    try {
        const response = yield call(getListItemsAPI, payload);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListItemsSuccess(response.data.result));
        } else {
            yield put(fetchListItemsFailure());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListItemsFailure());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* fetchListTaxSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(getListTax, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListTaxSuccess(response.data.result));
            // yield put(
            //     enqueueSnackbar(
            //         parseDataNotifications(
            //             response.data.error_mess,
            //             typeMessage.success
            //         )
            //     )
            // );
        } else {
            yield put(fetchListTaxFailure());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListTaxFailure());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* fetchListItemGroupsSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(getListItemGroups, payload);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchListItemGroupsSuccess(response.data.result));
            // yield put(
            //     enqueueSnackbar(
            //         parseDataNotifications(
            //             response.data.error_mess,
            //             typeMessage.success
            //         )
            //     )
            // );
        } else {
            yield put(fetchListItemGroupsFailure());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListItemGroupsFailure());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
// Add new item
function* addNewItemSaga(data) {
    const { payload, params } = data;
    try {
        const response = yield call(addNewItem, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(addNewItemSuccess(response.data.result));
            // yield put(
            //     enqueueSnackbar(
            //         parseDataNotifications(
            //             response.data.error_mess,
            //             typeMessage.success
            //         )
            //     )
            // );
            yield put(fetchListItemsPending(params));
        } else {
            yield put(addNewItemFailure());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchListItemGroupsFailure());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
export function* itemsSaga() {
    yield takeEvery(FETCH_SALES_ITEM_PENDING, fetchListItemsSaga);
}
export function* taxSaga() {
    yield takeEvery(FETCH_SALES_TAX_PENDING, fetchListTaxSaga);
}

export function* itemGroupsSaga() {
    yield takeEvery(FETCH_LIST_ITEM_GROUPS_PENDING, fetchListItemGroupsSaga);
}
export function* newItemSaga() {
    yield takeEvery(ADD_NEW_ITEM_PENDING, addNewItemSaga);
}

function* ItemsSaga() {
    yield all([
        fork(itemsSaga),
        fork(taxSaga),
        fork(itemGroupsSaga),
        fork(newItemSaga),
    ]);
}
export default ItemsSaga;
