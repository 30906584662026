// @flow
import { all, call, put, takeEvery } from 'redux-saga/effects';
import ActionTypes from './types';
import {
    getListSupportSpamFilterFailed,
    getListSupportSpamFilterSuccess,
    createSupportSpamFilterFailed,
    createSupportSpamFilterSuccess,
    editSupportSpamFilterFailed,
    editSupportSpamFilterSuccess,
    deleteSupportSpamFilterFailed,
    deleteSupportSpamFilterSuccess,
    fetchSupportSpamFilterSuccess,
    fetchSupportSpamFilterFailed,
    getAllSupportSpamFilterFailed,
    getAllSupportSpamFilterSuccess
} from './actions';
import { enqueueSnackbar } from '../../../../../redux/CommonReducer';
import {
    getListSupportSpamFilter,
    createSupportSpamFilter,
    editSupportSpamFilter,
    deleteSupportSpamFilter,
    fetchSupportSpamFilter,
    getSpamFilterTypeofSender,
    getSpamFilterTypeofSubject,
    getSpamFilterTypeofPhrase
} from './api';
import { responeCode, typeMessage } from '../../../../../constants/Const';
import { throwErrorMessage, parseDataNotifications } from '../../../../../helpers/HandleError';

function* getAllSpamfilterSaga() {
    try {
        const [blockedSender, blockedSubject, blockedPhrases] = yield all([
            call(getSpamFilterTypeofSender),
            call(getSpamFilterTypeofSubject),
            call(getSpamFilterTypeofPhrase)
        ])
        if (blockedSender && blockedSubject && blockedPhrases) {
            if (!blockedSender.data || blockedSender.data.error_code !== responeCode.success || !blockedPhrases.data.result
                || !blockedSubject.data || blockedSender.data.error_code !== responeCode.success || !blockedSubject.data.result
                || !blockedPhrases.data || blockedPhrases.data.error_code !== responeCode.success || !blockedPhrases.data.result) {
                yield put(getAllSupportSpamFilterFailed());
                if (!(blockedSender.data && blockedSender.data.error_code === responeCode.success)) {
                    yield put(enqueueSnackbar(parseDataNotifications(blockedSender.data.error_mess, typeMessage.error)));
                }
                if (!(blockedSubject.data && blockedSubject.data.error_code === responeCode.success)) {
                    yield put(enqueueSnackbar(parseDataNotifications(blockedSubject.data.error_mess, typeMessage.error)));
                }
                if (!(blockedPhrases.data && blockedPhrases.data.error_code === responeCode.success)) {
                    yield put(enqueueSnackbar(parseDataNotifications(blockedPhrases.data.error_mess, typeMessage.error)));
                }
                return;
            } else {
                let listAll = [blockedSender.data.result, blockedSubject.data.result, blockedPhrases.data.result]
                yield put(getAllSupportSpamFilterSuccess(listAll));
            }
        } else {
            yield put(getAllSupportSpamFilterFailed());
        }
    } catch (error) {
        yield put(getAllSupportSpamFilterFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
}

function* getListSupportSpamFilterSaga() {
    try {
        const response = yield call(getListSupportSpamFilter);
        if (response && response.data && response.data.error_code === responeCode.success && response.data.result) {
            yield put(getListSupportSpamFilterSuccess(response.data.result));
        }
        else {
            yield put(getListSupportSpamFilterFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(getListSupportSpamFilterFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

function* createSupportSpamFilterSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(createSupportSpamFilter, payload);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(createSupportSpamFilterSuccess(response.data.result));
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
        }
        else {
            yield put(createSupportSpamFilterFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(createSupportSpamFilterFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

function* editSupportSpamFilterSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(editSupportSpamFilter, payload);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(editSupportSpamFilterSuccess(response.data.result));
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));

        }
        else {
            yield put(editSupportSpamFilterFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(editSupportSpamFilterFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

function* deleteSupportSpamFilterSaga(data) {
    const { payload } = data;

    try {
        const response = yield call(deleteSupportSpamFilter, payload);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(deleteSupportSpamFilterSuccess(payload));
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
        }
        else {
            yield put(deleteSupportSpamFilterFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(deleteSupportSpamFilterFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};
function* fetchSupportSpamFilterSaga(action) {
    try {
        const response = yield call(fetchSupportSpamFilter, action);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(fetchSupportSpamFilterSuccess(response.data.result));
        }
        else {
            yield put(fetchSupportSpamFilterFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(fetchSupportSpamFilterFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};


export function* adminSupportSpamFilterSaga() {
    yield takeEvery(ActionTypes.GET_ALL_SUPPORT_SPAM_FILTER_REQUEST, getAllSpamfilterSaga)
    yield takeEvery(ActionTypes.GET_LIST_SUPPORT_SPAM_FILTER_REQUEST, getListSupportSpamFilterSaga);
    yield takeEvery(ActionTypes.CREATE_SUPPORT_SPAM_FILTER_REQUEST, createSupportSpamFilterSaga);
    yield takeEvery(ActionTypes.EDIT_SUPPORT_SPAM_FILTER_REQUEST, editSupportSpamFilterSaga);
    yield takeEvery(ActionTypes.DELETE_SUPPORT_SPAM_FILTER_REQUEST, deleteSupportSpamFilterSaga);
    yield takeEvery(ActionTypes.FETCH_SUPPORT_SPAM_FILTER_REQUEST, fetchSupportSpamFilterSaga);

}


export default adminSupportSpamFilterSaga;