// @flow
import { call, put, takeEvery } from 'redux-saga/effects';
import {
    GET_LIST_SUPPORT_DEPARTMENT_REQUEST,
    CREATE_SUPPORT_DEPARTMENT_REQUEST,
    EDIT_SUPPORT_DEPARTMENT_REQUEST,
    DELETE_SUPPORT_DEPARTMENT_REQUEST,
    FETCH_SUPPORT_DEPARTMENT_REQUEST
} from './types';
import {
    getListSupportDepartmentSuccess,
    getListSupportDepartmentFailed,
    createSupportDepartmentSuccess,
    createSupportDepartmentFailed,
    editSupportDepartmentSuccess,
    editSupportDepartmentFailed,
    deleteSupportDepartmentSuccess,
    deleteSupportDepartmentFailed,
    fetchSupportDepartmentSuccess,
    fetchSupportDepartmentFailed
} from './actions';
import { enqueueSnackbar } from '../../../../../redux/CommonReducer';
import {
    getListSupportDepartment,
    createSupportDepartment,
    editSupportDepartment,
    deleteSupportDepartment,
    fetchSupportDepartment,
} from './api';
import { responeCode, typeMessage } from '../../../../../constants/Const';
import { throwErrorMessage, parseDataNotifications } from '../../../../../helpers/HandleError';

function* getListSupportDepartmentSaga() {
    try {
        const response = yield call(getListSupportDepartment);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(getListSupportDepartmentSuccess(response.data.result));
        }
        else {
            yield put(getListSupportDepartmentFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(getListSupportDepartmentFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

function* createSupportDepartmentSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(createSupportDepartment, payload);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(createSupportDepartmentSuccess(response.data.result));
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
        }
        else {
            yield put(createSupportDepartmentFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(createSupportDepartmentFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

function* editSupportDepartmentSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(editSupportDepartment, payload);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(editSupportDepartmentSuccess(response.data.result));
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));

        }
        else {
            yield put(editSupportDepartmentFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(editSupportDepartmentFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

function* deleteSupportDepartmentSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(deleteSupportDepartment, payload);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(deleteSupportDepartmentSuccess(payload));
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
        }
        else {
            yield put(deleteSupportDepartmentFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(deleteSupportDepartmentFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

function* fetchSupportDepartmentSaga(action) {
    try {
        const response = yield call(fetchSupportDepartment, action);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(fetchSupportDepartmentSuccess(response.data.result));
        }
        else {
            yield put(fetchSupportDepartmentFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(fetchSupportDepartmentFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};
export function* adminSupportDepartmentSaga() {
    yield takeEvery(GET_LIST_SUPPORT_DEPARTMENT_REQUEST, getListSupportDepartmentSaga);
    yield takeEvery(CREATE_SUPPORT_DEPARTMENT_REQUEST, createSupportDepartmentSaga);
    yield takeEvery(EDIT_SUPPORT_DEPARTMENT_REQUEST, editSupportDepartmentSaga);
    yield takeEvery(DELETE_SUPPORT_DEPARTMENT_REQUEST, deleteSupportDepartmentSaga);
    yield takeEvery(FETCH_SUPPORT_DEPARTMENT_REQUEST, fetchSupportDepartmentSaga);
}

export default adminSupportDepartmentSaga;