import _ from "lodash";
import apiBase from "../../../common/baseAPI";
const URL = "project/activity";

export const getProjectActivity = (projectId) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${URL}/${projectId}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editProjectActivity = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .post(`${URL}/${action.projectId}`, action.data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
