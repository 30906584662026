import apiBase from "../../../../common/baseAPI";

const CUSTOMER_CONTACT = "contact/customer";
const CONTACT_ACTIVE = "contact/active";
const CONTACT = "contact";

export const fetchContactCustomerRequest = (action) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(
                `${CUSTOMER_CONTACT}/${action.id}?search=${action.value}&&limit=${action.params.limit}&&page=${action.params.page}`
            )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const editActiveRequest = (action) => {
    const data = {
        active: action.value ? 1 : 0,
    };
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${CONTACT_ACTIVE}/${action.id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const addContactRequest = (action) => {
    const newFormData = new FormData();
    newFormData.append("profile_image", action.data.profile_image);
    newFormData.append("firstname", action.data.firstname);
    newFormData.append("lastname", action.data.lastname);
    newFormData.append("title", action.data.title);
    newFormData.append("email", action.data.email);
    newFormData.append("phonenumber", action.data.phonenumber);
    newFormData.append("direction", action.data.direction);
    newFormData.append("password", action.data.password);
    newFormData.append("is_primary", action.data.is_primary);
    newFormData.append("invoice_emails", action.data.invoice_emails);
    newFormData.append("credit_note_emails", action.data.credit_note_emails);
    newFormData.append("project_emails", action.data.project_emails);
    newFormData.append("ticket_emails", action.data.ticket_emails);
    newFormData.append("contract_emails", action.data.contract_emails);
    newFormData.append("task_emails", action.data.task_emails);
    const header = { "Content-Type": "multipart/form-data" };
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${CUSTOMER_CONTACT}/${action.id}`, newFormData, header)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const editContactRequest = (action) => {
    const newFormData = new FormData();
    newFormData.append("profile_image", action.data.profile_image);
    newFormData.append("firstname", action.data.firstname);
    newFormData.append("lastname", action.data.lastname);
    newFormData.append("title", action.data.title);
    newFormData.append("email", action.data.email);
    newFormData.append("phonenumber", action.data.phonenumber);
    newFormData.append("direction", action.data.direction);
    newFormData.append("password", action.data.password);
    newFormData.append("is_primary", action.data.is_primary);
    newFormData.append("invoice_emails", action.data.invoice_emails);
    newFormData.append("credit_note_emails", action.data.credit_note_emails);
    newFormData.append("project_emails", action.data.project_emails);
    newFormData.append("ticket_emails", action.data.ticket_emails);
    newFormData.append("contract_emails", action.data.contract_emails);
    newFormData.append("task_emails", action.data.task_emails);
    const header = { "Content-Type": "multipart/form-data" };
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${CONTACT}/${action.id}`, newFormData, header)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const deleteContactRequest = (action) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${CONTACT}/${action.id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
