//fetch invoices 
export const FETCH_INVOICES_PROJECT = 'FETCH_INVOICES_PROJECT';
export const FETCH_INVOICES_PROJECT_SUCCESS = 'FETCH_INVOICES_PROJECT_SUCCESS';
export const FETCH_INVOICES_PROJECT_FAILED = 'FETCH_INVOICES_PROJECT_FAILED';

// filter year with invoice
export const FETCH_FILTER_YEAR_INVOICE_PRJ = 'FETCH_FILTER_YEAR_INVOICE_PRJ';
export const FETCH_FILTER_YEAR_INVOICE_PRJ_SUCCESS = 'FETCH_FILTER_YEAR_INVOICE_PRJ_SUCCESS';
export const FETCH_FILTER_YEAR_INVOICE_PRJ_FAILED = 'FETCH_FILTER_YEAR_INVOICE_PRJ_FAILED';

// get total invoice
export const FETCH_TOTAL_INVOICE_PRJ = 'FETCH_TOTAL_INVOICE_PRJ';
export const FETCH_TOTAL_INVOICE_PRJ_SUCCESS = 'FETCH_TOTAL_INVOICE_PRJ_SUCCESS';
export const FETCH_TOTAL_INVOICE_PRJ_FAILED = 'FETCH_TOTAL_INVOICE_PRJ_FAILED';

// get currencies
export const FETCH_CURRENCIES = 'FETCH_CURRENCIES';
export const FETCH_CURRENCIES_SUCCESS = 'FETCH_CURRENCIES_SUCCESS';
export const FETCH_CURRENCIES_FAILED = 'FETCH_CURRENCIES_FAILED';

//get user sale invoice
export const FETCH_STAFF_INVOICE_PRJ = 'FETCH_STAFF_INVOICE_PRJ';
export const FETCH_STAFF_INVOICE_PRJ_SUCCESS = 'FETCH_STAFF_INVOICE_PRJ_SUCCESS';
export const FETCH_STAFF_INVOICE_PRJ_FAILED = 'FETCH_STAFF_INVOICE_PRJ_FAILED';

//filter
export const FILTER_INVOICE_PRJ = 'FILTER_INVOICE_PRJ';
export const FILTER_INVOICE_PRJ_SUCCESS = 'FILTER_INVOICE_PRJ_SUCCESS';
export const FILTER_INVOICE_PRJ_FAILED = 'FILTER_INVOICE_PRJ_FAILED';