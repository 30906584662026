import apiBase from "../../../../common/baseAPI";

const CUSTOMER_VAULT = "vault/customer";

export const addVaultCustomerRequest = (action) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`${CUSTOMER_VAULT}/${action.id}`, action.data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
