import React from "react";
import { PrivateRoute } from "./PrivateRoute";
import i18n from "../i18n.js";
import { customer, lead } from "../constants/Route.js";

const CustomerReminders = React.lazy(() => import("../pages/Customer/Reminders/index.js"));
const CustomerFiles = React.lazy(() => import("../pages/Customer/Files/index.js"));
const CustomerTickets = React.lazy(() => import("../pages/Customer/Tickets/index.js"));
const CustomerVault = React.lazy(() => import("../pages/Customer/Vault/index.js"));
const CustomerTasks = React.lazy(() => import("../pages/Customer/Tasks/index.js"));
const Customer = React.lazy(() => import("../pages/Customer"));
const CustomerProfile = React.lazy(() => import("../pages/Customer/Profile/index.js"));
const CustomerContacts = React.lazy(() => import("../pages/Customer/Contacts/index.js"));
const CustomerContracts = React.lazy(() => import("../pages/Contract/ContractsCustomer/index"));
const CustomerNewContracts = React.lazy(() => import("../pages/Contract/ContractsCustomer/NewContracts"));
const CustomerViewContracts = React.lazy(() => import("../pages/Contract/ContractsCustomer/ViewContracts"));
const CustomerNewSignature = React.lazy(() => import("../pages/Contract/ContractsCustomer/NewSignature"));
const CustomerNotes = React.lazy(() => import("../pages/Customer/Notes/index.js"));
const CustomerProjects = React.lazy(() => import("../pages/Project/Customer/index.js"));
const AddNewProject = React.lazy(() => import("../pages/Project/Add/NewProject.js"));
const Lead = React.lazy(() => import("../pages/Lead"));
const CustomerStatement = React.lazy(() => import("../pages/Customer/Statement/index.js"));
const CustomerInvoices = React.lazy(() => import("../pages/Customer/Invoices/index.js"));
const CustomerPayments = React.lazy(() => import("../pages/Customer/Payments/index.js"));
const ViewPayment = React.lazy(() => import("../pages/Sales/Payments/View"));
const Proposal = React.lazy(() => import("../pages/Proposal/index.js"));
const ProposalNew = React.lazy(() => import("../pages/Proposal/New/index.js"));
const ProposalEdit = React.lazy(() => import("../pages/Proposal/Edit/index.js"));
const ProposalbyCustomer = React.lazy(() => import("../pages/Proposal/Customer/index.js"));

const CustomerEstimates = React.lazy(() => import("../pages/Customer/Estimates/index.js"));
const Estimates = React.lazy(() => import("../pages/Estimates/index.js"));

const NewEstimate = React.lazy(() => import("../pages/Estimates/Add/index.js"));
const EditEstimate = React.lazy(() => import("../pages/Estimates/Edit/index.js"));


export const customersRoute = [
    {
        name: `${i18n.t("route.customer")}`,
        route: PrivateRoute,
        roles: ["Admin", "MENU"],
        title: `${i18n.t("route.customer")}`,
        childs: [
            {
                path: customer.index,
                name: `${i18n.t("route.customer")}`,
                component: Customer,
                route: PrivateRoute,
                roles: ["Admin"],
                exact: true,
                title: "Customer",
            },
            {
                path: lead.index,
                name: `${i18n.t("route.lead")}`,
                component: Lead,
                route: PrivateRoute,
                roles: ["Admin"],
                title: "Lead",
            }
        ]
        
    },
    {
        name: `${i18n.t("route.plan")}`,
        route: PrivateRoute,
        roles: ["Admin"],
        title: `${i18n.t("route.plan")}`,
        childs: [
            {
                path: "/proposal",
                name: `${i18n.t("route.proposal")}`,
                component: Proposal,
                route: PrivateRoute,
                roles: ["Admin"],
                title: `${i18n.t("route.proposal")}`,
                exact: true,
            },
            {
                path: "/estimates",
                name: `${i18n.t("route.estimates")}`,
                component: Estimates,
                route: PrivateRoute,
                roles: ["Admin"],
                title: `${i18n.t("route.estimates")}`,
                exact: true,
            },
            
        ],
    },
    {
        path: "/customer/estimates/:id",
        name: `Customer Estimates`,
        component: CustomerEstimates,
        route: PrivateRoute,
        roles: ["Admin", "estimates"],
        title: "Customer Estimates",
        exact: true,
    },
    {
        path: "/proposal/new",
        name: `Proposal_New`,
        component: ProposalNew,
        route: PrivateRoute,
        roles: ["Admin", "Proposal"],
        title: "New Proposal",
        exact: true,
    },
    {
        path: "/proposal/edit/:id",
        name: `Proposal_Edit`,
        component: ProposalEdit,
        route: PrivateRoute,
        roles: ["Admin", "Proposal"],
        title: "Edit Proposal",
        exact: true,
    },
    {
        path: "/customer/contract/new/:id",
        name: `Customer NewContracts`,
        component: CustomerNewContracts,
        route: PrivateRoute,
        roles: ["Admin", "newContracts"],
        title: "Customer NewContracts",
        exact: true,
    },
    {
        path: "/estimate/new",
        name: `New Estimate`,
        component: NewEstimate,
        route: PrivateRoute,
        roles: ["Admin", "estimate"],
        title: "New Estimate",
        exact: true,
    },
    {
        path: "/estimate/edit/:id",
        name: `Edit Estimate`,
        component: EditEstimate,
        route: PrivateRoute,
        roles: ["Admin", "estimate"],
        title: "Edit Estimate",
        exact: true,
    },
    {
        path: "/customer/payments/:id",
        name: `Customer Payments`,
        component: CustomerPayments,
        route: PrivateRoute,
        roles: ["Admin", "Payments"],
        title: "Customer Payments",
        exact: true,
    },
    {
        path: "/customer/payments/view/:id",
        name: `Customer Payments View`,
        component: ViewPayment,
        route: PrivateRoute,
        roles: ["Admin", "Payments"],
        title: "Customer Payments View",
        exact: true,
    },
    {
        path: "/customer/profile/:id",
        name: `Customer Profile`,
        component: CustomerProfile,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: "Customer Profile",
        exact: true,
    },
    {
        path: "/customer/contacts/:id",
        name: `Customer Contacts`,
        component: CustomerContacts,
        route: PrivateRoute,
        roles: ["Admin", "contacts"],
        title: "Customer Contacts",
        exact: true,
    },
    {
        path: "/customer/notes/:id",
        name: `Customer Notes`,
        component: CustomerNotes,
        route: PrivateRoute,
        roles: ["Admin", "notes"],
        title: "Customer Notes",
        exact: true,
    },
    {
        path: "/customer/statement/:id",
        name: `Customer Statement`,
        component: CustomerStatement,
        route: PrivateRoute,
        roles: ["Admin", "statement"],
        title: "Customer Statement",
        exact: true,
    },
    {
        path: "/customer/invoices/:id",
        name: `Customer Invoices`,
        component: CustomerInvoices,
        route: PrivateRoute,
        roles: ["Admin"],
        title: "Customer Invoices",
        exact: true,
    },
    {
        path: "/customer/vault/:id",
        name: `Customer Vault`,
        component: CustomerVault,
        route: PrivateRoute,
        roles: ["Admin", "vault"],
        title: "Customer Vault",
        exact: true,
    },
    {
        path: "/customer/reminders/:id",
        name: `Customer Reminders`,
        component: CustomerReminders,
        route: PrivateRoute,
        roles: ["Admin", "reminders"],
        title: "Customer Reminders",
        exact: true,
    },
    {
        path: "/customer/tasks/:id",
        name: `Customer Tasks`,
        component: CustomerTasks,
        route: PrivateRoute,
        roles: ["Admin", "tasks"],
        title: "Customer Tasks",
        exact: true,
    },
    {
        path: "/customer/proposal/:id",
        name: `Customer_Proposal`,
        component: ProposalbyCustomer,
        route: PrivateRoute,
        roles: ["Admin", "Proposal"],
        title: "ProposalbyCustomer",
        exact: true,
    },
    {
        path: "/customer/tickets/:id",
        name: `Customer Tickets`,
        component: CustomerTickets,
        route: PrivateRoute,
        roles: ["Admin", "tickets"],
        title: "Customer Tickets",
        exact: true,
    },
    {
        path: "/customer/files/:id",
        name: `Customer Files`,
        component: CustomerFiles,
        route: PrivateRoute,
        roles: ["Admin", "files"],
        title: "Customer Files",
        exact: true,
    },
    {
        path: "/customer/contracts/:id",
        name: `Customer Contracts`,
        component: CustomerContracts,
        route: PrivateRoute,
        roles: ["Admin", "contacts"],
        title: "Customer Contacts",
        exact: true,
    },
    {
        path: "/customer/contract/view/:id",
        name: `Customer ViewContracts`,
        component: CustomerViewContracts,
        route: PrivateRoute,
        roles: ["Admin", "viewContracts"],
        title: "Customer ViewContracts",
        exact: true,
    },
    {
        path: "/customer/contract/signature/new",
        name: `Customer NewSignature`,
        component: CustomerNewSignature,
        route: PrivateRoute,
        roles: ["Admin", "newSignature"],
        title: "Customer NewSignature",
        exact: true,
    },
    {
        path: "/customer/projects/:id",
        name: `Customer Projects`,
        component: CustomerProjects,
        route: PrivateRoute,
        roles: ["Admin", "projects"],
        title: "Customer Projects",
    },
    {
        path: "/customer/project/new/:id",
        name: "customer_project_new",
        component: AddNewProject,
        route: PrivateRoute,
        roles: ["Admin", "projects"],
        title: "New Project",
        exact: true,
    },
    
]