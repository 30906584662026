export default {
    contract: {
        summary: {
            active: "Active",
            expried: "Expried",
            aboutExpried: "About To Expried",
            recentlyAdded: "Recently Added",
            trash: "Trash"
        },
        index: {
            add: "New project",
            summary: 'Summary',
            confirmDelete: "Are you want to delete?",
            titleFilter: "Filter",
            keywordFind: "Search",
            assigned: "Assigned",
            delete: "Delete",
            cancel: "Cancel",
            status: "Status",
            source: "Source",
            view: "View",
            edit: "Edit",
            yes: "Yes",
            no: "No"
        },
        common: {
            id: "Id",
            name: "Name",
        },
        contract: 'Contract',
        attachments: 'Attachments',
        comments: 'Comments',
        renewContractTitle: 'Contract Renewal History',
        task: 'Tasks',
        notes: 'Notes',
        tooltipEmail: 'Emails Tracking',
        tooltipToggle: 'Toggle full View',
        tasks: {
            priority : {
                low: 'Low',
                medium: 'Medium',
                high: 'High',
                urgent: 'Urgent',
            },
            repeat : {
                week: 'Week',
                twoWeek: 'Two Week',
                _1Month: '1 Month',
                _2Month: '2 Months',
                _3Month: '3 Months',
                _6Month: '6 Months',
                _1Year: '1 Year',
                custom: 'Custom'
            },
            relate: {
                project: 'Project',
                invoice: 'Invoice',
                customer: 'Customer',
                estimates: 'Estimates',
                contract: 'Contract',
                ticket: 'Tickets',
                expense: 'Expense',
                lead: 'Lead',
                proposal: 'Proposal'
            } 
        },
        trashContract: 'Exclude Trashed Contract',
        all: 'All',
        expried: 'Expried',
        dateendContract: 'Contract with out Date End',
        viewTrash: 'View Trash',
        newContract: 'New Contract',
        month: 'Month',
        normal: 'Normal',
        test: 'Test',
        contractSummary: 'Contract Summary',
        active: 'Active',
        expired: 'Expired',
        aboutToExpired: 'About To Expired',
        recentlyAdded: 'Recently Added',
        trash: 'Trash',
        contractsByType: 'Contracts by Type',
        contractsValueByType: 'Contracts Value by Type',
        export: 'Export',
        reload: 'Reload',
        searching: 'Search...',
        subject: 'Subject',
        customer: 'Customer',
        contractType: 'Contract Type',
        contractValue:'Contract Value',
        startDate:'Start Date',
        endDate: 'End Date',
        signature: 'Signature',
        view: 'View',
        edit: 'Edit',

        subjectContract: 'View Contract',
        dropFile: 'Drops file here to upload',
        addComment: 'Add Comment',
        viewPdf: 'View PDF',
        viewPdfNewTab: 'View PDF in New Tab',
        download: 'Download',
        print: 'Print',
        sendTo: 'Send To',
        cc: 'CC',
        attachPdf: 'Attach PDF',
        previewEmail: 'Preview Email Template',
        more: 'More',
        viewContract: 'View Contracts',
        markAssign: 'Mark as Signed',
        copy: 'Copy',
        delete: 'Delete',
        mergeField: 'Avaiable merge fields',
        noTrackEmail: 'No tracked emails sent',
        renewContract : {
            title: 'Renew Contract',
            alert: 'Renewals for this contract are not found',
            startDate: 'Start Date',
            endDate: 'End Date',
            contractValue: 'Contract Value',

        },
        contractByType: 'Contract by type',
        contractValueByType: 'Contract value by type (VND)',
        january: 'January',
        february: 'February',	
        march: 'March',	
        april: 'April',	
        may: 'May',	
        june: 'June',	
        july: 'July',	
        august: 'August',	
        september: 'September',
        october: 'October',	
        november: 'November',	
        december: 'December',
    }
}