// @flow
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import adminSupportDepartmentSaga from "../Support/Department/redux/saga";
import adminSupportTicketPrioritySaga from "../Support/TicketPriority/redux/saga";
import adminCustomerGroupSaga from "../CustomerGroup/redux/saga";
import adminStaffSaga from "../../Staff/redux/saga";
import adminSupportPredefinedRepliesSaga from "../Support/PredefinedReplies/redux/saga";
import adminSupportServiceSaga from "../Support/Services/redux/saga";
import adminSupportSpamFilterSaga from "../Support/SpamFilters/redux/saga";
import adminSupportTicketStatusSaga from "../Support/TicketStatus/redux/saga";
import adminLeadsSaga from "../Leads/redux/saga";
import customFieldsSaga from "../CustomFields/redux/saga";
import adminFinanceSaga from "../Finance/redux/saga";
import admintratorContractTypeSaga from "../Contracts/redux/saga";

function* AdminSaga() {
    yield all([
        fork(adminLeadsSaga),
        fork(adminSupportTicketPrioritySaga),
        fork(adminCustomerGroupSaga),
        fork(adminSupportDepartmentSaga),
        fork(adminStaffSaga),
        fork(adminSupportPredefinedRepliesSaga),
        fork(adminSupportServiceSaga),
        fork(adminSupportSpamFilterSaga),
        fork(adminSupportTicketStatusSaga),
        fork(customFieldsSaga),
        fork(adminFinanceSaga),
        fork(admintratorContractTypeSaga),
    ]);
}

export default AdminSaga;
