const ActionTypes = {
  FETCH_CONTACT_REQUEST: "FETCH_CONTACT_REQUEST",
  FETCH_CONTACT_FAILED: "FETCH_CONTACT_FAILED",
  FETCH_CONTACT_SUCCESS: "FETCH_CONTACT_SUCCESS",

  ADD_CONTACT_REQUEST: "ADD_CONTACT_REQUEST",
  ADD_CONTACT_FAILED: "ADD_CONTACT_FAILED",
  ADD_CONTACT_SUCCESS: "ADD_CONTACT_SUCCESS",

  EDIT_CONTACT_REQUEST: "EDIT_CONTACT_REQUEST",
  EDIT_CONTACT_FAILED: "EDIT_CONTACT_FAILED",
  EDIT_CONTACT_SUCCESS: "EDIT_CONTACT_SUCCESS",

  DELETE_CONTACT_REQUEST: "DELETE_CONTACT_REQUEST",
  DELETE_CONTACT_FAILED: "DELETE_CONTACT_FAILED",
  DELETE_CONTACT_SUCCESS: "DELETE_CONTACT_SUCCESS",

  EDIT_ACTIVE_CUSTOMER_CONTACT_REQUEST: "EDIT_ACTIVE_CUSTOMER_CONTACT_REQUEST",
  EDIT_ACTIVE_CUSTOMER_CONTACT_SUCCESS: "EDIT_ACTIVE_CUSTOMER_CONTACT_SUCCESS",
  EDIT_ACTIVE_CUSTOMER_CONTACT_FAILED: "EDIT_ACTIVE_CUSTOMER_CONTACT_FAILED",
};

export default ActionTypes;
