import apiBase from "./../../../../common/baseAPI";

const URL = 'customer-group';

// const getListCustomerGroup = () => {
//     return new Promise((resolve, reject) => {
//         return apiBase
//             .get(URL)
//             .then((res) => resolve(res))
//             .catch((err) => reject(err));
//     });
// };

const getListCustomerGroup = (action) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${URL}?search=${action.search}&&limit=${action.params.limit}&&page=${action.params.page}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const createCustomerGroup = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(URL, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const editCustomerGroup = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .put(`${URL}/${data.id}`, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const deleteCustomerGroup = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .delete(`${URL}/${data}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};



export {
    getListCustomerGroup,
    createCustomerGroup,
    editCustomerGroup,
    deleteCustomerGroup,
    // fetchListCustomerGroup

}