import apiBase from "../../../common/baseAPI";
const CREDITNOTES_URL = "creditNotes";
const getListCreditNote = ({ params }) => {
    return new Promise((resolve, reject) => {
        //         Trong sale => chọn creditNote
        // Get dữ liệu bị sai
        // Truyền phương thức: GET
        // Đường dẫn api: api/creditNotes?limit=15&&page=1
        return apiBase
            .get(CREDITNOTES_URL, {
                params: params,
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
export { getListCreditNote };
