import apiBase from "../../../../common/baseAPI";
const INVOICE_CUSTOMER = "invoice/customer";
const INVOICE_CUSTOMER_YEAR = "invoice/customer/year";

export const fetchInvoiceCustomerRequest = (action) => {
  return new Promise((resolve, reject) => {
    return apiBase
      .get(
        `${INVOICE_CUSTOMER}/${action.id}?search=${action.value}&&limit=${action.params.limit}&&page=${action.params.page}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const fetchYearInvoiceCustomerRequest = (action) => {
  const arrYear = action.value.map((item) => item.year);
  return new Promise((resolve, reject) => {
    return apiBase
      .get(`${INVOICE_CUSTOMER_YEAR}/${action.id}?year=[${arrYear}]`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
