export default {
  support: {
    newTicket: "Yêu cầu mới",
    weeklyStats: "Số liệu mỗi tuần",
    filterBy: "Được lọc theo",
    edit: "Sửa",
    btnFilter: {
      all: "Tất cả",
      assigned: "Yêu cầu được chỉ định cho tôi",
      open: "Mở",
      inProgress: "Đang thực hiện",
      answered: "Đã trả lời",
      onHold: "Tạm ngưng",
      close: "Đóng",
    },
    weeklyStatistics: "Thống kê yêu cầu hỗ trợ hàng tuần",
    dialogBulkActions: {
      bulkActions: "Bulk Actions",
      massDelete: "Xoá diện rộng",
      changeStatus: "Thay đổi trạng thái",
      department: "Phòng ban",
      ticketPriority: "Quyền ưu tiên hỗ trợ",
      tags: "Các thẻ",
      service: "Dịch vụ",
      close: "Đóng",
      confirm: "Xác nhận",
    },
    ticketWithoutContact: "Vé không liên hệ",
    ticketToContact: "Vé để liên hệ",
    tableSupport: {
      subject: "Chủ đề",
      tags: "Các thẻ",
      department: "Phòng ban",
      service: "Dịch vụ",
      contact: "Khách hàng",
      status: "Trạng thái",
      priority: "Mức độ ưu tiên",
      lastReply: "Phản hồi lần cuối",
      created: "Ngày tạo",
    },
    note: "Ghi chú",
    addNote: "Thêm ghi chú",
    tabReminders: {
      setTicketReminders: "Set Ticket Reminder",
      iconQuestion: "Tùy chọn này cho phép bạn không bao giờ quên bất cứ điều gì về khách hàng của mình.",
      dateNotified: " Chuyển nhắc nhở sang",
      setReminder: "Set reminder to",
      description: "Mô tả",
      checkboxSend: "Gửi email cho cả nhắc nhở này",
      save: "Lưu lại",
      description: "Mô tả",
      checkboxSend: "Gửi email cho cả nhắc nhở này",
      save: "Lưu",
      date: "Ngày",
      remind: "Nhắc nhở",
      isNotified: "Được thông báo?"
    },
    tabTasks: {
      newTask: "Phân công mới",
      filterAll: "Tất cả",
      notStarted: "Chưa bắt đầu",
      inProgress: "Đang tiến hành",
      dueDatePassed: "Quá hạn",
      name: "Tên",
      status: "Trạng thái",
      startDate: "Ngày bắt đầu",
      dueDate: "Ngày chốt",
      assignedTo: "Đã chỉ định cho",
      tags: "Các thẻ",
      priority: "Mức độ ưu tiên",
    },
    tabOtherTickets: {
      subject: "Chủ đề",
      tags: "Các thẻ",
      department: "Phòng ban",
      service: "Dịch vụ",
      contact: "Khách hàng",
      status: "Trạng thái",
      priority: "Mức độ ưu tiên",
      lastReply: "Phản hồi lần cuối",
      created: "Ngày tạo"
    }
  }
}