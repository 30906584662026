const ActionTypes = {
    GET_LIST_TICKET_PRIORITY_REQUEST: 'GET_LIST_TICKET_PRIORITY_REQUEST',
    GET_LIST_TICKET_PRIORITY_FAILED: 'GET_LIST_TICKET_PRIORITY_FAILED',
    GET_LIST_TICKET_PRIORITY_SUCCESS: 'GET_LIST_TICKET_PRIORITY_SUCCESS',

    CREATE_TICKET_PRIORITY_REQUEST: 'CREATE_TICKET_PRIORITY_REQUEST',
    CREATE_TICKET_PRIORITY_FAILED: 'CREATE_TICKET_PRIORITY_FAILED',
    CREATE_TICKET_PRIORITY_SUCCESS: 'CREATE_TICKET_PRIORITY_SUCCESS',

    EDIT_TICKET_PRIORITY_REQUEST: 'EDIT_TICKET_PRIORITY_REQUEST',
    EDIT_TICKET_PRIORITY_FAILED: 'EDIT_TICKET_PRIORITY_FAILED',
    EDIT_TICKET_PRIORITY_SUCCESS: 'EDIT_TICKET_PRIORITY_SUCCESS',

    DELETE_TICKET_PRIORITY_REQUEST: 'DELETE_TICKET_PRIORITY_REQUEST',
    DELETE_TICKET_PRIORITY_FAILED: 'DELETE_TICKET_PRIORITY_FAILED',
    DELETE_TICKET_PRIORITY_SUCCESS: 'DELETE_TICKET_PRIORITY_SUCCESS',

    FETCH_TICKET_PRIORITY_REQUEST: 'FETCH_TICKET_PRIORITY_REQUEST',
    FETCH_TICKET_PRIORITY_FAILED: 'FETCH_TICKET_PRIORITY_FAILED',
    FETCH_TICKET_PRIORITY_SUCCESS: 'FETCH_TICKET_PRIORITY_SUCCESS',
    CHANGE_STATUS_DIALOG: 'CHANGE_STATUS_DIALOG'
};
export default ActionTypes;
