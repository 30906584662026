//GET contract type
export const GET_CONTRACT_TYPE_PENDING = "GET_CONTRACT_TYPE_PENDING";
export const GET_CONTRACT_TYPE_SUCCESS = "GET_CONTRACT_TYPE_SUCCESS";
export const GET_CONTRACT_TYPE_FAILED = "GET_CONTRACT_TYPE_FAILED";
//ADD contract type
export const ADD_CONTRACT_TYPE_PENDING = "ADD_CONTRACT_TYPE_PENDING";
export const ADD_CONTRACT_TYPE_SUCCESS = "ADD_CONTRACT_TYPE_SUCCESS";
export const ADD_CONTRACT_TYPE_FAILED = "ADD_CONTRACT_TYPE_FAILED";
//PUT contract type
export const PUT_CONTRACT_TYPE_PENDING = "PUT_CONTRACT_TYPE_PENDING";
export const PUT_CONTRACT_TYPE_SUCCESS = "PUT_CONTRACT_TYPE_SUCCESS";
export const PUT_CONTRACT_TYPE_FAILED = "PUT_CONTRACT_TYPE_FAILED";
//Delete contract type
export const DELETE_CONTRACT_TYPE_PENDING = "DELETE_CONTRACT_TYPE_PENDING";
export const DELETE_CONTRACT_TYPE_SUCCESS = "DELETE_CONTRACT_TYPE_SUCCESS";
export const DELETE_CONTRACT_TYPE_FAILED = "DELETE_CONTRACT_TYPE_FAILED";
