export default {
  sales: {
    common: {
      comment: "Bình luận",
      remind: "Nhắc nhở",
      task: "Phân công",
      note: "Chi chú",
      emailTracking: "Theo dõi email",
      viewsTracking: "Theo dõi lượt xem",
      fullScreen: "Bật xem đầy đủ",
      id: "Id",
      to: "Đến",
      qty: "Số lượng",
      hours: "Giờ",
      lead: "Khách hàng tiềm năng",
      rate: "Giá",
      tax: "Thuế",
      amount: "Tổng",
      noTax: "Miễn thuế",
      subTotal: "Chưa qua thuế - Chi phí",
      discount: "Chiết khấu",
      adjustment: "Điều chỉnh",
      allowComment: "Cho phép bình luận",
      currency: "Tiền tệ",
      discountType: "Lọai chiết khấu",
      related: "Liên quan",
      asigned: "Người tạo",
      name: "Tên",
      company: "Công ty",
      email: "Email",
      numberPhone: "Điện thoại",
      tag: "Thẻ",
      createBy: "Người tạo",
      status: "Trạng thái",
      source: "Nguồn",
      lastContact: "Lần cuối liên hệ",
      createAt: "Đã tạo",
      address: "Địa chỉ",
      position: "Vị trí",
      city: "Thành phố",
      state: "Tiểu bang",
      website: "Website",
      country: "Quốc gia",
      zip: "Mã bưu chính",
      defaultLanguage: "Ngôn ngữ mặc định",
      description: "Mô tả",
      public: "Công khai",
      activityLog: "Lịch sử hoạt động",
      yes: "Có",
      no: "Không",
      proposal: "Đề xuất kế hoạch",
      subject: "Chủ đề",
      total: "Tổng cộng",
      date: "Ngày",
      acceptDate: "Ngày chốt",
      view: "Xem",
      edit: "Chỉnh sửa",
      cancel: "Hủy",
      close: "Đóng",
      save: "Lưu Lại",
      delete: "Xóa",
      export: "Xuất ra",
      goBack: "Quay lại",
      draft: "Bản nháp",
      sent: "Gửi",
      declined: "Giảm",
      accepted: "Chấp nhận",
      expired: "Hết hạn",
      validateItemTable: "bạn cần 1 trong 3 giá trị",
    },
    proposal: {
      draft: "Nháp",
      tooltipEdit: "Sửa",
      sendToEmail: "Gửi đến email",
      proposal: "Đề xuất kế hoạch #",
      subject: "Chủ đề",
      to: "Đến",
      total: "Tổng cộng",
      date: "Ngày",
      openTill: "Ngày chốt",
      tags: "Các thẻ",
      dateCreated: "Ngày tạo",
      status: "Trạng Thái",
      btnNewProposal: "Đề xuất kế hoạch mới",
      btnAddComment: "Thêm bình luận",
      btnUpdateComment: "Cập nhật bình luận",
    },
    estimate: {
      estimate: "Bản báo giá #",
      amount: "Tổng cộng",
      totalTax: "Tổng thuế",
      customer: "Khách hàng",
      project: "Dự án",
      tags: "Các thẻ",
      date: "Ngày",
      expiryDate: "Ngày hết hạn",
      reference: "Tham khảo #",
      status: "Trạng thái",
      draft: "Nháp",
      sent: "Đã gửi",
      expired: "Hết hạn",
      declined: "Từ chối",
      accepted: "Chấp nhận",
      toolTipEmail: "Theo dõi Email",
      toolTipView: "Theo dõi lượt xem",
      toolTipZoom: "Bật xem đầy đủ",
      toolTipEdit: "Sửa báo giá",
      toolTipSendEmail: "Gửi đến email",
      btnNewEstimate: "Tạo báo giá mới",
      btnSwitch: "Chuyển sang dạng cột",
      download: "Tải Xuống",
      decline: "Từ chối",
      accept: "Chấp nhận",
      noDiscount: "Không giảm giá",
      beforeTax: "Trước thuế",
      afterTax: "Sau thuế",
    },
    invoice: {
      outstandingInvoices: "Hóa đơn nổi bật",
      pastDueInvoices: "Hóa đơn quá hạn",
      paidInvoices: "Hóa đơn đã thanh toán",
      unpaid: "Chưa thanh toán",
      paid: "Đã thanh toán",
      partiallyPaid: "Đã thanh toán một phần",
      overdue: "Quá hạn",
      draft: "Nháp",
      btnNewInvoice: "Tạo hóa đơn mới",
      btnRecurringInvoice: "Các hóa đơn gửi định kỳ",
      invoice: "Hóa đơn #",
      amount: "Tổng cộng",
      totalTax: "Tổng thuế",
      date: "Ngày xuất",
      customer: "Khách hàng",
      project: "Dự án",
      tags: "Các thẻ",
      dueDate: "Hạn trả",
      status: "Trạng thái",
      payment: "Thanh toán",
      amountDue: "Số lượng chưa trả",
      download: "Tải Xuống",
      amountReceived: "Số tiền nhận được",
      paymentDate: "Ngày thanh toán",
      paymentMode: "Phương thức thanh toán",
      transactionID: "ID giao dịch",
      leaveNote: "Để lại ghi chú",
      noteCheckBox:
        "Không gửi thư ghi lại thanh toán hóa đơn cho các liên hệ khách hàng",
      frequency: "Tần số",
      cyclesRemaining: "Chu kỳ còn lại",
      lastDate: "Ngày lập hóa đơn cuối cùng",
      nextDate: "Ngày lập hóa đơn tiếp theo",
    },
    payment: {
      payment: "Thanh toán #",
      invoice: "Hóa đơn #",
      paymentMode: "Hình thức thanh toán",
      transactionID: "ID giao dịch",
      customer: "Khách hàng",
      amount: "Tổng cộng",
      date: "Ngày xuất",
    },
    creditNotes: {
      newCreditNotes: "Ghi chú tín dụng mới",
      toggleTable: "Bật/tắt danh sách",
      filterBy: "Được lọc theo",
      searchFilter: {
        all: "Tất cả",
        open: "Mở",
        void: "Vô hiệu",
      },
      tableCreditNotes: {
        creditNote: "Ghi chú tín dụng #",
        creditNoteDate: "Ngày ghi chú tín dụng",
        customer: "Khách hàng",
        status: "Trạng thái",
        project: "Dự án",
        reference: "Tham khảo #",
        amount: "Số tiền",
        remainingAmount: "Số tiền còn lại",
      },
      detailCreditNotes: {
        applyToInvoice: "Apply to invoice",
        more: "Xem thêm",
        refund: "Hoàn tiền",
        attachFile: "Đính kèm tệp tin",
        billTo: "Người nhận:",
        shipTo: "Chuyển đến:",
        creditsRemaining: "Các khoản tín dụng còn lại",
        note: "Ghi chú",
        termsConditions: "Điều khoản và điều kiện",
        noteInvoices: "Không tìm thấy hóa đơn được ghi",
        noteRefunds: "Không tìm thấy khoản tiền hoàn lại",
        noteEmail: "Không có email theo dõi nào được gửi",
      },
      refundedAmount: "Số tiền đã hoàn lại",
      date: "Ngày",
      paymentMode: "Phương thức thanh toán",
      note: "Ghi chú",
    },
    invoiceItem: {
      btnNew: "Yếu tố mới",
      btnGroups: "Nhóm",
      btnImport: " Thống kê",
      description: "Mô tả",
      longDescription: "Mô tả dài",
      rate: "Giá",
      tax1: "Thuế 1",
      tax2: "Thuế 2",
      unit: "Đơn vị",
      groupName: "Tên nhóm",
      titleAdd: "Chỉnh sửa Thêm mục mới",
      rateCurrency: "Rate - VND (Tiền tệ cơ bản)",
      itemGroup: "Nhóm mặt hàng",
      titleEdit: "Chỉnh sửa",
      noteEdit:
        "Chỉnh sửa thông tin sản phẩm không ảnh hưởng đến hóa đơn/báo giá/đề xuất đã tạo.",
      titleGroups: "Nhóm",
      btnNewGroup: "Nhóm mới",
      creditNotes: {
        newCreditNotes: "Ghi chú tín dụng mới",
        toggleTable: "Bật/tắt danh sách",
        filterBy: "Được lọc theo",
        searchFilter: {
          all: "Tất cả",
          open: "Mở",
          void: "Vô hiệu",
        },
        tableCreditNotes: {
          creditNote: "Ghi chú tín dụng #",
          creditNoteDate: "Ngày ghi chú tín dụng",
          customer: "Khách hàng",
          status: "Trạng thái",
          project: "Dự án",
          reference: "Tham khảo #",
          amount: "Số tiền",
          remainingAmount: "Số tiền còn lại",
        },
        detailCreditNotes: {
          applyToInvoice: "Apply to invoice",
          more: "Xem thêm",
          refund: "Hoàn tiền",
          attachFile: "Đính kèm tệp tin",
          billTo: "Người nhận:",
          shipTo: "Chuyển đến:",
          creditsRemaining: "Các khoản tín dụng còn lại",
          note: "Ghi chú",
          termsConditions: "Điều khoản và điều kiện",
          noteInvoices: "Không tìm thấy hóa đơn được ghi",
          noteRefunds: "Không tìm thấy khoản tiền hoàn lại",
          noteEmail: "Không có email theo dõi nào được gửi",
        },
      },
    },
  },
};
