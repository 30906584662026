import {
    GET_LIST_SUPPORT_DEPARTMENT_REQUEST,
    GET_LIST_SUPPORT_DEPARTMENT_FAILED,
    GET_LIST_SUPPORT_DEPARTMENT_SUCCESS,

    CREATE_SUPPORT_DEPARTMENT_REQUEST,
    CREATE_SUPPORT_DEPARTMENT_FAILED,
    CREATE_SUPPORT_DEPARTMENT_SUCCESS,

    EDIT_SUPPORT_DEPARTMENT_REQUEST,
    EDIT_SUPPORT_DEPARTMENT_FAILED,
    EDIT_SUPPORT_DEPARTMENT_SUCCESS,

    DELETE_SUPPORT_DEPARTMENT_REQUEST,
    DELETE_SUPPORT_DEPARTMENT_FAILED,
    DELETE_SUPPORT_DEPARTMENT_SUCCESS,
    CHANGE_STATUS_DEPARTMENT,
    FETCH_SUPPORT_DEPARTMENT_REQUEST,
    FETCH_SUPPORT_DEPARTMENT_FAILED,
    FETCH_SUPPORT_DEPARTMENT_SUCCESS
} from './types';

export const getListSupportDepartmentRequest = () => {
    return {
        type: GET_LIST_SUPPORT_DEPARTMENT_REQUEST
    }
};

export const getListSupportDepartmentSuccess = (data) => {
    return {
        type: GET_LIST_SUPPORT_DEPARTMENT_SUCCESS,
        payload: data
    }
};

export const getListSupportDepartmentFailed = () => {
    return {
        type: GET_LIST_SUPPORT_DEPARTMENT_FAILED
    }
};

export const createSupportDepartmentRequest = (data) => {
    return {
        type: CREATE_SUPPORT_DEPARTMENT_REQUEST,
        payload: data,
    }
};

export const createSupportDepartmentSuccess = (data) => {
    return {
        type: CREATE_SUPPORT_DEPARTMENT_SUCCESS,
        payload: data
    }
};

export const createSupportDepartmentFailed = () => {
    return {
        type: CREATE_SUPPORT_DEPARTMENT_FAILED
    }
};

export const editSupportDepartmentRequest = (data) => {
    return {
        type: EDIT_SUPPORT_DEPARTMENT_REQUEST,
        payload: data,
    }
};

export const editSupportDepartmentSuccess = (data) => {
    return {
        type: EDIT_SUPPORT_DEPARTMENT_SUCCESS,
        payload: data
    }
};

export const editSupportDepartmentFailed = () => {
    return {
        type: EDIT_SUPPORT_DEPARTMENT_FAILED
    }
};

export const deleteSupportDepartmentRequest = (data) => {
    return {
        type: DELETE_SUPPORT_DEPARTMENT_REQUEST,
        payload: data,
    }
};

export const deleteSupportDepartmentSuccess = (data) => {
    return {
        type: DELETE_SUPPORT_DEPARTMENT_SUCCESS,
        payload: data
    }
};

export const deleteSupportDepartmentFailed = () => {
    return {
        type: DELETE_SUPPORT_DEPARTMENT_FAILED
    }
};

export const changeStatusDepartment = () => {
    return {
        type: CHANGE_STATUS_DEPARTMENT
    }
};

export const fetchSupportDepartmentRequest = (search, params) => {
    return {
        type: FETCH_SUPPORT_DEPARTMENT_REQUEST,
        search, params
    }
};

export const fetchSupportDepartmentSuccess = (data) => {
    return {
        type: FETCH_SUPPORT_DEPARTMENT_SUCCESS,
        payload: data
    }
};

export const fetchSupportDepartmentFailed = () => {
    return {
        type: FETCH_SUPPORT_DEPARTMENT_FAILED
    }
};