import ActionTypes from "./type";



export const addVaultCustomerRequest = (id, data) => {
    return {
        type: ActionTypes.ADD_VAULT_CUSTOMER_REQUEST,
        id,
        data,
    };
};

export const addVaultCustomerSuccess = (data) => {
    return {
        type: ActionTypes.ADD_VAULT_CUSTOMER_SUCCESS,
        data,
    };
};

export const addVaultCustomerFailed = () => {
    return {
        type: ActionTypes.ADD_VAULT_CUSTOMER_FAILED,
    };
};

