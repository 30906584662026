import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  fetchListEstimateSaleSuccess,
  fetchListEstimateSaleFailed,
  listSelectCustomerSuccess,
  listSelectCustomerFailed,
  getCustomFieldEstimateSuccess,
  getCustomFieldEstimateFailed,
} from "./actions";
import { enqueueSnackbar } from "../../../redux/CommonReducer";
import {
  getEstimateSaleRequest,
  listSelectCustomerRequest,
  listCustomFieldRequest,
} from "./api";
import { responeCode, typeMessage } from "../../../constants/Const";
import ActionTypes from "./types";
import {
  throwErrorMessage,
  parseDataNotifications,
} from "../../../helpers/HandleError";

function* fetchEstimateSale(action) {
  try {
    const response = yield call(getEstimateSaleRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success &&
      response.data.result &&
      response.data.result.data
    ) {
      yield put(fetchListEstimateSaleSuccess(response.data.result));
    } else {
      yield put(fetchListEstimateSaleFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchListEstimateSaleFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* listSelectCustomer(action) {
  try {
    const response = yield call(listSelectCustomerRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(listSelectCustomerSuccess(response.data.result));
    } else {
      yield put(listSelectCustomerFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(listSelectCustomerFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* listCustomField() {
  try {
    const response = yield call(listCustomFieldRequest);

    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(getCustomFieldEstimateSuccess(response.data.result));
    } else {
      yield put(getCustomFieldEstimateFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(getCustomFieldEstimateFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

export function* saleEstimateSaga() {
  yield takeEvery(ActionTypes.FETCH_ESTIMATES_SALE_REQUEST, fetchEstimateSale);
  yield takeEvery(ActionTypes.LIST_SELECT_CUSTOMER_REQUEST, listSelectCustomer);
  yield takeEvery(ActionTypes.GET_CUSTOM_FIELD_REQUEST, listCustomField);
}

function* SaleEstimateSaga() {
  yield all([fork(saleEstimateSaga)]);
}

export default SaleEstimateSaga;
