// import { createListContract } from "./actions";
import {
    FETCH_LIST_CONTRACT,
    FETCH_LIST_CONTRACT_SUCCESS,
    FETCH_LIST_CONTRACT_FAILED,
    FETCH_CONTRACT,
    FETCH_CONTRACT_SUCCESS,
    FETCH_CONTRACT_FAILED,
    DELETE_CONTRACT,
    DELETE_CONTRACT_SUCCESS,
    DELETE_CONTRACT_FAILED,
    CREATE_CONTRACT,
    CREATE_CONTRACT_SUCCESS,
    CREATE_CONTRACT_FAILED,
    FETCH_LIST_CUSTOMER_CONTRACT,
    FETCH_LIST_CUSTOMER_CONTRACT_SUCCESS,
    FETCH_LIST_CUSTOMER_CONTRACT_FAILED,
    SET_EDIT_CONTRACT,
    UPDATE_CONTRACT,
    UPDATE_CONTRACT_SUCCESS,
    UPDATE_CONTRACT_FAILED,
    FILTER_CONTRACT,
    FILTER_CONTRACT_SUCCESS,
    FILTER_CONTRACT_FAILED,
    FETCH_SUMMARY_CONTRACT,
    FETCH_SUMMARY_CONTRACT_SUCCESS,
    FETCH_SUMMARY_CONTRACT_FAILED,
    FETCH_LIST_ATTACHMENT_CONTRACT,
    FETCH_LIST_ATTACHMENT_CONTRACT_SUCCESS,
    FETCH_LIST_ATTACHMENT_CONTRACT_FAILED,
    CREATE_ATTACHMENT_CONTRACT,
    CREATE_ATTACHMENT_CONTRACT_SUCCESS,
    CREATE_ATTACHMENT_CONTRACT_FAILED,
    DELETE_ATTACHMENT_CONTRACT,
    DELETE_ATTACHMENT_CONTRACT_SUCCESS,
    DELETE_ATTACHMENT_CONTRACT_FAILED,
    FETCH_NOTE_CONTRACT,
    FETCH_NOTE_CONTRACT_SUCCESS,
    FETCH_NOTE_CONTRACT_FAILED,
    CREATE_NOTE_CONTRACT,
    CREATE_NOTE_CONTRACT_SUCCESS,
    CREATE_NOTE_CONTRACT_FAILED,
    DELETE_NOTE_CONTRACT,
    DELETE_NOTE_CONTRACT_SUCCESS,
    DELETE_NOTE_CONTRACT_FAILED,
    UPDATE_NOTE_CONTRACT,
    UPDATE_NOTE_CONTRACT_SUCCESS,
    UPDATE_NOTE_CONTRACT_FAILED,
    FETCH_LIST_COMMENT_CONTRACT,
    FETCH_LIST_COMMENT_CONTRACT_SUCCESS,
    FETCH_LIST_COMMENT_CONTRACT_FAILED,
    CREATE_COMMENT_CONTRACT,
    CREATE_COMMENT_CONTRACT_SUCCESS,
    CREATE_COMMENT_CONTRACT_FAILED,
    DELETE_COMMENT_CONTRACT,
    DELETE_COMMENT_CONTRACT_SUCCESS,
    DELETE_COMMENT_CONTRACT_FAILED,
    UPDATE_COMMENT_CONTRACT,
    UPDATE_COMMENT_CONTRACT_SUCCESS,
    UPDATE_COMMENT_CONTRACT_FAILED,
    FETCH_LIST_RENEWAL_CONTRACT,
    FETCH_LIST_RENEWAL_CONTRACT_SUCCESS,
    FETCH_LIST_RENEWAL_CONTRACT_FAILED,
    CREATE_RENEWAL_CONTRACT,
    CREATE_RENEWAL_CONTRACT_SUCCESS,
    CREATE_RENEWAL_CONTRACT_FAILED,
    DELETE_RENEWAL_CONTRACT,
    DELETE_RENEWAL_CONTRACT_SUCCESS,
    DELETE_RENEWAL_CONTRACT_FAILED,
    FETCH_LIST_TASK_CONTRACT,
    FETCH_LIST_TASK_CONTRACT_SUCCESS,
    FETCH_LIST_TASK_CONTRACT_FAILED,
    DELETE_TASK_CONTRACT,
    DELETE_TASK_CONTRACT_SUCCESS,
    DELETE_TASK_CONTRACT_FAILED,
    DATA_COMMENT,
    DATA_NOTES,
    FETCH_CONTRACT_TYPE,
    FETCH_CONTRACT_TYPE_SUCCESS,
    FETCH_CONTRACT_TYPE_FAILED,
    CREATE_CONTRACT_TYPE,
    CREATE_CONTRACT_TYPE_SUCCESS,
    CREATE_CONTRACT_TYPE_FAILED,
    FETCH_CUSTOM_FIELD,
    FETCH_CUSTOM_FIELD_SUCCESS,
    FETCH_CUSTOM_FIELD_FAILED,
    FETCH_CONTRACT_BY_TYPE,
    FETCH_CONTRACT_BY_TYPE_SUCCESS,
    FETCH_CONTRACT_BY_TYPE_FAILED,
    FETCH_CONTRACT_VALUE_BY_TYPE,
    FETCH_CONTRACT_VALUE_BY_TYPE_SUCCESS,
    FETCH_CONTRACT_VALUE_BY_TYPE_FAILED,
    FETCH_YEAR,
    FETCH_YEAR_SUCCESS,
    FETCH_YEAR_FAILED,
    ADD_NEW_CONTRACT_PENDING,
    ADD_NEW_CONTRACT_SUCCESS,
    ADD_NEW_CONTRACT_FAILED,
} from "./types";

const INIT_STATE = {
    isLoading: false,
    listContracts: [],
    listCustomers: [],
    currentContract: {},
    summaryContracts: {},
    listAttachment: [],
    listNote: [],
    listComment: [],
    listRenewal: [],
    isFetchAttachment: false,
    isFetchNote: false,
    isFetchComment: false,
    isFetchRenewal: false,
    listTask: [],
    isFetchTask: false,
    dataComment: "",
    dataNotes: "",
    listContractType: [],
    isFetchContractType: false,
    listCustomField: [],
    contractByType: {},
    contractValueByType: {},
    listYear: [],
};
const ContractReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_LIST_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };
        case FETCH_LIST_CONTRACT_SUCCESS:
            return {
                ...state,
                listContracts: action.payload,
                isLoading: false,
            };
        case FETCH_LIST_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case FETCH_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };
        case FETCH_CONTRACT_SUCCESS:
            return {
                ...state,
                currentContract: action.payload,
                isLoading: false,
            };
        case FETCH_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case CREATE_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };
        case CREATE_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case CREATE_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case DELETE_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };
        case DELETE_CONTRACT_SUCCESS:
            const newArr = [...state.listContracts.data];
            const newListContract = newArr.filter(
                (item) => item.id !== action.payload
            );
            return {
                ...state,
                isLoading: false,
                listContracts: {
                    ...state.listContracts,
                    data: newListContract,
                },
            };
        case DELETE_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case FETCH_LIST_CUSTOMER_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };
        case FETCH_LIST_CUSTOMER_CONTRACT_SUCCESS:
            return {
                ...state,
                listCustomers: action.payload,
                isLoading: false,
                createSuccess: false,
            };
        case FETCH_LIST_CUSTOMER_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case SET_EDIT_CONTRACT:
            return {
                ...state,
                isLoading: false,
                contractEditting: action.payload,
            };
        case UPDATE_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };
        case UPDATE_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case UPDATE_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case FILTER_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };
        case FILTER_CONTRACT_SUCCESS:
            return {
                ...state,
                listContracts: action.payload,
                isLoading: false,
            };
        case FILTER_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case FETCH_SUMMARY_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };
        case FETCH_SUMMARY_CONTRACT_SUCCESS:
            return {
                ...state,
                summaryContracts: action.payload,
                isLoading: false,
            };
        case FETCH_SUMMARY_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case FETCH_LIST_ATTACHMENT_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_LIST_ATTACHMENT_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listAttachment: action.payload,
            };

        case FETCH_LIST_ATTACHMENT_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case CREATE_ATTACHMENT_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case CREATE_ATTACHMENT_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchAttachment: !state.isFetchAttachment,
            };

        case CREATE_ATTACHMENT_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case DELETE_ATTACHMENT_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case DELETE_ATTACHMENT_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchAttachment: !state.isFetchAttachment,
            };

        case DELETE_ATTACHMENT_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case FETCH_NOTE_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_NOTE_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listNote: action.payload,
            };

        case FETCH_NOTE_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case CREATE_NOTE_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case CREATE_NOTE_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchNote: !state.isFetchNote,
                dataNotes: "",
            };

        case CREATE_NOTE_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case DELETE_NOTE_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case DELETE_NOTE_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchNote: !state.isFetchNote,
            };

        case DELETE_NOTE_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case UPDATE_NOTE_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case UPDATE_NOTE_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchNote: !state.isFetchNote,
            };

        case UPDATE_NOTE_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case FETCH_LIST_COMMENT_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_LIST_COMMENT_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listComment: action.payload,
            };

        case FETCH_LIST_COMMENT_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case CREATE_COMMENT_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case CREATE_COMMENT_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchComment: !state.isFetchComment,
                dataComment: "",
            };

        case CREATE_COMMENT_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case DELETE_COMMENT_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case DELETE_COMMENT_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchComment: !state.isFetchComment,
            };

        case DELETE_COMMENT_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case UPDATE_COMMENT_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case UPDATE_COMMENT_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchComment: !state.isFetchComment,
            };

        case UPDATE_COMMENT_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case FETCH_LIST_RENEWAL_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_LIST_RENEWAL_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listRenewal: action.payload,
            };

        case FETCH_LIST_RENEWAL_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case CREATE_RENEWAL_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case CREATE_RENEWAL_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchRenewal: !state.isFetchRenewal,
            };

        case CREATE_RENEWAL_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case DELETE_RENEWAL_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case DELETE_RENEWAL_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchRenewal: !state.isFetchRenewal,
            };

        case DELETE_RENEWAL_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case FETCH_LIST_TASK_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_LIST_TASK_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listTask: action.payload,
            };

        case FETCH_LIST_TASK_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case DELETE_TASK_CONTRACT:
            return {
                ...state,
                isLoading: true,
            };

        case DELETE_TASK_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchTask: !state.isFetchTask,
            };

        case DELETE_TASK_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case DATA_COMMENT:
            return {
                ...state,
                dataComment: action.payload,
            };
        case DATA_NOTES:
            return {
                ...state,
                dataNotes: action.payload,
            };

        case FETCH_CONTRACT_TYPE:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_CONTRACT_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listContractType: action.payload,
                isFetchContractType: false,
            };

        case FETCH_CONTRACT_TYPE_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case CREATE_CONTRACT_TYPE:
            return {
                ...state,
                isLoading: true,
            };

        case CREATE_CONTRACT_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchContractType: true,
            };

        case CREATE_CONTRACT_TYPE_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case FETCH_CUSTOM_FIELD:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_CUSTOM_FIELD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listCustomField: action.payload,
            };

        case FETCH_CUSTOM_FIELD_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case FETCH_CONTRACT_BY_TYPE:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_CONTRACT_BY_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                contractByType: action.payload,
            };

        case FETCH_CONTRACT_BY_TYPE_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case FETCH_CONTRACT_VALUE_BY_TYPE:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_CONTRACT_VALUE_BY_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                contractValueByType: action.payload,
            };

        case FETCH_CONTRACT_VALUE_BY_TYPE_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case FETCH_YEAR:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_YEAR_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listYear: action.payload,
            };

        case FETCH_YEAR_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ADD_NEW_CONTRACT_PENDING:
            return {
                ...state,
                isLoading: true,
            };

        case ADD_NEW_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case ADD_NEW_CONTRACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return { ...state };
    }
};

export default ContractReducer;
