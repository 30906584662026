import { isArray } from "lodash";
import {
  GET_TASK_BY_LEAD,
  GET_TASK_BY_LEAD_SUCCESS,
  GET_TASK_BY_LEAD_FAILED,
  CREATE_NEW_TASK,
  CREATE_NEW_TASK_SUCCESS,
  CREATE_NEW_TASK_FAILED,
  FETCH_STATUS_LEADS,
  FETCH_STATUS_LEADS_SUCCESS,
  FETCH_STATUS_LEADS_FAILED,
  GET_TASK,
  GET_TASK_SUCCESS,
  GET_TASK_FAILED,
  GET_COUNT_TASK,
  GET_COUNT_TASK_SUCCESS,
  GET_COUNT_TASK_FAILED,
  CHANGE_TASK_PRIORITY,
  CHANGE_TASK_PRIORITY_SUCCESS,
  CHANGE_TASK_PRIORITY_FAILED,
  CHANGE_TASK_STATUS,
  CHANGE_TASK_STATUS_SUCCESS,
  CHANGE_TASK_STATUS_FAILED,
  SEARCH_RELATED,
  SEARCH_RELATED_SUCCESS,
  SEARCH_RELATED_FAILED,
  SEARCH_RELATED_BY_FILTER,
  SEARCH_RELATED_BY_FILTER_SUCCESS,
  SEARCH_RELATED_BY_FILTER_FAILED,
  EDIT_TASK,
  EDIT_TASK_SUCCESS,
  EDIT_TASK_FAILED,
  DELETE_TASK,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAILED,
  GET_TASK_BY_ID,
  GET_TASK_BY_ID_SUCCESS,
  GET_TASK_BY_ID_FAILED,
  OPEN_TASK_DETAIL,
  CLOSE_TASK_DETAIL,
  REDIRECT_TO_TASK_EDIT,
  CLOSE_POPUP_DELETE,
  OPEN_POPUP_DELETE,
  ADD_NEW_TIMER,
  ADD_NEW_TIMER_SUCCESS,
  ADD_NEW_TIMER_FAILED,
  DELETE_TIMER,
  DELETE_TIMER_SUCCESS,
  DELETE_TIMER_FAILED,
  ADD_NEW_CHECKLIST,
  ADD_NEW_CHECKLIST_SUCCESS,
  ADD_NEW_CHECKLIST_FAILED,
  DELETE_CHECKLIST,
  DELETE_CHECKLIST_SUCCESS,
  DELETE_CHECKLIST_FAILED,
  ADD_COMMENT,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_FAILED,
  EDIT_COMMENT,
  EDIT_COMMENT_SUCCESS,
  EDIT_COMMENT_FAILED,
  DELETE_COMMENT,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAILED,
  ADD_REMINDER,
  ADD_REMINDER_SUCCESS,
  ADD_REMINDER_FAILED,
  EDIT_REMINDER,
  EDIT_REMINDER_SUCCESS,
  EDIT_REMINDER_FAILED,
  DELETE_REMINDER,
  DELETE_REMINDER_SUCCESS,
  DELETE_REMINDER_FAILED,
  ADD_ASSIGN,
  ADD_ASSIGN_SUCCESS,
  ADD_ASSIGN_FAILED,
  DELETE_ASSIGN,
  DELETE_ASSIGN_SUCCESS,
  DELETE_ASSIGN_FAILED,
  ADD_FOLLOWER,
  ADD_FOLLOWER_SUCCESS,
  ADD_FOLLOWER_FAILED,
  DELETE_FOLLOWER,
  DELETE_FOLLOWER_SUCCESS,
  DELETE_FOLLOWER_FAILED,
  OPEN_TASK_EDIT,
  CLOSE_TASK_EDIT,
  OPEN_TASK_CREATE,
  CLOSE_TASK_CREATE,
  GET_TASK_BY_PROJECT,
  GET_TASK_BY_PROJECT_SUCCESS,
  GET_TASK_BY_PROJECT_FAILED,
  GET_TASK_SUMMARY_BY_PROJECT_REQUEST,
  GET_TASK_SUMMARY_BY_PROJECT_SUCCESS,
  GET_TASK_SUMMARY_BY_PROJECT_FAILED,
  FILTER_TASK_BY_PROJECT_REQUEST,
  FILTER_TASK_BY_PROJECT_SUCCESS,
  FILTER_TASK_BY_PROJECT_FAILED,
  FILTER_TASK_REQUEST,
  FILTER_TASK_SUCCESS,
  FILTER_TASK_FAILED,
  GET_LIST_STAFF,
  GET_LIST_STAFF_SUCCESS,
  GET_LIST_STAFF_FAILED,
  CLOSE_TASK_BY_LEADS,
  COPY_TASK_PENDING,
  COPY_TASK_SUCCESS,
  COPY_TASK_FAILURE,
  GET_FILE_IN_TASK_SUCCESS,
  GET_FILE_IN_TASK_FAILURE,
  GET_FILE_IN_TASK_PENDING,
  DELETE_TAG_IN_TASK_PENDING,
  DELETE_TAG_IN_TASK_SUCCESS,
  DELETE_TAG_IN_TASK_FAILURE,
} from "./types";

const INIT_STATE = {
  listTask: {},
  listStatus: [],
  listCountTask: [],
  relatedItem: [],
  relatedItemByFilter: [],
  taskEdited: "",
  isUpdateTaskSuccess: false,
  taskDelete: "",
  isLoading: false,
  taskDetail: "",
  isCreateSuccess: false,

  onOpenTaskDetail: false,
  onOpenCreateTask: false,
  onOpenPopupDel: false,
  onOpenEditTask: false,

  onRedirect: true,
  dataTimer: {},
  dataChecklist: {},
  deleteTimer: false,
  deleteChecklistSuccess: false,
  dataComment: {},
  dataEditComment: {},
  deleteCommentSuccess: false,
  dataReminder: {},
  dataEditReminder: {},
  deleteReminderSuccess: false,
  dataAssign: {},
  deleteAssignSuccess: false,
  dataFollower: {},
  deleteFollowerSuccess: false,
  dataChangeStatus: {},
  dataChangePriority: {},
  taskSummaryProject: {},
  isFetchTask: false,
  listStaff: {},
  isCloseTaskByLead: false,
  isEditTaskByLead: false,
  listFiles: [],
  isChangePriority: false,
  isChangeStatus: false,
  isSuccess: false,
  isDeleteTag: false,
};

const TaskReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TASK:
      return {
        ...state,
        isLoading: true,
        taskDelete: "",
        isCreateSuccess: false,
        isChangePriority: false,
        isChangeStatus: false,
        isSuccess: false,
      };

    case GET_TASK_SUCCESS:
      return {
        ...state,
        listTask: action.payload,
        isLoading: false,
      };

    case GET_TASK_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_STATUS_LEADS:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_STATUS_LEADS_SUCCESS:
      return {
        ...state,
        listStatus: action.payload,
        isLoading: false,
      };

    case FETCH_STATUS_LEADS_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case GET_TASK_BY_LEAD:
      return {
        ...state,
        isLoading: true,
        taskDelete: "",
        isEditTaskByLead: false,
      };

    case GET_TASK_BY_LEAD_SUCCESS:
      return {
        ...state,
        listTask: action.payload,
        isLoading: false,
      };

    case GET_TASK_BY_LEAD_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case CREATE_NEW_TASK:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_NEW_TASK_SUCCESS:
      if (isArray(action.payload) === true) {
        return {
          ...state,
          taskDetail: action.payload[0],
          isLoading: false,
          onOpenTaskDetail: true,
          onOpenCreateTask: false,
          isCreateSuccess: true,
        };
      } else {
        return {
          ...state,
          taskDetail: action.payload,
          isLoading: false,
          onOpenTaskDetail: true,
          onOpenCreateTask: false,
          isCreateSuccess: true,
        };
      }
    case CREATE_NEW_TASK_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case CHANGE_TASK_PRIORITY:
      return {
        ...state,
      };
    case CHANGE_TASK_PRIORITY_SUCCESS:
      return {
        ...state,
        dataChangePriority: action.payload,
        isChangePriority: true,
      };
    case CHANGE_TASK_PRIORITY_FAILED:
      return {
        ...state,
      };
    case CHANGE_TASK_STATUS:
      return {
        ...state,
      };
    case CHANGE_TASK_STATUS_SUCCESS:
      return {
        ...state,
        dataChangeStatus: action.payload,
        isChangeStatus: true,
      };
    case CHANGE_TASK_STATUS_FAILED:
      return {
        ...state,
      };
    case GET_COUNT_TASK:
      return {
        ...state,
        isLoading: true,
      };

    case GET_COUNT_TASK_SUCCESS:
      return {
        ...state,
        listCountTask: action.payload,
        isLoading: false,
      };

    case GET_COUNT_TASK_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SEARCH_RELATED:
      return {
        ...state,
        isLoading: true,
      };
    case SEARCH_RELATED_SUCCESS:
      return {
        ...state,
        relatedItem: [action.payload],
        isLoading: false,
      };
    case SEARCH_RELATED_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SEARCH_RELATED_BY_FILTER:
      return {
        ...state,
      };
    case SEARCH_RELATED_BY_FILTER_SUCCESS:
      return {
        ...state,
        relatedItemByFilter: action.payload,
      };
    case SEARCH_RELATED_BY_FILTER_FAILED:
      return {
        ...state,
      };
    case EDIT_TASK:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_TASK_SUCCESS:
      if (isArray(action.payload) === true) {
        return {
          ...state,
          taskDetail: action.payload[0],
          isLoading: false,
          onOpenTaskDetail: true,
          onOpenEditTask: false,
          isEditTaskByLead: true,
          isSuccess: true,
        };
      } else {
        return {
          ...state,
          taskDetail: action.payload,
          isLoading: false,
          onOpenTaskDetail: true,
          onOpenEditTask: false,
          isEditTaskByLead: true,
          isSuccess: true,
        };
      }

    case EDIT_TASK_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_TASK:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_TASK_SUCCESS:
      return {
        ...state,
        taskDelete: action.payload,
        isLoading: false,
        onOpenTaskDetail: false,
        isSuccess: true,
      };
    case DELETE_TASK_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case GET_TASK_BY_ID:
      return {
        ...state,
        isLoading: true,
        deleteTimer: false,
        deleteChecklistSuccess: false,
        deleteCommentSuccess: false,
        deleteReminderSuccess: false,
        deleteAssignSuccess: false,
        deleteFollowerSuccess: false,
        isDeleteTag: false,
        dataTimer: {},
        dataReminder: {},
        dataEditReminder: {},
        dataChecklist: {},
        dataComment: {},
        dataEditComment: {},
        dataAssign: {},
        dataFollower: {},
        dataChangeStatus: {},
        dataChangePriority: {},
      };

    case GET_TASK_BY_ID_SUCCESS:
      return {
        ...state,
        taskDetail: action.payload,
        isLoading: false,
      };

    case GET_TASK_BY_ID_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case OPEN_TASK_DETAIL:
      return {
        ...state,
        onOpenTaskDetail: true,
        isLoading: true,
        taskDetail: action.payload,
      };

    case CLOSE_TASK_DETAIL:
      return {
        ...state,
        taskDetail: "",
        onOpenTaskDetail: false,
        isLoading: false,
        isCloseTaskByLead: true,
      };

    case REDIRECT_TO_TASK_EDIT:
      return {
        ...state,
        isLoading: false,
        onOpenEditTask: true,
        onOpenTaskDetail: false,
      };

    case OPEN_POPUP_DELETE:
      return {
        ...state,
        onOpenPopupDel: true,
        taskDetail: action.payload,
      };

    case CLOSE_POPUP_DELETE:
      return {
        ...state,
        onOpenPopupDel: false,
      };

    case ADD_NEW_TIMER:
      return {
        ...state,
      };

    case ADD_NEW_TIMER_SUCCESS:
      return {
        ...state,
        dataTimer: action.payload,
        isSuccess: true,
      };

    case ADD_NEW_TIMER_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case DELETE_TIMER:
      return {
        ...state,
      };

    case DELETE_TIMER_SUCCESS:
      return {
        ...state,
        deleteTimer: true,
        isSuccess: true,
      };

    case DELETE_TIMER_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ADD_NEW_CHECKLIST:
      return {
        ...state,
      };

    case ADD_NEW_CHECKLIST_SUCCESS:
      return {
        ...state,
        dataChecklist: action.payload,
        isSuccess: true,
      };

    case ADD_NEW_CHECKLIST_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case DELETE_CHECKLIST:
      return {
        ...state,
      };

    case DELETE_CHECKLIST_SUCCESS:
      return {
        ...state,
        deleteChecklistSuccess: true,
      };

    case DELETE_CHECKLIST_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ADD_COMMENT:
      return {
        ...state,
      };

    case ADD_COMMENT_SUCCESS:
      return {
        ...state,
        dataComment: action.payload,
      };

    case ADD_COMMENT_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case EDIT_COMMENT:
      return {
        ...state,
      };

    case EDIT_COMMENT_SUCCESS:
      return {
        ...state,
        dataEditComment: action.payload,
        isSuccess: true,
      };

    case EDIT_COMMENT_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case DELETE_COMMENT:
      return {
        ...state,
      };

    case DELETE_COMMENT_SUCCESS:
      return {
        ...state,
        deleteCommentSuccess: true,
      };

    case DELETE_COMMENT_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ADD_REMINDER:
      return {
        ...state,
      };

    case ADD_REMINDER_SUCCESS:
      return {
        ...state,
        dataReminder: action.payload,
        isSuccess: true,
      };

    case ADD_REMINDER_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case EDIT_REMINDER:
      return {
        ...state,
      };

    case EDIT_REMINDER_SUCCESS:
      return {
        ...state,
        dataEditReminder: action.payload,
        isSuccess: true,
      };

    case EDIT_REMINDER_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case DELETE_REMINDER:
      return {
        ...state,
      };

    case DELETE_REMINDER_SUCCESS:
      return {
        ...state,
        deleteReminderSuccess: true,
      };

    case DELETE_REMINDER_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case DELETE_ASSIGN:
      return {
        ...state,
      };

    case DELETE_ASSIGN_SUCCESS:
      return {
        ...state,
        deleteAssignSuccess: true,
      };

    case DELETE_ASSIGN_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ADD_ASSIGN:
      return {
        ...state,
      };

    case ADD_ASSIGN_SUCCESS:
      return {
        ...state,
        dataAssign: action.payload,
        isSuccess: true,
      };

    case ADD_ASSIGN_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ADD_FOLLOWER:
      return {
        ...state,
      };

    case ADD_FOLLOWER_SUCCESS:
      return {
        ...state,
        dataFollower: action.payload,
        isSuccess: true,
      };

    case ADD_FOLLOWER_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case DELETE_FOLLOWER:
      return {
        ...state,
      };

    case DELETE_FOLLOWER_SUCCESS:
      return {
        ...state,
        deleteFollowerSuccess: true,
      };

    case DELETE_FOLLOWER_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case OPEN_TASK_EDIT:
      return {
        ...state,
        isLoading: true,
        taskDetail: action.payload,
        onOpenEditTask: true,
      };

    case CLOSE_TASK_EDIT:
      return {
        ...state,
        isLoading: false,
        onOpenEditTask: false,
      };

    case OPEN_TASK_CREATE:
      return {
        ...state,
        onOpenCreateTask: true,
      };

    case CLOSE_TASK_CREATE:
      return {
        ...state,
        onOpenCreateTask: false,
      };

    case GET_TASK_BY_PROJECT:
      return {
        ...state,
        isLoading: true,
        taskDelete: "",
        isCreateSuccess: false,
        isChangePriority: false,
        isChangeStatus: false,
        isSuccess: false,
      };

    case GET_TASK_BY_PROJECT_SUCCESS:
      return {
        ...state,
        listTask: action.payload,
        isLoading: false,
      };

    case GET_TASK_BY_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case GET_TASK_SUMMARY_BY_PROJECT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_TASK_SUMMARY_BY_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        taskSummaryProject: action.payload,
      };
    case GET_TASK_SUMMARY_BY_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case FILTER_TASK_BY_PROJECT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FILTER_TASK_BY_PROJECT_SUCCESS:
      // let filterTaskProject = { data: action.payload };
      return {
        ...state,
        isLoading: false,
        listTask: action.payload,
      };
    case FILTER_TASK_BY_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case FILTER_TASK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FILTER_TASK_SUCCESS:
      // let filterTask = { data: action.payload };
      return {
        ...state,
        isLoading: false,
        listTask: action.payload,
      };
    case FILTER_TASK_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case GET_LIST_STAFF:
      return {
        ...state,
      };

    case GET_LIST_STAFF_SUCCESS:
      return {
        ...state,
        listStaff: action.payload,
      };

    case GET_LIST_STAFF_FAILED:
      return {
        ...state,
      };
    case CLOSE_TASK_BY_LEADS:
      return {
        ...state,
        isCloseTaskByLead: action.payload,
      };
    case COPY_TASK_PENDING:
      return { ...state, isLoading: true };
    case COPY_TASK_SUCCESS:
      return { ...state, onOpenTaskDetail: false, isLoading: false };
    case COPY_TASK_FAILURE:
      return { ...state, isLoading: false };

    case GET_FILE_IN_TASK_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_FILE_IN_TASK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listFiles: action.payload,
      };
    case GET_FILE_IN_TASK_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_TAG_IN_TASK_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_TAG_IN_TASK_SUCCESS:
      console.log("asdasdasd");
      return {
        ...state,
        isLoading: false,
        isDeleteTag: true,
        isSuccess: true,
      };
    case DELETE_TAG_IN_TASK_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return { ...state };
  }
};

export default TaskReducer;
