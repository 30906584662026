import ActionTypes from "./type";

export const fetchNoteRequest = (id, value, params) => {
  return {
    type: ActionTypes.FETCH_NOTES_REQUEST,
    id,
    value,
    params,
  };
};

export const fetchNoteSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_NOTES_SUCCESS,
    data,
  };
};

export const fetchNoteFailed = () => {
  return {
    type: ActionTypes.FETCH_NOTES_FAILED,
  };
};

export const newNotesRequest = (id, value) => {
  return {
    type: ActionTypes.NEW_NOTES_REQUEST,
    id,
    value,
  };
};

export const newNotesSuccess = (data) => {
  return {
    type: ActionTypes.NEW_NOTES_SUCCESS,
    data,
  };
};

export const newNotesFailed = () => {
  return {
    type: ActionTypes.NEW_NOTES_FAILED,
  };
};

export const editNotesRequest = (id, value) => {
  return {
    type: ActionTypes.EDIT_NOTES_REQUEST,
    id,
    value,
  };
};

export const editNotesSuccess = (data) => {
  return {
    type: ActionTypes.EDIT_NOTES_SUCCESS,
    data,
  };
};

export const editNotesFailed = () => {
  return {
    type: ActionTypes.EDIT_NOTES_FAILED,
  };
};

export const deleteNoteRequest = (id) => {
  return {
    type: ActionTypes.DELETE_NOTES_REQUEST,
    id,
  };
};

export const deleteNotesSuccess = () => {
  return {
    type: ActionTypes.DELETE_NOTES_SUCCESS,
  };
};

export const deleteNotesFailed = () => {
  return {
    type: ActionTypes.DELETE_NOTES_FAILED,
  };
};
