export const responeCode = {
  success: 0,
  error: 1,
};

export const typeMessage = {
  error: "error",
  success: "success",
  warning: "warning",
};
export const listYear = [
  {
    id: 1,
    year: 2021,
  },
  {
    id: 2,
    year: 2020,
  },
];
