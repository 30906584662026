export const FETCH_LIST_CONTRACT = "FETCH_LIST_CONTRACT";
export const FETCH_LIST_CONTRACT_SUCCESS = "FETCH_LIST_CONTRACT_SUCCESS";
export const FETCH_LIST_CONTRACT_FAILED = "FETCH_LIST_CONTRACT_FAILED";

export const FETCH_CONTRACT = "FETCH_CONTRACT";
export const FETCH_CONTRACT_SUCCESS = "FETCH_CONTRACT_SUCCESS";
export const FETCH_CONTRACT_FAILED = "FETCH_CONTRACT_FAILED";

export const CREATE_CONTRACT = "CREATE_CONTRACT";
export const CREATE_CONTRACT_SUCCESS = "CREATE_CONTRACT_SUCCESS";
export const CREATE_CONTRACT_FAILED = "CREATE_CONTRACT_FAILED";

export const DELETE_CONTRACT = "DELETE_CONTRACT";
export const DELETE_CONTRACT_SUCCESS = "DELETE_CONTRACT_SUCCESS";
export const DELETE_CONTRACT_FAILED = "DELETE_CONTRACT_FAILED";

export const FETCH_LIST_CUSTOMER_CONTRACT = "FETCH_LIST_CUSTOMER_CONTRACT";
export const FETCH_LIST_CUSTOMER_CONTRACT_SUCCESS =
    "FETCH_LIST_CUSTOMER_CONTRACT_SUCCESS";
export const FETCH_LIST_CUSTOMER_CONTRACT_FAILED =
    "FETCH_LIST_CUSTOMER_CONTRACT_FAILED";

export const SET_EDIT_CONTRACT = "SET_EDIT_CONTRACT";

export const UPDATE_CONTRACT = "UPDATE_CONTRACT";
export const UPDATE_CONTRACT_SUCCESS = "UPDATE_CONTRACT_SUCCESS";
export const UPDATE_CONTRACT_FAILED = "UPDATE_CONTRACT_FAILED";

export const FILTER_CONTRACT = "FILTER_CONTRACT";
export const FILTER_CONTRACT_SUCCESS = "FILTER_CONTRACT_SUCCESS";
export const FILTER_CONTRACT_FAILED = "FILTER_CONTRACT_FAILED";

export const FETCH_SUMMARY_CONTRACT = "FETCH_SUMMARY_CONTRACT";
export const FETCH_SUMMARY_CONTRACT_SUCCESS = "FETCH_SUMMARY_CONTRACT_SUCCESS";
export const FETCH_SUMMARY_CONTRACT_FAILED = "FETCH_SUMMARY_CONTRACT_FAILED";

export const FETCH_LIST_ATTACHMENT_CONTRACT = "FETCH_LIST_ATTACHMENT_CONTRACT";
export const FETCH_LIST_ATTACHMENT_CONTRACT_SUCCESS =
    "FETCH_LIST_ATTACHMENT_CONTRACT_SUCCESS";
export const FETCH_LIST_ATTACHMENT_CONTRACT_FAILED =
    "FETCH_LIST_NOTE_CONTRACT_FAILED";

export const CREATE_ATTACHMENT_CONTRACT = "CREATE_ATTACHMENT_CONTRACT";
export const CREATE_ATTACHMENT_CONTRACT_SUCCESS =
    "CREATE_ATTACHMENT_CONTRACT_SUCCESS";
export const CREATE_ATTACHMENT_CONTRACT_FAILED =
    "CREATE_ATTACHMENT_CONTRACT_FAILED";

export const DELETE_ATTACHMENT_CONTRACT = "DELETE_ATTACHMENT_CONTRACT";
export const DELETE_ATTACHMENT_CONTRACT_SUCCESS =
    "DELETE_ATTACHMENT_CONTRACT_SUCCESS";
export const DELETE_ATTACHMENT_CONTRACT_FAILED =
    "DELETE_ATTACHMENT_CONTRACT_FAILED";

export const FETCH_NOTE_CONTRACT = "FETCH_NOTE_CONTRACT";
export const FETCH_NOTE_CONTRACT_SUCCESS = "FETCH_NOTE_CONTRACT_SUCCESS";
export const FETCH_NOTE_CONTRACT_FAILED = "FETCH_NOTE_CONTRACT_FAILED";

export const CREATE_NOTE_CONTRACT = "CREATE_NOTE_CONTRACT";
export const CREATE_NOTE_CONTRACT_SUCCESS = "CREATE_NOTE_CONTRACT_SUCCESS";
export const CREATE_NOTE_CONTRACT_FAILED = "CREATE_NOTE_CONTRACT_FAILED";

export const DELETE_NOTE_CONTRACT = "DELETE_NOTE_CONTRACT";
export const DELETE_NOTE_CONTRACT_SUCCESS = "DELETE_NOTE_CONTRACT_SUCCESS";
export const DELETE_NOTE_CONTRACT_FAILED = "DELETE_NOTE_CONTRACT_FAILED";

export const UPDATE_NOTE_CONTRACT = "UPDATE_NOTE_CONTRACT";
export const UPDATE_NOTE_CONTRACT_SUCCESS = "UPDATE_NOTE_CONTRACT_SUCCESS";
export const UPDATE_NOTE_CONTRACT_FAILED = "UPDATE_NOTE_CONTRACT_FAILED";

export const FETCH_LIST_COMMENT_CONTRACT = "FETCH_LIST_COMMENT_CONTRACT";
export const FETCH_LIST_COMMENT_CONTRACT_SUCCESS =
    "FETCH_LIST_COMMENT_CONTRACT_SUCCESS";
export const FETCH_LIST_COMMENT_CONTRACT_FAILED =
    "FETCH_LIST_COMMENT_CONTRACT_FAILED";

export const CREATE_COMMENT_CONTRACT = "CREATE_COMMENT_CONTRACT";
export const CREATE_COMMENT_CONTRACT_SUCCESS =
    "CREATE_COMMENT_CONTRACT_SUCCESS";
export const CREATE_COMMENT_CONTRACT_FAILED = "CREATE_COMMENT_CONTRACT_FAILED";

export const DELETE_COMMENT_CONTRACT = "DELETE_COMMENT_CONTRACT";
export const DELETE_COMMENT_CONTRACT_SUCCESS =
    "DELETE_COMMENT_CONTRACT_SUCCESS";
export const DELETE_COMMENT_CONTRACT_FAILED = "DELETE_COMMENT_CONTRACT_FAILED";

export const UPDATE_COMMENT_CONTRACT = "UPDATE_COMMENT_CONTRACT";
export const UPDATE_COMMENT_CONTRACT_SUCCESS =
    "UPDATE_COMMENT_CONTRACT_SUCCESS";
export const UPDATE_COMMENT_CONTRACT_FAILED = "UPDATE_COMMENT_CONTRACT_FAILED";

export const FETCH_LIST_RENEWAL_CONTRACT = "FETCH_LIST_RENEWAL_CONTRACT";
export const FETCH_LIST_RENEWAL_CONTRACT_SUCCESS =
    "FETCH_LIST_RENEWAL_CONTRACT_SUCCESS";
export const FETCH_LIST_RENEWAL_CONTRACT_FAILED =
    "FETCH_LIST_RENEWAL_CONTRACT_FAILED";

export const CREATE_RENEWAL_CONTRACT = "CREATE_RENEWAL_CONTRACT";
export const CREATE_RENEWAL_CONTRACT_SUCCESS =
    "CREATE_RENEWAL_CONTRACT_SUCCESS";
export const CREATE_RENEWAL_CONTRACT_FAILED = "CREATE_RENEWAL_CONTRACT_FAILED";

export const DELETE_RENEWAL_CONTRACT = "DELETE_RENEWAL_CONTRACT";
export const DELETE_RENEWAL_CONTRACT_SUCCESS =
    "DELETE_RENEWAL_CONTRACT_SUCCESS";
export const DELETE_RENEWAL_CONTRACT_FAILED = "DELETE_RENEWAL_CONTRACT_FAILED";

export const FETCH_LIST_TASK_CONTRACT = "FETCH_LIST_TASK_CONTRACT";
export const FETCH_LIST_TASK_CONTRACT_SUCCESS =
    "FETCH_LIST_TASK_CONTRACT_SUCCESS";
export const FETCH_LIST_TASK_CONTRACT_FAILED =
    "FETCH_LIST_TASK_CONTRACT_FAILED";

export const CREATE_TASK_CONTRACT = "CREATE_TASK_CONTRACT";
export const CREATE_TASK_CONTRACT_SUCCESS = "CREATE_TASK_CONTRACT_SUCCESS";
export const CREATE_TASK_CONTRACT_FAILED = "CREATE_TASK_CONTRACT_FAILED";

export const DELETE_TASK_CONTRACT = "DELETE_TASK_CONTRACT";
export const DELETE_TASK_CONTRACT_SUCCESS = "DELETE_TASK_CONTRACT_SUCCESS";
export const DELETE_TASK_CONTRACT_FAILED = "DELETE_TASK_CONTRACT_FAILED";

export const UPDATE_TASK_CONTRACT = "UPDATE_TASK_CONTRACT";
export const UPDATE_TASK_CONTRACT_SUCCESS = "UPDATE_TASK_CONTRACT_SUCCESS";
export const UPDATE_TASK_CONTRACT_FAILED = "UPDATE_TASK_CONTRACT_FAILED";

export const DATA_COMMENT = "DATA_COMMENT";
export const DATA_NOTES = "DATA_NOTES";

export const FETCH_CONTRACT_TYPE = "FETCH_CONTRACT_TYPE";
export const FETCH_CONTRACT_TYPE_SUCCESS = "FETCH_CONTRACT_TYPE_SUCCESS";
export const FETCH_CONTRACT_TYPE_FAILED = "FETCH_CONTRACT_TYPE_FAILED";

export const CREATE_CONTRACT_TYPE = "CREATE_CONTRACT_TYPE";
export const CREATE_CONTRACT_TYPE_SUCCESS = "CREATE_CONTRACT_TYPE_SUCCESS";
export const CREATE_CONTRACT_TYPE_FAILED = "CREATE_CONTRACT_TYPE_FAILED";

export const FETCH_CUSTOM_FIELD = "FETCH_CUSTOM_FIELD";
export const FETCH_CUSTOM_FIELD_SUCCESS = "FETCH_CUSTOM_FIELD_SUCCESS";
export const FETCH_CUSTOM_FIELD_FAILED = "FETCH_CUSTOM_FIELD_FAILED";

export const FETCH_CONTRACT_BY_TYPE = "FETCH_CONTRACT_BY_TYPE";
export const FETCH_CONTRACT_BY_TYPE_SUCCESS = "FETCH_CONTRACT_BY_TYPE_SUCCESS";
export const FETCH_CONTRACT_BY_TYPE_FAILED = "FETCH_CONTRACT_BY_TYPE_FAILED";

export const FETCH_CONTRACT_VALUE_BY_TYPE = "FETCH_CONTRACT_VALUE_BY_TYPE";
export const FETCH_CONTRACT_VALUE_BY_TYPE_SUCCESS =
    "FETCH_CONTRACT_VALUE_BY_TYPE_SUCCESS";
export const FETCH_CONTRACT_VALUE_BY_TYPE_FAILED =
    "FETCH_CONTRACT_VALUE_BY_TYPE_FAILED";

export const FETCH_YEAR = "FETCH_YEAR";
export const FETCH_YEAR_SUCCESS = "FETCH_YEAR_SUCCESS";
export const FETCH_YEAR_FAILED = "FETCH_YEAR_FAILED";

export const ADD_NEW_CONTRACT_PENDING = "ADD_NEW_CONTRACT_PENDING";
export const ADD_NEW_CONTRACT_SUCCESS = "ADD_NEW_CONTRACT_SUCCESS";
export const ADD_NEW_CONTRACT_FAILED = "ADD_NEW_CONTRACT_FAILED";
