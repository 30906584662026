// @flow
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import i18n from "../../../../i18n";

import {
  FETCH_DISCUSSION,
  UPDATE_DISCUSSION,
  DELETE_DISCUSSION,
  CREATE_DISCUSSION,
} from "./types";

import {
  fetchDiscussionSuccess,
  fetchDiscussionFailed,
  createDiscussionSuccess,
  createDiscussionFailed,
  deleteDiscussionSuccess,
  deleteDiscussionFailed,
  updateDiscussionSuccess,
  updateDiscussionFailed,
} from "./actions";
import { enqueueSnackbar } from "../../../../redux/CommonReducer";
import {
  getDiscussionProject,
  createDiscussionProject,
  deleteDiscussionProject,
  updateDiscussionProject,
} from "./api";
import { responeCode, typeMessage } from "../../../../constants/Const";
import {
  throwErrorMessage,
  parseDataNotifications,
} from "../../../../helpers/HandleError";

function* fetchDiscussionProjectSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(getDiscussionProject, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(fetchDiscussionSuccess(response.data.result));
    } else {
      yield put(fetchDiscussionFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchDiscussionFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* createDiscussionProjectSaga(data) {
  const { payload, id } = data;
  try {
    const response = yield call(createDiscussionProject, payload, id);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(createDiscussionSuccess("asdasd"));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(
            `${i18n.t("project.createSuccess")}`,
            typeMessage.success
          )
        )
      );
    } else {
      yield put(createDiscussionFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(createDiscussionFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* deleteDiscussionProjectSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(deleteDiscussionProject, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(deleteDiscussionSuccess());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(
            `${i18n.t("project.deleteSuccess")}`,
            typeMessage.success
          )
        )
      );
    } else {
      yield put(deleteDiscussionFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(deleteDiscussionFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* updateDiscussionProjectSaga(data) {
  const { payload } = data;
  try {
    const response = yield call(updateDiscussionProject, payload);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(updateDiscussionSuccess());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(
            `${i18n.t("project.updateSuccess")}`,
            typeMessage.success
          )
        )
      );
    } else {
      yield put(updateDiscussionFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(updateDiscussionFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

export function* discussionProject() {
  yield takeEvery(FETCH_DISCUSSION, fetchDiscussionProjectSaga);
  yield takeEvery(CREATE_DISCUSSION, createDiscussionProjectSaga);
  yield takeEvery(DELETE_DISCUSSION, deleteDiscussionProjectSaga);
  yield takeEvery(UPDATE_DISCUSSION, updateDiscussionProjectSaga);
}

function* DiscussionProject() {
  yield all([fork(discussionProject)]);
}

export default DiscussionProject;
