import {
	FETCH_LIST_LEAD_SOURCE,
	FETCH_LIST_LEAD_SOURCE_SUCCESS,
	FETCH_LIST_LEAD_SOURCE_FAILED,

	FETCH_CREATE_LEAD_SOURCE,
	FETCH_CREATE_LEAD_SOURCE_SUCCESS,
	FETCH_CREATE_LEAD_SOURCE_FAILED,

	FETCH_EDIT_LEAD_SOURCE,
	FETCH_EDIT_LEAD_SOURCE_SUCCESS,
	FETCH_EDIT_LEAD_SOURCE_FAILED,

	FETCH_DELETE_LEAD_SOURCE,
	FETCH_DELETE_LEAD_SOURCE_SUCCESS,
	FETCH_DELETE_LEAD_SOURCE_FAILED,

	FETCH_LIST_LEAD_STATUS,
	FETCH_LIST_LEAD_STATUS_SUCCESS,
	FETCH_LIST_LEAD_STATUS_FAILED,

	FETCH_CREATE_LEAD_STATUS,
	FETCH_CREATE_LEAD_STATUS_SUCCESS,
	FETCH_CREATE_LEAD_STATUS_FAILED,

	FETCH_EDIT_LEAD_STATUS,
	FETCH_EDIT_LEAD_STATUS_SUCCESS,
	FETCH_EDIT_LEAD_STATUS_FAILED,

	FETCH_DELETE_LEAD_STATUS,
	FETCH_DELETE_LEAD_STATUS_SUCCESS,
	FETCH_DELETE_LEAD_STATUS_FAILED,

	FETCH_LEAD_EMAIL_INTEGRATION,
	FETCH_LEAD_EMAIL_INTEGRATION_SUCCESS,
	FETCH_LEAD_EMAIL_INTEGRATION_FAILED,

	FETCH_CREATE_LEAD_EMAIL_INTEGRATION,
	FETCH_CREATE_LEAD_EMAIL_INTEGRATION_SUCCESS,
	FETCH_CREATE_LEAD_EMAIL_INTEGRATION_FAILED,

	FETCH_EDIT_LEAD_EMAIL_INTEGRATION,
	FETCH_EDIT_LEAD_EMAIL_INTEGRATION_SUCCESS,
	FETCH_EDIT_LEAD_EMAIL_INTEGRATION_FAILED,

	FETCH_DELETE_LEAD_EMAIL_INTEGRATION,
	FETCH_DELETE_LEAD_EMAIL_INTEGRATION_SUCCESS,
	FETCH_DELETE_LEAD_EMAIL_INTEGRATION_FAILED,

	FETCH_LIST_RESPONSIBLE,
	FETCH_LIST_RESPONSIBLE_SUCCESS,
	FETCH_LIST_RESPONSIBLE_FAILED,

	FETCH_LIST_WEB_LEAD,
	FETCH_LIST_WEB_LEAD_SUCCESS,
	FETCH_LIST_WEB_LEAD_FAILED,

	FETCH_WEB_LEAD_BY_ID,
	FETCH_WEB_LEAD_BY_ID_SUCCESS,
	FETCH_WEB_LEAD_BY_ID_FAILED,

	FETCH_CREATE_WEB_LEAD,
	FETCH_CREATE_WEB_LEAD_SUCCESS,
	FETCH_CREATE_WEB_LEAD_FAILED,

	FETCH_EDIT_WEB_LEAD,
	FETCH_EDIT_WEB_LEAD_SUCCESS,
	FETCH_EDIT_WEB_LEAD_FAILED,

	FETCH_DELETE_WEB_LEAD,
	FETCH_DELETE_WEB_LEAD_SUCCESS,
	FETCH_DELETE_WEB_LEAD_FAILED,

	SET_LEAD_SOURCE,
	SET_ISSUCCESS,
	SET_WEB_LEAD,
	GET_ALL_ADMIN_LEADS_SPAM_FILTER_REQUEST,
	GET_ALL_ADMIN_LEADS_SPAM_FILTER_SUCCESS,
	GET_ALL_ADMIN_LEADS_SPAM_FILTER_FAILED,
	CREATE_ADMIN_LEADS_SPAM_FILTER_REQUEST,
	CREATE_ADMIN_LEADS_SPAM_FILTER_SUCCESS,
	CREATE_ADMIN_LEADS_SPAM_FILTER_FAILED,
	EDIT_ADMIN_LEADS_SPAM_FILTER_REQUEST,
	EDIT_ADMIN_LEADS_SPAM_FILTER_SUCCESS,
	EDIT_ADMIN_LEADS_SPAM_FILTER_FAILED,
	DELETE_ADMIN_LEADS_SPAM_FILTER_REQUEST,
	DELETE_ADMIN_LEADS_SPAM_FILTER_SUCCESS,
	CHANGE_STATUS_DIALOG,
	DELETE_ADMIN_LEADS_SPAM_FILTER_FAILED,
	FETCH_ADMIN_LEADS_SPAM_FILTER_REQUEST,
	FETCH_ADMIN_LEADS_SPAM_FILTER_SUCCESS,
	FETCH_ADMIN_LEADS_SPAM_FILTER_FAILED,
	GET_COUNT_LEADS_SOURCE_SUCCESS,
	GET_COUNT_LEADS_SOURCE_FAILED,
	GET_COUNT_LEADS_STATUS_SUCCESS,
	GET_COUNT_LEADS_STATUS_FAILED
} from './types';

const INIT_STATE = {
	listSource: [],
	listStatus: [],
	listWebLead: [],
	itemWebLead: [],
	listResponsible: [],
	leadSourceItem: {},
	leadEmailIntegration: [],
	webLead: {},
	isLoading: false,
	isSuccess: false,
	listSpamFilter: [],
	isCloseDialog: false,
	typeOfSearchRequest: 'sender',
	listCountSource: [],
	listCountStatus: []
};

const AdminLeadsReducer = (state = INIT_STATE, action) => {
	switch (action.type) {

		case SET_WEB_LEAD:
			return {
				...state,
				webLead: action.payload
			};

		case SET_ISSUCCESS:
			return {
				...state,
				isSuccess: action.payload
			};

		case SET_LEAD_SOURCE:
			return {
				...state,
				leadSourceItem: action.payload
			};

		case FETCH_LIST_LEAD_SOURCE:
			return {
				...state,
				isLoading: true,
			};

		case FETCH_LIST_LEAD_SOURCE_SUCCESS:
			return {
				...state,
				listSource: action.payload,
				isLoading: false,
			};

		case FETCH_LIST_LEAD_SOURCE_FAILED:
			return {
				...state,
				isLoading: false
			};

		case FETCH_CREATE_LEAD_SOURCE:
			return {
				...state,
				isLoading: true,
				isSuccess: false,
			};

		case FETCH_CREATE_LEAD_SOURCE_SUCCESS:
			return {
				...state,
				isLoading: false,
				isSuccess: true,
			};

		case FETCH_CREATE_LEAD_SOURCE_FAILED:
			return {
				...state,
				isLoading: false
			};

		case FETCH_EDIT_LEAD_SOURCE:
			return {
				...state,
				isLoading: true,
				isSuccess: false
			};

		case FETCH_EDIT_LEAD_SOURCE_SUCCESS:
			return {
				...state,
				isLoading: false,
				isSuccess: true
			};

		case FETCH_EDIT_LEAD_SOURCE_FAILED:
			return {
				...state,
				isLoading: false
			};

		case FETCH_DELETE_LEAD_SOURCE:
			return {
				...state,
				isLoading: true,
				isSuccess: false
			};

		case FETCH_DELETE_LEAD_SOURCE_SUCCESS:
			return {
				...state,
				isLoading: false,
				isSuccess: true
			};

		case FETCH_DELETE_LEAD_SOURCE_FAILED:
			return {
				...state,
				isLoading: false
			};

		case FETCH_LIST_LEAD_STATUS:
			return {
				...state,
				isLoading: true
			};

		case FETCH_LIST_LEAD_STATUS_SUCCESS:
			return {
				...state,
				listStatus: action.payload,
				isLoading: false,
			};

		case FETCH_LIST_LEAD_STATUS_FAILED:
			return {
				...state,
				isLoading: false
			};

		case FETCH_CREATE_LEAD_STATUS:
			return {
				...state,
				isLoading: true,
				isSuccess: false
			};

		case FETCH_CREATE_LEAD_STATUS_SUCCESS:
			return {
				...state,
				isLoading: false,
				isSuccess: true
			};

		case FETCH_CREATE_LEAD_STATUS_FAILED:
			return {
				...state,
				isLoading: false
			};

		case FETCH_EDIT_LEAD_STATUS:
			return {
				...state,
				isLoading: true,
				isSuccess: false
			};

		case FETCH_EDIT_LEAD_STATUS_SUCCESS:
			return {
				...state,
				isLoading: false,
				isSuccess: true
			};

		case FETCH_EDIT_LEAD_STATUS_FAILED:
			return {
				...state,
				isLoading: false
			};

		case FETCH_DELETE_LEAD_STATUS:
			return {
				...state,
				isLoading: true,
				isSuccess: false
			};

		case FETCH_DELETE_LEAD_STATUS_SUCCESS:
			return {
				...state,
				isLoading: false,
				isSuccess: true
			};

		case FETCH_DELETE_LEAD_STATUS_FAILED:
			return {
				...state,
				isLoading: false
			};

		case FETCH_LEAD_EMAIL_INTEGRATION:
			return {
				...state,
				isLoading: true
			};

		case FETCH_LEAD_EMAIL_INTEGRATION_SUCCESS:
			return {
				...state,
				leadEmailIntegration: action.payload,
				isLoading: false,
			};

		case FETCH_LEAD_EMAIL_INTEGRATION_FAILED:
			return {
				...state,
				isLoading: false
			};

		case FETCH_CREATE_LEAD_EMAIL_INTEGRATION:
			return {
				...state,
				isLoading: true,
				isSuccess: false
			};

		case FETCH_CREATE_LEAD_EMAIL_INTEGRATION_SUCCESS:
			return {
				...state,
				isLoading: false,
				isSuccess: true
			};

		case FETCH_CREATE_LEAD_EMAIL_INTEGRATION_FAILED:
			return {
				...state,
				isLoading: false
			};

		case FETCH_EDIT_LEAD_EMAIL_INTEGRATION:
			return {
				...state,
				isLoading: true,
				isSuccess: false
			};

		case FETCH_EDIT_LEAD_EMAIL_INTEGRATION_SUCCESS:
			return {
				...state,
				isLoading: false,
				isSuccess: true
			};

		case FETCH_EDIT_LEAD_EMAIL_INTEGRATION_FAILED:
			return {
				...state,
				isLoading: false
			};

		case FETCH_DELETE_LEAD_EMAIL_INTEGRATION:
			return {
				...state,
				isLoading: true,
				isSuccess: false
			};

		case FETCH_DELETE_LEAD_EMAIL_INTEGRATION_SUCCESS:
			return {
				...state,
				isLoading: false,
				isSuccess: true
			};

		case FETCH_DELETE_LEAD_EMAIL_INTEGRATION_FAILED:
			return {
				...state,
				isLoading: false
			};

		case FETCH_LIST_RESPONSIBLE:
			return {
				...state,
				isLoading: true
			};

		case FETCH_LIST_RESPONSIBLE_SUCCESS:
			return {
				...state,
				listResponsible: action.payload,
				isLoading: false,
			};

		case FETCH_LIST_RESPONSIBLE_FAILED:
			return {
				...state,
				isLoading: false
			};

		case FETCH_LIST_WEB_LEAD:
			return {
				...state,
				isLoading: true,
				isSuccess: false
			};

		case FETCH_LIST_WEB_LEAD_SUCCESS:
			return {
				...state,
				listWebLead: action.payload,
				isLoading: false,
			};

		case FETCH_LIST_WEB_LEAD_FAILED:
			return {
				...state,
				isLoading: false
			};

		case FETCH_WEB_LEAD_BY_ID:
			return {
				...state,
				isLoading: true
			};

		case FETCH_WEB_LEAD_BY_ID_SUCCESS:
			return {
				...state,
				itemWebLead: action.payload,
				isLoading: false,
			};

		case FETCH_WEB_LEAD_BY_ID_FAILED:
			return {
				...state,
				isLoading: false
			};

		case FETCH_CREATE_WEB_LEAD:
			return {
				...state,
				isLoading: true,
				isSuccess: false
			};

		case FETCH_CREATE_WEB_LEAD_SUCCESS:
			return {
				...state,
				isLoading: false,
				isSuccess: true
			};

		case FETCH_CREATE_WEB_LEAD_FAILED:
			return {
				...state,
				isLoading: false
			};

		case FETCH_EDIT_WEB_LEAD:
			return {
				...state,
				isLoading: true,
				isSuccess: false
			};

		case FETCH_EDIT_WEB_LEAD_SUCCESS:
			return {
				...state,
				isLoading: false,
				isSuccess: true
			};

		case FETCH_EDIT_WEB_LEAD_FAILED:
			return {
				...state,
				isLoading: false
			};

		case FETCH_DELETE_WEB_LEAD:
			return {
				...state,
				isLoading: true,
				isSuccess: false
			};

		case FETCH_DELETE_WEB_LEAD_SUCCESS:
			return {
				...state,
				isLoading: false,
				isSuccess: true
			};

		case FETCH_DELETE_WEB_LEAD_FAILED:
			return {
				...state,
				isLoading: false,
				isSuccess: true
			};

		case GET_ALL_ADMIN_LEADS_SPAM_FILTER_REQUEST:
			return {
				...state,
				isLoading: true
			}

		case GET_ALL_ADMIN_LEADS_SPAM_FILTER_SUCCESS:
			return {
				...state,
				isLoading: false,
				listSpamFilter: action.payload
			}
		case GET_ALL_ADMIN_LEADS_SPAM_FILTER_FAILED:
			return {
				...state,
				isLoading: false
			}

		case CREATE_ADMIN_LEADS_SPAM_FILTER_REQUEST:
			return {
				...state,
				isLoading: true,
				typeOfSearchRequest: action.payload.type
			}

		case CREATE_ADMIN_LEADS_SPAM_FILTER_SUCCESS:
			return {
				...state,
				isCloseDialog: true,
				isLoading: false,
				listSpamFilter: state.listSpamFilter
			};

		case CREATE_ADMIN_LEADS_SPAM_FILTER_FAILED:
			return {
				...state,
				isLoading: false,
				isCloseDialog: true,
			};

		case EDIT_ADMIN_LEADS_SPAM_FILTER_REQUEST:
			return {
				...state,
				isLoading: true,
				typeOfSearchRequest: action.payload.type
			}

		case EDIT_ADMIN_LEADS_SPAM_FILTER_SUCCESS:

			return {
				...state,
				isCloseDialog: true,
				isLoading: false,
			}

		case EDIT_ADMIN_LEADS_SPAM_FILTER_FAILED:
			return {
				...state,
				isLoading: false,
				isCloseDialog: true,
			};

		case DELETE_ADMIN_LEADS_SPAM_FILTER_REQUEST:
			return {
				...state,
				isLoading: true,
				typeOfSearchRequest: action.payload.type
			}

		case DELETE_ADMIN_LEADS_SPAM_FILTER_SUCCESS:
			return {
				...state,
				isCloseDialog: true,
				isLoading: false,
			};
		case CHANGE_STATUS_DIALOG:
			return {
				...state,
				isCloseDialog: false,
			}

		case DELETE_ADMIN_LEADS_SPAM_FILTER_FAILED:
			return {
				...state,
				isLoading: false,
				isCloseDialog: true,
			};

		case FETCH_ADMIN_LEADS_SPAM_FILTER_REQUEST:
			return {
				...state,
				isLoading: true,
				typeOfSearchRequest: action.params.type
			}
		case FETCH_ADMIN_LEADS_SPAM_FILTER_SUCCESS:
			if (state.typeOfSearchRequest === "sender") {
				state.listSpamFilter.splice(0, 1, action.payload)
			} else if (state.typeOfSearchRequest === "subject") {
				state.listSpamFilter.splice(1, 1, action.payload)
			} else {
				state.listSpamFilter.splice(2, 1, action.payload)
			}
			return {
				...state,
				isLoading: false,
				isCloseDialog: false
			};

		case FETCH_ADMIN_LEADS_SPAM_FILTER_FAILED:
			return {
				...state,
				isLoading: false,
			};

		case GET_COUNT_LEADS_SOURCE_SUCCESS:
			return {
				...state,
				listCountSource: action.payload
			}

		case GET_COUNT_LEADS_SOURCE_FAILED:
			return {
				...state,
			}

		case GET_COUNT_LEADS_STATUS_SUCCESS:
			return {
				...state,
				listCountStatus: action.payload
			}

		case GET_COUNT_LEADS_STATUS_FAILED:
			return {
				...state,
			}

		default: return { ...state };
	}
}

export default AdminLeadsReducer;