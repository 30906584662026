import ActionTypes from "./type";

export const fetchInvoiceCustomerRequest = (id, value, params) => {
    return {
        type: ActionTypes.FETCH_INVOICE_CUSTOMER_REQUEST,
        id,
        value,
        params,
    };
};

export const fetchInvoiceCustomerSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_INVOICE_CUSTOMER_SUCCESS,
        data,
    };
};

export const fetchInvoiceCustomerFailed = () => {
    return {
        type: ActionTypes.FETCH_INVOICE_CUSTOMER_FAILED,
    };
};

export const filterYearInvoiceCustomerRequest = (id, value) => {
    return {
        type: ActionTypes.FILTER_YEAR_INVOICE_CUSTOMER_REQUEST,
        id,
        value,
    };
};

export const filterYearInvoiceCustomerSuccess = (data) => {
    return {
        type: ActionTypes.FILTER_YEAR_INVOICE_CUSTOMER_SUCCESS,
        payload: data,
    };
};

export const filterYearInvoiceCustomerFailed = () => {
    return {
        type: ActionTypes.FILTER_YEAR_INVOICE_CUSTOMER_FAILED,
    };
};
