export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "LOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

export const GET_LIST_COUNTRY = "GET_LIST_COUNTRY";
export const GET_LIST_COUNTRY_SUCCESS = "GET_LIST_COUNTRY_SUCCESS";
export const GET_LIST_COUNTRY_FAILED = "GET_LIST_COUNTRY_FAILED";

export const GET_LIST_CURRENCY = "GET_LIST_CURRENCY";
export const GET_LIST_CURRENCY_FAILED = "GET_LIST_CURRENCY_FAILED";
export const GET_LIST_CURRENCY_SUCCESS = "GET_LIST_CURRENCY_SUCCESS";

export const GET_LIST_SOURCE = "GET_LIST_SOURCE";
export const GET_LIST_SOURCE_SUCCESS = "GET_LIST_SOURCE_SUCCESS";
export const GET_LIST_SOURCE_FAILED = "GET_LIST_SOURCE_FAILED";

export const GET_LIST_TAGS = "GET_LIST_TAGS";
export const GET_LIST_TAGS_SUCCESS = "GET_LIST_TAGS_SUCCESS";
export const GET_LIST_TAGS_FAILED = "GET_LIST_TAGS_FAILED";

export const GET_LIST_STATUS = "GET_LIST_STATUS";
export const GET_LIST_STATUS_SUCCESS = "GET_LIST_STATUS_SUCCESS";
export const GET_LIST_STATUS_FAILED = "GET_LIST_STATUS_FAILED";

export const GET_LIST_STAFF = "GET_LIST_STAFF";
export const GET_LIST_STAFF_SUCCESS = "GET_LIST_STAFF_SUCCESS";
export const GET_LIST_STAFF_FAILED = "GET_LIST_STAFF_FAILED";

export const GET_LIST_ITEM = "GET_LIST_ITEM";
export const GET_LIST_ITEM_SUCCESS = "GET_LIST_ITEM_SUCCESS";
export const GET_LIST_ITEM_FAILED = "GET_LIST_ITEM_FAILED";

export const GET_LIST_TAX = "GET_LIST_TAX";
export const GET_LIST_TAX_SUCCESS = "GET_LIST_TAX_SUCCESS";
export const GET_LIST_TAX_FAILED = "GET_LIST_TAX_FAILED";

export const GET_LIST_ITEM_GROUP = "GET_LIST_ITEM_GROUP";
export const GET_LIST_ITEM_GROUP_SUCCESS = "GET_LIST_ITEM_GROUP_SUCCESS";
export const GET_LIST_ITEM_GROUP_FAILED = "GET_LIST_ITEM_GROUP_FAILED";

export const ADD_ITEM_TABLE = "ADD_ITEM_TABLE";
export const ADD_ITEM_TABLE_SUCCESS = "ADD_ITEM_TABLE_SUCCESS";
export const ADD_ITEM_TABLE_FAILED = "ADD_ITEM_TABLE_FAILED";

export const GET_LIST_CONTACT_PENDING = "GET_LIST_CONTACT_PENDING";
export const GET_LIST_CONTACT_SUCCESS = "GET_LIST_CONTACT_SUCCESS";
export const GET_LIST_CONTACT_FAILED = "GET_LIST_CONTACT_FAILED";
//-----------GET CONTACT
export const getListContactPending = () => {
    return {
        type: GET_LIST_CONTACT_PENDING,
    };
};

export const getListContactSuccess = (data) => {
    return {
        type: GET_LIST_CONTACT_SUCCESS,
        payload: data,
    };
};

export const getListContactFailed = () => {
    return {
        type: GET_LIST_CONTACT_FAILED,
    };
};
//----------NOTIFICATIONS
export const enqueueSnackbar = (notification) => {
    const key = notification.options && notification.options.key;
    return {
        type: ENQUEUE_SNACKBAR,
        notification: {
            ...notification,
            key: key || new Date().getTime() + Math.random(),
        },
    };
};

export const closeSnackbar = (key) => ({
    type: CLOSE_SNACKBAR,
    dismissAll: !key, // dismiss all if no key has been defined
    key,
});

export const removeSnackbar = (key) => ({
    type: REMOVE_SNACKBAR,
    key,
});

//----------GET LIST STAff
export const getListStaff = () => {
    return {
        type: GET_LIST_STAFF,
    };
};

export const getListStaffSuccess = (data) => {
    return {
        type: GET_LIST_STAFF_SUCCESS,
        payload: data,
    };
};

export const getListStaffFailed = () => {
    return {
        type: GET_LIST_STAFF_FAILED,
    };
};

//----------GET LIST STATUS
export const getListStatus = () => {
    return {
        type: GET_LIST_STATUS,
    };
};

export const getListStatusSuccess = (data) => {
    return {
        type: GET_LIST_STATUS_SUCCESS,
        payload: data,
    };
};

export const getListStatusFailed = () => {
    return {
        type: GET_LIST_STATUS_FAILED,
    };
};

//----------GET LIST COUNTRIES
export const getListCountry = () => {
    return {
        type: GET_LIST_COUNTRY,
    };
};

export const getListCountrySuccess = (data) => {
    return {
        type: GET_LIST_COUNTRY_SUCCESS,
        payload: data,
    };
};

export const getListCountryFailed = () => {
    return {
        type: GET_LIST_COUNTRY_FAILED,
    };
};

//----------GET LIST CURRENCIES
export const getListCurrency = () => {
    return {
        type: GET_LIST_CURRENCY,
    };
};

export const getListCurrencySuccess = (data) => {
    return {
        type: GET_LIST_CURRENCY_SUCCESS,
        payload: data,
    };
};

export const getListCurrencyFailed = () => {
    return {
        type: GET_LIST_CURRENCY_FAILED,
    };
};

// ---------GET LIST SOURCE
export const getListSource = () => {
    return {
        type: GET_LIST_SOURCE,
    };
};

export const getListSourceSuccess = () => {
    return {
        type: GET_LIST_SOURCE_SUCCESS,
    };
};

export const getListSourceFailed = () => {
    return {
        type: GET_LIST_SOURCE_FAILED,
    };
};

//---------GET LIST TAGS
export const getListTags = () => {
    return {
        type: GET_LIST_TAGS,
    };
};

export const getListTagsSuccess = (data) => {
    return {
        type: GET_LIST_TAGS_SUCCESS,
        payload: data,
    };
};

export const getListTagsFailed = () => {
    return {
        type: GET_LIST_TAGS_FAILED,
    };
};

//----------GET LIST ITEM
export const getListItem = () => {
    return {
        type: GET_LIST_ITEM,
    };
};

export const getListItemSuccess = (data) => {
    return {
        type: GET_LIST_ITEM_SUCCESS,
        payload: data,
    };
};

export const getListItemFailed = () => {
    return {
        type: GET_LIST_ITEM_FAILED,
    };
};
//----------GET LIST TAX
export const getListTax = () => {
    return {
        type: GET_LIST_TAX,
    };
};

export const getListTaxSuccess = (data) => {
    return {
        type: GET_LIST_TAX_SUCCESS,
        payload: data,
    };
};

export const getListTaxFailed = () => {
    return {
        type: GET_LIST_TAX_FAILED,
    };
};
//----------GET LIST ITEM GROUP
export const getListItemGroup = () => {
    return {
        type: GET_LIST_ITEM_GROUP,
    };
};

export const getListItemGroupSuccess = (data) => {
    return {
        type: GET_LIST_ITEM_GROUP_SUCCESS,
        payload: data,
    };
};

export const getListItemGroupFailed = () => {
    return {
        type: GET_LIST_ITEM_GROUP_FAILED,
    };
};

//----------GET LIST ITEM GROUP
export const addItemTable = (data) => {
    return {
        type: ADD_ITEM_TABLE,
        data,
    };
};

export const addItemTableSuccess = (data) => {
    return {
        type: ADD_ITEM_TABLE_SUCCESS,
        payload: data,
    };
};

export const addItemTableFailed = () => {
    return {
        type: ADD_ITEM_TABLE_FAILED,
    };
};

const initialState = {
    notifications: [],
    listCountries: [],
    listCurrency: [],
    listSource: [],
    listTags: [],
    listStatus: [],
    listStaff: [],
    listItem: [],
    listTax: [],
    listItemGroup: [],
    dataAddItem: "",
    statusAddItem: false,
    listContact: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ENQUEUE_SNACKBAR:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        key: action.key,
                        ...action.notification,
                    },
                ],
            };

        case CLOSE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.map((notification) =>
                    action.dismissAll || notification.key === action.key
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                ),
            };

        case REMOVE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.filter(
                    (notification) => notification.key !== action.key
                ),
            };

        case GET_LIST_STATUS:
            return {
                ...state,
                isLoading: true,
            };

        case GET_LIST_STATUS_SUCCESS:
            return {
                ...state,
                listStatus: action.payload,
                isLoading: false,
            };

        case GET_LIST_STATUS_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case GET_LIST_STAFF:
            return {
                ...state,
                isLoading: true,
            };

        case GET_LIST_STAFF_SUCCESS:
            return {
                ...state,
                listStaff: action.payload,
                isLoading: false,
            };

        case GET_LIST_STAFF_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case GET_LIST_CONTACT_PENDING:
            return {
                ...state,
                isLoading: true,
            };

        case GET_LIST_CONTACT_SUCCESS:
            return {
                ...state,
                listContact: action.payload,
                isLoading: false,
            };

        case GET_LIST_CONTACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case GET_LIST_COUNTRY:
            return {
                ...state,
                isLoading: true,
            };

        case GET_LIST_COUNTRY_SUCCESS:
            return {
                ...state,
                listCountries: action.payload,
                isLoading: false,
            };

        case GET_LIST_COUNTRY_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case GET_LIST_CURRENCY:
            return {
                ...state,
                isLoading: true,
            };

        case GET_LIST_CURRENCY_SUCCESS:
            return {
                ...state,
                listCurrency: action.payload,
                isLoading: false,
            };

        case GET_LIST_CURRENCY_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case GET_LIST_SOURCE:
            return {
                ...state,
                isLoading: true,
            };

        case GET_LIST_SOURCE_SUCCESS:
            return {
                ...state,
                listSource: action.payload,
                isLoading: false,
            };

        case GET_LIST_SOURCE_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case GET_LIST_TAGS:
            return {
                ...state,
                isLoading: true,
            };

        case GET_LIST_TAGS_SUCCESS:
            return {
                ...state,
                listTags: action.payload,
                isLoading: false,
            };

        case GET_LIST_TAGS_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case GET_LIST_ITEM:
            return {
                ...state,
                isLoading: true,
            };

        case GET_LIST_ITEM_SUCCESS:
            return {
                ...state,
                listItem: action.payload,
                isLoading: false,
            };

        case GET_LIST_ITEM_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case GET_LIST_TAX:
            return {
                ...state,
                isLoading: true,
            };

        case GET_LIST_TAX_SUCCESS:
            return {
                ...state,
                listTax: action.payload,
                isLoading: false,
            };

        case GET_LIST_TAX_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case GET_LIST_ITEM_GROUP:
            return {
                ...state,
                isLoading: true,
            };

        case GET_LIST_ITEM_GROUP_SUCCESS:
            return {
                ...state,
                listItemGroup: action.payload,
                isLoading: false,
            };

        case GET_LIST_ITEM_GROUP_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case ADD_ITEM_TABLE:
            return {
                ...state,
                statusAddItem: false,
            };

        case ADD_ITEM_TABLE_SUCCESS:
            let listAddItem = [...state.listItem];
            listAddItem.push(action.payload[0]);
            return {
                ...state,
                listItem: listAddItem,
                dataAddItem: action.payload[0],
                statusAddItem: true,
            };

        case ADD_ITEM_TABLE_FAILED:
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default reducer;
