import { all, call, fork, put, takeEvery } from "@redux-saga/core/effects";
import { responeCode, typeMessage } from "../../../constants/Const";
import {
    parseDataNotifications,
    throwErrorMessage,
} from "../../../helpers/HandleError";
import { enqueueSnackbar } from "../../../redux/CommonReducer";
import { getListCreditNoteFailed, getListCreditNoteSuccess } from "./actions";
import { getListCreditNote } from "./api";
import { GET_LIST_CREDIT_NOTE_PENDING } from "./type";

function* getListCreditNotesSaga(action) {
    try {
        const response = yield call(getListCreditNote, action.payload);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getListCreditNoteSuccess(response.data.result));
        } else {
            yield put(getListCreditNoteFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListCreditNoteFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* defaultSaga() {
    yield takeEvery(GET_LIST_CREDIT_NOTE_PENDING, getListCreditNotesSaga);
}
function* CreditNotesSaga() {
    yield all([fork(defaultSaga)]);
}
export default CreditNotesSaga;
