const ActionTypes = {
  CREATE_PROJECT_NOTES_REQUEST: "CREATE_PROJECT_NOTES_REQUEST",
  CREATE_PROJECT_NOTES_SUCCESS: "CREATE_PROJECT_NOTES_SUCCESS",
  CREATE_PROJECT_NOTES_FAILED: "CREATE_PROJECT_NOTES_FAILED",
  EDIT_PROJECT_NOTES_REQUEST: "EDIT_PROJECT_NOTES_REQUEST",
  EDIT_PROJECT_NOTES_SUCCESS: "EDIT_PROJECT_NOTES_SUCCESS",
  EDIT_PROJECT_NOTES_FAILED: "EDIT_PROJECT_NOTES_FAILED",
  DELETE_PROJECT_NOTES_REQUEST: "DELETE_PROJECT_NOTES_REQUEST",
  DELETE_PROJECT_NOTES_SUCCESS: "DELETE_PROJECT_NOTES_SUCCESS",
  DELETE_PROJECT_NOTES_FAILED: "DELETE_PROJECT_NOTES_FAILED",
  SEARCH_PROJECT_NOTES_REQUEST: "SEARCH_PROJECT_NOTES_REQUEST",
  SEARCH_PROJECT_NOTES_SUCCESS: "SEARCH_PROJECT_NOTES_SUCCESS",
  SEARCH_PROJECT_NOTES_FAILED: "SEARCH_PROJECT_NOTES_FAILED",
  GET_PROJECT_NOTES_REQUEST: "GET_PROJECT_NOTES_REQUEST",
  GET_PROJECT_NOTES_SUCCESS: "GET_PROJECT_NOTES_SUCCESS",
  GET_PROJECT_NOTES_FAILED: "GET_PROJECT_NOTES_FAILED",
};
export default ActionTypes;
