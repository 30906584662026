const ActionTypes = {
  FETCH_NOTES_REQUEST: "FETCH_NOTES_REQUEST",
  FETCH_NOTES_FAILED: "FETCH_NOTES_FAILED",
  FETCH_NOTES_SUCCESS: "FETCH_NOTES_SUCCESS",

  NEW_NOTES_REQUEST: "NEW_NOTES_REQUEST",
  NEW_NOTES_FAILED: "NEW_NOTES_FAILED",
  NEW_NOTES_SUCCESS: "NEW_NOTES_SUCCESS",

  EDIT_NOTES_REQUEST: "EDIT_NOTES_REQUEST",
  EDIT_NOTES_FAILED: "EDIT_NOTES_FAILED",
  EDIT_NOTES_SUCCESS: "EDIT_NOTES_SUCCESS",

  DELETE_NOTES_REQUEST: "DELETE_NOTES_REQUEST",
  DELETE_NOTES_FAILED: "DELETE_NOTES_FAILED",
  DELETE_NOTES_SUCCESS: "DELETE_NOTES_SUCCESS",
};

export default ActionTypes;
