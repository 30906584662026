import ActionTypes from "./type";

export const fecthStatementCustomerRequest = (id, value) => {
  return {
    type: ActionTypes.FETCH_STATEMENT_CUSTOMER_REQUEST,
    id,
    value,
  };
};

export const fecthStatementCustomerSuccess = (data) => {
  return {
    type: ActionTypes.FETCH_STATEMENT_CUSTOMER_SUCCESS,
    data,
  };
};

export const fecthStatementCustomerFailed = () => {
  return {
    type: ActionTypes.FETCH_STATEMENT_CUSTOMER_FAILED,
  };
};
