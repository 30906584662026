const ActionTypes = {
    GET_LIST_SUPPORT_TICKET_STATUS_REQUEST:
        "GET_LIST_SUPPORT_TICKET_STATUS_REQUEST",
    GET_LIST_SUPPORT_TICKET_STATUS_FAILED:
        "GET_LIST_SUPPORT_TICKET_STATUS_FAILED",
    GET_LIST_SUPPORT_TICKET_STATUS_SUCCESS:
        "GET_LIST_SUPPORT_TICKET_STATUS_SUCCESS",

    CREATE_SUPPORT_TICKET_STATUS_REQUEST:
        "CREATE_SUPPORT_TICKET_STATUS_REQUEST",
    CREATE_SUPPORT_TICKET_STATUS_FAILED: "CREATE_SUPPORT_TICKET_STATUS_FAILED",
    CREATE_SUPPORT_TICKET_STATUS_SUCCESS:
        "CREATE_SUPPORT_TICKET_STATUS_SUCCESS",

    EDIT_SUPPORT_TICKET_STATUS_REQUEST: "EDIT_SUPPORT_TICKET_STATUS_REQUEST",
    EDIT_SUPPORT_TICKET_STATUS_FAILED: "EDIT_SUPPORT_TICKET_STATUS_FAILED",
    EDIT_SUPPORT_TICKET_STATUS_SUCCESS: "EDIT_SUPPORT_TICKET_STATUS_SUCCESS",

    DELETE_SUPPORT_TICKET_STATUS_REQUEST:
        "DELETE_SUPPORT_TICKET_STATUS_REQUEST",
    DELETE_SUPPORT_TICKET_STATUS_FAILED: "DELETE_SUPPORT_TICKET_STATUS_FAILED",
    DELETE_SUPPORT_TICKET_STATUS_SUCCESS:
        "DELETE_SUPPORT_TICKET_STATUS_SUCCESS",

    FETCH_SUPPORT_TICKET_STATUS_REQUEST: "FETCH_SUPPORT_TICKET_STATUS_REQUEST",
    FETCH_SUPPORT_TICKET_STATUS_FAILED: "FETCH_SUPPORT_TICKET_STATUS_FAILED",
    FETCH_SUPPORT_TICKET_STATUS_SUCCESS: "FETCH_SUPPORT_TICKET_STATUS_SUCCESS",
    CHANGE_STATUS_DIALOG: "CHANGE_STATUS_DIALOG",

    GET_COUNT_SUPPORT_TICKET_STATUS_REQUEST:
        "GET_COUNT_SUPPORT_TICKET_STATUS_REQUEST",
    GET_COUNT_SUPPORT_TICKET_STATUS_FAILED:
        "GET_COUNT_SUPPORT_TICKET_STATUS_FAILED",
    GET_COUNT_SUPPORT_TICKET_STATUS_SUCCESS:
        "GET_COUNT_SUPPORT_TICKET_STATUS_SUCCESS",
};
export default ActionTypes;
