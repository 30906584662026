// @flow
import { call, put, takeEvery } from "redux-saga/effects";
import { enqueueSnackbar } from "../../../../redux/CommonReducer";
import { responeCode, typeMessage } from "../../../../constants/Const";
import {
  throwErrorMessage,
  parseDataNotifications,
} from "../../../../helpers/HandleError";
import ActionTypes from "./type";

import {
  addVaultCustomerSuccess,
  addVaultCustomerFailed,
} from "./actions";
import {
  addVaultCustomerRequest,
} from "./api";

function* addVaultCustomer(action) {
  try {
    const response = yield call(addVaultCustomerRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(addVaultCustomerSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(addVaultCustomerFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(addVaultCustomerFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

export function* vaultCustomerSaga() {

  yield takeEvery(
    ActionTypes.ADD_VAULT_CUSTOMER_REQUEST,
    addVaultCustomer
  );
}

export default vaultCustomerSaga;
