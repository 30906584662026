import axios from "axios";
// const baseApiUrl = 'http://192.168.100.100:9999/bhome_be/public/api';
const baseApiUrl = process.env.REACT_APP_REST_URL_API;

const baseInstance = axios.create({
  baseURL: baseApiUrl,
});

baseInstance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("authtoken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

baseInstance.interceptors.response.use(
  (response) => {
    // wil be fix late
    try {
      if (response.data.result.access_token) {
        localStorage.setItem("authtoken", response.data.result.access_token);
        return response;
      };
    }
    catch {

    }
      
    if (response.headers.authorization) {
      localStorage.setItem("authtoken", response.headers.authorization);
    };

    return response;
  },
  (error) => {
    console.log(error);
    //  message = error.message;
    // if (error.response.data && error.response.data.errors) {
    //   message = error.response.data.errors;
    // } else if (error.response.data && error.response.data.error) {
    //   message = error.response.data.error;
    // }
    return Promise.reject(error);
  }
);

export default baseInstance;
