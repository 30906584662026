import apiBase from "../../../../common/baseAPI";

const CUSTOMER_PAYMENT = "payment/customer";

export const fetchPaymentCustomerRequest = ({ id, params }) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(`${CUSTOMER_PAYMENT}/${id}`, {
                params: params,
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
export const addPaymentCustomerRequest = ({ id, body }) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .post(
                //Phương thức: POST dường dẫn: api/payment/customer/{customer_id}
                `${CUSTOMER_PAYMENT}/${id}`,
                body
            )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
