export const GET_LIST_SUPPORT_DEPARTMENT_REQUEST = 'GET_LIST_SUPPORT_DEPARTMENT_REQUEST';
export const GET_LIST_SUPPORT_DEPARTMENT_FAILED = 'GET_LIST_SUPPORT_DEPARTMENT_FAILED';
export const GET_LIST_SUPPORT_DEPARTMENT_SUCCESS = 'GET_LIST_SUPPORT_DEPARTMENT_SUCCESS';

export const CREATE_SUPPORT_DEPARTMENT_REQUEST = 'CREATE_SUPPORT_DEPARTMENT_REQUEST';
export const CREATE_SUPPORT_DEPARTMENT_FAILED = 'CREATE_SUPPORT_DEPARTMENT_FAILED';
export const CREATE_SUPPORT_DEPARTMENT_SUCCESS = 'CREATE_SUPPORT_DEPARTMENT_SUCCESS';

export const EDIT_SUPPORT_DEPARTMENT_REQUEST = 'EDIT_SUPPORT_DEPARTMENT_REQUEST';
export const EDIT_SUPPORT_DEPARTMENT_FAILED = 'EDIT_SUPPORT_DEPARTMENT_FAILED';
export const EDIT_SUPPORT_DEPARTMENT_SUCCESS = 'EDIT_SUPPORT_DEPARTMENT_SUCCESS';

export const DELETE_SUPPORT_DEPARTMENT_REQUEST = 'DELETE_SUPPORT_DEPARTMENT_REQUEST';
export const DELETE_SUPPORT_DEPARTMENT_FAILED = 'DELETE_SUPPORT_DEPARTMENT_FAILED';
export const DELETE_SUPPORT_DEPARTMENT_SUCCESS = 'DELETE_SUPPORT_DEPARTMENT_SUCCESS';

export const CHANGE_STATUS_DEPARTMENT = 'CHANGE_STATUS_DEPARTMENT';

export const FETCH_SUPPORT_DEPARTMENT_REQUEST = 'FETCH_SUPPORT_DEPARTMENT_REQUEST';
export const FETCH_SUPPORT_DEPARTMENT_FAILED = 'FETCH_SUPPORT_DEPARTMENT_FAILED';
export const FETCH_SUPPORT_DEPARTMENT_SUCCESS = 'FETCH_SUPPORT_DEPARTMENT_SUCCESS';
