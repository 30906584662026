import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
    getCustomFieldInvoiceSuccess,
    getCustomFieldInvoiceFailed,
} from "./action";
import { enqueueSnackbar } from "../../../../redux/CommonReducer";
import { getInvoiceSaleRequest } from "./api";
import { responeCode, typeMessage } from "../../../../constants/Const";
import { GET_SALE_INVOICE_REQUEST } from "./types";
import {
    throwErrorMessage,
    parseDataNotifications,
} from "../../../../helpers/HandleError";

function* listCustomField(data) {
    try {
        const response = yield call(getInvoiceSaleRequest, data);

        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(getCustomFieldInvoiceSuccess(response.data.result));
        } else {
            yield put(getCustomFieldInvoiceFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getCustomFieldInvoiceFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* invoiceSaga() {
    yield takeEvery(GET_SALE_INVOICE_REQUEST, listCustomField);
}

function* SaleInvoiceSaga() {
    yield all([fork(invoiceSaga)]);
}

export default SaleInvoiceSaga;
