// @flow
import { call, put, takeEvery } from "redux-saga/effects";
import ActionTypes from "./types";
import {
    getListSupportTicketStatusFailed,
    getListSupportTicketStatusSuccess,
    createSupportTicketStatusFailed,
    createSupportTicketStatusSuccess,
    editSupportTicketStatusFailed,
    editSupportTicketStatusSuccess,
    fetchSupportTicketStatusSuccess,
    fetchSupportTicketStatusFailed,
    getCountSupportTicketStatusFailed,
    getCountSupportTicketStatusSuccess,
    deleteSupportTicketStatusSuccess,
    deleteSupportTicketStatusFailed,
} from "./actions";
import { enqueueSnackbar } from "../../../../../redux/CommonReducer";
import {
    getListSupportTicketStatus,
    createSupportTicketStatus,
    editSupportTicketStatus,
    fetchSupportTicketStatus,
    getCountSupportTicketStatus,
    deleteSupportTicketStatus,
} from "./api";
import { responeCode, typeMessage } from "../../../../../constants/Const";
import {
    throwErrorMessage,
    parseDataNotifications,
} from "../../../../../helpers/HandleError";

function* getListSupportTicketStatusSaga() {
    try {
        const response = yield call(getListSupportTicketStatus);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success &&
            response.data.result
        ) {
            yield put(getListSupportTicketStatusSuccess(response.data.result));
        } else {
            yield put(getListSupportTicketStatusFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getListSupportTicketStatusFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* createSupportTicketStatusSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(createSupportTicketStatus, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(createSupportTicketStatusSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(createSupportTicketStatusFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(createSupportTicketStatusFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* editSupportTicketStatusSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(editSupportTicketStatus, payload);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(editSupportTicketStatusSuccess(response.data.result));
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(editSupportTicketStatusFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(editSupportTicketStatusFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* fetchSupportTicketStatusSaga(action) {
    try {
        const response = yield call(fetchSupportTicketStatus, action);
        if (
            response &&
            response.data &&
            response.data.error_code === responeCode.success
        ) {
            yield put(fetchSupportTicketStatusSuccess(response.data.result));
        } else {
            yield put(fetchSupportTicketStatusFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(fetchSupportTicketStatusFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

function* getCountSupportTicketStatusSaga() {
    try {
        const response = yield call(getCountSupportTicketStatus);
        if (response && response.data) {
            yield put(getCountSupportTicketStatusSuccess(response.data));
        } else {
            yield put(getCountSupportTicketStatusFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(getCountSupportTicketStatusFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}
function* deleteSupportTicketStatusSaga({ payload }) {
    try {
        const response = yield call(deleteSupportTicketStatus, payload);

        if (response && response.data) {
            yield put(deleteSupportTicketStatusSuccess());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.success
                    )
                )
            );
        } else {
            yield put(deleteSupportTicketStatusFailed());
            yield put(
                enqueueSnackbar(
                    parseDataNotifications(
                        response.data.error_mess,
                        typeMessage.error
                    )
                )
            );
        }
    } catch (error) {
        yield put(deleteSupportTicketStatusFailed());
        yield put(
            enqueueSnackbar(
                parseDataNotifications(
                    throwErrorMessage(error),
                    typeMessage.warning
                )
            )
        );
    }
}

export function* adminSupportTicketStatusSaga() {
    yield takeEvery(
        ActionTypes.GET_LIST_SUPPORT_TICKET_STATUS_REQUEST,
        getListSupportTicketStatusSaga
    );
    yield takeEvery(
        ActionTypes.DELETE_SUPPORT_TICKET_STATUS_REQUEST,
        deleteSupportTicketStatusSaga
    );
    yield takeEvery(
        ActionTypes.CREATE_SUPPORT_TICKET_STATUS_REQUEST,
        createSupportTicketStatusSaga
    );
    yield takeEvery(
        ActionTypes.EDIT_SUPPORT_TICKET_STATUS_REQUEST,
        editSupportTicketStatusSaga
    );
    yield takeEvery(
        ActionTypes.FETCH_SUPPORT_TICKET_STATUS_REQUEST,
        fetchSupportTicketStatusSaga
    );
    yield takeEvery(
        ActionTypes.GET_COUNT_SUPPORT_TICKET_STATUS_REQUEST,
        getCountSupportTicketStatusSaga
    );
}

export default adminSupportTicketStatusSaga;
