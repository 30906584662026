import React from "react";
import { PrivateRoute } from "./PrivateRoute";
import i18n from "../i18n";

// Administrator/Support
const Department = React.lazy(() => import("../pages/Administrator/Support/Department"));
const TicketPriority = React.lazy(() => import("../pages/Administrator/Support/TicketPriority"));
const TicketStatus = React.lazy(() => import("../pages/Administrator/Support/TicketStatus"));
const Services = React.lazy(() => import("../pages/Administrator/Support/Services"));
const SupportSpamFilters = React.lazy(() => import("../pages/Administrator/Support/SpamFilters"));
const PredefinedReplies = React.lazy(() => import("../pages/Administrator/Support/PredefinedReplies/index"));
const NewPredefinedReply = React.lazy(() => import("../pages/Administrator/Support/PredefinedReplies/NewPredefinedReplies/NewPredefinedReplies"));
const EditPredefinedReply = React.lazy(() => import("../pages/Administrator/Support/PredefinedReplies/EditPredefinedReplies/EditPredefinedReplies"));

// Administrator/Leads
const Source = React.lazy(() => import("../pages/Administrator/Leads/Source"));
const Status = React.lazy(() => import("../pages/Administrator/Leads/Statuses"));
const EmailIntegration = React.lazy(() => import("../pages/Administrator/Leads/EmailIntegration"));
const SpamFilters = React.lazy(() => import("../pages/Administrator/Leads/EmailIntegration/SpamFilters"));
const WebToLead = React.lazy(() => import("../pages/Administrator/Leads/WebToLead"));
const LeadForm = React.lazy(() => import("../pages/Administrator/Leads/WebToLead/LeadForm"));
const LeadFormEdit = React.lazy(() => import("../pages/Administrator/Leads/WebToLead/LeadFormEdit"));

// Administrator/Contracts
const ContractTypes = React.lazy(() => import("../pages/Administrator/Contracts/ContractTypes"));

// Administrator/CustomFields
const CustomFields = React.lazy(() => import("../pages/Administrator/CustomFields"));
const CustomFieldsNew = React.lazy(() => import("../pages/Administrator/CustomFields/NewCustomFields"));
const CustomFieldsEdit = React.lazy(() => import("../pages/Administrator/CustomFields/EditCustomFields"));

// Administrator/Finance
const TaxRate = React.lazy(() => import("../pages/Administrator/Finance/TaxRate/TaxRate"));
const Currency = React.lazy(() => import("../pages/Administrator/Finance/Currency/Currency"));
const ExpensesCategories = React.lazy(() => import("../pages/Administrator/Finance/ExpensesCategories/ExpensesCategories"));
const PaymentMode = React.lazy(() => import("../pages/Administrator/Finance/PaymentMode/PaymentMode"));
const PaymentGateways = React.lazy(() => import("../pages/Administrator/Setting/Payment"));

// Administrator/Staff
const Staff = React.lazy(() => import("../pages/Staff"));
const NewStaff = React.lazy(() => import("../pages/Staff/NewStaff"));
const ViewStaff = React.lazy(() => import("../pages/Staff/View"));
const Roles = React.lazy(() => import("../pages/Staff/Roles"));
const NewRole = React.lazy(() => import("../pages/Staff/Roles/NewRole"));
const EditRole = React.lazy(() => import("../pages/Staff/Roles/EditRole"));

// Administrator/Customer
const CustomerGroup = React.lazy(() => import("../pages/Administrator/CustomerGroup"));

// Administrator/Settings
const SettingGeneral = React.lazy(() => import("../pages/Administrator/Setting/General"));
const SettingCompany = React.lazy(() => import("../pages/Administrator/Setting/Company"));
const SettingLocalization = React.lazy(() => import("../pages/Administrator/Setting/Localization"));
const SettingEmail = React.lazy(() => import("../pages/Administrator/Setting/Email"));
const SettingFinance = React.lazy(() => import("../pages/Administrator/Setting/Finance"));
const SettingSubscriptions = React.lazy(() => import("../pages/Administrator/Setting/Subscriptions"));
const SettingCustomers = React.lazy(() => import("../pages/Administrator/Setting/Customers"));
const SettingTasks = React.lazy(() => import("../pages/Administrator/Setting/Tasks"));
const SettingSupport = React.lazy(() => import("../pages/Administrator/Setting/Support"));
const SettingLeads = React.lazy(() => import("../pages/Administrator/Setting/Leads"));
const SettingSMS = React.lazy(() => import("../pages/Administrator/Setting/SMS"));
const SettingCalendar = React.lazy(() => import("../pages/Administrator/Setting/Calendar"));
const SettingPDF = React.lazy(() => import("../pages/Administrator/Setting/PDF"));
const SettingEsign = React.lazy(() => import("../pages/Administrator/Setting/Esign"));
const SettingCronJob = React.lazy(() => import("../pages/Administrator/Setting/CronJob"));
const SettingTags = React.lazy(() => import("../pages/Administrator/Setting/Tags"));
const SettingPusher = React.lazy(() => import("../pages/Administrator/Setting/Pusher"));
const SettingGoogle = React.lazy(() => import("../pages/Administrator/Setting/Google"));
const SettingMisc = React.lazy(() => import("../pages/Administrator/Setting/Misc"));
const SettingSystemUpdate = React.lazy(() => import("../pages/Administrator/Setting/SystemUpdate"));
const SettingSystemInfo = React.lazy(() => import("../pages/Administrator/Setting/SystemInfo"));
const SettingPaymentGateways = React.lazy(() => import("../pages/Administrator/Setting/Payment"));

// Task Management
const Task = React.lazy(() => import("../pages/Task/index.js"));

export const administratorsRoute = [
    // Administator
    {
        name: `${i18n.t("route.setup")}`,
        route: PrivateRoute,
        roles: ["Admin", "MENU"],
        title: "Setup",
        childs: [
            {
                path: "/administrator/staff",
                name: `${i18n.t("route.staff")}`,
                component: Staff,
                route: PrivateRoute,
                roles: ["Admin"],
                title: `${i18n.t("route.staff")}`,
                exact: true,
            },
            {
                path: "/administrator",
                name: `${i18n.t("route.customer")}`,
                route: PrivateRoute,
                roles: ["Admin"],
                title: `${i18n.t("route.customer")}`,
                //Dropdown lv 3 of customer
                childs: [
                    {
                        path: "/administrator/customer-group",
                        name: `${i18n.t("route.group")}`,
                        component: CustomerGroup,
                        route: PrivateRoute,
                        roles: ["Admin"],
                        title: `${i18n.t("route.group")}`,
                        exact: true,
                    },
                    {
                        path: "/administator/customer-source",
                        name: `${i18n.t("route.source")}`,
                        component: Source,
                        route: PrivateRoute,
                        roles: ["Admin"],
                        title: `${i18n.t("route.source")}`,
                        exact: true,
                    },
                    {
                        path: "/administator/customer-status",
                        name: `${i18n.t("route.status")}`,
                        component: Status,
                        route: PrivateRoute,
                        roles: ["Admin"],
                        title: `${i18n.t("route.status")}`,
                        exact: true,
                    },
                ],
            },
            {
                name: `${i18n.t("route.support")}`,
                route: PrivateRoute,
                roles: ["Admin"],
                title: `${i18n.t("route.support")}`,
                childs: [
                    {
                        path: "/administrator/department",
                        name: `${i18n.t("route.department")}`,
                        component: Department,
                        route: PrivateRoute,
                        roles: ["Admin"],
                        title: `${i18n.t("route.department")}`,
                        exact: true,
                    },
                    {
                        path: "/administrator/predefined_reply",
                        name: `${i18n.t("route.predefinedReply")}`,
                        component: PredefinedReplies,
                        route: PrivateRoute,
                        roles: ["Admin"],
                        title: `${i18n.t("route.predefinedReply")}`,
                        exact: true,
                    },
                    {
                        path: "/administator/ticket_priority",
                        name: `${i18n.t("route.ticketPriority")}`,
                        component: TicketPriority,
                        route: PrivateRoute,
                        roles: ["Admin"],
                        title: `${i18n.t("route.ticketPriority")}`,
                        exact: true,
                    },
                    {
                        path: "/administator/ticket_status",
                        name: `${i18n.t("route.ticketStatus")}`,
                        component: TicketStatus,
                        route: PrivateRoute,
                        roles: ["Admin"],
                        title: `${i18n.t("route.ticketStatus")}`,
                        exact: true,
                    },
                    {
                        path: "/administator/service",
                        name: `${i18n.t("route.service")}`,
                        component: Services,
                        route: PrivateRoute,
                        roles: ["Admin"],
                        title: `${i18n.t("route.service")}`,
                        exact: true,
                    },
                    {
                        path: "/administator/spam_filter",
                        name: `${i18n.t("route.spamFilter")}`,
                        component: SupportSpamFilters,
                        route: PrivateRoute,
                        roles: ["Admin"],
                        title: `${i18n.t("route.spamFilter")}`,
                        exact: true,
                    },
                ],
            },
            {
                name: `${i18n.t("route.lead")}`,
                route: PrivateRoute,
                roles: ["Admin"],
                title: `${i18n.t("route.lead")}`,
                //Dropdown lv 3 of lead
                childs: [
                    
                    {
                        path: "/administator/email_integration",
                        name: `${i18n.t("route.emailIntegation")}`,
                        component: EmailIntegration,
                        route: PrivateRoute,
                        roles: ["Admin"],
                        title: `${i18n.t("route.emailIntegation")}`,
                        exact: true,
                    },

                    {
                        path: "/administrator/web_lead",
                        name: `${i18n.t("route.webToLead")}`,
                        component: WebToLead,
                        route: PrivateRoute,
                        roles: ["Admin"],
                        title: `${i18n.t("route.webToLead")}`,
                        exact: true,
                    },
                ],
            },
            {
                name: `${i18n.t("route.finance")}`,
                route: PrivateRoute,
                roles: ["Admin"],
                title: `${i18n.t("route.finance")}`,
                //Dropdown lv 3 of finance
                childs: [
                    {
                        path: "/administator/tax_rate",
                        name: `${i18n.t("route.taxRate")}`,
                        component: TaxRate,
                        route: PrivateRoute,
                        roles: ["Admin"],
                        title: `${i18n.t("route.taxRate")}`,
                        exact: true,
                    },
                    {
                        path: "/administator/currency",
                        name: `${i18n.t("route.currency")}`,
                        component: Currency,
                        route: PrivateRoute,
                        roles: ["Admin"],
                        title: `${i18n.t("route.currency")}`,
                        exact: true,
                    },
                    {
                        path: "/administator/payment_mode",
                        name: `${i18n.t("route.paymentMode")}`,
                        component: PaymentMode,
                        route: PrivateRoute,
                        roles: ["Admin"],
                        title: `${i18n.t("route.paymentMode")}`,
                        exact: true,
                    },
                    {
                        path: "/administator/expense_category",
                        name: `${i18n.t("route.expenseCategory")}`,
                        component: ExpensesCategories,
                        route: PrivateRoute,
                        roles: ["Admin"],
                        title: `${i18n.t("route.expenseCategory")}`,
                        exact: true,
                    },
                ],
            },
            {
                name: `${i18n.t("route.contract")}`,
                route: PrivateRoute,
                roles: ["Admin"],
                title: `${i18n.t("route.contract")}`,
                //Dropdown lv 3 of contract
                childs: [
                    {
                        path: "/administator/contract_type",
                        name: `${i18n.t("route.contractType")}`,
                        component: ContractTypes,
                        route: PrivateRoute,
                        roles: ["Admin"],
                        title: `${i18n.t("route.contractType")}`,
                        exact: true,
                    },
                ],
            },
            {
                path: "/administrator/custom_fields",
                name: `${i18n.t("route.customFields")}`,
                component: CustomFields,
                route: PrivateRoute,
                roles: ["Admin", "customFields"],
                title: `${i18n.t("route.customFields")}`,
                exact: true,
            },
            {
                path: "/administrator/role",
                name: `${i18n.t("route.role")}`,
                component: Roles,
                route: PrivateRoute,
                roles: ["Admin", "profile"],
                title: `${i18n.t("route.role")}`,
                exact: true,
            },
            {
                path: "/administrator/setting",
                name: `${i18n.t("route.setting")}`,
                component: SettingGeneral,
                route: PrivateRoute,
                roles: ["Admin", "profile"],
                title: `${i18n.t("route.setting")}`,
                exact: true,
            },
        ],
    },
    {
        path: "/support/new_predefined_reply",
        name: "support_new_predefined_reply",
        component: NewPredefinedReply,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/support/edit_predefined_reply/:id",
        name: "support_edit_predefined_reply",
        component: EditPredefinedReply,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administator/email_integration/SpamFilters",
        name: "administator_email_integration_SpamFilters",
        component: SpamFilters,
        route: PrivateRoute,
        roles: ["Admin", "SpamFilters"],
        title: `${i18n.t("route.emailIntegation")}`,
        exact: true,
    },
    {
        path: "/administator/web_lead/form",
        name: "administator_web_lead_form",
        component: LeadForm,
        route: PrivateRoute,
        roles: ["Admin", "form"],
        title: `${i18n.t("route.emailIntegation")}`,
        exact: true,
    },
    {
        path: "/administrator/web_lead/form/edit/:id",
        name: "administator_web_lead_form_edit_:id",
        component: LeadFormEdit,
        route: PrivateRoute,
        roles: ["Admin", "formEdit"],
        title: `${i18n.t("route.emailIntegation")}`,
        exact: true,
    },
    {
        path: "/administrator/custom_fields/new",
        name: "administrator_customfields_new",
        component: CustomFieldsNew,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.customFields")}`,
        exact: true,
    },
    {
        path: "/administrator/custom_fields/edit/:id",
        name: "administrator_cutomsfields_edit",
        component: CustomFieldsEdit,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.customFields")}`,
        exact: true,
    },
    {
        path: "/administrator/roles/new_role",
        name: "administrator_roles_new_role",
        component: NewRole,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.role")}`,
        exact: true,
    },
    {
        path: "/administrator/roles/edit_role",
        name: "administrator_roles_edit_role",
        component: EditRole,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.role")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=general",
        name: "administrator_setting_group=general",
        component: SettingGeneral,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=company",
        name: "administrator_setting_group=company",
        component: SettingCompany,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=localization",
        name: "administrator_setting_group=localization",
        component: SettingLocalization,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=email",
        name: "administrator_setting_group=email",
        component: SettingEmail,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=finance",
        name: "administrator_setting_group=finance",
        component: SettingFinance,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=subscriptions",
        name: "administrator_setting_group=subscriptions",
        component: SettingSubscriptions,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=paymentGateways",
        name: "administrator_setting_group=paymentGateways",
        component: SettingPaymentGateways,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=customers",
        name: "administrator_setting_group=customers",
        component: SettingCustomers,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=tasks",
        name: "administrator-setting-group=tasks",
        component: SettingTasks,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=tickets",
        name: "administrator_setting_group=tickets",
        component: SettingSupport,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=leads",
        name: "administrator_setting_group=leads",
        component: SettingLeads,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=sms",
        name: "administrator_setting_group=sms",
        component: SettingSMS,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },{
        path: "/administrator/setting/group=calendar",
        name: "administrator_setting_group=calendar",
        component: SettingCalendar,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=pdf",
        name: "administrator_setting_group=pdf",
        component: SettingPDF,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=eSign",
        name: "administrator_setting_group=eSign",
        component: SettingEsign,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=cronJob",
        name: "administrator_setting_group=cronJob",
        component: SettingCronJob,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=tags",
        name: "administrator_setting_group=tags",
        component: SettingTags,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=pusher",
        name: "administrator_setting_group=pusher",
        component: SettingPusher,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=google",
        name: `administrator_setting_group=google`,
        component: SettingGoogle,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=misc",
        name: "administrator_setting_group=misc",
        component: SettingMisc,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=update",
        name: "administrator_setting_group=update",
        component: SettingSystemUpdate,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    {
        path: "/administrator/setting/group=info",
        name: "administrator_setting_group=info",
        component: SettingSystemInfo,
        route: PrivateRoute,
        roles: ["Admin", "profile"],
        title: `${i18n.t("route.setting")}`,
        exact: true,
    },
    /////////////////////
    {
        path: "/administrator/staff/new",
        name: `New Staff`,
        component: NewStaff,
        route: PrivateRoute,
        roles: ["Admin", "staff"],
        title: "New Staff",
        exact: true,
    },
    {
        path: "/administrator/staff/view/:id",
        name: `View Staff`,
        component: ViewStaff,
        route: PrivateRoute,
        roles: ["Admin", "Staff"],
        title: `View Staff`,
        exact: true,
    },
]