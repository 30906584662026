import { connect } from "react-redux";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { isUserAuthenticated, getLoggedInUser } from "./helpers/authUtils";

// import Backend from "i18next-xhr-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
import customer_en from "./lang/en/customer";
import customer_vi from "./lang/vi/customer";
import lead_en from "./lang/en/lead";
import lead_vi from "./lang/vi/lead";
import project_en from "./lang/en/project";
import project_vi from "./lang/vi/project";
import task_en from "./lang/en/task";
import task_vi from "./lang/vi/task";
import route_en from "./lang/en/route";
import route_vi from "./lang/vi/route";
import sales_en from "./lang/en/sales";
import sales_vi from "./lang/vi/sales";
import support_en from "./lang/en/support";
import support_vi from "./lang/vi/support";

import administrator_en from "./lang/en/administrator";
import administrator_vi from "./lang/vi/administrator";
import expense_en from "./lang/en/expense";
import expense_vi from "./lang/vi/expense";

import contract_en from "./lang/en/contract";
import contract_vi from "./lang/vi/contract";

const fallbackLng = ["vi"];
const availableLanguages = ["en", "vi"];

const resources = {
    en: {
        translation: {
        ...customer_en,
        ...lead_en,
        ...project_en,
        ...task_en,
        ...route_en,
        ...sales_en,
        ...support_en,

        ...administrator_en,
        ...expense_en,
        ...contract_en,
        
        },
    },
    vi: {
        translation: {
        ...customer_vi,
        ...lead_vi,
        ...project_vi,
        ...task_vi,
        ...route_vi,
        ...sales_vi,
        ...support_vi,
        ...administrator_vi,
        ...expense_vi,
        ...contract_vi,
        },
    },
};

i18n.use(initReactI18next).init({
    resources,
    fallbackLng,
    detection: {
        checkWhitelist: true,
    },
    debug: false,
    whitelist: availableLanguages,
    interpolation: {
        escapeValue: false,
    },
    
});

export default i18n;
