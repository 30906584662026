import {
    GET_LIST_SUPPORT_DEPARTMENT_FAILED,
    GET_LIST_SUPPORT_DEPARTMENT_SUCCESS,
    CREATE_SUPPORT_DEPARTMENT_FAILED,
    CREATE_SUPPORT_DEPARTMENT_SUCCESS,
    EDIT_SUPPORT_DEPARTMENT_FAILED,
    EDIT_SUPPORT_DEPARTMENT_SUCCESS,
    DELETE_SUPPORT_DEPARTMENT_FAILED,
    DELETE_SUPPORT_DEPARTMENT_SUCCESS,
    CHANGE_STATUS_DEPARTMENT,
    GET_LIST_SUPPORT_DEPARTMENT_REQUEST,
    DELETE_SUPPORT_DEPARTMENT_REQUEST,
    EDIT_SUPPORT_DEPARTMENT_REQUEST,
    CREATE_SUPPORT_DEPARTMENT_REQUEST,
    FETCH_SUPPORT_DEPARTMENT_SUCCESS,
    FETCH_SUPPORT_DEPARTMENT_FAILED,
    FETCH_SUPPORT_DEPARTMENT_REQUEST
} from './types';

const INIT_STATE = {
    listDepartments: [],
    statusDepartment: false,
    isLoading: false,
    deleteDepartment: false,
};

const AdminSupportDepartmentReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_SUPPORT_DEPARTMENT_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case GET_LIST_SUPPORT_DEPARTMENT_SUCCESS:
            return {
                ...state,
                listDepartments: action.payload,
                isLoading: false,
            };

        case GET_LIST_SUPPORT_DEPARTMENT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case CREATE_SUPPORT_DEPARTMENT_REQUEST:
            return {
                ...state,
                isLoading: true
            }

        case CREATE_SUPPORT_DEPARTMENT_SUCCESS:
            state.listDepartments.data.unshift(action.payload)
            return {
                ...state,
                listDepartments: state.listDepartments,
                statusDepartment: true,
                isLoading: false
            };

        case CREATE_SUPPORT_DEPARTMENT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case EDIT_SUPPORT_DEPARTMENT_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case EDIT_SUPPORT_DEPARTMENT_SUCCESS:
            let itemIndex = state.listDepartments.data.findIndex(item => item.departmentid === action.payload.departmentid)
            state.listDepartments.data.splice(itemIndex, 1, action.payload)
            return {
                ...state,
                statusDepartment: true,
                isLoading: false,
                listDepartments: state.listDepartments
            }

        case EDIT_SUPPORT_DEPARTMENT_FAILED:
            return {
                ...state,
                isLoading: false
            };

        case DELETE_SUPPORT_DEPARTMENT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case DELETE_SUPPORT_DEPARTMENT_SUCCESS:
            return {
                ...state,
                statusDepartment: true,
                isLoading: false,
                deleteDepartment: true
            };

        case DELETE_SUPPORT_DEPARTMENT_FAILED:
            return {
                ...state,
                isLoading: false
            };
        case CHANGE_STATUS_DEPARTMENT:
            return {
                ...state,
                statusDepartment: false
            }
        case FETCH_SUPPORT_DEPARTMENT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_SUPPORT_DEPARTMENT_SUCCESS:
            return {
                ...state,
                listDepartments: action.payload,
                isLoading: false,
                deleteDepartment: false
            };
        case FETCH_SUPPORT_DEPARTMENT_FAILED:
            return {
                ...state,
                isLoading: false
            };
        default: return { ...state };
    }
}

export default AdminSupportDepartmentReducer;