export default {
    project: {
        addNew: "DỰ ÁN MỚI",
        content: "Nội dung",
        watchGant: "Xem biểu đồ Gantt",
        filterBy: "Được lọc theo",
        summary: "Tóm lược phân công",
        notStarted: "Chưa bắt đầu",
        inProgress: "Đang thực hiện",
        onHold: "Tạm ngưng",
        cancelled: "Đã hủy",
        finished: "Hoàn thành",
        export: "Xuất ra",
        reload: "Tải lại",
        searching: "Tìm kiếm...",
        search: "Tìm kiếm",
        projectName: "Tên dự án",
        customer: "Khách hàng",
        tags: "Các thẻ",
        startDate: "Ngày bắt đầu",
        deadline: "Hạn chót",
        members: "Thành viên",
        status: "Trạng thái",
        view: "Xem",
        copyProject: "Sao chép dự án",
        edit: "Chỉnh sửa",
        delete: "Xóa",
        save: "LƯU LẠI",
        all: "Tất cả",
        myProjects: "Dự án của tôi",
        selectAll: "Chọn tất cả",
        deselectAll: "Bỏ chọn tất cả",
        notSelect: "Không có mục nào được chọn",
        fixedRate: "Giá cố định",
        projectHours: "Tính theo thời gian dự án",
        taskHours: "Tính theo thời gian phân công",
        taskHoursNote: "Dựa trên tiền công mỗi giờ của công việc",
        nothingSelected: "Không có mục nào được lựa chọn",
        projectOverview: "Tổng quan dự án",
        tasks: "Phân công",
        timesheets: "Biểu đồ thời gian",
        milestones: "Cột mốc",
        files: "Các tập tin",
        discussions: "Trao đổi",
        tickets: "Yêu cầu",
        sales: "Doanh số",
        notes: "Ghi chú",
        activity: "Hoạt động",
        invoices: "Hóa đơn",
        estimates: "Báo giá",
        expenses: "Thu chi",
        creditNotes: "Ghi chú tín dụng",
        subscriptions: "Đăng ký",
        overview: "TỔNG QUAN",
        title: "Dự án",
        billingType: "Loại biên nhận",
        dateCreated: "Ngày tạo",
        estimatedHours: "Thời gian ước tính",
        totalLoggedHours: "Tổng số giờ đăng nhập",
        description: "Mô tả",
        totalLoggedTime: "Tổng thời gian đăng nhập",
        timesheetsTags: "Thẻ biểu đồ thời gian",
        startTime: "Thời gian bắt đầu",
        endTime: "Thời gian kết thúc",
        timeH: "Giờ (tiếng)",
        timeDecimal: "Giờ (hiển thị thập phân)",
        options: "Cài đặt",
        timesheet: "Biểu đồ thời gian",
        EXPORT: "Xu",
        close: "ĐÓNG",
        timeSpent: "Thời gian thực hiện",
        contentTimeSpent: `:15 - 15 Phút
		2 - 2 Giờ
		5:5 - 5 Giờ & 5 Phút
		2:50 - 2 Giờ & 50 Phút`,
        actionDelete: "Bạn có chắc chắn muốn thực hiện thao tác này?",
        deleteMilestone: "Xoá cột mốc",
        deleteTimesheet: "Xóa thời gian biểu",
        createDiscussion: "Tạo cuộc trao đổi",
        subject: "Chủ đề",
        lastActivity: "Hoạt động cuối",
        totalComments: "Tổng số bình luận",
        visibleToCustomer: "Hiển thị cho khách hàng",
        amount: "Số tiền",
        totalTax: "Tổng thuế",
        dateExport: "Ngày xuất",
        dueDate: "Hạn trả",
        viewQuickStats: "Xem nhanh thông kê",
        outstandingInvoices: "Hóa đơn nổi bật",
        pastDueInvoices: "Hóa đơn quá hạn",
        paidInvoices: "Hóa đơn đã thanh toán",
        unpaid: "Chưa thanh toán",
        paid: "Đã thanh toán",
        partiallyPaid: "Đã thanh toán một phần",
        overdue: "Quá hạn",
        draft: "Nháp",
        creditNotesDate: "Thời gian ghi chú tín dụng",
        reference: "Tham khảo",
        remainingAmount: "Số tiền còn lại",
        subscriptionName: "Tên đăng ký",
        nextBillingCycle: "Chu kỳ thanh toán tiếp theo",
        dateSubscribed: "Ngày đã đăng ký",
        estimate: "Bản báo giá",
        date: "Ngày",
        expiryDate: "Ngày hết hạn",
        sent: "Đã gửi",
        expired: "Hết hạn",
        declined: "Từ chối",
        accepted: "Chấp nhận",
        openTask: "PHÂN CÔNG CHƯA HOÀN THÀNH",
        daysLeft: "NGÀY CÒN LẠI",
        loggedHours: "Thời gian đăng nhập",
        billableHours: "Thời gian có thể xuất biên nhận",
        billedHours: "Thời gian đã xuất",
        unbilledHours: "Thời gian chưa xuất",
        totalExpenses: "Thu chi tổng cộng",
        billableExpenses: "Thu chi có thể xuất biên nhận",
        billedExpenses: "Thu chi đã xuất",
        unbilledExpenses: "Thu chi chưa xuất",
        copyChecklist: "Sao chép danh sách đánh dấu",
        copyFollowers: "Sao chép người theo dõi",
        copyAssignees: "Sao chép người đảm nhận",
        taskStatus: "Trạng thái phân công",
        test: "Đang kiểm tra",
        feedback: "Đang chờ phản hồi",
        complete: "Đã hoàn thành",
        tooltip: {
            member: "Thêm/Sửa Thành viên",
        },
        ticket: {
            ticketsSummary: "Tóm lược yêu cầu hỗ trợ",
            open: "Mở",
            inProgress: "Đang thực hiện",
            answered: "Đã trả lời",
            close: "Đóng",
            newTicket: "YÊU CẦU MỚI",
            btnNewTicket: {
                subject: "Chủ đề",
                contact: "Liên hệ",
                name: "Đến",
                emailAddress: "Địa chỉ email",
                department: "Phòng ban",
                tag: "Thẻ",
                assignTicket:
                    "Chỉ định yêu cầu (mặc định là người dùng hiện tại)",
                priority: "Mức độ ưu tiên",
                service: "Dịch vụ",
                btnService: {
                    newService: "Dịch vụ mới",
                    serviceName: "Tên dịch vụ",
                    close: "ĐÓNG",
                },
                project: "Dự án",
                ticketBody: "Nội dung yêu cầu",
                attachments: "Đính kèm",
                openTicket: "MỞ YÊU CẦU",
            },
            tableTicket: {
                status: "Trạng thái",
                lastReply: "Phản hồi lần cuối",
                created: "Ngày tạo",
            },
            settingTicket: {
                addReply: "Thêm phản hồi",
                addNote: "Thêm ghi chú",
                reminders: "Nhắc nhở",
                otherTickets: "Những yêu cầu khác",
                tasks: "Phân công",
                settings: "Cài đặt",
                textTitle: "Yêu cầu này được dẫn liên kết đến dự án:",
                open: "Mở",
                priority: "Mức độ ưu tiên: ",
                medium: "Bình thường",
                department: "Phòng ban: ",
                assigned: "Phân công: ",
                changeStatus: "Thay đổi trạng thái",
                checkboxReturn:
                    "Quay lại danh sách vé sau khi phản hồi  đã được gửi",
                staff: "Nhân viên",
                createMailCustomer: "Tạo một email gửi khách hàng",
                btnConvertTask: "Chuyển đổi thành phân công",
                addResponse: "Thêm câu trả lời",
                posted: "Đã đăng:",
            },
            summaryProfile: {
                totalLoggedTime: "Tổng thời gian đăng nhập",
                lastMonth: "Thời gian đăng nhập tháng trước",
                thisMonth: "Thời gian đăng nhập tháng này",
                lastWeek: "Thời gian đăng nhập tuần trước",
                thisWeek: "Thời gian đăng nhập tuần này",
                profile: "Tiểu sử",
                noteAdminProfile: "Đây là tiểu sử của admin",
                notifications: "Thông báo",
                noteNotifications: "Đánh dấu tất cả là đã đọc",
                loadMore: "Tải thêm",
            },
        },
        note: {
            personalNotes: "Ghi chú riêng tư",
            btnSaveNote: "Lưu ghi chú",
        },

        total: "Tổng cộng",
        billable: "Có thể tạo biên nhận",
        nonBillable: "Không thể tạo biên nhận",
        notInvoiced: "Chưa xuất hóa đơn",
        billed: "Đã xuất biên nhận",
        category: "Thể loại",
        name: "Name",
        expensesReceipt: "Hóa đơn chi",
        paymentMode: "Phương thức  thanh toán",
        recordExpense: "Báo cáo chi phí",
        andNewExpense: "Thêm mới chi phí",
        attachReceipt: "Đính kèm biên lai",
        expenseCategory: "Phân loại chi phí",
        expenseDate: "Ngày chi",
        tax1: "Thuế 1",
        tax2: "Thuế 2",

        validateInput: "Không được bỏ trống.",
        updateDiscussion: "Cập nhật Discussion",
        display: "Hiển thị",
        noDisplay: "Không hiển thị",
        notSent: "Chưa gửi",
        invoiceWithNoPaymentRecords: "Báo cáo hóa đơn không có thanh toán",
        recurringInvoices: "Các hóa đơn gửi định kỳ",
        saleAgent: "Người bán",
        madePaymentByBank: "Thanh toán đã tạo bằng Bank",
        invoiced: "Đã xuất hóa đơn",
        recurring: "Định kỳ",
        byCategories: "Theo danh mục",
        months: "Tháng",
        january: "Tháng 1",
        february: "Tháng 2",
        march: "Tháng 3",
        april: "Tháng 4",
        may: "Tháng 5",
        june: "Tháng 6",
        july: "Tháng 7",
        august: "Tháng 8",
        september: "Tháng 9",
        october: "Tháng 10",
        november: "Tháng 11",
        december: "Tháng 12",
        fileName: "Tên tập tin",
        fileType: "Loại tập tin",
        dateUploaded: "Ngày Đăng",
        uploadBy: "Được đăng bởi",
        createSuccess: "Thành công",
        content: "Nội dung",
    },
};
