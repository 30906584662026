export default {
  sales: {
    common: {
      comment: "Comment",
      remind: "Remind",
      task: "Task",
      note: "Note",
      emailTracking: "Email Tracking",
      viewsTracking: "Views tracking",
      fullScreen: "Toggle full view",
      id: "Id",
      to: "To",
      lead: "Lead",
      rate: "Rate",
      tax: "Tax",
      amount: "Amount",
      noTax: "No tax",
      subTotal: "Sub total",
      discount: "Discount",
      adjustment: "Adjustment",
      qty: "Qty",
      hours: "Hours",
      allowComment: "Allow comment",
      currency: "Currency",
      discountType: "Discount type",
      related: "Related",
      asigned: "Asigned",
      name: "Name",
      company: "Company",
      email: "Email",
      numberPhone: "Phone",
      tag: "Tags",
      createBy: " Create by",
      status: "Status",
      source: "Source",
      lastContact: "Last contact",
      createAt: "Create at",
      address: "Address",
      position: "Position",
      city: "City",
      state: "State",
      website: "Website",
      country: "Country",
      zip: "Zip",
      defaultLanguage: "Default language",
      description: "Description",
      public: "Public",
      activityLog: "Activity log",
      yes: "Yes",
      no: "No",
      proposal: "Proposal",
      subject: "Subject",
      total: "Total",
      date: "Date",
      acceptDate: "Accept date",
      view: "View",
      edit: "Edit",
      cancel: "Cancel",
      close: "Close",
      save: "Save",
      delete: "Delete",
      export: "Export",
      goBack: "Go Back",
      draft: "Draft",
      sent: "Sent",
      declined: "Declined",
      accepted: "Accepted",
      expired: "Expired",
      validateItemTable: "You need 1 out of 3 value",
    },
    proposal: {
      draft: "Draft",
      tooltipEdit: "Edit",
      sendToEmail: "Send to email",
      proposal: "Proposal #",
      subject: "Subject",
      to: "To",
      total: "Total",
      date: "Date",
      openTill: "Open Till",
      tags: "Tags",
      dateCreated: "Date Created",
      status: "Status",
      btnNewProposal: "New Proposal",
      btnAddComment: "Add Comment",
      btnUpdateComment: "Update Comment",
    },
    estimate: {
      estimate: "Estimate #",
      amount: "Amount",
      totalTax: "Total Tax",
      customer: "Customer",
      project: "Project",
      tags: "tags",
      date: "Date",
      expiryDate: "Expiry Date",
      reference: "Reference #",
      status: "Status",
      draft: "Draft",
      sent: "Sent",
      expired: "Expired",
      declined: "Declined",
      accepted: "Accepted",
      toolTipEmail: "Emails Tracking",
      toolTipView: "Views Tracking",
      toolTipZoom: "Toggle full view",
      toolTipEdit: "Edit Estimate",
      toolTipSendEmail: "Send to email",
      btnNewEstimate: "Create new estimate",
      btnSwitch: "Switch to pipeline",
      download: "Download",
      decline: "Decline",
      accept: "Accept",
      noDiscount: "No discount",
      beforeTax: "Before Tax",
      afterTax: "After Tax",
    },
    invoice: {
      outstandingInvoices: "Outstanding Invoices",
      pastDueInvoices: "Past Due Invoices",
      paidInvoices: "Paid Invoices",
      unpaid: "Unpaid",
      paid: "Paid",
      partiallyPaid: "Partially Paid",
      overdue: "Overdue",
      draft: "Draft",
      btnNewInvoice: "Create New Invoice",
      btnRecurringInvoice: "Recurring Invoices",
      invoice: "Invoice #",
      amount: "Amount",
      totalTax: "Total Tax",
      date: "Date",
      customer: "Customer",
      project: "Project",
      tags: "Tags",
      dueDate: "Due Date",
      status: "Status",
      payment: "Payment",
      amountDue: "Amount Due",
      download: "Download",
      amountReceived: "Amount Received",
      paymentDate: "Payment Date",
      paymentMode: "Payment Mode",
      transactionID: "Transaction ID",
      leaveNote: "Leave a note",
      noteCheckBox:
        "Do not send invoice payment recorded email to customer contacts",
      frequency: "Frequency",
      cyclesRemaining: "Cycles Remaining",
      lastDate: "Last Child Invoice Date",
      nextDate: "Next Invoice Date",
    },
    payment: {
      payment: "Payment #",
      invoice: "Invoice #",
      paymentMode: "Payment Mode",
      transactionID: "Transaction ID",
      customer: "Customer",
      amount: "Amount",
      date: "Date",
    },
    creditNotes: {
      newCreditNotes: "New Credit Notes",
      toggleTable: "Toggle Table",
      filterBy: "Filter by",
      searchFilter: {
        all: "All",
        open: "Open",
        void: "Void",
      },
      tableCreditNotes: {
        creditNote: "Credit Note #",
        creditNoteDate: "Credit Note Date",
        customer: "Customer",
        status: "Status",
        project: "Project",
        reference: "Reference #",
        amount: "Amount",
        remainingAmount: "Remaning Amount",
      },
      detailCreditNotes: {
        applyToInvoice: "Apply to invoice",
        more: "More",
        refund: "Refund",
        attachFile: "Attach File",
        billTo: "Bill to:",
        shipTo: "Ship to:",
        creditsRemaining: "Credits Remaining",
        note: "Note",
        termsConditions: "Terms & Conditions",
        noteInvoices: "Credited Invoices Not Found",
        noteRefunds: "No refunds found",
        noteEmail: "No tracked emails sent",
      },
      refundedAmount: "Refunded Amount",
      date: "Date",
      paymentMode: "Payment Mode",
      note: "Note",
    },
    invoiceItem: {
      btnNew: "New item",
      btnGroups: "Groups",
      btnImport: "Import items",
      description: "Description",
      longDescription: "Long Description",
      rate: "Rate",
      tax1: "Tax 1",
      tax2: "Tax 2",
      unit: "Unit",
      groupName: "Group Name",
      titleAdd: "Add New Item",
      rateCurrency: "Rate - VND (Base Currency)",
      itemGroup: "Item Group",
      titleEdit: "Edit Item",
      noteEdit:
        "Changing item info won't affect on the created invoices/estimates/proposals/credit notes.",
      titleGroups: "Groups",
      btnNewGroup: "New Group",
      creditNotes: {
        newCreditNotes: "New Credit Notes",
        toggleTable: "Toggle Table",
        filterBy: "Filter by",
        searchFilter: {
          all: "All",
          open: "Open",
          void: "Void",
        },
        tableCreditNotes: {
          creditNote: "Credit Note #",
          creditNoteDate: "Credit Note Date",
          customer: "Customer",
          status: "Status",
          project: "Project",
          reference: "Reference #",
          amount: "Amount",
          remainingAmount: "Remaning Amount",
        },
        detailCreditNotes: {
          applyToInvoice: "Apply to invoice",
          more: "More",
          refund: "Refund",
          attachFile: "Attach File",
          billTo: "Bill to:",
          shipTo: "Ship to:",
          creditsRemaining: "Credits Remaining",
          note: "Note",
          termsConditions: "Terms & Conditions",
          noteInvoices: "Credited Invoices Not Found",
          noteRefunds: "No refunds found",
          noteEmail: "No tracked emails sent",
        },
      },
    },
  },
};
