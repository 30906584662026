import React from 'react';
import ButtonCloseSnackbar from '../redux/ButtonCloseSnackbar';

export const throwErrorMessage = (error) => {
    let message = 'Something wrong , please try again';
    switch (error.status) {
        case 500: return message = 'Internal Server Error'; 
        case 401: return message = 'Invalid credentials'; 
        default: break;
    }
    return message;
};

export const parseDataNotifications = (message, type) =>{
    const dataMessage = typeof message === 'object' ? Object.keys(message).map(key => message[key]) : message;
    return {
        message: Array.isArray(dataMessage) ? dataMessage[0] : dataMessage,
        options: {
            key: new Date().getTime() + Math.random(),
            variant: type,
            action: key => <ButtonCloseSnackbar key={key} />
        },
    }
}