// @flow
import { call, put, takeEvery } from "redux-saga/effects";
import { responeCode, typeMessage } from "../../../../constants/Const";
import {
  parseDataNotifications,
  throwErrorMessage,
} from "../../../../helpers/HandleError";
import { enqueueSnackbar } from "../../../../redux/CommonReducer";
import {
  deleteCustomFieldFailed,
  deleteCustomFieldSuccess,
  editActiveCustomFieldFailed,
  editActiveCustomFieldSuccess,
  editCustomFieldFailed,
  editCustomFieldSuccess,
  fetchListCustomFieldFailed,
  fetchListCustomFieldSuccess,
  getItemCustomfieldFailed,
  getItemCustomfieldSuccess,
} from "./actions";
import {
  fetchListCustomFieldRequest,
  editActiveCustomFieldRequest,
  addCustomFieldRequest,
  editCustomFieldRequest,
  deleteCustomFieldRequest,
  getCustomfiledRequest,
} from "./api";
import { administrator } from "../../../../constants/Route";
import ActionTypes from "./types";

function* fetchCustomField(action) {
  try {
    const response = yield call(fetchListCustomFieldRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(fetchListCustomFieldSuccess(response.data.result));
    } else {
      yield put(fetchListCustomFieldFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(fetchListCustomFieldFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}
function* editActiveCustomField(action) {
  try {
    const response = yield call(editActiveCustomFieldRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(editActiveCustomFieldSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(editActiveCustomFieldFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(editActiveCustomFieldFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}
function* addCustomField(action) {
  try {
    const response = yield call(addCustomFieldRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      action.history.push(
        `${administrator.customField.edit_custom_field}/${response.data.result.id}`
      );
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}
function* editCustomfiled(action) {
  try {
    const response = yield call(editCustomFieldRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(editCustomFieldSuccess(response.data.result));
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(editCustomFieldFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(editCustomFieldFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

function* deleteCustomField(action) {
  try {
    const response = yield call(deleteCustomFieldRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(deleteCustomFieldSuccess());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.success)
        )
      );
    } else {
      yield put(deleteCustomFieldFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(deleteCustomFieldFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}
function* getCustomfiled(action) {
  try {
    const response = yield call(getCustomfiledRequest, action);
    if (
      response &&
      response.data &&
      response.data.error_code === responeCode.success
    ) {
      yield put(getItemCustomfieldSuccess(response.data.result));
    } else {
      yield put(getItemCustomfieldFailed());
      yield put(
        enqueueSnackbar(
          parseDataNotifications(response.data.error_mess, typeMessage.error)
        )
      );
    }
  } catch (error) {
    yield put(getItemCustomfieldFailed());
    yield put(
      enqueueSnackbar(
        parseDataNotifications(throwErrorMessage(error), typeMessage.warning)
      )
    );
  }
}

export function* customFieldsSaga() {
  yield takeEvery(
    ActionTypes.FETCH_LIST_CUSTOM_FIELD_REQUEST,
    fetchCustomField
  );
  yield takeEvery(
    ActionTypes.EDIT_ACTIVE_CUSTOM_FIELD_REQUEST,
    editActiveCustomField
  );
  yield takeEvery(ActionTypes.ADD_CUSTOM_FIELD_REQUEST, addCustomField);
  yield takeEvery(ActionTypes.DELETE_CUSTOM_FIELD_REQUEST, deleteCustomField);
  yield takeEvery(ActionTypes.GET_ITEM_CUSTOM_FIELD_REQUEST, getCustomfiled);
  yield takeEvery(ActionTypes.EDIT_CUSTOM_FIELD_REQUEST, editCustomfiled);
}

export default customFieldsSaga;
