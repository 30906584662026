// @flow
import { call, put, takeEvery } from 'redux-saga/effects';
import ActionTypes from './types';
import {
    getListSupportServiceFailed,
    getListSupportServiceSuccess,
    createSupportServiceFailed,
    createSupportServiceSuccess,
    editSupportServiceFailed,
    editSupportServiceSuccess,
    deleteSupportServiceFailed,
    deleteSupportServiceSuccess,
    fetchSupportServiceSuccess,
    fetchSupportServiceFailed,
} from './actions';
import { enqueueSnackbar } from '../../../../../redux/CommonReducer';
import {
    getListSupportService,
    createSupportService,
    editSupportService,
    deleteSupportService,
    fetchSupportService
} from './api';
import { responeCode, typeMessage } from '../../../../../constants/Const';
import { throwErrorMessage, parseDataNotifications } from '../../../../../helpers/HandleError';

function* getListSupportServiceSaga() {
    try {
        const response = yield call(getListSupportService);
        if (response && response.data && response.data.error_code === responeCode.success && response.data.result) {
            yield put(getListSupportServiceSuccess(response.data.result));
        }
        else {
            yield put(getListSupportServiceFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(getListSupportServiceFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

function* createSupportServiceSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(createSupportService, payload);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(createSupportServiceSuccess(response.data.result));
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
        }
        else {
            yield put(createSupportServiceFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(createSupportServiceFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

function* editSupportServiceSaga(data) {
    const { payload } = data;
    try {
        const response = yield call(editSupportService, payload);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(editSupportServiceSuccess(response.data.result));
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));

        }
        else {
            yield put(editSupportServiceFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(editSupportServiceFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

function* deleteSupportServiceSaga(data) {
    const { payload } = data;

    try {
        const response = yield call(deleteSupportService, payload);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(deleteSupportServiceSuccess(payload));
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.success)));
        }
        else {
            yield put(deleteSupportServiceFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(deleteSupportServiceFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};
function* fetchSupportServiceSaga(action) {
    try {
        const response = yield call(fetchSupportService, action);
        if (response && response.data && response.data.error_code === responeCode.success) {
            yield put(fetchSupportServiceSuccess(response.data.result));
        }
        else {
            yield put(fetchSupportServiceFailed());
            yield put(enqueueSnackbar(parseDataNotifications(response.data.error_mess, typeMessage.error)));
        }
    } catch (error) {
        yield put(fetchSupportServiceFailed());
        yield put(enqueueSnackbar(parseDataNotifications(throwErrorMessage(error), typeMessage.warning)));
    }
};

export function* adminSupportServiceSaga() {
    yield takeEvery(ActionTypes.GET_LIST_SUPPORT_SERVICE_REQUEST, getListSupportServiceSaga);
    yield takeEvery(ActionTypes.CREATE_SUPPORT_SERVICE_REQUEST, createSupportServiceSaga);
    yield takeEvery(ActionTypes.EDIT_SUPPORT_SERVICE_REQUEST, editSupportServiceSaga);
    yield takeEvery(ActionTypes.DELETE_SUPPORT_SERVICE_REQUEST, deleteSupportServiceSaga);
    yield takeEvery(ActionTypes.FETCH_SUPPORT_SERVICE_REQUEST, fetchSupportServiceSaga);

}


export default adminSupportServiceSaga;