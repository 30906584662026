export const FETCH_LIST_LEAD_SOURCE = 'FETCH_LIST_LEAD_SOURCE';
export const FETCH_LIST_LEAD_SOURCE_FAILED = 'FETCH_LIST_LEAD_SOURCE_FAILED';
export const FETCH_LIST_LEAD_SOURCE_SUCCESS = 'FETCH_LIST_LEAD_SOURCE_SUCCESS';

export const FETCH_CREATE_LEAD_SOURCE = 'FETCH_CREATE_LEAD_SOURCE';
export const FETCH_CREATE_LEAD_SOURCE_FAILED = 'FETCH_CREATE_LEAD_SOURCE_FAILED';
export const FETCH_CREATE_LEAD_SOURCE_SUCCESS = 'FETCH_CREATE_LEAD_SOURCE_SUCCESS';

export const FETCH_EDIT_LEAD_SOURCE = 'FETCH_EDIT_LEAD_SOURCE';
export const FETCH_EDIT_LEAD_SOURCE_FAILED = 'FETCH_EDIT_LEAD_SOURCE_FAILED';
export const FETCH_EDIT_LEAD_SOURCE_SUCCESS = 'FETCH_EDIT_LEAD_SOURCE_SUCCESS';

export const FETCH_DELETE_LEAD_SOURCE = 'FETCH_DELETE_LEAD_SOURCE';
export const FETCH_DELETE_LEAD_SOURCE_FAILED = 'FETCH_ DELETE_LEAD_SOURCE_FAILED';
export const FETCH_DELETE_LEAD_SOURCE_SUCCESS = 'FETCH_DELETE_LEAD_SOURCE_SUCCESS';

export const FETCH_LIST_LEAD_STATUS = 'FETCH_LIST_LEAD_STATUS';
export const FETCH_LIST_LEAD_STATUS_FAILED = 'FETCH_LIST_LEAD_STATUS_FAILED';
export const FETCH_LIST_LEAD_STATUS_SUCCESS = 'FETCH_LIST_LEAD_STATUS_SUCCESS';

export const FETCH_CREATE_LEAD_STATUS = 'FETCH_CREATE_LEAD_STATUS';
export const FETCH_CREATE_LEAD_STATUS_FAILED = 'FETCH_CREATE_LEAD_STATUS_FAILED';
export const FETCH_CREATE_LEAD_STATUS_SUCCESS = 'FETCH_CREATE_LEAD_STATUS_SUCCESS';

export const FETCH_EDIT_LEAD_STATUS = 'FETCH_EDIT_LEAD_STATUS';
export const FETCH_EDIT_LEAD_STATUS_FAILED = 'FETCH_EDIT_LEAD_STATUS_FAILED';
export const FETCH_EDIT_LEAD_STATUS_SUCCESS = 'FETCH_EDIT_LEAD_STATUS_SUCCESS';

export const FETCH_DELETE_LEAD_STATUS = 'FETCH_DELETE_LEAD_STATUS';
export const FETCH_DELETE_LEAD_STATUS_FAILED = 'FETCH_ DELETE_LEAD_STATUS_FAILED';
export const FETCH_DELETE_LEAD_STATUS_SUCCESS = 'FETCH_DELETE_LEAD_STATUS_SUCCESS';

export const FETCH_LEAD_EMAIL_INTEGRATION = 'FETCH_LEAD_EMAIL_INTEGRATION';
export const FETCH_LEAD_EMAIL_INTEGRATION_FAILED = 'FETCH_LEAD_EMAIL_INTEGRATION_FAILED';
export const FETCH_LEAD_EMAIL_INTEGRATION_SUCCESS = 'FETCH_LEAD_EMAIL_INTEGRATION_SUCCESS';

export const FETCH_CREATE_LEAD_EMAIL_INTEGRATION = 'FETCH_CREATE_LEAD_EMAIL_INTEGRATION';
export const FETCH_CREATE_LEAD_EMAIL_INTEGRATION_FAILED = 'FETCH_CREATE_LEAD_EMAIL_INTEGRATION_FAILED';
export const FETCH_CREATE_LEAD_EMAIL_INTEGRATION_SUCCESS = 'FETCH_CREATE_LEAD_EMAIL_INTEGRATION_SUCCESS';

export const FETCH_EDIT_LEAD_EMAIL_INTEGRATION = 'FETCH_EDIT_LEAD_EMAIL_INTEGRATION';
export const FETCH_EDIT_LEAD_EMAIL_INTEGRATION_FAILED = 'FETCH_EDIT_LEAD_EMAIL_INTEGRATION_FAILED';
export const FETCH_EDIT_LEAD_EMAIL_INTEGRATION_SUCCESS = 'FETCH_EDIT_LEAD_EMAIL_INTEGRATION_SUCCESS';

export const FETCH_DELETE_LEAD_EMAIL_INTEGRATION = 'FETCH_DELETE_LEAD_EMAIL_INTEGRATION';
export const FETCH_DELETE_LEAD_EMAIL_INTEGRATION_FAILED = 'FETCH_ DELETE_LEAD_EMAIL_INTEGRATION_FAILED';
export const FETCH_DELETE_LEAD_EMAIL_INTEGRATION_SUCCESS = 'FETCH_DELETE_LEAD_EMAIL_INTEGRATION_SUCCESS';

export const FETCH_LIST_RESPONSIBLE = 'FETCH_LIST_RESPONSIBLE';
export const FETCH_LIST_RESPONSIBLE_FAILED = 'FETCH_LIST_RESPONSIBLE_FAILED';
export const FETCH_LIST_RESPONSIBLE_SUCCESS = 'FETCH_LIST_RESPONSIBLE_SUCCESS';

export const FETCH_LIST_WEB_LEAD = 'FETCH_LIST_WEB_LEAD';
export const FETCH_LIST_WEB_LEAD_FAILED = 'FETCH_LIST_WEB_LEAD_FAILED';
export const FETCH_LIST_WEB_LEAD_SUCCESS = 'FETCH_LIST_WEB_LEAD_SUCCESS';

export const FETCH_WEB_LEAD_BY_ID = 'FETCH_WEB_LEAD_BY_ID';
export const FETCH_WEB_LEAD_BY_ID_FAILED = 'FETCH_WEB_LEAD_BY_ID_FAILED';
export const FETCH_WEB_LEAD_BY_ID_SUCCESS = 'FETCH_WEB_LEAD_BY_ID_SUCCESS';

export const FETCH_CREATE_WEB_LEAD = 'FETCH_CREATE_WEB_LEAD';
export const FETCH_CREATE_WEB_LEAD_FAILED = 'FETCH_CREATE_WEB_LEAD_FAILED';
export const FETCH_CREATE_WEB_LEAD_SUCCESS = 'FETCH_CREATE_WEB_LEAD_SUCCESS';

export const FETCH_EDIT_WEB_LEAD = 'FETCH_EDIT_WEB_LEAD';
export const FETCH_EDIT_WEB_LEAD_FAILED = 'FETCH_EDIT_WEB_LEAD_FAILED';
export const FETCH_EDIT_WEB_LEAD_SUCCESS = 'FETCH_EDIT_WEB_LEAD_SUCCESS';

export const FETCH_DELETE_WEB_LEAD = 'FETCH_DELETE_WEB_LEAD';
export const FETCH_DELETE_WEB_LEAD_FAILED = 'FETCH_ DELETE_WEB_LEAD_FAILED';
export const FETCH_DELETE_WEB_LEAD_SUCCESS = 'FETCH_DELETE_WEB_LEAD_SUCCESS';

export const SET_ISSUCCESS = 'SET_ISSUCCESS';

export const SET_LEAD_SOURCE = 'SET_LEAD_SOURCE';

export const SET_WEB_LEAD = 'SET_WEB_LEAD';


export const GET_ALL_ADMIN_LEADS_SPAM_FILTER_REQUEST = 'GET_ALL_ADMIN_LEADS_SPAM_FILTER_REQUEST';
export const GET_ALL_ADMIN_LEADS_SPAM_FILTER_FAILED = 'GET_ALL_ADMIN_LEADS_SPAM_FILTER_FAILED';
export const GET_ALL_ADMIN_LEADS_SPAM_FILTER_SUCCESS = 'GET_ALL_ADMIN_LEADS_SPAM_FILTER_SUCCESS';

export const CREATE_ADMIN_LEADS_SPAM_FILTER_REQUEST = 'CREATE_ADMIN_LEADS_SPAM_FILTER_REQUEST';
export const CREATE_ADMIN_LEADS_SPAM_FILTER_FAILED = 'CREATE_ADMIN_LEADS_SPAM_FILTER_FAILED';
export const CREATE_ADMIN_LEADS_SPAM_FILTER_SUCCESS = 'CREATE_ADMIN_LEADS_SPAM_FILTER_SUCCESS';
export const EDIT_ADMIN_LEADS_SPAM_FILTER_REQUEST = 'EDIT_ADMIN_LEADS_SPAM_FILTER_REQUEST';
export const EDIT_ADMIN_LEADS_SPAM_FILTER_FAILED = 'EDIT_ADMIN_LEADS_SPAM_FILTER_FAILED';
export const EDIT_ADMIN_LEADS_SPAM_FILTER_SUCCESS = 'EDIT_ADMIN_LEADS_SPAM_FILTER_SUCCESS';
export const DELETE_ADMIN_LEADS_SPAM_FILTER_REQUEST = 'DELETE_ADMIN_LEADS_SPAM_FILTER_REQUEST';
export const DELETE_ADMIN_LEADS_SPAM_FILTER_FAILED = 'DELETE_ADMIN_LEADS_SPAM_FILTER_FAILED';
export const DELETE_ADMIN_LEADS_SPAM_FILTER_SUCCESS = 'DELETE_ADMIN_LEADS_SPAM_FILTER_SUCCESS';
export const FETCH_ADMIN_LEADS_SPAM_FILTER_REQUEST = 'FETCH_ADMIN_LEADS_SPAM_FILTER_REQUEST';
export const FETCH_ADMIN_LEADS_SPAM_FILTER_FAILED = 'FETCH_ADMIN_LEADS_SPAM_FILTER_FAILED';
export const FETCH_ADMIN_LEADS_SPAM_FILTER_SUCCESS = 'FETCH_ADMIN_LEADS_SPAM_FILTER_SUCCESS';
export const CHANGE_STATUS_DIALOG = 'CHANGE_STATUS_DIALOG';

export const GET_COUNT_LEADS_SOURCE_REQUEST = 'GET_COUNT_LEADS_SOURCE_REQUEST';
export const GET_COUNT_LEADS_SOURCE_FAILED = 'GET_COUNT_LEADS_SOURCE_FAILED';
export const GET_COUNT_LEADS_SOURCE_SUCCESS = 'GET_COUNT_LEADS_SOURCE_SUCCESS';

export const GET_COUNT_LEADS_STATUS_REQUEST = 'GET_COUNT_LEADS_STATUS_REQUEST';
export const GET_COUNT_LEADS_STATUS_FAILED = 'GET_COUNT_LEADS_STATUS_FAILED';
export const GET_COUNT_LEADS_STATUS_SUCCESS = 'GET_COUNT_LEADS_STATUS_SUCCESS';