import {
    ADD_TICKET_NOTE_FAILURE,
    ADD_TICKET_NOTE_PENDING,
    ADD_TICKET_NOTE_SUCCESS,
    ADD_TICKET_REMINDER_FAILURE,
    ADD_TICKET_REMINDER_PENDING,
    ADD_TICKET_REMINDER_SUCCESS,
    FETCH_CONTACT_TICKETS_FAILURE,
    FETCH_CONTACT_TICKETS_PENDING,
    FETCH_CONTACT_TICKETS_SUCCESS,
    FETCH_DEPARTMENT_TICKETS_FAILURE,
    FETCH_DEPARTMENT_TICKETS_PENDING,
    FETCH_DEPARTMENT_TICKETS_SUCCESS,
    FETCH_LIST_TICKETS_FAILURE,
    FETCH_LIST_TICKETS_PENDING,
    FETCH_LIST_TICKETS_SUCCESS,
    FETCH_REMINDER_TICKET_FAILURE,
    FETCH_REMINDER_TICKET_PENDING,
    FETCH_REMINDER_TICKET_SUCCESS,
    FETCH_TASKS_TICKET_FAILURE,
    FETCH_TASKS_TICKET_PENDING,
    FETCH_TASKS_TICKET_SUCCESS,
    FETCH_TICKET_SUMMARY_FAILURE,
    FETCH_TICKET_SUMMARY_PENDING,
    FETCH_TICKET_SUMMARY_SUCCESS,
    FILTER_TASK_IN_TICKET_FAILURE,
    FILTER_TASK_IN_TICKET_PENDING,
    FILTER_TASK_IN_TICKET_SUCCESS,
    GET_TICKET_NOTE_FAILURE,
    GET_TICKET_NOTE_PENDING,
    GET_TICKET_NOTE_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
    ticketSummary: [],
    listTickets: [],
    listContacts: [],
    listDepartment: [],
    listTicketTasks: [],
    listStaffsOfProject: [],
    listTicketReminder: [],
    listNotes: { data: [], total: 0 },
};
const SupportReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_TICKET_SUMMARY_PENDING:
            return {
                ...state,
                isLoading: true,
                // isCloseDialog: false,
                // isSuccess: false,
            };

        case FETCH_TICKET_SUMMARY_SUCCESS:
            if (!action.payload.data) {
                state.ticketSummary = { data: action.payload };
                return {
                    ...state,
                    ticketSummary: state.ticketSummary,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    ticketSummary: action.payload,
                    isLoading: false,
                };
            }

        case FETCH_TICKET_SUMMARY_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case FETCH_LIST_TICKETS_PENDING:
            return {
                ...state,
                isLoading: true,
                // isCloseDialog: false,
                // isSuccess: false,
            };

        case FETCH_LIST_TICKETS_SUCCESS:
            if (!action.payload.data) {
                state.listTickets = action.payload;
                return {
                    ...state,
                    listTickets: state.listTickets,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    listTickets: action.payload,
                    isLoading: false,
                };
            }

        case FETCH_LIST_TICKETS_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        // DEPARTMENT
        case FETCH_DEPARTMENT_TICKETS_PENDING:
            return {
                ...state,
                isLoading: true,
                // isCloseDialog: false,
                // isSuccess: false,
            };

        case FETCH_DEPARTMENT_TICKETS_SUCCESS:
            if (!action.payload.data) {
                return {
                    ...state,
                    listDepartment: action.payload,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    listDepartment: action.payload,
                    isLoading: false,
                };
            }
        // break;
        case FETCH_DEPARTMENT_TICKETS_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case FETCH_CONTACT_TICKETS_PENDING:
            return {
                ...state,
                isLoading: true,
                // isCloseDialog: false,
                // isSuccess: false,
            };

        case FETCH_CONTACT_TICKETS_SUCCESS:
            if (!action.payload.data) {
                return {
                    ...state,
                    listContacts: action.payload,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    listContacts: action.payload,
                    isLoading: false,
                };
            }
        // break;
        case FETCH_CONTACT_TICKETS_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case FETCH_TASKS_TICKET_PENDING:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_TASKS_TICKET_SUCCESS:
            if (!action.payload.data) {
                return {
                    ...state,
                    listTicketTasks: action.payload,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    listTicketTasks: action.payload,
                    isLoading: false,
                };
            }
        // break;
        case FETCH_TASKS_TICKET_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case FETCH_REMINDER_TICKET_PENDING:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_REMINDER_TICKET_SUCCESS:
            if (!action.payload.data) {
                return {
                    ...state,
                    listTicketReminder: action.payload,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    listTicketReminder: action.payload,
                    isLoading: false,
                };
            }
        // break;
        case FETCH_REMINDER_TICKET_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case ADD_TICKET_REMINDER_PENDING:
            return {
                ...state,
                isLoading: true,
            };

        case ADD_TICKET_REMINDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case ADD_TICKET_REMINDER_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case GET_TICKET_NOTE_PENDING:
            return {
                ...state,
                isLoading: true,
            };

        case GET_TICKET_NOTE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listNotes: action.payload,
            };

        case GET_TICKET_NOTE_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case ADD_TICKET_NOTE_PENDING:
            return {
                ...state,
                isLoading: true,
            };

        case ADD_TICKET_NOTE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case ADD_TICKET_NOTE_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case FILTER_TASK_IN_TICKET_PENDING:
            return {
                ...state,
                isLoading: true,
            };

        case FILTER_TASK_IN_TICKET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listTicketTasks: action.payload,
            };

        case FILTER_TASK_IN_TICKET_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
};
export default SupportReducer;
