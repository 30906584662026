import _ from "lodash";
import apiBase from "../../../common/baseAPI";
const TICKET_SUMMARY_URL = "ticket/count";
const LIST_TICKETS_URL = "ticket";
const DEPARTMENT_URL = "department";
const CONTACT_URL = "contact";
const getTicketSummary = () => {
    let newUrl = TICKET_SUMMARY_URL;
    return new Promise((resolve, reject) => {
        return apiBase
            .get(newUrl)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const getListTickets = (data) => {
    const newData = _.pickBy(data, _.identity);
    let newUrl = LIST_TICKETS_URL;
    return new Promise((resolve, reject) => {
        return apiBase
            .get(newUrl, newData)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

const getTicketContact = () => {
    let newUrl = CONTACT_URL;
    return new Promise((resolve, reject) => {
        return apiBase
            .get(newUrl)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const getTicketDepartment = () => {
    let newUrl = DEPARTMENT_URL;
    return new Promise((resolve, reject) => {
        return apiBase
            .get(newUrl)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
const getTasksTicket = ({ ticketId, params }) => {
    return new Promise((resolve, reject) => {
        return (
            apiBase
                // api/task/ticket/{ticket_id}?limit=15&&page=1&&seach=
                .get(`/task/ticket/${ticketId}`, params)
                .then((res) => resolve(res))
                .catch((err) => reject(err))
        );
    });
};
const getReminderTicket = ({ ticketId, params }) => {
    return new Promise((resolve, reject) => {
        return (
            apiBase
                // api/reminder/ticket/{ticket_id}?limit=15&&page=1&&seach=
                .get(`/reminder/ticket/${ticketId}`, params)
                .then((res) => resolve(res))
                .catch((err) => reject(err))
        );
    });
};
const postTicketReminder = ({ ticketId, body }) => {
    return new Promise((resolve, reject) => {
        return (
            apiBase
                // api/reminder/ticket/{ticket_id}
                .post(`/reminder/ticket/${ticketId}`, body)
                .then((res) => resolve(res))
                .catch((err) => reject(err))
        );
    });
};
const getTicketNote = ({ ticketId, params }) => {
    return new Promise((resolve, reject) => {
        return (
            apiBase
                // note/ticket/{ticket_id}
                .get(`/note/ticket/${ticketId}`, { params: params })
                .then((res) => resolve(res))
                .catch((err) => reject(err))
        );
    });
};
const postTicketNote = ({ ticketId, body }) => {
    return new Promise((resolve, reject) => {
        return (
            apiBase
                // note/ticket/{ticket_id}
                // body{
                //        "description": "abc"
                //    }
                .post(`/note/ticket/${ticketId}`, body)
                .then((res) => resolve(res))
                .catch((err) => reject(err))
        );
    });
};
const filterTaskInTicket = ({ ticketId, body }) => {
    return new Promise((resolve, reject) => {
        return (
            apiBase
                // api/task/filter/ticket/{ticket_id}
                // body{
                //        "description": "abc"
                //    }
                .post(`/task/filter/ticket/${ticketId}`, body)
                .then((res) => resolve(res))
                .catch((err) => reject(err))
        );
    });
};
const createTicket = (payload) => {
    const newFormData = new FormData();

    newFormData.append("ticketkey", payload.ticketkey);
    newFormData.append(" adminreplying", payload.adminreplying);
    newFormData.append("message", payload.message);
    newFormData.append("admin", payload.admin);
    newFormData.append("astreply", payload.astreply);
    newFormData.append("clientread", payload.clientread);
    newFormData.append("adminread", payload.adminread);
   
    if (payload.tag) {
        payload.tag.map((item, index) => {
            newFormData.append(`tag[${index}][name]`, item.name);
            newFormData.append(`tag[${index}][tag_order]`, item.tag_order);
            if (item.id) {
                newFormData.append(`tag[${index}][id]`, item.id);
            }
            return item;
        });
    }

    if (payload.file_name) {
        payload.file_name.map((item, index) => {
            newFormData.append(`file_name[${index}]`, item.file);
            return item;
        });
    }
    // newFormData.append(`file_name`, JSON.stringify(payload.file_name["file"]));

    const header = { "Content-Type": "multipart/form-data" };
    return new Promise((resolve, reject) => {
        return apiBase
            .post(`ticket`, newFormData, header)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
export {
    filterTaskInTicket,
    getTicketNote,
    postTicketNote,
    getReminderTicket,
    getTicketSummary,
    getListTickets,
    getTicketContact,
    getTasksTicket,
    getTicketDepartment,
    postTicketReminder,
};
