import ActionTypes from "./types";

export const getListSupportTicketStatusRequest = () => {
    return {
        type: ActionTypes.GET_LIST_SUPPORT_TICKET_STATUS_REQUEST,
    };
};

export const getListSupportTicketStatusSuccess = (data) => {
    return {
        type: ActionTypes.GET_LIST_SUPPORT_TICKET_STATUS_SUCCESS,
        payload: data,
    };
};

export const getListSupportTicketStatusFailed = () => {
    return {
        type: ActionTypes.GET_LIST_SUPPORT_TICKET_STATUS_FAILED,
    };
};

export const createSupportTicketStatusRequest = (data) => {
    return {
        type: ActionTypes.CREATE_SUPPORT_TICKET_STATUS_REQUEST,
        payload: data,
    };
};

export const createSupportTicketStatusSuccess = (data) => {
    return {
        type: ActionTypes.CREATE_SUPPORT_TICKET_STATUS_SUCCESS,
        payload: data,
    };
};

export const createSupportTicketStatusFailed = () => {
    return {
        type: ActionTypes.CREATE_SUPPORT_TICKET_STATUS_FAILED,
    };
};

export const editSupportTicketStatusRequest = (data) => {
    return {
        type: ActionTypes.EDIT_SUPPORT_TICKET_STATUS_REQUEST,
        payload: data,
    };
};

export const editSupportTicketStatusSuccess = (data) => {
    return {
        type: ActionTypes.EDIT_SUPPORT_TICKET_STATUS_SUCCESS,
        payload: data,
    };
};

export const editSupportTicketStatusFailed = () => {
    return {
        type: ActionTypes.EDIT_SUPPORT_TICKET_STATUS_FAILED,
    };
};

export const changeStatusDialog = () => {
    return {
        type: ActionTypes.CHANGE_STATUS_DIALOG,
    };
};

export const fetchSupportTicketStatusRequest = (search, params) => {
    return {
        type: ActionTypes.FETCH_SUPPORT_TICKET_STATUS_REQUEST,
        search,
        params,
    };
};

export const fetchSupportTicketStatusSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_SUPPORT_TICKET_STATUS_SUCCESS,
        payload: data,
    };
};

export const fetchSupportTicketStatusFailed = () => {
    return {
        type: ActionTypes.FETCH_SUPPORT_TICKET_STATUS_FAILED,
    };
};

//GET count total tickets
export const getCountSupportTicketStatusRequest = () => {
    return {
        type: ActionTypes.GET_COUNT_SUPPORT_TICKET_STATUS_REQUEST,
    };
};

export const getCountSupportTicketStatusSuccess = (data) => {
    return {
        type: ActionTypes.GET_COUNT_SUPPORT_TICKET_STATUS_SUCCESS,
        payload: data,
    };
};

export const getCountSupportTicketStatusFailed = () => {
    return {
        type: ActionTypes.GET_COUNT_SUPPORT_TICKET_STATUS_FAILED,
    };
};

//delete status ticket
export const deleteSupportTicketStatusRequest = (id) => {
    return {
        type: ActionTypes.DELETE_SUPPORT_TICKET_STATUS_REQUEST,
        payload: { id: id },
    };
};

export const deleteSupportTicketStatusSuccess = () => {
    return {
        type: ActionTypes.DELETE_SUPPORT_TICKET_STATUS_SUCCESS,
    };
};

export const deleteSupportTicketStatusFailed = () => {
    return {
        type: ActionTypes.DELETE_SUPPORT_TICKET_STATUS_FAILED,
    };
};
