import ActionTypes from "./type";

export const addExpensesCustomerRequest = (id, data) => {
  return {
    type: ActionTypes.ADD_EXPENSES_CUSTOMER_REQUEST,
    id,
    data,
  };
};

export const addExpensesCustomerSuccess = (data) => {
  return {
    type: ActionTypes.ADD_EXPENSES_CUSTOMER_SUCCESS,
    data,
  };
};

export const addExpensesCustomerFailed = () => {
  return {
    type: ActionTypes.ADD_EXPENSES_CUSTOMER_FAILED,
  };
};
