/* AUTH */
export const FETCH_LIST_LEADS = "FETCH_LIST_LEADS";
export const FETCH_LIST_LEADS_FAILED = "FETCH_LIST_LEADS_FAILED";
export const FETCH_LIST_LEADS_SUCCESS = "FETCH_LIST_LEADS_SUCCESS";

export const GET_LIST_CURRENCY = "GET_LIST_CURRENCY";
export const GET_LIST_CURRENCY_FAILED = "GET_LIST_CURRENCY_FAILED";
export const GET_LIST_CURRENCY_SUCCESS = "GET_LIST_CURRENCY_SUCCESS";

export const GET_TASK_BY_LEAD = "GET_TASK_BY_LEAD";
export const GET_TASK_BY_LEAD_SUCCESS = "GET_TASK_BY_LEAD_SUCCESS";
export const GET_TASK_BY_LEAD_FAILED = "GET_TASK_BY_LEAD_FAILED";

export const DELETE_LEAD = "DELETE_LEAD";
export const DELETE_LEAD_FAILED = "DELETE_LEAD_FAILED";
export const DELETE_LEAD_SUCCESS = "DELETE_LEAD_SUCCESS";

export const FETCH_LIST_STATUS_LEADS = "FETCH_LIST_STATUS_LEADS";
export const FETCH_LIST_STATUS_LEADS_SUCCESS =
    "FETCH_LIST_STATUS_LEADS_SUCCESS";
export const FETCH_LIST_STATUS_LEADS_FAILED = "FETCH_LIST_STATUS_LEADS_FAILED";

export const UPDATE_STATUS_LEAD = "UPDATE_STATUS_LEAD";
export const UPDATE_STATUS_LEAD_SUCCESS = "UPDATE_STATUS_LEAD_SUCCESS";
export const UPDATE_STATUS_LEAD_FAILED = "UPDATE_STATUS_LEAD_FAILED";

export const GET_LIST_CREATED_BY = "GET_LIST_CREATED_BY";
export const GET_LIST_CREATED_BY_SUCCESS = "GET_LIST_CREATED_BY_SUCCESS";
export const GET_LIST_CREATED_BY_FAILED = "GET_LIST_CREATED_BY_FAILED";

export const GET_LIST_SOURCE = "GET_LIST_SOURCE";
export const GET_LIST_SOURCE_SUCCESS = "GET_LIST_SOURCE_SUCCESS";
export const GET_LIST_SOURCE_FAILED = "GET_LIST_SOURCE_FAILED";

export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

export const CREATE_NEW_LEAD = "CREATE_NEW_LEAD";
export const CREATE_NEW_LEAD_SUCCESS = "CREATE_NEW_LEAD_SUCCESS";
export const CREATE_NEW_LEAD_FAILED = "CREATE_NEW_LEAD_FAILED";

export const GET_LIST_TAGS_IN_LEAD = "GET_LIST_TAGS_IN_LEAD";
export const GET_LIST_TAGS_IN_LEAD_SUCCESS = "GET_LIST_TAGS_IN_LEAD_SUCCESS";
export const GET_LIST_TAGS_IN_LEAD_FAILED = "GET_LIST_TAGS_IN_LEAD_FAILED";

export const GET_LIST_COUNTRY = "GET_LIST_COUNTRY";
export const GET_LIST_COUNTRY_SUCCESS = "GET_LIST_COUNTRY_SUCCESS";
export const GET_LIST_COUNTRY_FAILED = "GET_LIST_COUNTRY_FAILED";

export const UPDATE_IS_CREATE_SUCCESS = "UPDATE_IS_CREATE_SUCCESS";

export const UPDATE_LEAD = "UPDATE_LEAD";
export const UPDATE_LEAD_SUCCESS = "UPDATE_LEAD_SUCCESS";
export const UPDATE_LEAD_FAILED = "UPDATE_LEAD_FAILED";

export const GET_LIST_PROPOSAL = "GET_LIST_PROPOSAL";
export const GET_LIST_PROPOSAL_SUCCESS = "GET_LIST_PROPOSAL_SUCCESS";
export const GET_LIST_PROPOSAL_FAILED = "GET_LIST_PROPOSAL_FAILED";

export const GET_LIST_PROPOSAL_BY_LEAD = "GET_LIST_PROPOSAL_BY_LEAD";
export const GET_LIST_PROPOSAL_BY_LEAD_SUCCESS =
    "GET_LIST_PROPOSAL_BY_LEAD_SUCCESS";
export const GET_LIST_PROPOSAL_BY_LEAD_FAILED =
    "GET_LIST_PROPOSAL_BY_LEAD_FAILED";

export const CREATE_PROPOSAL = "CREATE_PROPOSAL";
export const CREATE_PROPOSAL_SUCCESS = "CREATE_PROPOSAL_SUCCESS";
export const CREATE_PROPOSAL_FAILED = "CREATE_PROPOSAL_FAILED";

export const SET_IS_NO_CONTACT = "SET_IS_NO_CONTACT";
export const SET_IS_NO_CONTACT_SUCCESS = "SET_IS_NO_CONTACT_SUCCESS";
export const SET_IS_NO_CONTACT_FAILED = "SET_IS_NO_CONTACT_FAILED";

export const SET_IS_TRASH = "SET_IS_TRASH";
export const SET_IS_TRASH_SUCCESS = "SET_IS_TRASH_SUCCESS";
export const SET_IS_TRASH_FAILED = "SET_IS_TRASH_FAILED";

export const SWITCH_TO_CUSTOMER = "SWITCH_TO_CUSTOMER";
export const SWITCH_TO_CUSTOMER_SUCCESS = "SWITCH_TO_CUSTOMER_SUCCESS";
export const SWITCH_TO_CUSTOMER_FAILED = "SWITCH_TO_CUSTOMER_FAILED";

export const CREATE_TASK = "CREATE_TASK";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const CREATE_TASK_FAILED = "CREATE_TASK_FAILED";

export const SAVE_ACTIVITY = "SAVE_ACTIVITY";
export const SAVE_ACTIVITY_SUCCESS = "SAVE_ACTIVITY_SUCCESS";
export const SAVE_ACTIVITY_FAILED = "SAVE_ACTIVITY_FAILED";

export const GET_LIST_ITEMS = "GET_LIST_ITEMS";
export const GET_LIST_ITEMS_SUCCESS = "GET_LIST_ITEMS_SUCCESS";
export const GET_LIST_ITEMS_FAILED = "GET_LIST_ITEMS_FAILED";

export const GET_LIST_GROUP_ITEMS = "GET_LIST_GROUP_ITEMS";
export const GET_LIST_GROUP_ITEMS_SUCCESS = "GET_LIST_GROUP_ITEMS_SUCCESS";
export const GET_LIST_GROUP_ITEMS_FAILED = "GET_LIST_GROUP_ITEMS_FAILED";

// Fetch lisst Proposal module Lead (lead lớn)
export const FETCH_LIST_PROPOSAL_LEAD = "FETCH_LIST_PROPOSAL_LEAD";
export const FETCH_LIST_PROPOSAL_LEAD_SUCCESS =
    "FETCH_LIST_PROPOSAL_LEAD_SUCCESS";
export const FETCH_LIST_PROPOSAL_LEAD_FAILED =
    "FETCH_LIST_PROPOSAL_LEAD_FAILED";

export const FETCH_LIST_STAFF = "FETCH_LIST_STAFF";
export const FETCH_LIST_STAFF_SUCCESS = "FETCH_LIST_STAFF_SUCCESS";
export const FETCH_LIST_STAFF_FAILED = "FETCH_LIST_STAFF_FAILED";

export const FETCH_LIST_REMINDER_LEAD = "FETCH_LIST_REMINDER_LEAD";
export const FETCH_LIST_REMINDER_LEAD_SUCCESS =
    "FETCH_LIST_REMINDER_LEAD_SUCCESS";
export const FETCH_LIST_REMINDER_LEAD_FAILED = "CREATE_REMINDER_LEAD_FAILED";

export const CREATE_REMINDER_LEAD = "CREATE_REMINDER_LEAD";
export const CREATE_REMINDER_LEAD_SUCCESS = "CREATE_REMINDER_LEAD_SUCCESS";
export const CREATE_REMINDER_LEAD_FAILED = "CREATE_REMINDER_LEAD_FAILED";

export const DELETE_REMINDER_LEAD = "DELETE_REMINDER_LEAD";
export const DELETE_REMINDER_LEAD_SUCCESS = "DELETE_REMINDER_LEAD_SUCCESS";
export const DELETE_REMINDER_LEAD_FAILED = "DELETE_REMINDER_LEAD_FAILED";

export const UPDATE_REMINDER_LEAD = "UPDATE_REMINDER_LEAD";
export const UPDATE_REMINDER_LEAD_SUCCESS = "UPDATE_REMINDER_LEAD_SUCCESS";
export const UPDATE_REMINDER_LEAD_FAILED = "UPDATE_REMINDER_LEAD_FAILED";

export const FETCH_NOTE_LEAD = "FETCH_NOTE_LEAD";
export const FETCH_NOTE_LEAD_SUCCESS = "FETCH_NOTE_LEAD_SUCCESS";
export const FETCH_NOTE_LEAD_FAILED = "FETCH_NOTE_LEAD_FAILED";

export const CREATE_NOTE_LEAD = "CREATE_NOTE_LEAD";
export const CREATE_NOTE_LEAD_SUCCESS = "CREATE_NOTE_LEAD_SUCCESS";
export const CREATE_NOTE_LEAD_FAILED = "CREATE_NOTE_LEAD_FAILED";

export const DELETE_NOTE_LEAD = "DELETE_NOTE_LEAD";
export const DELETE_NOTE_LEAD_SUCCESS = "DELETE_NOTE_LEAD_SUCCESS";
export const DELETE_NOTE_LEAD_FAILED = "DELETE_NOTE_LEAD_FAILED";

export const UPDATE_NOTE_LEAD = "UPDATE_NOTE_LEAD";
export const UPDATE_NOTE_LEAD_SUCCESS = "UPDATE_NOTE_LEAD_SUCCESS";
export const UPDATE_NOTE_LEAD_FAILED = "UPDATE_NOTE_LEAD_FAILED";

export const FETCH_LIST_ATTACHMENT_LEAD = "FETCH_LIST_ATTACHMENT_LEAD";
export const FETCH_LIST_ATTACHMENT_LEAD_SUCCESS =
    "FETCH_LIST_ATTACHMENT_LEAD_SUCCESS";
export const FETCH_LIST_ATTACHMENT_LEAD_FAILED = "FETCH_LIST_NOTE_LEAD_FAILED";

export const CREATE_ATTACHMENT_LEAD = "CREATE_ATTACHMENT_LEAD";
export const CREATE_ATTACHMENT_LEAD_SUCCESS = "CREATE_ATTACHMENT_LEAD_SUCCESS";
export const CREATE_ATTACHMENT_LEAD_FAILED = "CREATE_ATTACHMENT_LEAD_FAILED";

export const DELETE_ATTACHMENT_LEAD = "DELETE_ATTACHMENT_LEAD";
export const DELETE_ATTACHMENT_LEAD_SUCCESS = "DELETE_ATTACHMENT_LEAD_SUCCESS";
export const DELETE_ATTACHMENT_LEAD_FAILED = "DELETE_ATTACHMENT_LEAD_FAILED";

export const FETCH_LIST_TASK_LEAD = "FETCH_LIST_TASK_LEAD";
export const FETCH_LIST_TASK_LEAD_SUCCESS = "FETCH_LIST_TASK_LEAD_SUCCESS";
export const FETCH_LIST_TASK_LEAD_FAILED = "FETCH_LIST_TASK_LEAD_FAILED";

export const CONVERT_TO_CUSTOMER_LEAD_PENDING =
    "CONVERT_TO_CUSTOMER_LEAD_PENDING";
export const CONVERT_TO_CUSTOMER_LEAD_SUCCESS =
    "CONVERT_TO_CUSTOMER_LEAD_SUCCESS";
export const CONVERT_TO_CUSTOMER_LEAD_FAILED =
    "CONVERT_TO_CUSTOMER_LEAD_FAILED";

export const GET_CONVERT_TO_CUSTOMER = "GET_CONVERT_TO_CUSTOMER";
