import ActionTypes from "./types";

const INIT_STATE = {
  listCustomField: [],
  isLoading: false,
  isLoadingEdit: false,
  statusDeleteSuccess: false,
  itemSelect: {},
};

const CustomFieldsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_LIST_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_LIST_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        listCustomField: action.payload,
        isLoading: false,
      };
    case ActionTypes.FETCH_LIST_CUSTOM_FIELD_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.EDIT_ACTIVE_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.EDIT_ACTIVE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.EDIT_ACTIVE_CUSTOM_FIELD_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.DELETE_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.DELETE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        statusDeleteSuccess: !state.statusDeleteSuccess,
      };
    case ActionTypes.DELETE_CUSTOM_FIELD_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.GET_ITEM_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        isLoadingEdit: true,
      };
    case ActionTypes.GET_ITEM_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        isLoadingEdit: false,
        itemSelect: action.item,
      };
    case ActionTypes.GET_ITEM_CUSTOM_FIELD_FAILED:
      return {
        ...state,
        isLoadingEdit: false,
      };
    case ActionTypes.EDIT_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        isLoadingEdit: true,
      };
    case ActionTypes.EDIT_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        isLoadingEdit: false,
        itemSelect: action.item,
      };
    case ActionTypes.EDIT_CUSTOM_FIELD_FAILED:
      return {
        ...state,
        isLoadingEdit: false,
      };

    default:
      return { ...state };
  }
};

export default CustomFieldsReducer;
