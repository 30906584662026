//fetch estimate
export const FETCH_ESTIMATES_PROJECT = 'FETCH_ESTIMATES_PROJECT';
export const FETCH_ESTIMATES_PROJECT_SUCCESS = 'FETCH_ESTIMATES_PROJECT_SUCCESS';
export const FETCH_ESTIMATES_PROJECT_FAILED = 'FETCH_ESTIMATES_PROJECT_FAILED';

// filter year with estimate

export const FETCH_FILTER_YEAR_ESTIMATE_PRJ = 'FETCH_FILTER_YEAR_ESTIMATE_PRJ';
export const FETCH_FILTER_YEAR_ESTIMATE_PRJ_SUCCESS = 'FETCH_FILTER_YEAR_ESTIMATE_PRJ_SUCCESS';
export const FETCH_FILTER_YEAR_ESTIMATE_PRJ_FAILED = 'FETCH_FILTER_YEAR_ESTIMATE_PRJ_FAILED';

// get total estimate

export const FETCH_TOTAL_ESTIMATE_PRJ = 'FETCH_TOTAL_ESTIMATE_PRJ';
export const FETCH_TOTAL_ESTIMATE_PRJ_SUCCESS = 'FETCH_TOTAL_ESTIMATE_PRJ_SUCCESS';
export const FETCH_TOTAL_ESTIMATE_PRJ_FAILED = 'FETCH_TOTAL_ESTIMATE_PRJ_FAILED';

//filter estimate

export const FILTER_ESTIMATE = "FILTER_ESTIMATE";
export const FILTER_ESTIMATE_SUCCESS = "FILTER_ESTIMATE_SUCCESS";
export const FILTER_ESTIMATE_FAILED = "FILTER_ESTIMATE_FAILED";