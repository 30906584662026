import ActionTypes from "./type";

const INIT_STATE = {
    isLoading: false,
    listContact: [],
    statusAddSuccess: false,
    statusDeleteSuccess: false,
    statusUpdate: false,
};

const ContactsCustomerReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_CONTACT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.FETCH_CONTACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listContact: action.data,
                statusUpdate: false,
            };
        case ActionTypes.FETCH_CONTACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.EDIT_ACTIVE_CUSTOMER_CONTACT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.EDIT_ACTIVE_CUSTOMER_CONTACT_SUCCESS:
            let dataChangeActive = { ...state.listContact };
            const indexChange = dataChangeActive.data.findIndex(
                (item) => item.id === action.data.id
            );
            dataChangeActive.data.splice(indexChange, 1, action.data);
            return {
                ...state,
                isLoading: false,
                listContact: dataChangeActive,
                statusUpdate: true,
            };
        case ActionTypes.EDIT_ACTIVE_CUSTOMER_CONTACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.ADD_CONTACT_REQUEST:
            return {
                ...state,
            };
        case ActionTypes.ADD_CONTACT_SUCCESS:
            return {
                ...state,
                statusUpdate: true,
            };
        case ActionTypes.ADD_CONTACT_FAILED:
            return {
                ...state,
            };

        case ActionTypes.DELETE_CONTACT_REQUEST:
            return {
                ...state,
            };
        case ActionTypes.DELETE_CONTACT_SUCCESS:
            return {
                ...state,
                statusUpdate: true,
            };
        case ActionTypes.DELETE__CONTACT_FAILED:
            return {
                ...state,
            };
        default:
            return { ...state };
    }
};

export default ContactsCustomerReducer;
