import apiBase from "../../../common/baseAPI";
const PROPOSAL = "proposal";

export const getProposalRequest = (data) => {
    return new Promise((resolve, reject) => {
        return apiBase
            .get(PROPOSAL, data)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
